import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
    Box, 
    Typography, 
    CircularProgress, 
    Paper,
    Breadcrumbs,
    Link,
    Chip,
    Divider,
    IconButton,
    Tooltip,
    Alert
} from '@mui/material';
import { metricsAndViewsApi } from '../../services/metricsAndViewsApi';
import OptimizedSpreadsheet from '../spreadsheets/OptimizedSpreadsheet';
import { useSnackbar } from 'notistack';
import FullScreenLayout from '../layouts/FullScreenLayout';
import RefreshIcon from '@mui/icons-material/Refresh';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

const ViewData = () => {
    const { viewId } = useParams();
    const [loading, setLoading] = useState(true);
    const [viewData, setViewData] = useState(null);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await metricsAndViewsApi.getViewData(viewId);
            setViewData(response);
            enqueueSnackbar('Data refreshed successfully', { variant: 'success' });
        } catch (err) {
            console.error('Error fetching view data:', err);
            setError(err.message);
            enqueueSnackbar('Error loading view data', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [viewId]);

    useEffect(() => {
        if (viewData?.view) {
            // Update recent views in localStorage
            const recentViews = JSON.parse(localStorage.getItem('recentViews') || '[]');
            const updatedRecentViews = [
                viewData.view,
                ...recentViews.filter(v => v.view_id !== viewData.view.view_id)
            ].slice(0, 10); // Keep only last 10 views
            
            localStorage.setItem('recentViews', JSON.stringify(updatedRecentViews));
        }
    }, [viewData?.view]);

    const header = (
        <Box sx={{ mb: 3 }}>
            <Breadcrumbs 
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mb: 2 }}
            >
                <Link 
                    color="inherit" 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/metrics-and-views');
                    }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    Metrics & Views
                </Link>
                <Typography color="text.primary">
                    {viewData?.view?.name || 'Loading...'}
                </Typography>
            </Breadcrumbs>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                        <Typography variant="h4" component="h1">
                            {viewData?.view?.name}
                        </Typography>
                        <IconButton size="small">
                            {viewData?.view?.is_favorite ? (
                                <StarIcon sx={{ color: '#fbbc04' }} />
                            ) : (
                                <StarBorderIcon />
                            )}
                        </IconButton>
                    </Box>
                    {viewData?.view?.description && (
                        <Typography color="text.secondary" variant="body1">
                            {viewData.view.description}
                        </Typography>
                    )}
                </Box>
                <Tooltip title="Refresh data">
                    <IconButton onClick={fetchData} disabled={loading}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {viewData?.metrics && (
                <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {viewData.metrics.map((metric, index) => (
                        <Chip
                            key={index}
                            label={metric.name}
                            variant="outlined"
                            size="small"
                            sx={{ 
                                backgroundColor: '#e8f0fe',
                                borderColor: '#1a73e8',
                                color: '#1a73e8'
                            }}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );

    const mainContent = (
        <Box>
            {error ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            ) : loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                    <CircularProgress />
                </Box>
            ) : !viewData?.data ? (
                <Alert severity="info">
                    No data available for this view
                </Alert>
            ) : (
                <Paper 
                    elevation={0} 
                    sx={{ 
                        height: 'calc(100vh - 250px)', 
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        overflow: 'hidden'
                    }}
                >
                    <OptimizedSpreadsheet
                        data={viewData.data}
                        metrics={viewData.metrics.map(m => m.name)}
                        title={viewData.view.name}
                        readOnly={true}
                        hideZeros={true}
                    />
                </Paper>
            )}
        </Box>
    );

    return (
        <FullScreenLayout
            header={header}
            mainPanel={mainContent}
        />
    );
};

export default ViewData; 