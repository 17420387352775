import React, { useState, useCallback } from 'react';
import DynamicSpreadsheet from './DynamicSpreadsheet';
import { Box, Paper, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { format } from 'date-fns';

function TestSpreadsheet4() {
  // Sample initial data with multiple data types per location
  const [spreadsheetData, setSpreadsheetData] = useState({
    "7": {
      "4": {
        "XR": {
          "Location A": {
            inventory_count: 500,
            minimum_stock: 100,
            maximum_stock: 1000,
            pending_orders: 50
          },
          "Location B": {
            inventory_count: 325,
            minimum_stock: 80,
            maximum_stock: 800,
            pending_orders: 30
          }
        },
        "R": {
          "Location A": {
            inventory_count: 450,
            minimum_stock: 90,
            maximum_stock: 900,
            pending_orders: 40
          },
          "Location B": {
            inventory_count: 275,
            minimum_stock: 70,
            maximum_stock: 700,
            pending_orders: 25
          }
        }
      }
    },
    "8": {
      "4": {
        "XR": {
          "Location A": {
            inventory_count: 600,
            minimum_stock: 120,
            maximum_stock: 1200,
            pending_orders: 60
          },
          "Location B": {
            inventory_count: 425,
            minimum_stock: 100,
            maximum_stock: 1000,
            pending_orders: 40
          }
        },
        "R": {
          "Location A": {
            inventory_count: 550,
            minimum_stock: 110,
            maximum_stock: 1100,
            pending_orders: 55
          },
          "Location B": {
            inventory_count: 375,
            minimum_stock: 90,
            maximum_stock: 900,
            pending_orders: 35
          }
        }
      }
    }
  });

  // State for tracking changes history
  const [changes, setChanges] = useState([]);

  const handleCellValueChanged = useCallback(({ size, brim, shape, location, field, newValue, oldValue }) => {
    // Update spreadsheet data
    setSpreadsheetData(prevData => {
      const newData = JSON.parse(JSON.stringify(prevData));
      if (!newData[size]) newData[size] = {};
      if (!newData[size][brim]) newData[size][brim] = {};
      if (!newData[size][brim][shape]) newData[size][brim][shape] = {};
      if (!newData[size][brim][shape][location]) newData[size][brim][shape][location] = {};
      
      newData[size][brim][shape][location][field] = newValue;
      return newData;
    });

    // Add to changes history
    setChanges(prevChanges => [{
      timestamp: new Date(),
      size,
      brim,
      shape,
      location,
      field,
      oldValue,
      newValue
    }, ...prevChanges]);
  }, []);

  return (
    <Box sx={{ 
      display: 'flex', 
      gap: 2, 
      p: 2,
      height: 'calc(100vh - 100px)'
    }}>
      {/* Main spreadsheet area */}
      <Box sx={{ flex: 1 }}>
        <DynamicSpreadsheet
          data={spreadsheetData}
          title="Dynamic Inventory Management"
          onCellValueChanged={handleCellValueChanged}
        />
      </Box>

      {/* Changes visualization panel */}
      <Paper sx={{ 
        width: 300, 
        p: 2, 
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}>
        <Typography variant="h6" component="h2">
          Changes History
        </Typography>
        <Divider />
        <List dense>
          {changes.map((change, index) => (
            <ListItem key={index} sx={{ 
              flexDirection: 'column', 
              alignItems: 'flex-start',
              bgcolor: index === 0 ? 'action.hover' : 'transparent',
              borderRadius: 1
            }}>
              <Typography variant="caption" color="text.secondary">
                {format(change.timestamp, 'HH:mm:ss')}
              </Typography>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {`${change.size}/${change.brim}/${change.shape}`}
                    <br />
                    {`${change.location} - ${change.field.split('_').join(' ')}`}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {`${change.oldValue} → ${change.newValue}`}
                  </Typography>
                }
              />
              {index !== changes.length - 1 && <Divider flexItem />}
            </ListItem>
          ))}
          {changes.length === 0 && (
            <ListItem>
              <ListItemText 
                secondary="No changes yet. Edit cells to see the history."
                sx={{ textAlign: 'center' }}
              />
            </ListItem>
          )}
        </List>
      </Paper>
    </Box>
  );
}

export default TestSpreadsheet4; 