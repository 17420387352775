import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, Tabs, Tab, Paper } from '@mui/material';

/**
 * MultiValueSpreadsheetV3 expects data in format:
 * {
 *   items: [
 *     {
 *       type: string,
 *       size: string,
 *       brim: string,
 *       shape: string,
 *       values: { [key: string]: number },  // e.g., { available: 10, ordered: 5 }
 *       metadata?: any  // optional metadata per cell
 *     }
 *   ],
 *   valueFields: string[]  // names of value fields to display
 * }
 */
function MultiValueSpreadsheetV3({ 
  data,
  title,
  onCellValueChanged,
  readOnly = false,
  hideZeros = false,
  fullScreen = false,
  readOnlyFields = []
}) {
  // State for active type tab
  const [activeType, setActiveType] = useState(null);

  // Extract unique values and organize data
  const { 
    types, 
    sizes, 
    brims, 
    shapes, 
    valueFields,
    transformedData 
  } = useMemo(() => {
    if (!data?.items) {
      return { 
        types: [], 
        sizes: [], 
        brims: [], 
        shapes: [], 
        valueFields: [],
        transformedData: {} 
      };
    }

    // Extract unique values
    const typesSet = new Set();
    const sizesSet = new Set();
    const brimsSet = new Set();
    const shapesSet = new Set();
    const valueFieldsSet = new Set();

    // Transform data into required format
    const transformed = {};

    data.items.forEach(item => {
      const { type, size, brim, shape, values, metadata = {} } = item;
      
      typesSet.add(type);
      sizesSet.add(size);
      brimsSet.add(brim);
      shapesSet.add(shape);
      Object.keys(values).forEach(field => valueFieldsSet.add(field));

      // Build nested structure
      if (!transformed[type]) transformed[type] = {};
      if (!transformed[type][size]) transformed[type][size] = {};
      if (!transformed[type][size][brim]) transformed[type][size][brim] = {};
      if (!transformed[type][size][brim][shape]) {
        transformed[type][size][brim][shape] = {
          values: { ...values },
          metadata
        };
      }
    });

    return {
      types: Array.from(typesSet),
      sizes: Array.from(sizesSet)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map(size => ({ id: size, label: size })),
      brims: Array.from(brimsSet)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map(brim => ({ id: brim, label: brim })),
      shapes: Array.from(shapesSet),
      valueFields: data.valueFields || Array.from(valueFieldsSet),
      transformedData: transformed
    };
  }, [data]);

  // Set initial active type
  useEffect(() => {
    if (types.length && !activeType) {
      setActiveType(types[0]);
    }
  }, [types]);

  // Handle cell value changes
  const handleCellValueChanged = useCallback((params) => {
    if (!onCellValueChanged) return;

    const { data: rowData, colDef, newValue, oldValue } = params;
    const [brimId, shape, valueField] = colDef.field.split('-');
    
    onCellValueChanged({
      type: activeType,
      size: rowData.sizeLabel,
      brim: brimId,
      shape,
      valueField,
      newValue,
      oldValue,
      metadata: rowData.metadata,
      rowData
    });
  }, [onCellValueChanged, activeType]);

  // Generate column definitions
  const columnDefs = useMemo(() => {
    if (!brims.length) return [];

    const columns = [
      {
        headerName: 'Size',
        field: 'sizeLabel',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'size-column',
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressResize: true,
        width: 80,
        headerClass: 'size-header',
        editable: false,
        rowSpan: params => {
          return params.rowIndex % valueFields.length === 0 ? valueFields.length : 1;
        },
        cellClassRules: {
          'cell-span': params => params.rowIndex % valueFields.length === 0
        }
      },
      {
        headerName: 'Metric',
        field: 'valueField',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'metric-column',
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressResize: true,
        width: 100,
        headerClass: 'metric-header',
        editable: false
      }
    ];

    // Create columns for each brim and shape combination
    brims.forEach(brim => {
      const groupChildren = shapes.map(shape => 
        valueFields.map(valueField => ({
          headerName: `${shape} ${valueField}`,
          field: `${brim.id}-${shape}-${valueField}`,
          cellClass: params => {
            const classes = ['data-cell'];
            if (!readOnly && !readOnlyFields.includes(valueField)) {
              classes.push('editable-cell');
            }
            return classes.join(' ');
          },
          headerClass: 'data-header',
          suppressMovable: true,
          suppressResize: true,
          width: 80,
          editable: !readOnly && !readOnlyFields.includes(valueField),
          valueParser: params => {
            const newValue = Number(params.newValue);
            return isNaN(newValue) ? params.oldValue : newValue;
          },
          valueFormatter: params => {
            if (hideZeros && (params.value === 0 || params.value === '0')) return '';
            return params.value === undefined || params.value === null ? '' : params.value;
          }
        }))
      ).flat();

      columns.push({
        headerName: brim.label,
        children: groupChildren,
        headerClass: 'brim-group-header'
      });
    });

    return columns;
  }, [brims, shapes, valueFields, readOnly, readOnlyFields, hideZeros]);

  // Prepare row data
  const rowData = useMemo(() => {
    if (!activeType || !transformedData[activeType]) return [];

    const rows = [];
    const typeData = transformedData[activeType];

    sizes.forEach(size => {
      valueFields.forEach(valueField => {
        const row = {
          sizeLabel: size.label,
          valueField,
          metadata: {}
        };

        brims.forEach(brim => {
          shapes.forEach(shape => {
            const cellData = typeData[size.id]?.[brim.id]?.[shape] || { values: {}, metadata: {} };
            row[`${brim.id}-${shape}-${valueField}`] = cellData.values[valueField] ?? (hideZeros ? '' : 0);
            row.metadata = { ...row.metadata, ...cellData.metadata };
          });
        });

        rows.push(row);
      });
    });

    return rows;
  }, [transformedData, activeType, sizes, brims, shapes, valueFields, hideZeros]);

  const defaultColDef = {
    resizable: false,
    sortable: false,
    filter: false,
    suppressMovable: true
  };

  if (!data?.items?.length) {
    return <div>No data available</div>;
  }

  return (
    <Box sx={{ 
      height: fullScreen ? '100%' : 'auto',
      '& .spreadsheet-container': {
        height: fullScreen ? '100%' : 'auto',
        margin: 0,
        border: 'none',
        borderRadius: 0,
      }
    }}>
      {title && <h1 style={{ textAlign: 'center', margin: '20px 0' }}>{title}</h1>}
      
      <Paper sx={{ mb: 2 }}>
        <Tabs 
          value={types.indexOf(activeType)}
          onChange={(_, newValue) => setActiveType(types[newValue])}
          variant="scrollable"
          scrollButtons="auto"
        >
          {types.map((type) => (
            <Tab key={type} label={type} />
          ))}
        </Tabs>
      </Paper>

      <div 
        className="ag-theme-alpine custom-grid" 
        style={{ width: '100%', height: '600px' }}
      >
        <AgGridReact 
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onCellValueChanged={handleCellValueChanged}
          suppressHorizontalScroll={false}
          headerHeight={40}
          groupHeaderHeight={45}
          rowHeight={35}
          suppressMenuHide={true}
          stopEditingWhenCellsLoseFocus={true}
          suppressRowTransform={true}
          rowClassRules={{
            'metric-border': params => (params.rowIndex + 1) % valueFields.length === 0
          }}
        />
      </div>

      <style>
        {`
          .custom-grid {
            --ag-header-height: 40px;
            --ag-header-foreground-color: #181D1F;
            --ag-header-background-color: #f8f9fa;
            --ag-header-cell-hover-background-color: #f8f9fa;
            --ag-header-cell-moving-background-color: #f8f9fa;
            --ag-borders: solid 1px;
            --ag-border-color: #181D1F26;
            --ag-cell-horizontal-border: solid #181D1F26;
            --ag-row-border-color: #181D1F26;
          }

          .ag-header-group-cell {
            border-right: 2px solid #181D1F40 !important;
          }

          .size-column {
            background-color: #f8f9fa;
            font-weight: 600;
            text-align: center;
            border-right: 2px solid #181D1F40 !important;
          }

          .metric-column {
            background-color: #f8f9fa;
            font-weight: 600;
            text-align: center;
            border-right: 2px solid #181D1F40 !important;
          }

          .data-cell {
            text-align: center;
          }

          .editable-cell {
            background-color: rgba(255, 255, 255, 0.9);
          }

          .editable-cell:hover {
            background-color: rgba(240, 240, 240, 0.9);
            cursor: pointer;
          }

          .cell-span {
            background-color: #f8f9fa !important;
          }

          .metric-border {
            border-bottom: 2px solid #181D1F40;
          }

          .brim-group-header {
            background-color: #f1f3f5 !important;
            font-weight: 700;
            text-align: center;
          }
        `}
      </style>
    </Box>
  );
}

export default MultiValueSpreadsheetV3; 