import api from './api';

/**
 * Helper function to transform source type IDs to readable names
 * @param {number} sourceTypeId - The ID of the source type
 * @returns {string} The readable name of the source type
 */
const getSourceTypeName = (sourceTypeId) => {
    const sourceTypes = {
        1: 'Warehouse',
        2: 'Location',
        3: 'Shipment',
        4: 'Order'
    };
    return sourceTypes[sourceTypeId] || 'Unknown';
};

/**
 * API service for handling metrics and views operations
 */
export const metricsAndViewsApi = {
    // Metrics Operations
    getAllMetrics: async () => {
        try {
            const response = await api.get('/v1/metrics');
            return response.data;
        } catch (error) {
            console.error('Error fetching metrics:', error);
            throw new Error('Failed to fetch metrics');
        }
    },

    createMetric: async (metricData) => {
        try {
            // Transform and validate sources data
            const transformedData = {
                ...metricData,
                sources: metricData.sources
                    .map(source => ({
                        sourceTypeId: parseInt(source.sourceTypeId),
                        referenceId: parseInt(source.referenceId),
                        operation: source.operation || '+',
                        displayOrder: source.displayOrder
                    }))
                    .filter(source => source.sourceTypeId && source.referenceId)
            };

            const response = await api.post('/v1/metrics', transformedData);
            return response.data;
        } catch (error) {
            console.error('Error creating metric:', error);
            throw new Error('Failed to create metric');
        }
    },

    getMetricById: async (metricId) => {
        try {
            const response = await api.get(`/v1/metrics/${metricId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching metric:', error);
            throw new Error('Failed to fetch metric details');
        }
    },

    // Views Operations
    getAllViews: async () => {
        try {
            const response = await api.get('/v1/views');
            return response.data;
        } catch (error) {
            console.error('Error fetching views:', error);
            throw new Error('Failed to fetch views');
        }
    },

    createView: async (viewData) => {
        try {
            const response = await api.post('/v1/views', viewData);
            return response.data;
        } catch (error) {
            console.error('Error creating view:', error);
            throw new Error('Failed to create view');
        }
    },

    getViewById: async (viewId) => {
        try {
            const response = await api.get(`/v1/views/${viewId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching view:', error);
            throw new Error('Failed to fetch view details');
        }
    },

    // Data Sources Operations
    getDataSources: async (filters = {}) => {
        try {
            const queryString = new URLSearchParams(filters).toString();
            const endpoints = [
                'warehouses',
                'locations',
                'shipments',
                'orders',
                'customers'
            ];

            const responses = await Promise.all(
                endpoints.map(endpoint => 
                    api.get(`/v1/data/${endpoint}?${queryString}`)
                )
            );

            return responses.reduce((acc, response, index) => ({
                ...acc,
                [endpoints[index]]: response.data || []
            }), {});
        } catch (error) {
            console.error('Error fetching data sources:', error);
            throw new Error('Failed to fetch data sources');
        }
    },

    getViewData: async (viewId) => {
        try {
            const [viewResponse, metricsResponse] = await Promise.all([
                api.get(`/v1/views/${viewId}`),
                api.get(`/v1/views/${viewId}/data`)
            ]);

            return {
                view: viewResponse.data,
                data: metricsResponse.data,
                metrics: viewResponse.data.metrics.map(metric => ({
                    ...metric,
                    sources: metric.sources.map(source => ({
                        ...source,
                        operation: source.operation || '+',
                        sourceName: getSourceTypeName(source.sourceTypeId)
                    }))
                }))
            };
        } catch (error) {
            console.error('Error fetching view data:', error);
            throw new Error('Failed to fetch view data');
        }
    },

    // Deletion Operations
    deleteMetric: async (metricId) => {
        try {
            const response = await api.delete(`/v1/metrics/${metricId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting metric:', error);
            throw new Error('Failed to delete metric');
        }
    },

    deleteView: async (viewId) => {
        try {
            const response = await api.delete(`/v1/views/${viewId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting view:', error);
            throw new Error('Failed to delete view');
        }
    },

    // Metric Usage Check
    getMetricUsage: async (metricId) => {
        try {
            const response = await api.get(`/v1/metrics/${metricId}/usage`);
            return response.data;
        } catch (error) {
            console.error('Error checking metric usage:', error);
            throw new Error('Failed to check metric usage');
        }
    },

    // Export helper function
    getSourceTypeName
}; 