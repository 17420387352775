import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Chip,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Card,
  LinearProgress,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Edit as EditIcon,
  LocalShipping as ShippingIcon,
  Assignment as AssignmentIcon,
  Comment as CommentIcon,
  Send as SendIcon,
  CheckCircle as ApproveIcon,
  Cancel as RejectIcon,
} from '@mui/icons-material';
import { 
  getOrderById, 
  updateOrderStatus, 
  addOrderComment,
  getOrderComments,
} from '../services/api';
import { format } from 'date-fns';
import SpreadsheetEditable from './spreadsheets/SpreadsheetEditable';
import { toast } from 'react-hot-toast';
import FullScreenLayout from './layouts/FullScreenLayout';
import { differenceInDays } from 'date-fns';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
import OptimizedSpreadsheet from './spreadsheets/OptimizedSpreadsheet';

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const StatsCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  transition: 'all 0.2s ease',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
}));

const ChatContainer = styled(ContentPaper)({
  display: 'flex',
  flexDirection: 'column',
  height: '400px',
  overflow: 'hidden',
});

const ChatMessages = styled('div')({
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column-reverse',
});

const ChatInputContainer = styled('div')({
  padding: '16px',
  borderTop: '1px solid #e0e0e0',
  backgroundColor: '#fff',
});

const MessageBubble = styled('div')(({ theme, isCurrentUser }) => ({
  maxWidth: '70%',
  padding: '12px 16px',
  borderRadius: '12px',
  marginBottom: '8px',
  alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  backgroundColor: isCurrentUser ? theme.palette.primary.main : '#e0e0e0',
  color: isCurrentUser ? theme.palette.primary.contrastText : 'inherit',
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3, 4),
  marginBottom: theme.spacing(4),
  boxShadow: '0 1px 3px rgba(0,0,0,0.02)',
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '8px 16px',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`order-tabpanel-${index}`}
      aria-labelledby={`order-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
        case 'completed':
            return 'success';
        case 'in progress':
            return 'primary';
        case 'cancelled':
            return 'error';
        case 'pending':
            return 'warning';
        default:
            return 'default';
    }
};

const OrderSummary = ({ order }) => {
  const totalItems = order.items.reduce((sum, item) => sum + item.quantity, 0);
  const daysSinceOrder = differenceInDays(new Date(), new Date(order.created_at));
  const shippedItems = order.total_shipped_quantity || 0;

  return (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} md={4}>
        <StatsCard>
          <ShoppingBasketIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
          <Typography variant="h4" gutterBottom>
            {totalItems}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Total Items
          </Typography>
        </StatsCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <StatsCard>
          <LocalShippingIcon sx={{ fontSize: 40, color: 'success.main', mb: 1 }} />
          <Typography variant="h4" gutterBottom>
            {shippedItems} / {totalItems}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Items Shipped
          </Typography>
        </StatsCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <StatsCard>
          <AccessTimeIcon sx={{ fontSize: 40, color: 'secondary.main', mb: 1 }} />
          <Typography variant="h4" gutterBottom>
            {daysSinceOrder}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Days Since Order
          </Typography>
        </StatsCard>
      </Grid>
    </Grid>
  );
};

const ShipmentsTab = ({ order }) => {
  const navigate = useNavigate();
  const hasShipments = order.shipments && order.shipments.length > 0;
  
  return (
    <ContentPaper sx={{ mt: 2 }}>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Shipping Progress
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={order.shipping_completion_percentage || 0}
          sx={{ height: 10, borderRadius: 5, mb: 1 }}
        />
        <Typography color="textSecondary">
          {order.shipping_completion_percentage || 0}% Complete 
          ({order.total_shipped_quantity || 0} of {order.items.reduce((sum, item) => sum + item.quantity, 0)} items shipped)
        </Typography>
      </Box>

      {hasShipments ? (
        <Grid container spacing={3}>
          {order.shipments.map((shipment) => (
            <Grid item xs={12} key={shipment.shipment_id}>
              <Card 
                sx={{ 
                  cursor: 'pointer',
                  borderRadius: '12px',
                  border: '1px solid',
                  borderColor: 'divider',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                    transform: 'translateY(-2px)',
                  },
                  transition: 'all 0.2s ease'
                }}
                onClick={() => navigate(`/shipments/${shipment.shipment_id}`)}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        Shipment {shipment.shipment_number}
                      </Typography>
                      <Chip 
                        label={shipment.status}
                        color={getStatusColor(shipment.status)}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {shipment.tracking_number && (
                        <Typography>
                          <strong>Tracking:</strong> {shipment.carrier} - {shipment.tracking_number}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {shipment.shipped_date && (
                          <>Shipped: {format(new Date(shipment.shipped_date), 'PPp')}<br /></>
                        )}
                        {shipment.estimated_delivery_date && (
                          <>Expected: {format(new Date(shipment.estimated_delivery_date), 'PPp')}<br /></>
                        )}
                        {shipment.actual_delivery_date && (
                          <>Delivered: {format(new Date(shipment.actual_delivery_date), 'PPp')}</>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity="info">No shipments created yet</Alert>
      )}
    </ContentPaper>
  );
};

const ItemShipmentDetails = ({ shipments }) => {
  const navigate = useNavigate();
  
  if (!shipments || shipments.length === 0) {
    return <Typography color="textSecondary">Not shipped yet</Typography>;
  }

  return (
    <Box>
      {shipments.map((shipment, index) => (
        <Box 
          key={shipment.shipment_id} 
          sx={{ 
            mb: index !== shipments.length - 1 ? 1 : 0,
            cursor: 'pointer',
            '&:hover': {
              '& .shipment-link': {
                textDecoration: 'underline'
              }
            }
          }}
          onClick={() => navigate(`/shipments/${shipment.shipment_id}`)}
        >
          <Typography variant="body2">
            {shipment.quantity} items in{' '}
            <span className="shipment-link" style={{ color: 'primary.main' }}>
              shipment {shipment.shipment_number}
            </span>
            <Chip 
              label={shipment.status}
              color={getStatusColor(shipment.status)}
              size="small"
              sx={{ ml: 1 }}
            />
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const transformSpreadsheetData = (rawData, view) => {
  const transformedData = {};
  
  // Iterate through sizes and brims
  Object.entries(rawData).forEach(([size, brimData]) => {
    Object.entries(brimData).forEach(([brim, shapes]) => {
      // For each shape in this size/brim combination
      Object.entries(shapes).forEach(([shape, value]) => {
        const itemKey = `${size}-${brim}-${shape}`;
        transformedData[itemKey] = {};

        if (view === 'combined') {
          // For combined view, create two metrics
          transformedData[itemKey]['ordered'] = value.ordered;
          transformedData[itemKey]['shipped'] = value.shipped;
        } else {
          // For single value views
          transformedData[itemKey][view] = typeof value === 'object' ? value.ordered : value;
        }
      });
    });
  });
  
  return transformedData;
};

function OrderDetails() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const statusOptions = {
    pending: { label: 'Pending', color: 'default' },
    processing: { label: 'Processing', color: 'info' },
    completed: { label: 'Completed', color: 'success' },
    cancelled: { label: 'Cancelled', color: 'error' },
  };

  useEffect(() => {
    fetchOrderData();
  }, [orderId]);

  const fetchOrderData = async () => {
    try {
      setLoading(true);
      const [orderData, commentsData] = await Promise.all([
        getOrderById(orderId),
        getOrderComments(orderId)
      ]);
      setOrder(orderData);
      setComments(commentsData || []);
    } catch (err) {
      setError(err.message || 'Failed to fetch order details');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async () => {
    try {
      await updateOrderStatus(orderId, selectedStatus);
      await fetchOrderData();
      setStatusDialogOpen(false);
      setSelectedStatus('');
    } catch (err) {
      setError(err.message || 'Failed to update status');
    }
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      await addOrderComment(orderId, newComment);
      await fetchOrderData();
      setNewComment('');
    } catch (err) {
      setError(err.message || 'Failed to add comment');
    }
  };

  const handleApproveRequest = async () => {
    try {
      await updateOrderStatus(orderId, 'processing');
      await fetchOrderData();
      toast.success('Order request approved successfully');
    } catch (error) {
      setError(error.message || 'Failed to approve order request');
    }
  };

  const handleRejectRequest = async () => {
    try {
      if (!rejectionReason.trim()) {
        throw new Error('Rejection reason is required');
      }
      await updateOrderStatus(orderId, 'rejected', rejectionReason);
      await fetchOrderData();
      setRejectDialogOpen(false);
      setRejectionReason('');
      toast.success('Order request rejected successfully');
    } catch (error) {
      setError(error.message || 'Failed to reject order request');
    }
  };

  const handleReadOnlyEditAttempt = () => {
    enqueueSnackbar('Please click the "Edit Order" button to make changes', {
      variant: 'warning',
      action: (key) => (
        <Button 
          color="inherit" 
          size="small"
          onClick={() => {
            navigate(`/orders/${orderId}/edit`);
          }}
        >
          Edit Now
        </Button>
      ),
    });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <Alert severity="error">{error}</Alert>
      </PageContainer>
    );
  }

  if (!order) {
    return (
      <PageContainer>
        <Alert severity="info">Order not found</Alert>
      </PageContainer>
    );
  }

  return (
    <FullScreenLayout
      hasNavigation={true}
      header={
        <HeaderContainer>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
                  Order {order.order_number}
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <Chip 
                    label={statusOptions[order.status]?.label || order.status}
                    color={statusOptions[order.status]?.color || 'default'}
                    sx={{ 
                      height: '28px',
                      '& .MuiChip-label': {
                        px: 2,
                        fontWeight: 500
                      }
                    }}
                  />
                  <Typography variant="subtitle1" color="text.secondary">
                    <strong>Customer:</strong> {order.company_name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <HeaderButton
                  variant="outlined"
                  onClick={() => setStatusDialogOpen(true)}
                  startIcon={<AssignmentIcon />}
                  sx={{
                    borderColor: 'divider',
                    '&:hover': {
                      borderColor: 'primary.main',
                      backgroundColor: 'primary.50',
                    }
                  }}
                >
                  Update Status
                </HeaderButton>
                <HeaderButton
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => navigate(`/orders/${orderId}/edit`)}
                  sx={{
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    }
                  }}
                >
                  Edit Order
                </HeaderButton>
              </Box>
            </Grid>
          </Grid>
        </HeaderContainer>
      }
      mainPanel={
        <PageContainer>
          <OrderSummary order={order} />
          
          <ContentPaper>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{ 
                borderBottom: 1, 
                borderColor: 'divider',
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '1rem',
                  minWidth: 100,
                  '&.Mui-selected': {
                    fontWeight: 600
                  }
                }
              }}
            >
              <Tab label="Details" />
              <Tab label="Items Spreadsheet" />
              <Tab label="Shipments" />
              <Tab label="Comments" />
            </Tabs>

            <Box sx={{ mt: 3 }}>
              <TabPanel value={currentTab} index={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <ContentPaper>
                      <Typography variant="h6" gutterBottom>
                        Order Details
                      </Typography>
                      <Box mb={2}>
                        <Chip 
                          label={statusOptions[order.status]?.label || order.status}
                          color={statusOptions[order.status]?.color || 'default'}
                          sx={{ mb: 1 }}
                        />
                      </Box>
                      <Typography><strong>Order Number:</strong> {order.order_number}</Typography>
                      <Typography><strong>Customer:</strong> {order.company_name}</Typography>
                      {order.notes && (
                        <Typography><strong>Notes:</strong> {order.notes}</Typography>
                      )}
                      {order.is_customer_request && (
                        <Box mt={2}>
                          <Typography variant="h6" gutterBottom>
                            Request Details
                          </Typography>
                          <Typography><strong>Requested By:</strong> {order.customer_name}</Typography>
                          <Typography><strong>Requested At:</strong> {format(new Date(order.requested_at), 'PPpp')}</Typography>
                          {order.eta_type === 'date' && (
                            <Typography><strong>Requested ETA:</strong> {format(new Date(order.requested_eta_date), 'PPp')}</Typography>
                          )}
                          {order.customer_notes && (
                            <Typography><strong>Customer Notes:</strong> {order.customer_notes}</Typography>
                          )}
                          {order.request_status === 'awaiting_approval' && (
                            <Box mt={2}>
                              <Button
                                variant="contained"
                                color="success"
                                startIcon={<ApproveIcon />}
                                onClick={handleApproveRequest}
                                sx={{ mr: 2 }}
                              >
                                Approve Request
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                startIcon={<RejectIcon />}
                                onClick={() => setRejectDialogOpen(true)}
                              >
                                Reject Request
                              </Button>
                            </Box>
                          )}
                          {order.request_status === 'rejected' && (
                            <Typography color="error" mt={1}>
                              <strong>Rejection Reason:</strong> {order.rejection_reason}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </ContentPaper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ContentPaper>
                      <Typography variant="h6" gutterBottom>
                        Dates
                      </Typography>
                      <Typography>
                        <strong>Created:</strong> {format(new Date(order.created_at), 'PPpp')}
                      </Typography>
                      <Typography>
                        <strong>Last Updated:</strong> {format(new Date(order.updated_at), 'PPpp')}
                      </Typography>
                    </ContentPaper>
                  </Grid>

                  <Grid item xs={12}>
                    <ContentPaper>
                      <Typography variant="h6" gutterBottom>
                        Order Items
                      </Typography>
                      <DataGrid
                        rows={order.items.map(item => ({
                          id: item.order_item_id,
                          name: `${item.type} - ${item.size}${item.brim} ${item.shape}`,
                          itemDetails: `${item.name || 'N/A'}`,
                          quantity: item.quantity,
                          status: item.status,
                          shipped: item.shipped_quantities ? 
                            item.shipped_quantities.reduce((sum, sq) => sum + sq.quantity, 0) : 0,
                          remaining: item.quantity - (item.shipped_quantities ? 
                            item.shipped_quantities.reduce((sum, sq) => sum + sq.quantity, 0) : 0),
                          ...item
                        }))}
                        columns={[
                          { 
                            field: 'name', 
                            headerName: 'Item', 
                            width: 200,
                            renderCell: (params) => (
                              <Box>
                                <Typography variant="body2" component="div">
                                  {params.row.name}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                  {params.row.itemDetails}
                                </Typography>
                              </Box>
                            )
                          },
                          { 
                            field: 'quantity', 
                            headerName: 'Ordered', 
                            width: 100,
                            align: 'right',
                            headerAlign: 'right'
                          },
                          { 
                            field: 'shipped', 
                            headerName: 'Shipped', 
                            width: 100,
                            align: 'right',
                            headerAlign: 'right'
                          },
                          { 
                            field: 'remaining', 
                            headerName: 'Remaining', 
                            width: 100,
                            align: 'right',
                            headerAlign: 'right'
                          },
                          { 
                            field: 'status', 
                            headerName: 'Status', 
                            width: 150,
                            renderCell: (params) => (
                              <Chip 
                                label={params.value}
                                color={getStatusColor(params.value)}
                                size="small"
                              />
                            )
                          },
                          { 
                            field: 'shipmentDetails', 
                            headerName: 'Shipment Details', 
                            width: 300,
                            flex: 1,
                            renderCell: (params) => (
                              <ItemShipmentDetails shipments={params.row.shipped_quantities} />
                            )
                          }
                        ]}
                        autoHeight
                        disableRowSelectionOnClick
                        sx={{
                          '& .MuiDataGrid-cell': {
                            cursor: 'pointer'
                          },
                          '& .MuiDataGrid-row:hover': {
                            backgroundColor: 'action.hover'
                          },
                          border: 'none',
                          '& .MuiDataGrid-cell:focus': {
                            outline: 'none'
                          }
                        }}
                        onRowClick={(params) => handleReadOnlyEditAttempt()}
                      />
                    </ContentPaper>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={currentTab} index={1}>
                <Box sx={{ height: '100%' }}>
                  <OptimizedSpreadsheet
                    data={order.items.reduce((acc, item) => {
                      acc[item.order_item_id] = {
                        ordered: item.quantity,
                        shipped: item.shipped_quantities ? 
                          item.shipped_quantities.reduce((sum, sq) => sum + sq.quantity, 0) : 0
                      };
                      return acc;
                    }, {})}
                    metrics={['ordered', 'shipped']}
                    title="Order Items"
                    readOnly={true}
                    hideZeros={true}
                    onReadOnlyEditAttempt={handleReadOnlyEditAttempt}
                    fullScreen={true}
                  />
                </Box>
              </TabPanel>

              <TabPanel value={currentTab} index={2}>
                <ShipmentsTab order={order} />
              </TabPanel>

              <TabPanel value={currentTab} index={3}>
                <Typography variant="h6" gutterBottom>
                  Comments & Updates
                </Typography>
                <ChatContainer>
                  <ChatMessages>
                    {comments.map((comment) => (
                      <Box key={comment.comment_id} sx={{ mb: 2 }}>
                        <MessageBubble 
                          isCurrentUser={
                            (!comment.is_customer_comment && comment.commenter_id === parseInt(localStorage.getItem('userId'))) ||
                            (comment.is_customer_comment && comment.commenter_id === parseInt(localStorage.getItem('customerId')))
                          }
                        >
                          <Box display="flex" alignItems="center" gap={1} mb={1}>
                            <Typography variant="subtitle2" component="span">
                              {comment.user_name}
                              {comment.is_customer_comment && ' (Customer)'}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {format(new Date(comment.created_at), 'PPpp')}
                            </Typography>
                          </Box>
                          <Typography>{comment.comment_text}</Typography>
                        </MessageBubble>
                      </Box>
                    ))}
                  </ChatMessages>
                  <ChatInputContainer>
                    <form onSubmit={handleAddComment}>
                      <Box display="flex" gap={1}>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Type a comment..."
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          variant="outlined"
                        />
                        <IconButton 
                          type="submit"
                          disabled={!newComment.trim()}
                          color="primary"
                        >
                          <SendIcon />
                        </IconButton>
                      </Box>
                    </form>
                  </ChatInputContainer>
                </ChatContainer>
              </TabPanel>
            </Box>
          </ContentPaper>

          {/* Status Update Dialog */}
          <Dialog open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
            <DialogTitle>Update Order Status</DialogTitle>
            <DialogContent>
              <TextField
                select
                fullWidth
                label="Status"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                margin="normal"
                SelectProps={{
                  native: true,
                }}
              >
                <option value="">Select status</option>
                {Object.entries(statusOptions).map(([value, { label }]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setStatusDialogOpen(false)}>Cancel</Button>
              <Button 
                onClick={handleStatusChange}
                disabled={!selectedStatus}
                variant="contained"
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)}>
            <DialogTitle>Reject Order Request</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Rejection Reason"
                fullWidth
                multiline
                rows={4}
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setRejectDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleRejectRequest} color="error" variant="contained">
                Reject
              </Button>
            </DialogActions>
          </Dialog>
        </PageContainer>
      }
    />
  );
}

export default OrderDetails; 