import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    IconButton,
    Stepper,
    Step,
    StepLabel,
    Paper,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemSecondary,
    Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const steps = ['Basic Information', 'Select Metrics', 'Review & Create'];

const initialState = {
    activeStep: 0,
    name: '',
    description: '',
    isFavorite: false,
    selectedMetrics: [],
    error: ''
};

const CreateViewDialog = ({ open, onClose, onSubmit, availableMetrics = [] }) => {
    const metrics = Array.isArray(availableMetrics) ? availableMetrics : [];
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isFavorite, setIsFavorite] = useState(false);
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const resetForm = () => {
        setActiveStep(initialState.activeStep);
        setName(initialState.name);
        setDescription(initialState.description);
        setIsFavorite(initialState.isFavorite);
        setSelectedMetrics(initialState.selectedMetrics);
        setError(initialState.error);
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const handleNext = () => {
        if (activeStep === 0 && !name.trim()) {
            setError('Please enter a view name');
            return;
        }
        if (activeStep === 1 && selectedMetrics.length === 0) {
            setError('Please select at least one metric');
            return;
        }
        setError('');
        setActiveStep((prev) => prev + 1);
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
        setError('');
    };

    const handleAddMetric = () => {
        setSelectedMetrics([...selectedMetrics, { metricId: '', displayOrder: selectedMetrics.length }]);
    };

    const handleRemoveMetric = (index) => {
        setSelectedMetrics(selectedMetrics.filter((_, i) => i !== index));
    };

    const handleMetricChange = (index, metricId) => {
        if (selectedMetrics.some((m, i) => m.metricId === metricId && i !== index)) {
            setError('This metric is already selected');
            return;
        }

        const newMetrics = [...selectedMetrics];
        newMetrics[index] = { ...newMetrics[index], metricId };
        setSelectedMetrics(newMetrics);
        setError('');
    };

    const handleDragEnd = (result) => {
        try {
            if (!result.destination) return;

            const items = Array.from(selectedMetrics);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);

            setSelectedMetrics(items.map((item, index) => ({ 
                ...item, 
                displayOrder: index 
            })));
        } catch (error) {
            console.error('Error reordering metrics:', error);
            setError('Failed to reorder metrics');
        }
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            setError('');
            
            const submitData = {
                name,
                description,
                isFavorite,
                metrics: selectedMetrics
                    .filter(m => m.metricId)
                    .map((m, index) => ({
                        metricId: m.metricId,
                        displayOrder: index
                    }))
            };

            await onSubmit(submitData);
            handleClose();
        } catch (err) {
            setError(err.message || 'Failed to create view');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                        <TextField
                            label="View Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            required
                            error={!!error && !name}
                            helperText={error && !name ? error : ''}
                            placeholder="E.g., Warehouse Overview"
                        />
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={3}
                            fullWidth
                            placeholder="Describe what this view represents..."
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isFavorite}
                                    onChange={(e) => setIsFavorite(e.target.checked)}
                                    icon={<StarBorderIcon />}
                                    checkedIcon={<StarIcon sx={{ color: '#fbbc04' }} />}
                                />
                            }
                            label="Mark as Favorite"
                        />
                    </Box>
                );

            case 1:
                return (
                    <Box sx={{ mt: 2 }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="metrics">
                                {(provided) => (
                                    <List 
                                        {...provided.droppableProps} 
                                        ref={provided.innerRef}
                                        sx={{ mb: 2 }}
                                    >
                                        {selectedMetrics.map((metric, index) => (
                                            <Draggable
                                                key={index}
                                                draggableId={`metric-${index}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <ListItem
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        sx={{ 
                                                            backgroundColor: 'background.paper',
                                                            mb: 1,
                                                            borderRadius: 1,
                                                            border: '1px solid',
                                                            borderColor: 'divider'
                                                        }}
                                                    >
                                                        <Box {...provided.dragHandleProps} sx={{ mr: 1 }}>
                                                            <DragIndicatorIcon color="action" />
                                                        </Box>
                                                        <FormControl fullWidth sx={{ mr: 1 }}>
                                                            <Select
                                                                value={metric.metricId}
                                                                onChange={(e) => handleMetricChange(index, e.target.value)}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select a metric
                                                                </MenuItem>
                                                                {metrics.map((m) => (
                                                                    <MenuItem 
                                                                        key={m.metric_id} 
                                                                        value={m.metric_id}
                                                                        disabled={selectedMetrics.some(
                                                                            sm => sm.metricId === m.metric_id && sm !== metric
                                                                        )}
                                                                    >
                                                                        {m.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <IconButton 
                                                            onClick={() => handleRemoveMetric(index)}
                                                            color="error"
                                                            size="small"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </List>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <Button
                            startIcon={<AddIcon />}
                            onClick={handleAddMetric}
                            variant="outlined"
                            fullWidth
                        >
                            Add Metric
                        </Button>

                        {error && (
                            <Typography color="error" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}
                    </Box>
                );

            case 2:
                return (
                    <Box sx={{ mt: 2 }}>
                        <Paper sx={{ p: 3, mb: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">
                                    {name}
                                </Typography>
                                {isFavorite && (
                                    <StarIcon sx={{ color: '#fbbc04' }} />
                                )}
                            </Box>
                            {description && (
                                <Typography color="text.secondary" paragraph>
                                    {description}
                                </Typography>
                            )}
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle2" gutterBottom>
                                Selected Metrics
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {selectedMetrics.map((metric, index) => {
                                    const metricData = metrics.find(m => m.metric_id === metric.metricId);
                                    return metricData && (
                                        <Box 
                                            key={index}
                                            sx={{ 
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                p: 1,
                                                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                                borderRadius: 1
                                            }}
                                        >
                                            <Typography variant="body2">
                                                {index + 1}.
                                            </Typography>
                                            <Typography>
                                                {metricData.name}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Paper>
                    </Box>
                );

            default:
                return null;
        }
    };

    useEffect(() => {
        return () => {
            resetForm();
        };
    }, []);

    return (
        <Dialog 
            open={open} 
            onClose={handleClose}
            maxWidth="md" 
            fullWidth
            PaperProps={{
                sx: { minHeight: '60vh' }
            }}
        >
            <DialogTitle>
                <Typography variant="h5">Create New View</Typography>
                <Stepper activeStep={activeStep} sx={{ mt: 2 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </DialogTitle>

            <DialogContent dividers>
                {renderStepContent(activeStep)}
            </DialogContent>

            <DialogActions sx={{ p: 2, gap: 1 }}>
                <Button onClick={handleClose}>Cancel</Button>
                {activeStep > 0 && (
                    <Button onClick={handleBack}>
                        Back
                    </Button>
                )}
                {activeStep === steps.length - 1 ? (
                    <Button 
                        onClick={handleSubmit} 
                        variant="contained" 
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Creating...' : 'Create View'}
                    </Button>
                ) : (
                    <Button 
                        onClick={handleNext} 
                        variant="contained" 
                        color="primary"
                    >
                        Next
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CreateViewDialog; 