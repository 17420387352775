import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Button, 
  TextField, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondary,
  IconButton,
  Chip,
  Divider,
  Card,
  CardContent,
  Alert,
  Checkbox,
  CircularProgress
} from '@mui/material';
import { 
  Add as AddIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  QrCode as QrCodeIcon
} from '@mui/icons-material';
import { shipmentPackingApi } from '../services/shipmentPackingApi';
import { useSnackbar } from 'notistack';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ShipmentPacking = () => {
  const { shipmentId } = useParams();
  const navigate = useNavigate();
  const [shipment, setShipment] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [openNewBox, setOpenNewBox] = useState(false);
  const [newBoxNumber, setNewBoxNumber] = useState('');
  const [selectedBox, setSelectedBox] = useState(null);
  const [openAddItems, setOpenAddItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAction, setLoadingAction] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const loadData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const [shipmentData, boxesData] = await Promise.all([
        shipmentPackingApi.getShipmentForPacking(shipmentId),
        shipmentPackingApi.getShipmentBoxes(shipmentId)
      ]);
      
      if (!shipmentData) {
        throw new Error('No shipment data found');
      }
      
      setShipment(shipmentData);
      setBoxes(boxesData || []);
    } catch (err) {
      setError(err.message || 'Failed to load shipment data');
      console.error('Error loading shipment data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shipmentId) {
      loadData();
    }
  }, [shipmentId]);

  const handleCreateBox = async () => {
    try {
      setLoadingAction('creating_box');
      await shipmentPackingApi.createBox(shipmentId, {
        boxNumber: newBoxNumber,
        parentBoxId: selectedBox?.box_id
      });
      setOpenNewBox(false);
      setNewBoxNumber('');
      await loadData();
      enqueueSnackbar('Box created successfully', { 
        variant: 'success'
      });
    } catch (err) {
      setError('Failed to create box');
      enqueueSnackbar('Failed to create box', { 
        variant: 'error' 
      });
      console.error(err);
    } finally {
      setLoadingAction('');
    }
  };

  const handleAddItems = async () => {
    try {
      setLoadingAction('adding_items');
      // Convert selectedItems array into the format expected by the API
      const itemsToAdd = selectedItems.map(itemId => ({
        shipmentItemId: itemId
      }));
      
      await shipmentPackingApi.addItemsToBox(selectedBox.box_id, itemsToAdd);
      setOpenAddItems(false);
      setSelectedItems([]);
      await loadData();
      enqueueSnackbar('Items added successfully', { variant: 'success' });
    } catch (err) {
      setError('Failed to add items to box');
      enqueueSnackbar(err.message || 'Failed to add items to box', { variant: 'error' });
      console.error(err);
    } finally {
      setLoadingAction('');
    }
  };

  const handleRemoveItem = async (boxId, contentId) => {
    try {
      // Add validation to ensure contentId is valid
      if (!contentId || isNaN(contentId)) {
        enqueueSnackbar('Invalid item selected for removal', { variant: 'error' });
        return;
      }
      
      setLoadingAction('removing_item');
      await shipmentPackingApi.removeItemFromBox(boxId, contentId);
      await loadData();
      enqueueSnackbar('Item removed successfully', { variant: 'success' });
    } catch (err) {
      setError('Failed to remove item');
      enqueueSnackbar(err.message || 'Failed to remove item from box', { variant: 'error' });
      console.error('Error removing item:', err);
    } finally {
      setLoadingAction('');
    }
  };

  const handleCompleteBox = async (boxId) => {
    try {
      await shipmentPackingApi.completeBoxPacking(boxId);
      loadData();
    } catch (err) {
      setError('Failed to complete box packing');
      console.error(err);
    }
  };

  const getUnpackedItems = () => {
    if (!shipment?.items) return [];
    return shipment.items.filter(item => item.quantity > item.packed_quantity);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Moving between lists
    if (source.droppableId !== destination.droppableId) {
      if (destination.droppableId === 'selected') {
        // Moving from available to selected
        const unpackedItems = getUnpackedItems();
        const item = unpackedItems[source.index];
        
        // Add null check for item
        if (!item || !item.shipment_item_id) {
          console.error('Invalid item data:', item);
          enqueueSnackbar('Error: Invalid item data', { variant: 'error' });
          return;
        }

        // Check if we can add another quantity of this item
        const currentQtyInSelection = selectedItems.filter(id => id === item.shipment_item_id).length;
        const availableQty = item.quantity - item.packed_quantity;
        
        if (currentQtyInSelection < availableQty) {
          setSelectedItems([...selectedItems, item.shipment_item_id]);
        } else {
          enqueueSnackbar('No more quantity available for this item', { variant: 'warning' });
        }
      } else {
        // Moving from selected to available
        const newSelectedItems = Array.from(selectedItems);
        newSelectedItems.splice(source.index, 1);
        setSelectedItems(newSelectedItems);
      }
    } else if (source.droppableId === 'selected') {
      // Reordering within selected list
      const newSelectedItems = Array.from(selectedItems);
      const [movedItem] = newSelectedItems.splice(source.index, 1);
      newSelectedItems.splice(destination.index, 0, movedItem);
      setSelectedItems(newSelectedItems);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : (
        <>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            sx={{ mb: 2 }}
          >
            Back
          </Button>

          {shipment && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Shipment Details
                  </Typography>
                  <Typography>Number: {shipment.shipment_number}</Typography>
                  <Typography>Status: {shipment.status}</Typography>
                  
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Unpacked Items
                    </Typography>
                    <List>
                      {getUnpackedItems().map(item => (
                        <ListItem key={item.shipment_item_id}>
                          <ListItemText
                            primary={item.item_name}
                            secondary={`${item.packed_quantity}/${item.quantity} packed`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h6">
                      Boxes
                    </Typography>
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={() => setOpenNewBox(true)}
                    >
                      New Box
                    </Button>
                  </Box>

                  {boxes.map(box => (
                    <Card key={box.box_id} sx={{ mb: 2 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle1">
                            Box #{box.box_number}
                          </Typography>
                          <IconButton onClick={() => window.alert(box.barcode)}>
                            <QrCodeIcon />
                          </IconButton>
                        </Box>
                        
                        <List>
                          {box.contents?.map(item => (
                            <ListItem key={item.content_id}>
                              <ListItemText
                                primary={item.item_name}
                                secondary={`Position: ${item.position_in_box}`}
                              />
                              <IconButton 
                                onClick={() => item.content_id ? handleRemoveItem(box.box_id, item.content_id) : null}
                                disabled={!item.content_id}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItem>
                          ))}
                        </List>

                        <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedBox(box);
                              setOpenAddItems(true);
                            }}
                          >
                            Add Items
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => handleCompleteBox(box.box_id)}
                          >
                            Complete
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          )}

          {/* New Box Dialog */}
          <Dialog open={openNewBox} onClose={() => setOpenNewBox(false)}>
            <DialogTitle>Create New Box</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Box Number"
                fullWidth
                value={newBoxNumber}
                onChange={(e) => setNewBoxNumber(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenNewBox(false)}>Cancel</Button>
              <Button onClick={handleCreateBox} variant="contained">Create</Button>
            </DialogActions>
          </Dialog>

          {/* Add Items Dialog */}
          <Dialog open={openAddItems} onClose={() => setOpenAddItems(false)} maxWidth="md" fullWidth>
            <DialogTitle>Add Items to Box</DialogTitle>
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <Typography color="text.secondary">
                  Drag items from the available list to the right to pack them in the box. 
                  You can also reorder items in the pack list to set their position in the box.
                </Typography>
              </Box>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                      Available Items
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {getUnpackedItems().length} items available
                    </Typography>
                    <Droppable droppableId="available">
                      {(provided) => (
                        <List
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{ 
                            bgcolor: 'background.paper', 
                            minHeight: 400,
                            border: '1px dashed #ccc',
                            borderRadius: 1,
                            p: 2,
                            '& .MuiListItem-root': {
                              bgcolor: 'white',
                              boxShadow: 1,
                              borderRadius: 1,
                              mb: 1
                            }
                          }}
                        >
                          {getUnpackedItems().map((item, index) => {
                            // Add null check for item
                            if (!item || !item.shipment_item_id) return null;

                            const currentQtyInSelection = selectedItems.filter(id => id === item.shipment_item_id).length;
                            const remainingQty = item.quantity - item.packed_quantity - currentQtyInSelection;
                            
                            return (
                              <Draggable 
                                key={item.shipment_item_id.toString()} 
                                draggableId={item.shipment_item_id.toString()} 
                                index={index}
                                isDragDisabled={remainingQty <= 0}
                              >
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{ 
                                      border: '1px solid #eee', 
                                      mb: 1,
                                      cursor: remainingQty > 0 ? 'grab' : 'not-allowed',
                                      opacity: remainingQty > 0 ? 1 : 0.5,
                                      '&:hover': {
                                        bgcolor: remainingQty > 0 ? 'action.hover' : undefined
                                      }
                                    }}
                                  >
                                    <ListItemText
                                      primary={item.item_name || 'Unnamed Item'}
                                      secondary={`Available: ${remainingQty} of ${item.quantity - item.packed_quantity}`}
                                    />
                                  </ListItem>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                      Items to Pack
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {selectedItems.length} items selected
                    </Typography>
                    <Droppable droppableId="selected">
                      {(provided) => (
                        <List
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{ 
                            bgcolor: 'background.paper', 
                            minHeight: 400,
                            border: '1px dashed #ccc',
                            borderRadius: 1,
                            p: 2,
                            '& .MuiListItem-root': {
                              bgcolor: 'white',
                              boxShadow: 1,
                              borderRadius: 1,
                              mb: 1
                            }
                          }}
                        >
                          {selectedItems.map((itemId, index) => {
                            const item = getUnpackedItems().find(i => i?.shipment_item_id === itemId);
                            
                            // Add null check for item
                            if (!item) return null;

                            const itemCount = selectedItems.filter(id => id === itemId).length;
                            
                            return (
                              <Draggable 
                                key={`${itemId}-${index}`} 
                                draggableId={`${itemId}-${index}`} 
                                index={index}
                              >
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{ 
                                      border: '1px solid #eee', 
                                      mb: 1,
                                      cursor: 'grab',
                                      '&:hover': {
                                        bgcolor: 'action.hover'
                                      }
                                    }}
                                  >
                                    <ListItemText
                                      primary={item.item_name || 'Unnamed Item'}
                                      secondary={`Quantity in box: ${itemCount}`}
                                    />
                                    <IconButton 
                                      onClick={() => {
                                        const newSelectedItems = [...selectedItems];
                                        newSelectedItems.splice(index, 1);
                                        setSelectedItems(newSelectedItems);
                                      }}
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItem>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>
              </DragDropContext>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenAddItems(false)}>Cancel</Button>
              <Button 
                onClick={handleAddItems} 
                variant="contained"
                disabled={selectedItems.length === 0}
              >
                Add to Box
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default ShipmentPacking; 