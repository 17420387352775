import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  Tab,
  Tabs,
  Autocomplete,
  Chip,
  Alert,
  Snackbar,
  CircularProgress,
  Checkbox,
  Card
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, Search as SearchIcon, FilterList as FilterIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  getAllItemForwarders,
  createItemForwarder,
  deleteItemForwarder,
  createBatchItemForwarders,
  deleteBatchItemForwarders,
  getAllItems
} from '../services/api';
import InputAdornment from '@mui/material/InputAdornment';
import FullScreenLayout from './layouts/FullScreenLayout';
import { format } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
}));

const CreateForwarderButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '10px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid #e2e8f0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.15)',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#64748b',
    fontWeight: 500,
  },
  '& .MuiInputBase-input': {
    padding: '16px',
  },
}));

const StyledDataTable = styled(DataTable)(({ theme }) => ({
  '& .p-datatable-wrapper': {
    borderRadius: '12px',
    overflow: 'hidden',
  },
  '& .p-datatable-header': {
    background: 'transparent',
    border: 'none',
    padding: theme.spacing(2),
  },
  '& .p-datatable-thead > tr > th': {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontWeight: 600,
    padding: '12px 16px',
  },
  '& .p-datatable-tbody > tr': {
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: `${theme.palette.action.hover} !important`,
    },
  },
  '& .p-datatable-tbody > tr > td': {
    padding: '12px 16px',
  },
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(6),
  textAlign: 'center',
}));

function ItemForwarders() {
  const [forwarders, setForwarders] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [sourceItem, setSourceItem] = useState(null);
  const [targetItem, setTargetItem] = useState(null);
  const [oldBrim, setOldBrim] = useState('');
  const [newBrim, setNewBrim] = useState('');
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [filters, setFilters] = useState({
    search: '',
    type: '',
    size: '',
    brim: '',
    dateFrom: '',
    dateTo: ''
  });
  const [selectedForwarders, setSelectedForwarders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState({ current: 0, total: 0 });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [forwardersData, itemsData] = await Promise.all([
        getAllItemForwarders(),
        getAllItems()
      ]);
      setForwarders(forwardersData);
      setItems(itemsData);
    } catch (error) {
      showSnackbar('Error fetching data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateForwarder = async () => {
    try {
      await createItemForwarder(sourceItem.id, targetItem.id);
      showSnackbar('Item forwarder created successfully');
      fetchData();
      setOpenDialog(false);
      resetForm();
    } catch (error) {
      showSnackbar(error.response?.data?.error || 'Error creating forwarder', 'error');
    }
  };

  const handleCreateBatchForwarders = async () => {
    try {
      await createBatchItemForwarders(oldBrim, newBrim, selectedSizes);
      showSnackbar('Batch forwarders created successfully');
      fetchData();
      setOpenBatchDialog(false);
      resetForm();
    } catch (error) {
      showSnackbar(error.response?.data?.error || 'Error creating batch forwarders', 'error');
    }
  };

  const handleDeleteForwarder = async (id) => {
    try {
      await deleteItemForwarder(id);
      showSnackbar('Forwarder deleted successfully');
      fetchData();
    } catch (error) {
      showSnackbar('Error deleting forwarder', 'error');
    }
  };

  const handleDeleteSelected = async (selectedIds) => {
    try {
      await deleteBatchItemForwarders(selectedIds);
      showSnackbar('Selected forwarders deleted successfully');
      fetchData();
    } catch (error) {
      showSnackbar('Error deleting forwarders', 'error');
    }
  };

  const resetForm = () => {
    setSourceItem(null);
    setTargetItem(null);
    setOldBrim('');
    setNewBrim('');
    setSelectedSizes([]);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getUniqueValues = (field) => {
    const values = new Set();
    forwarders.forEach(forwarder => {
      values.add(forwarder[`source_item_${field}`]);
      values.add(forwarder[`target_item_${field}`]);
    });
    return Array.from(values).filter(Boolean);
  };

  const filteredForwarders = forwarders.filter(forwarder => {
    const matchesSearch = filters.search.toLowerCase() === '' || 
      forwarder.source_item_name.toLowerCase().includes(filters.search.toLowerCase()) ||
      forwarder.target_item_name.toLowerCase().includes(filters.search.toLowerCase());

    const matchesType = !filters.type || 
      forwarder.source_item_type === filters.type ||
      forwarder.target_item_type === filters.type;

    const matchesSize = !filters.size || 
      forwarder.source_item_size === filters.size ||
      forwarder.target_item_size === filters.size;

    const matchesBrim = !filters.brim || 
      forwarder.source_item_brim === filters.brim ||
      forwarder.target_item_brim === filters.brim;

    const createdAt = new Date(forwarder.created_at);
    const matchesDateFrom = !filters.dateFrom || 
      createdAt >= new Date(filters.dateFrom);
    const matchesDateTo = !filters.dateTo || 
      createdAt <= new Date(filters.dateTo);

    return matchesSearch && matchesType && matchesSize && 
           matchesBrim && matchesDateFrom && matchesDateTo;
  });

  const handleBulkDelete = async () => {
    try {
      setDeleteInProgress(true);
      const totalItems = selectedForwarders.length;
      setDeleteProgress({ current: 0, total: totalItems });
      
      let successCount = 0;
      let failCount = 0;

      for (let i = 0; i < selectedForwarders.length; i++) {
        try {
          await deleteItemForwarder(selectedForwarders[i]);
          successCount++;
        } catch (error) {
          failCount++;
          console.error(`Failed to delete forwarder ${selectedForwarders[i]}:`, error);
        }
        setDeleteProgress({ current: i + 1, total: totalItems });
      }

      showSnackbar(`Successfully deleted ${successCount} forwarders${failCount > 0 ? `, ${failCount} failed` : ''}`);
      setSelectedForwarders([]);
      setSelectAll(false);
      fetchData();
    } catch (error) {
      showSnackbar('Error during bulk delete operation', 'error');
    } finally {
      setDeleteInProgress(false);
      setDeleteProgress({ current: 0, total: 0 });
    }
  };

  const handleSelectAllSizes = () => {
    const allSizes = [...new Set(items.map(item => item.size))];
    setSelectedSizes(allSizes);
  };

  const renderFilters = () => (
    <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      <TextField
        size="small"
        placeholder="Search items..."
        value={filters.search}
        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      
      <Autocomplete
        size="small"
        options={getUniqueValues('type')}
        value={filters.type}
        onChange={(e, value) => setFilters({ ...filters, type: value || '' })}
        renderInput={(params) => (
          <TextField {...params} label="Type" sx={{ minWidth: 150 }} />
        )}
        sx={{ flexGrow: 1 }}
      />

      <Autocomplete
        size="small"
        options={getUniqueValues('size')}
        value={filters.size}
        onChange={(e, value) => setFilters({ ...filters, size: value || '' })}
        renderInput={(params) => (
          <TextField {...params} label="Size" sx={{ minWidth: 150 }} />
        )}
        sx={{ flexGrow: 1 }}
      />

      <Autocomplete
        size="small"
        options={getUniqueValues('brim')}
        value={filters.brim}
        onChange={(e, value) => setFilters({ ...filters, brim: value || '' })}
        renderInput={(params) => (
          <TextField {...params} label="Brim" sx={{ minWidth: 150 }} />
        )}
        sx={{ flexGrow: 1 }}
      />

      <TextField
        size="small"
        type="date"
        label="From Date"
        value={filters.dateFrom}
        onChange={(e) => setFilters({ ...filters, dateFrom: e.target.value })}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        size="small"
        type="date"
        label="To Date"
        value={filters.dateTo}
        onChange={(e) => setFilters({ ...filters, dateTo: e.target.value })}
        InputLabelProps={{ shrink: true }}
      />

      <Button
        variant="outlined"
        startIcon={<FilterIcon />}
        onClick={() => setFilters({
          search: '',
          type: '',
          size: '',
          brim: '',
          dateFrom: '',
          dateTo: ''
        })}
      >
        Clear Filters
      </Button>
    </Box>
  );

  const renderHeader = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 3,
      flexWrap: 'wrap',
      gap: 2
    }}>
      <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
        Item Forwarders
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CreateForwarderButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => selectedTab === 0 ? setOpenDialog(true) : setOpenBatchDialog(true)}
        >
          {selectedTab === 0 ? 'Add Forwarder' : 'Add Batch Forwarders'}
        </CreateForwarderButton>
        
        {selectedForwarders.length > 0 && (
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleBulkDelete}
            disabled={deleteInProgress}
          >
            Delete Selected ({selectedForwarders.length})
          </Button>
        )}
      </Box>
    </Box>
  );

  const renderSummary = () => (
    <Box>
      <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
        <Tab label="Single Forwarder" />
        <Tab label="Batch Forwarders" />
      </Tabs>
      {renderFilters()}
    </Box>
  );

  const renderMainPanel = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={selectAll}
                onChange={(e) => {
                  setSelectAll(e.target.checked);
                  setSelectedForwarders(e.target.checked ? filteredForwarders.map(f => f.id) : []);
                }}
              />
            </TableCell>
            <TableCell>Source Item</TableCell>
            <TableCell>Target Item</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredForwarders.map((forwarder) => (
            <TableRow key={forwarder.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedForwarders.includes(forwarder.id)}
                  onChange={(e) => {
                    setSelectedForwarders(prev => 
                      e.target.checked 
                        ? [...prev, forwarder.id]
                        : prev.filter(id => id !== forwarder.id)
                    );
                  }}
                />
              </TableCell>
              <TableCell>
                {forwarder.source_item_name}
                <Typography variant="caption" display="block">
                  Type: {forwarder.source_item_type}, Size: {forwarder.source_item_size},
                  Brim: {forwarder.source_item_brim}
                </Typography>
              </TableCell>
              <TableCell>
                {forwarder.target_item_name}
                <Typography variant="caption" display="block">
                  Type: {forwarder.target_item_type}, Size: {forwarder.target_item_size},
                  Brim: {forwarder.target_item_brim}
                </Typography>
              </TableCell>
              <TableCell>
                {new Date(forwarder.created_at).toLocaleString()}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleDeleteForwarder(forwarder.id)} color="error">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const mainContent = (
    <>
      <ContentPaper elevation={0}>
        <Box sx={{ mb: 3 }}>
          <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
            <Tab label="Single Forwarder" />
            <Tab label="Batch Forwarders" />
          </Tabs>
        </Box>

        <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <StyledTextField
            size="small"
            placeholder="Search items..."
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          
          <Autocomplete
            size="small"
            options={getUniqueValues('type')}
            value={filters.type}
            onChange={(e, value) => setFilters({ ...filters, type: value || '' })}
            renderInput={(params) => (
              <StyledTextField {...params} label="Type" sx={{ minWidth: 150 }} />
            )}
            sx={{ flexGrow: 1 }}
          />

          <Autocomplete
            size="small"
            options={getUniqueValues('size')}
            value={filters.size}
            onChange={(e, value) => setFilters({ ...filters, size: value || '' })}
            renderInput={(params) => (
              <StyledTextField {...params} label="Size" sx={{ minWidth: 150 }} />
            )}
            sx={{ flexGrow: 1 }}
          />

          <Autocomplete
            size="small"
            options={getUniqueValues('brim')}
            value={filters.brim}
            onChange={(e, value) => setFilters({ ...filters, brim: value || '' })}
            renderInput={(params) => (
              <StyledTextField {...params} label="Brim" sx={{ minWidth: 150 }} />
            )}
            sx={{ flexGrow: 1 }}
          />

          <Button
            variant="outlined"
            startIcon={<FilterIcon />}
            onClick={() => setFilters({
              search: '',
              type: '',
              size: '',
              brim: '',
              dateFrom: '',
              dateTo: ''
            })}
          >
            Clear Filters
          </Button>
        </Box>

        {filteredForwarders.length > 0 ? (
          <StyledDataTable
            value={filteredForwarders}
            paginator
            rows={10}
            dataKey="id"
            loading={loading}
            emptyMessage="No forwarders found."
            responsiveLayout="scroll"
            scrollHeight="600px"
            rowHover
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: '3em' }}
              body={(rowData) => (
                <Checkbox
                  checked={selectedForwarders.includes(rowData.id)}
                  onChange={(e) => {
                    setSelectedForwarders(prev => 
                      e.target.checked 
                        ? [...prev, rowData.id]
                        : prev.filter(id => id !== rowData.id)
                    );
                  }}
                />
              )}
            />
            <Column field="source_item_name" header="Source Item" sortable />
            <Column field="target_item_name" header="Target Item" sortable />
            <Column 
              field="created_at" 
              header="Created" 
              sortable
              body={(rowData) => format(new Date(rowData.created_at), 'MMM d, yyyy HH:mm')}
            />
            <Column
              header="Actions"
              body={(rowData) => (
                <IconButton
                  onClick={() => handleDeleteForwarder(rowData.id)}
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            />
          </StyledDataTable>
        ) : (
          <EmptyState>
            <Typography variant="h6" gutterBottom>
              No Item Forwarders Found
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Create your first item forwarder to start managing item relationships
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenDialog(true)}
            >
              Create Forwarder
            </Button>
          </EmptyState>
        )}
      </ContentPaper>

      {/* Single Forwarder Dialog */}
      <StyledDialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create Item Forwarder</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={items}
            getOptionLabel={(item) => `${item.name} (${item.type} ${item.size} ${item.brim})`}
            value={sourceItem}
            onChange={(e, value) => setSourceItem(value)}
            renderInput={(params) => (
              <TextField {...params} label="Source Item" margin="normal" fullWidth />
            )}
          />
          <Autocomplete
            options={items}
            getOptionLabel={(item) => `${item.name} (${item.type} ${item.size} ${item.brim})`}
            value={targetItem}
            onChange={(e, value) => setTargetItem(value)}
            renderInput={(params) => (
              <TextField {...params} label="Target Item" margin="normal" fullWidth />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateForwarder}
            disabled={!sourceItem || !targetItem}
            variant="contained"
          >
            Create
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* Batch Forwarders Dialog */}
      <StyledDialog open={openBatchDialog} onClose={() => setOpenBatchDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create Batch Forwarders</DialogTitle>
        <DialogContent>
          <TextField
            label="Old Brim"
            value={oldBrim}
            onChange={(e) => setOldBrim(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="New Brim"
            value={newBrim}
            onChange={(e) => setNewBrim(e.target.value)}
            margin="normal"
            fullWidth
          />
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Autocomplete
              multiple
              options={[...new Set(items.map(item => item.size))]}
              value={selectedSizes}
              onChange={(e, value) => setSelectedSizes(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Sizes" margin="normal" fullWidth />
              )}
              sx={{ flexGrow: 1 }}
            />
            <Button
              variant="outlined"
              onClick={handleSelectAllSizes}
              sx={{ mt: 2 }}
            >
              Select All Sizes
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBatchDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateBatchForwarders}
            disabled={!oldBrim || !newBrim || selectedSizes.length === 0}
            variant="contained"
          >
            Create Batch
          </Button>
        </DialogActions>
      </StyledDialog>

      <Dialog open={deleteInProgress}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <CircularProgress />
            <Typography>
              Deleting forwarders... ({deleteProgress.current}/{deleteProgress.total})
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );

  return (
    <FullScreenLayout
      header={renderHeader()}
      mainPanel={
        <PageContainer>
          {renderHeader()}
          <ContentPaper elevation={0}>
            {mainContent}
          </ContentPaper>
        </PageContainer>
      }
    />
  );
}

export default ItemForwarders; 