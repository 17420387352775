import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getCustomerProfile, customerLogout, checkCustomerAuth, getCustomerUpdates, updateCustomerProfile, markUpdateViewed } from '../services/customerapi';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
  Chip,
  IconButton,
  Badge,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getCustomerOrders } from '../services/customerapi';
import AddIcon from '@mui/icons-material/Add';
import { motion, AnimatePresence } from 'framer-motion';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TimelineIcon from '@mui/icons-material/Timeline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CommentIcon from '@mui/icons-material/Comment';
import WarningIcon from '@mui/icons-material/Warning';
import DescriptionIcon from '@mui/icons-material/Description';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ProcessingAnimation from './ProcessingAnimation';

const StyledPaper = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4, 0),
  backgroundColor: '#ffffff',
  borderRadius: '15px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const Header = styled(motion.div)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  color: '#ffffff',
  padding: theme.spacing(6, 0),
  marginBottom: theme.spacing(4),
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 20% 150%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%)',
  }
}));

const InfoCard = styled(motion.div)(({ theme }) => ({
  background: '#ffffff',
  borderRadius: '12px',
  padding: theme.spacing(3),
  height: '100%',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  border: '1px solid rgba(0, 0, 0, 0.05)',
}));

const UpdateCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderLeft: '4px solid',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateX(5px)',
  }
}));

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

function CustomerDashboard() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [updates, setUpdates] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [originalProfile, setOriginalProfile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');

  useEffect(() => {
    if (!checkCustomerAuth()) {
      navigate('/customer/login');
      return;
    }
    
    fetchProfile();
    fetchOrders();
    fetchUpdates();
  }, [navigate]);

  const fetchProfile = async () => {
    try {
      const response = await getCustomerProfile();
      setProfile(response);
      setOriginalProfile(response);
    } catch (err) {
      setError('Failed to load profile data');
      console.error('Error fetching profile:', err);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await getCustomerOrders();
      setOrders(response);
    } catch (err) {
      setError('Failed to load orders');
      console.error('Error fetching orders:', err);
    }
  };

  const fetchUpdates = async () => {
    try {
      const response = await getCustomerUpdates();
      setUpdates(response);
      setUnreadCount(response.filter(update => !update.viewed_at).length);
    } catch (err) {
      console.error('Error fetching updates:', err);
    }
  };

  const handleLogout = () => {
    customerLogout();
    navigate('/customer/login');
  };

  const getUpdateIcon = (type) => {
    switch (type) {
      case 'order_status':
        return <ShoppingCartIcon />;
      case 'comment':
        return <CommentIcon />;
      case 'delay':
        return <WarningIcon />;
      case 'document':
        return <DescriptionIcon />;
      case 'price_change':
        return <PriceChangeIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'high':
        return 'error.main';
      case 'medium':
        return 'warning.main';
      case 'low':
        return 'success.main';
      default:
        return 'info.main';
    }
  };

  const formatTimeAgo = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d ago`;
    if (hours > 0) return `${hours}h ago`;
    if (minutes > 0) return `${minutes}m ago`;
    return 'Just now';
  };

  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setUpdateMessage('Updating Profile');
    setError(''); // Clear any previous errors

    try {
      const response = await updateCustomerProfile({
        company_name: profile.company_name,
        business_type: profile.business_type,
        tax_id: profile.tax_id,
        primary_contact_name: profile.primary_contact_name,
        primary_contact_email: profile.primary_contact_email,
        primary_contact_phone: profile.primary_contact_phone,
        billing_address_street: profile.billing_address_street,
        billing_address_city: profile.billing_address_city,
        billing_address_state: profile.billing_address_state,
        billing_address_postal: profile.billing_address_postal,
        billing_address_country: profile.billing_address_country,
        shipping_address_street: profile.shipping_address_street,
        shipping_address_city: profile.shipping_address_city,
        shipping_address_state: profile.shipping_address_state,
        shipping_address_postal: profile.shipping_address_postal,
        shipping_address_country: profile.shipping_address_country
      });
      
      // Only track fields that actually changed
      const changes = [];
      Object.keys(response).forEach(key => {
        if (originalProfile[key] !== response[key] && 
            profile[key] !== originalProfile[key]) {
          changes.push(`${key.replace(/_/g, ' ')} updated from "${originalProfile[key] || ''}" to "${response[key] || ''}"`);
        }
      });

      if (changes.length > 0) {
        setUpdateMessage(`Profile Updated Successfully:\n${changes.join('\n')}`);
      } else {
        setUpdateMessage('No changes were made to the profile');
      }
      
      setOriginalProfile(response);
      setProfile(response);
      setEditMode(false);
      
      setTimeout(() => {
        setProcessing(false);
        setUpdateMessage('');
      }, 3000);
    } catch (err) {
      const errorMessage = err.response?.data?.details || err.response?.data?.error || err.message;
      setError(`Failed to update profile: ${errorMessage}`);
      setUpdateMessage(`Failed to update profile: ${errorMessage}`);
      console.error('Error updating profile:', err);
      
      setTimeout(() => {
        setProcessing(false);
        setUpdateMessage('');
      }, 3000);
    }
  };

  const handleProfileChange = (event) => {
    const { name, value } = event.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const toggleEditMode = () => {
    if (editMode) {
      setProfile(originalProfile);
    }
    setEditMode(!editMode);
  };

  const renderOrdersTab = () => (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">My Orders</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/customer/orders/create')}
        >
          Create New Order
        </Button>
      </Box>
      
      <DataTable
        value={orders}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        emptyMessage="No orders found"
      >
        <Column field="order_number" header="Order Number" sortable />
        <Column field="order_date" header="Order Date" sortable 
          body={(rowData) => new Date(rowData.order_date).toLocaleDateString()} />
        <Column field="request_status" header="Status" sortable 
          body={(rowData) => (
            <Typography
              sx={{
                color: rowData.request_status === 'awaiting_approval' ? 'warning.main' :
                       rowData.request_status === 'rejected' ? 'error.main' :
                       rowData.request_status === 'processing' ? 'info.main' :
                       rowData.request_status === 'shipped' ? 'success.main' :
                       'text.primary'
              }}
            >
              {rowData.request_status.replace('_', ' ').toUpperCase()}
            </Typography>
          )}
        />
        <Column field="eta_type" header="ETA Type" sortable
          body={(rowData) => rowData.eta_type === 'asap' ? 'ASAP' : 'Specific Date'} />
        <Column field="requested_eta_date" header="Requested ETA" sortable
          body={(rowData) => rowData.requested_eta_date ? 
            new Date(rowData.requested_eta_date).toLocaleDateString() : 'N/A'} />
        <Column 
          body={(rowData) => (
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate(`/customer/orders/${rowData.order_id}`)}
            >
              View Details
            </Button>
          )}
        />
      </DataTable>
    </Box>
  );

  const renderUpdatesTab = () => (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Recent Updates
      </Typography>
      {updates.map((update) => (
        <UpdateCard
          key={update.update_id}
          sx={{
            borderLeftColor: getPriorityColor(update.priority),
            opacity: update.viewed_at ? 0.8 : 1
          }}
          onClick={async () => {
            if (!update.viewed_at) {
              try {
                await markUpdateViewed(update.update_id);
                fetchUpdates(); // Refresh updates to update counts
              } catch (err) {
                console.error('Error marking update as viewed:', err);
              }
            }
            
            // If there's a link, open it in a new tab
            if (update.link) {
              window.open(update.link, '_blank');
            }
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
              {getUpdateIcon(update.type)}
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {update.message.split('\n')[0]}
                </Typography>
                {update.message.split('\n').length > 1 && (
                  <Typography variant="body2" color="text.secondary">
                    {update.message.split('\n').slice(1).join('\n')}
                  </Typography>
                )}
                {update.link && (
                  <Link 
                    href={update.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    sx={{ 
                      display: 'inline-block',
                      mt: 1,
                      color: 'primary.main',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    View Details →
                  </Link>
                )}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Chip
                size="small"
                icon={<AccessTimeIcon />}
                label={formatTimeAgo(update.timestamp)}
                variant="outlined"
              />
              {!update.viewed_at && (
                <Chip
                  size="small"
                  color="primary"
                  label="New"
                />
              )}
            </Box>
          </Box>
        </UpdateCard>
      ))}
    </Box>
  );

  const renderProfileTab = () => (
    <StyledPaper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h5">Profile Settings</Typography>
        <Box>
          {!editMode ? (
            <IconButton color="primary" onClick={toggleEditMode}>
              <EditIcon />
            </IconButton>
          ) : (
            <>
              <IconButton color="error" onClick={toggleEditMode}>
                <CancelIcon />
              </IconButton>
              <IconButton 
                color="success" 
                onClick={handleProfileUpdate}
                disabled={isSubmitting}
              >
                <SaveIcon />
              </IconButton>
            </>
          )}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
            sx={{ ml: 1 }}
          >
            Logout
          </Button>
        </Box>
      </Box>

      <form onSubmit={handleProfileUpdate}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Company Name"
              name="company_name"
              value={profile.company_name || ''}
              onChange={handleProfileChange}
              variant="outlined"
              margin="normal"
              disabled={!editMode}
            />
            <TextField
              fullWidth
              label="Business Type"
              name="business_type"
              value={profile.business_type || ''}
              onChange={handleProfileChange}
              variant="outlined"
              margin="normal"
              disabled={!editMode}
            />
            <TextField
              fullWidth
              label="Tax ID"
              name="tax_id"
              value={profile.tax_id || ''}
              onChange={handleProfileChange}
              variant="outlined"
              margin="normal"
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Primary Contact Name"
              name="primary_contact_name"
              value={profile.primary_contact_name || ''}
              onChange={handleProfileChange}
              variant="outlined"
              margin="normal"
              disabled={!editMode}
            />
            <TextField
              fullWidth
              label="Primary Contact Email"
              name="primary_contact_email"
              value={profile.primary_contact_email || ''}
              onChange={handleProfileChange}
              variant="outlined"
              margin="normal"
              type="email"
              disabled={!editMode}
            />
            <TextField
              fullWidth
              label="Primary Contact Phone"
              name="primary_contact_phone"
              value={profile.primary_contact_phone || ''}
              onChange={handleProfileChange}
              variant="outlined"
              margin="normal"
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Billing Address</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Street Address"
                  name="billing_address_street"
                  value={profile.billing_address_street || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="City"
                  name="billing_address_city"
                  value={profile.billing_address_city || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="State/Province"
                  name="billing_address_state"
                  value={profile.billing_address_state || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Postal Code"
                  name="billing_address_postal"
                  value={profile.billing_address_postal || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Country"
                  name="billing_address_country"
                  value={profile.billing_address_country || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Shipping Address</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Street Address"
                  name="shipping_address_street"
                  value={profile.shipping_address_street || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="City"
                  name="shipping_address_city"
                  value={profile.shipping_address_city || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="State/Province"
                  name="shipping_address_state"
                  value={profile.shipping_address_state || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Postal Code"
                  name="shipping_address_postal"
                  value={profile.shipping_address_postal || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Country"
                  name="shipping_address_country"
                  value={profile.shipping_address_country || ''}
                  onChange={handleProfileChange}
                  variant="outlined"
                  disabled={!editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <ProcessingAnimation 
        open={processing} 
        message={updateMessage} 
      />
    </StyledPaper>
  );

  if (!profile) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <AnimatePresence>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <Header
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <motion.div
                initial={{ x: -30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
                  Welcome Back, {profile.company_name}
                </Typography>
                <Typography variant="h6" sx={{ opacity: 0.9 }}>
                  Manage your orders and business information
                </Typography>
              </motion.div>
              <IconButton color="inherit" sx={{ ml: 2 }}>
                <Badge badgeContent={unreadCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Box>
          </Container>
        </Header>

        <Container>
          <TabView 
            activeIndex={activeTab} 
            onTabChange={(e) => setActiveTab(e.index)}
            style={{
              borderRadius: '12px',
              overflow: 'hidden',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)'
            }}
          >
            <TabPanel header="Updates">
              <StyledPaper>
                {renderUpdatesTab()}
              </StyledPaper>
            </TabPanel>
            
            <TabPanel header="Orders">
              <StyledPaper>
                {renderOrdersTab()}
              </StyledPaper>
            </TabPanel>

            <TabPanel header="Profile">
              {renderProfileTab()}
            </TabPanel>
          </TabView>
        </Container>
      </motion.div>
    </AnimatePresence>
  );
}

export default CustomerDashboard;