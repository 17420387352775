import React, { useState, useEffect, useRef } from 'react';
import { getLocations, updateReplenishLevelsById, runUpdateReplenishLevels, runReloadProcess, deleteLocation } from '../services/api';
import { Container, Typography, Grid, Card, CardContent, IconButton, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { styled } from '@mui/material/styles';
import CustomButton from './CustomButton';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FullScreenLayout from './layouts/FullScreenLayout';
import CircularProgress from '@mui/material/CircularProgress';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  gap: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#ffffff',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  padding: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
    borderColor: theme.palette.primary.main,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  fontSize: '2.5rem',
  padding: theme.spacing(2, 0),
  borderBottom: `2px solid ${theme.palette.divider}`,
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  transform: props => props.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SheetLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: theme.spacing(2, 0),
}));

const SheetButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  textTransform: 'none',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  marginBottom: theme.spacing(1),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
    transform: 'translateX(4px)',
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1.5),
    fontSize: '1.2rem',
  },
}));

const ProgressBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  left: theme.spacing(3),
  right: theme.spacing(3),
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  border: `1px solid ${theme.palette.divider}`,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2, 3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontWeight: 600,
  padding: theme.spacing(1, 3),
}));

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiAlert-root': {
    width: '100%',
    alignItems: 'center',
    fontSize: '1rem',
    '& .MuiAlert-icon': {
      fontSize: '2rem',
    },
  },
}));

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  marginBottom: theme.spacing(4),
  width: '100%',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  width: '100%',
  maxWidth: '100%',
}));

const SearchField = styled(TextField)(({ theme }) => ({
  flex: 1,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    },
  },
}));

const FilterSelect = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    },
  },
}));

function Locations() {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);
  const replenishSettingsRef = useRef(null);
  const [isReloading, setIsReloading] = useState(false);
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [editingReplenishLevels, setEditingReplenishLevels] = useState({});
  const [originalReplenishLevels, setOriginalReplenishLevels] = useState({});
  const [reloadProgress, setReloadProgress] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState(localStorage.getItem('locationFilterPreference') || 'all');
  const [filteredLocations, setFilteredLocations] = useState([]);

  useEffect(() => {
    // Removed loadCachedLocations call
    fetchLocations();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let filtered = [...locations];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(location => 
        location.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply category filter
    switch (filterBy) {
      case 'high-inventory':
        filtered = filtered.sort((a, b) => b.TotalInventory - a.TotalInventory);
        break;
      case 'low-inventory':
        filtered = filtered.sort((a, b) => a.TotalInventory - b.TotalInventory);
        break;
      default:
        // 'all' - no filtering needed
        break;
    }

    setFilteredLocations(filtered);
  }, [locations, searchTerm, filterBy]);

  const fetchLocations = async () => {
    try {
      const response = await getLocations();
      console.log('Fetched Locations:', response);
      if (Array.isArray(response)) {
        setLocations(response);
        setLoading(false);
        // Removed localStorage.setItem call
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (err) {
      console.error('Error fetching locations:', err);
      setError('Failed to fetch locations. Please try again later.');
      setLoading(false);
    }
  };

  const handleClickOutside = (event) => {
    if (replenishSettingsRef.current && !replenishSettingsRef.current.contains(event.target)) {
      setEditingLocation(null);
    }
  };

  const handleReplenishLevelChange = (locationId, category, value) => {
    setEditingReplenishLevels(prev => ({
      ...prev,
      [locationId]: {
        ...prev[locationId],
        [category]: parseInt(value) || 0
      }
    }));
  };

  const saveReplenishLevels = async (locationId) => {
    try {
      const updatedLevels = {
        G: editingReplenishLevels[locationId].G,
        M: editingReplenishLevels[locationId].M,
        G2: editingReplenishLevels[locationId].G2,
        M2: editingReplenishLevels[locationId].M2
      };

      const response = await updateReplenishLevelsById(locationId, updatedLevels);

      if (response && response.data) {
        setLocations(locations.map(loc => 
          loc.db_location_id === locationId 
            ? { ...loc, ReplenishLevels: response.data }
            : loc
        ));
        setMessage('Replenish levels updated successfully!');
        setOriginalReplenishLevels(prev => ({ ...prev, [locationId]: null }));
        setEditingReplenishLevels(prev => ({ ...prev, [locationId]: null }));
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error updating replenish levels:', err);
      setMessage('Failed to update replenish levels. Please try again.');
    }
    setTimeout(() => setMessage(null), 3000);
  };

  const revertReplenishLevels = (locationId) => {
    setEditingReplenishLevels(prev => ({ ...prev, [locationId]: null }));
    setOriginalReplenishLevels(prev => ({ ...prev, [locationId]: null }));
  };

  const updateLevels = async (locationId) => {
    const location = locations.find(loc => loc.db_location_id === locationId);
    try {
      await updateReplenishLevelsById(locationId, {
        g_replenish_level: location.ReplenishLevels.G,
        m_replenish_level: location.ReplenishLevels.M,
        g2_replenish_level: location.ReplenishLevels.G2,
        m2_replenish_level: location.ReplenishLevels.M2
      });
      setMessage('Replenish levels updated successfully!');
      setEditingLocation(null);
    } catch (err) {
      console.error('Error updating replenish levels:', err);
      setMessage('Failed to update replenish levels. Please try again.');
    }
    setTimeout(() => setMessage(null), 20000);
  };

  const handleReload = async () => {
    setIsReloading(true);
    setMessage(null);
    setError(null);
    setReloadProgress({ step: 1, details: {} });

    // Removed localStorage.removeItem call

    try {
      const progressUpdates = await runReloadProcess();
      
      for (const update of progressUpdates) {
        setReloadProgress(update);
        // Reduced delay for smoother progress
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const updatedLocations = await getLocations();
      if (Array.isArray(updatedLocations)) {
        setLocations(updatedLocations);
        // Removed localStorage.setItem call
        setMessage('Locations reloaded successfully!');
      } else {
        throw new Error('Invalid data format received during reload');
      }
    } catch (err) {
      console.error('Error reloading locations:', err);
      setError('Failed to reload locations. Please try again.');
    } finally {
      setIsReloading(false);
      setReloadProgress(null);
    }
  };

  const handleLocationClick = (event, locationId) => {
    if (!event.target.closest('.MuiIconButton-root')) {
      navigate(`/locations/${locationId}`);
    }
  };

  const handleMenuClick = (event, location) => {
    setAnchorEl(event.currentTarget);
    setSelectedLocation(location);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLocation(null);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  const handleEditReplenishLevelsClick = () => {
    navigate(`/locations/${selectedLocation.db_location_id}/replenish-levels`);
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedLocation) {
      setError('No location selected for deletion. Please try again.');
      setDeleteConfirmOpen(false);
      return;
    }

    try {
      await deleteLocation(selectedLocation.db_location_id);
      setLocations(locations.filter(loc => loc.db_location_id !== selectedLocation.db_location_id));
      setMessage(`Location "${selectedLocation.name}" has been successfully deleted.`);
    } catch (err) {
      console.error('Error deleting location:', err);
      setError(`Failed to delete location "${selectedLocation.name}". Please try again.`);
    }
    setDeleteConfirmOpen(false);
    setSelectedLocation(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleExpandClick = (event, locationId) => {
    event.stopPropagation(); // Prevent the card click event from firing
    navigate(`/locations/${locationId}`);
  };

  const handleFilterChange = (event) => {
    const newFilter = event.target.value;
    setFilterBy(newFilter);
    localStorage.setItem('locationFilterPreference', newFilter);
  };

  const renderHeader = () => (
    <HeaderContainer>
      <Box display="flex" flexDirection="column" width="100%">
        <Title variant="h4">Locations</Title>
        <SearchContainer>
          <SearchField
            placeholder="Search locations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          
          <FilterSelect>
            <InputLabel id="filter-select-label">
              <FilterListIcon sx={{ mr: 1 }} />
              Filter By
            </InputLabel>
            <Select
              labelId="filter-select-label"
              value={filterBy}
              onChange={handleFilterChange}
              label="Filter By"
            >
              <MenuItem value="all">All Locations</MenuItem>
              <MenuItem value="high-inventory">Highest Inventory</MenuItem>
              <MenuItem value="low-inventory">Lowest Inventory</MenuItem>
            </Select>
          </FilterSelect>
        </SearchContainer>
      </Box>
    </HeaderContainer>
  );

  const renderMainContent = () => (
    <>
      {message && <Typography color="secondary" gutterBottom>{message}</Typography>}
      
      <Grid container spacing={3}>
        {filteredLocations.map((location) => (
          <Grid item xs={12} sm={6} md={4} key={location.db_location_id}>
            <StyledCard onClick={(event) => handleLocationClick(event, location.db_location_id)}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" component="div">
                    {location.name}
                  </Typography>
                </Box>
                <Typography color="textSecondary">
                  Total Inventory: {location.TotalInventory}
                </Typography>
                <SheetLinks>
                  {location.InventorySheet && (
                    <SheetButton 
                      href={location.InventorySheet} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      startIcon={<DescriptionIcon />}
                      size="small"
                    >
                      Inventory
                    </SheetButton>
                  )}
                  {location.RecommendedInventorySheet && (
                    <SheetButton 
                      href={location.RecommendedInventorySheet} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      startIcon={<AssignmentIcon />}
                      size="small"
                    >
                      Recommended
                    </SheetButton>
                  )}
                  {location.OrderSheet && (
                    <SheetButton 
                      href={location.OrderSheet} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      startIcon={<ShoppingCartIcon />}
                      size="small"
                    >
                      Order
                    </SheetButton>
                  )}
                </SheetLinks>
                <ExpandButton
                  onClick={(event) => handleExpandClick(event, location.db_location_id)}
                >
                  <ExpandMoreIcon />
                </ExpandButton>
                {expandedLocation === location.db_location_id && (
                  <>
                    <div className="inventory-breakdown">
                      <Typography>G: {location.InventoryByCategory?.G || 'N/A'}</Typography>
                      <Typography>M: {location.InventoryByCategory?.M || 'N/A'}</Typography>
                      <Typography>G2: {location.InventoryByCategory?.G2 || 'N/A'}</Typography>
                      <Typography>M2: {location.InventoryByCategory?.M2 || 'N/A'}</Typography>
                    </div>
                    <div className="replenish-levels">
                      <Typography variant="subtitle1">Replenish Levels:</Typography>
                      {['G', 'M', 'G2', 'M2'].map(category => (
                        <div key={category} className="replenish-level-input">
                          <TextField
                            label={category}
                            type="number"
                            value={
                              (editingReplenishLevels[location.db_location_id] && 
                               editingReplenishLevels[location.db_location_id][category]) ||
                              location.ReplenishLevels[category]
                            }
                            onChange={(e) => handleReplenishLevelChange(location.db_location_id, category, e.target.value)}
                            size="small"
                            style={{ width: '100px', marginRight: '10px' }}
                          />
                          <Typography 
                            variant="body2" 
                            color={
                              location.InventoryByCategory[category] < location.ReplenishLevels[category] ? 'error' :
                              location.InventoryByCategory[category] === location.ReplenishLevels[category] ? 'primary' :
                              'success'
                            }
                          >
                            Current: {location.InventoryByCategory[category]}
                          </Typography>
                        </div>
                      ))}
                      {editingReplenishLevels[location.db_location_id] && (
                        <div className="replenish-level-actions">
                          <IconButton onClick={() => saveReplenishLevels(location.db_location_id)} color="primary">
                            <SaveIcon />
                          </IconButton>
                          <IconButton onClick={() => revertReplenishLevels(location.db_location_id)} color="secondary">
                            <UndoIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <StyledSnackbar
        open={!!message || !!error}
        autoHideDuration={6000}
        onClose={() => {
          setMessage(null);
          setError(null);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => {
            setMessage(null);
            setError(null);
          }} 
          severity={message ? "success" : "error"} 
          elevation={6}
          variant="filled"
        >
          {message || error}
        </Alert>
      </StyledSnackbar>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditReplenishLevelsClick}>
          <EditIcon style={{ marginRight: 8 }} /> Edit Replenish Levels
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon style={{ marginRight: 8 }} /> Delete
        </MenuItem>
      </Menu>

      <StyledDialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the location "{selectedLocation?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleDeleteCancel} color="primary" variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDeleteConfirm} color="error" variant="contained" autoFocus>
            Delete
          </StyledButton>
        </DialogActions>
      </StyledDialog>

      {reloadProgress && (
        <ReloadProgressSnackbar open={!!reloadProgress} progress={reloadProgress} />
      )}
    </>
  );

  if (loading && locations.length === 0) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <FullScreenLayout
      header={renderHeader()}
      mainPanel={
        <PageContainer>
          {renderMainContent()}
        </PageContainer>
      }
      hasNavigation={true}
    />
  );
}

function ReloadProgressSnackbar({ open, progress }) {
  if (!progress) return null;

  const steps = [
    "Validating POS locations",
    "Updating inventory levels",
    "Updating replenish levels",
    "Generating Google Sheets",
    "Finalizing data"
  ];

  const getProgressDetails = () => {
    switch (progress.step) {
      case 1:
        return `POS checked: ${progress.details.posChecked || 0}, New locations: ${progress.details.newLocations || 0}`;
      case 2:
      case 3:
        return `Items: ${progress.details.itemsProcessed || 0} / ${progress.details.totalItems || 0}`;
      case 4:
        return `Sheets: ${progress.details.sheetsGenerated || 0} / ${progress.details.totalSheets || 0}`;
      default:
        return '';
    }
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <ProgressBox>
        <Typography variant="subtitle1" gutterBottom>
          {steps[progress.step - 1]}
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={(progress.step / steps.length) * 100} 
          sx={{ marginBottom: 1 }}
        />
        <Typography variant="body2">
          {getProgressDetails()}
        </Typography>
      </ProgressBox>
    </Snackbar>
  );
}

export default Locations;