import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Collapse,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  Comment as CommentIcon,
  CheckCircle as CheckCircleIcon,
  Share as ShareIcon,
  CloudDownload as CloudDownloadIcon,
  ViewList as ViewListIcon,
  GridView as GridViewIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  getWarehouseItemsForProduction,
  getProductionEntryById,
  createProductionEntry,
  updateProductionEntry,
} from '../services/api';
import debounce from 'lodash/debounce';
import useSound from 'use-sound';
import { motion, AnimatePresence } from 'framer-motion';
import ProcessingAnimation from './ProcessingAnimation';
import FullScreenLayout from './layouts/FullScreenLayout';
import OptimizedItemSearch from './common/OptimizedItemSearch';
import OptimizedSpreadsheet from './spreadsheets/OptimizedSpreadsheet';
import ItemsCacheService from '../services/itemsCacheService';
import { useBeforeUnload } from 'react-use';

const successSound = '/success.wav';
const errorSound = '/error.wav';
const PRODUCTION_CACHE_KEY = 'production_entry_cache';
const ITEMS_CACHE_KEY = 'warehouse_items_cache';

const saveToCache = (data) => {
  try {
    localStorage.setItem(PRODUCTION_CACHE_KEY, JSON.stringify(data));
  } catch (error) {
    console.error('Error saving to cache:', error);
  }
};

const loadFromCache = () => {
  try {
    const cached = localStorage.getItem(PRODUCTION_CACHE_KEY);
    return cached ? JSON.parse(cached) : null;
  } catch (error) {
    console.error('Error loading from cache:', error);
    return null;
  }
};

const clearCache = () => {
  localStorage.removeItem(PRODUCTION_CACHE_KEY);
};

const AnimatedButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Button
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        ...props.sx,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        },
      }}
    >
      {children}
    </Button>
  );
};

const LoadingSpinner = () => (
  <Box sx={{ 
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }}>
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.5, 1, 0.5],
      }}
      transition={{ 
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      style={{
        width: '120px',
        height: '120px',
        borderRadius: '50%',
        border: '4px solid #1976d2',
        borderTopColor: 'transparent',
        margin: '0 auto 24px'
      }}
    />
    <Typography variant="h6" sx={{ mt: 2, color: 'primary.main' }}>
      Loading...
    </Typography>
  </Box>
);

const ProductionEntry = () => {
  const { warehouseId, productionId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    warehouse_id: warehouseId,
    notes: '',
    items: []
  });
  const [cachedItems, setCachedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Map());
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [playSuccess] = useSound(successSound, { volume: 0.5 });
  const [playError] = useSound(errorSound, { volume: 0.5 });
  const searchInputRef = useRef(null);
  const [showNotes, setShowNotes] = useState(false);
  const [itemNotes, setItemNotes] = useState({});
  const searchCache = useRef(new Map());
  const [totals, setTotals] = useState({
    totalItems: 0,
    totalScans: 0,
    totalQuantity: 0
  });
  const [isCreating, setIsCreating] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [pendingChanges, setPendingChanges] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [pendingChangesMinimized, setPendingChangesMinimized] = useState(false);

  useBeforeUnload(
    hasUnsavedChanges,
    'You have unsaved changes. Are you sure you want to leave?'
  );

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  useEffect(() => {
    fetchData();
  }, [warehouseId, productionId]);

  useEffect(() => {
    const newTotals = {
      totalItems: selectedItems.size,
      totalScans: selectedItems.size, // or could be sum of all quantities
      totalQuantity: Array.from(selectedItems.values())
        .reduce((sum, item) => sum + (item.quantity || 0), 0)
    };
    setTotals(newTotals);
  }, [selectedItems]);

  useEffect(() => {
    const newSpreadsheetData = {};
    
    // Add all cached items with 0 quantities by default
    cachedItems.forEach(item => {
      newSpreadsheetData[item.id] = {
        current_quantity: 0,
        new_quantity: 0
      };
    });

    // Override with selected items' quantities
    selectedItems.forEach((data, itemId) => {
      newSpreadsheetData[itemId] = {
        current_quantity: data.quantity,
        new_quantity: data.quantity
      };
    });
    
    setSpreadsheetData(newSpreadsheetData);
  }, [cachedItems, selectedItems]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      let items = ItemsCacheService.getCachedItems();
      if (!items) {
        items = await ItemsCacheService.getItems();
      }
      setCachedItems(items);

      if (productionId) {
        const productionData = await getProductionEntryById(productionId);
        
        const itemsMap = new Map();
        productionData.items.forEach(item => {
          const cachedItem = items.find(i => i.id === item.warehouse_item_id);
          if (cachedItem) {
            itemsMap.set(item.warehouse_item_id, {
              quantity: item.quantity,
              item_details: cachedItem
            });
          }
        });
        setSelectedItems(itemsMap);
      } else {
        // Try to load from cache if no productionId
        const cachedData = loadFromCache();
        if (cachedData) {
          setSelectedItems(new Map(cachedData.selectedItems));
          setFormData(cachedData.formData);
          setSnackbar({
            open: true,
            message: 'Restored from previous session',
            severity: 'info'
          });
        }
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch data: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedItems.size > 0 || formData.notes) {
      setHasUnsavedChanges(true);
      saveToCache({
        selectedItems: Array.from(selectedItems.entries()),
        formData
      });
    }
  }, [selectedItems, formData]);

  const handleAddItem = (itemToAdd) => {
    if (!itemToAdd) return;

    const currentItem = selectedItems.get(itemToAdd.id);
    const newQuantity = (currentItem?.quantity || 0) + 1;

    setSelectedItems(prev => new Map(prev).set(itemToAdd.id, {
      quantity: newQuantity,
      item_details: itemToAdd
    }));

    playSuccess();
    
    setSnackbar({
      open: true,
      message: `${currentItem ? 'Increased' : 'Added'} ${itemToAdd.item_name} quantity to ${newQuantity}`,
      severity: 'success'
    });

    if (searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems(prev => {
      const newMap = new Map(prev);
      newMap.delete(itemId);
      return newMap;
    });
  };

  const handleItemChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const validateForm = () => {
    if (formData.items.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please add at least one item',
        severity: 'error'
      });
      return false;
    }

    for (const item of formData.items) {
      if (!item.warehouse_item_id || item.quantity <= 0) {
        setSnackbar({
          open: true,
          message: 'Please fill in all required fields for each item',
          severity: 'error'
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (selectedItems.size === 0) {
      setSnackbar({
        open: true,
        message: 'Please add at least one item',
        severity: 'error'
      });
      return;
    }

    setIsCreating(true);
    try {
      const productionData = {
        warehouse_id: warehouseId,
        notes: formData.notes,
        items: Array.from(selectedItems.entries()).map(([itemId, data]) => ({
          item_id: parseInt(itemId),
          quantity: data.quantity
        }))
      };

      const minLoadingTime = new Promise(resolve => setTimeout(resolve, 2000));
      
      const apiCall = productionId
        ? updateProductionEntry(productionId, productionData)
        : createProductionEntry(productionData);

      await Promise.all([minLoadingTime, apiCall]);
      
      clearCache();
      setShowSuccessDialog(true);
      setTimeout(() => {
        setShowSuccessDialog(false);
        navigate(`/warehouses/${warehouseId}`);
      }, 2000);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to ${productionId ? 'update' : 'create'} production entry: ${error.message}`,
        severity: 'error'
      });
      playError();
    } finally {
      setIsCreating(false);
    }
  };

  const handleCancel = () => {
    if (hasUnsavedChanges) {
      setConfirmDialog(true);
    } else {
      navigate(`/warehouses/${warehouseId}`);
    }
  };

  const handleConfirmedNavigation = () => {
    clearCache();
    setConfirmDialog(false);
    navigate(`/warehouses/${warehouseId}`);
  };

  const handleSpreadsheetChange = useCallback(({ itemId, metric, newValue }) => {
    if (metric !== 'new_quantity') return;
    
    const numericId = parseInt(itemId);
    const item = cachedItems.find(item => item.id === numericId);
    if (!item) return;

    const currentValue = selectedItems.get(numericId)?.quantity || 0;
    
    setPendingChanges(prev => [{
      timestamp: new Date().toLocaleTimeString(),
      warehouse_item_id: numericId,
      itemName: item.item_name,
      type: item.type_name,
      size: item.size_name,
      brim: item.brim_name,
      shape: item.shape_name,
      oldValue: currentValue,
      newValue
    }, ...prev]);

    setSpreadsheetData(prev => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        new_quantity: newValue
      }
    }));
  }, [cachedItems, selectedItems]);

  const applySpreadsheetChanges = () => {
    const newSelectedItems = new Map(selectedItems);
    
    pendingChanges.forEach(change => {
      const item = cachedItems.find(i => i.id === change.warehouse_item_id);
      if (!item) return;

      if (change.newValue > 0) {
        newSelectedItems.set(change.warehouse_item_id, {
          quantity: change.newValue,
          item_details: item
        });
      } else {
        newSelectedItems.delete(change.warehouse_item_id);
      }
    });

    setSelectedItems(newSelectedItems);
    setPendingChanges([]);
    playSuccess();
    setSnackbar({
      open: true,
      message: 'Changes applied successfully',
      severity: 'success'
    });
  };

  const renderHeader = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 3,
      px: 2,
      py: 1,
      backgroundColor: 'background.paper',
      borderBottom: 1,
      borderColor: 'divider'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton onClick={handleCancel} size="large">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">
          {productionId ? 'Edit Production Entry' : 'New Production Entry'}
        </Typography>
        <Box sx={{ ml: 2 }}>
          <IconButton 
            onClick={() => setViewMode('list')}
            color={viewMode === 'list' ? 'primary' : 'default'}
          >
            <ViewListIcon />
          </IconButton>
          <IconButton 
            onClick={() => setViewMode('spreadsheet')}
            color={viewMode === 'spreadsheet' ? 'primary' : 'default'}
          >
            <GridViewIcon />
          </IconButton>
        </Box>
      </Box>
      
      <AnimatedButton
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        loading={isCreating}
        onClick={handleSubmit}
        size="large"
        sx={{ 
          px: 4,
          py: 1,
          fontSize: '1.1rem',
          minWidth: 180
        }}
      >
        {productionId ? 'Update' : 'Create'} Production
      </AnimatedButton>
    </Box>
  );

  const renderSearchField = () => (
    <Box sx={{ 
      mb: 3,
      width: '100%',
      maxWidth: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 1200,
      backgroundColor: 'background.paper',
      p: 2,
      borderBottom: '1px solid',
      borderColor: 'divider',
      boxShadow: 2
    }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Search Items
      </Typography>
      <OptimizedItemSearch
        onItemSelect={(item) => {
          if (item) {
            handleAddItem(item);
          }
        }}
        searchRef={searchInputRef}
        showQuantity={true}
        placeholder="Search or scan items..."
        clearOnSelect={true}
      />
    </Box>
  );

  const renderMainContent = () => (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {viewMode === 'list' && renderSearchField()}
      
      <Box sx={{ 
        display: 'flex',
        gap: 3,
        p: viewMode === 'list' ? 1 : 0.5,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: viewMode === 'list' ? 2 : 1,
        mb: viewMode === 'list' ? 2 : 1,
        mx: viewMode === 'list' ? 2 : 0,
        justifyContent: 'center',
        ...(viewMode === 'spreadsheet' && {
          position: 'sticky',
          top: 0,
          zIndex: 1100,
          borderBottom: 1,
          borderColor: 'divider',
        })
      }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" color="primary.main">
            {totals.totalItems || 0}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Unique Items
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" color="success.main">
            {totals.totalScans || 0}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Total Scans
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" color="info.main">
            {totals.totalQuantity || 0}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Total Quantity
          </Typography>
        </Box>
      </Box>
      
      {viewMode === 'list' ? (
        <TableContainer 
          component={Paper} 
          sx={{ 
            flex: 1, 
            overflow: 'auto',
            boxShadow: 2,
            borderRadius: 2,
            mx: 2
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                  Item
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                  Quantity
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                  Notes
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(selectedItems.entries()).map(([itemId, data]) => (
                <TableRow key={itemId}>
                  <TableCell>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {data.item_details.item_name}
                      {data.item_details.barcode && (
                        <Typography 
                          component="span" 
                          sx={{ 
                            ml: 1,
                            color: 'text.secondary',
                            fontFamily: 'monospace',
                            fontSize: '0.9em'
                          }}
                        >
                          ({data.item_details.barcode})
                        </Typography>
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {[
                        data.item_details.type_name,
                        data.item_details.size_name,
                        data.item_details.shape_name,
                        data.item_details.brim_name
                      ]
                        .filter(Boolean)
                        .join(' • ')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={data.quantity}
                      onChange={(e) => {
                        const newQuantity = parseInt(e.target.value) || 0;
                        setSelectedItems(prev => new Map(prev).set(itemId, {
                          ...data,
                          quantity: newQuantity
                        }));
                      }}
                      size="small"
                      inputProps={{ min: 0 }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      size="small" 
                      onClick={() => {
                        setItemNotes({ ...itemNotes, [itemId]: data.item_details.notes });
                        setShowNotes(true);
                      }}
                    >
                      <CommentIcon 
                        color={data.item_details.notes ? 'primary' : 'action'} 
                        sx={{ 
                          transition: 'transform 0.2s',
                          '&:hover': { transform: 'scale(1.1)' }
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRemoveItem(itemId)} color="error" size="small">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ 
          flex: 1,
          height: 'calc(100vh - 150px)',
          p: 1,
          mt: -1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box sx={{ 
            width: '100%',
            height: '100%',
            position: 'relative',
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <OptimizedSpreadsheet
              data={spreadsheetData}
              metrics={['current_quantity', 'new_quantity']}
              onCellValueChanged={handleSpreadsheetChange}
              hideZeros={false}
              fullScreen
              title="Production Entry Items"
              readOnlyMetrics={['current_quantity']}
            />
            
            <Collapse
              in={!pendingChangesMinimized}
              collapsedSize={48}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                width: 300,
                maxHeight: 'calc(100vh - 150px)',
                backgroundColor: 'background.paper',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'all 0.3s ease',
                zIndex: 1000,
                '&:hover': {
                  boxShadow: 4
                }
              }}
            >
              <Card sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: 'none',
                borderRadius: 2,
              }}>
                <CardContent sx={{ 
                  p: 2, 
                  pb: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: pendingChangesMinimized ? 0 : 2
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="h6" sx={{ 
                        fontSize: pendingChangesMinimized ? '1rem' : '1.25rem',
                        transition: 'all 0.3s ease'
                      }}>
                        Pending Changes {pendingChanges.length > 0 && `(${pendingChanges.length})`}
                      </Typography>
                      <IconButton 
                        size="small"
                        onClick={() => setPendingChangesMinimized(!pendingChangesMinimized)}
                      >
                        {pendingChangesMinimized ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={applySpreadsheetChanges}
                      startIcon={<SaveIcon />}
                      disabled={pendingChanges.length === 0}
                      size="small"
                      sx={{
                        minWidth: pendingChangesMinimized ? 'unset' : 'auto',
                        px: pendingChangesMinimized ? 1 : 2
                      }}
                    >
                      {pendingChangesMinimized ? '' : 'Apply Changes'}
                    </Button>
                  </Box>
                  
                  {!pendingChangesMinimized && (
                    <Box sx={{ 
                      overflow: 'auto',
                      flex: 1,
                      maxHeight: 'calc(100vh - 300px)',
                      transition: 'all 0.3s ease'
                    }}>
                      {pendingChanges.length === 0 ? (
                        <Typography 
                          variant="body2" 
                          color="text.secondary" 
                          sx={{ 
                            textAlign: 'center', 
                            py: 2,
                            fontStyle: 'italic'
                          }}
                        >
                          No pending changes
                        </Typography>
                      ) : (
                        pendingChanges.map((change, index) => (
                          <Paper 
                            key={index} 
                            sx={{ 
                              p: 1.5,
                              mb: 1,
                              backgroundColor: 'rgba(0, 0, 0, 0.02)',
                              borderLeft: '3px solid',
                              borderColor: change.newValue > change.oldValue 
                                ? 'success.main' 
                                : 'error.main',
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                transform: 'translateX(4px)',
                                boxShadow: 1
                              }
                            }}
                          >
                            <Typography variant="caption" display="block" color="text.secondary">
                              {change.timestamp}
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                              {change.itemName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {change.type} • {change.size}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {change.brim} • {change.shape}
                            </Typography>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                mt: 0.5,
                                color: change.newValue > change.oldValue 
                                  ? 'success.main' 
                                  : 'error.main',
                                fontWeight: 500 
                              }}
                            >
                              Quantity: {change.oldValue} → {change.newValue}
                            </Typography>
                          </Paper>
                        ))
                      )}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Collapse>
          </Box>
        </Box>
      )}
    </Box>
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FullScreenLayout
        header={renderHeader()}
        mainPanel={renderMainContent()}
        hasNavigation
        sx={{
          '& > .main-panel': {
            height: 'calc(100vh - 64px)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      />
      
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes that will be lost if you leave this page. 
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>
            Stay
          </Button>
          <Button 
            onClick={handleConfirmedNavigation} 
            color="error"
            variant="contained"
          >
            Leave and Discard Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showSuccessDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            background: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(8px)',
          }
        }}
      >
        <DialogContent sx={{ textAlign: 'center', py: 6 }}>
          <motion.div
            initial={{ scale: 0, rotate: -180 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20 
            }}
          >
            <CheckCircleIcon 
              sx={{ 
                fontSize: 80,
                color: 'success.main',
                filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))'
              }} 
            />
          </motion.div>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Typography variant="h5" sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
              Success!
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Production Entry {productionId ? 'Updated' : 'Created'} Successfully
            </Typography>
          </motion.div>
        </DialogContent>
      </Dialog>

      <ProcessingAnimation 
        open={isCreating} 
        message={`${productionId ? 'Updating' : 'Creating'} Production Entry`}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductionEntry; 