import React, { useState, useEffect } from 'react';
import {
    getLocations,
    getLocationsWithMissingItems,
    getAvailableTransferItems,
    createTransferOrder,
    getTransferOrders,
    updateTransferOrderStatus,
    getAvailableTransferItemsIgnoreSource
} from '../services/api';
import {
    Container,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardActions,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    Snackbar,
    Alert,
    TextField,
    Stepper,
    Step,
    StepLabel,
    Collapse,
    Tabs,
    Tab,
    Box,
    IconButton,
    Tooltip,
    Chip,
    FormControlLabel,
    Switch,
    FormControl
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
    Refresh as RefreshIcon,
    Send as SendIcon,
    ArrowBack as ArrowBackIcon,
    CheckCircle as CheckCircleIcon,
    Cancel as CancelIcon,
    Inventory as InventoryIcon,
    LocalShipping as LocalShippingIcon,
    ArrowForward as ArrowForwardIcon,
    Description as DescriptionIcon
} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Confetti from 'react-confetti';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import FullScreenLayout from './layouts/FullScreenLayout';

// Add this styled component near the top of your file, after the imports
const CenteredSnackbar = styled(Snackbar)(({ theme }) => ({
  left: '50%',
  transform: 'translateX(-50%)',
  right: 'auto',
  '& .MuiAlert-root': {
    width: '100%',
    minWidth: '300px',
    maxWidth: '500px',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiAlert-message': {
    fontSize: '1.1rem',
    padding: theme.spacing(1),
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
    borderColor: theme.palette.primary.main,
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiCardActions-root': {
    padding: theme.spacing(2, 3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  '& .MuiTableHead-root': {
    backgroundColor: theme.palette.background.default,
    '& .MuiTableCell-root': {
      fontWeight: 600,
      borderBottom: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(2),
    },
  },
  '& .MuiTableBody-root .MuiTableCell-root': {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiTableRow-root:last-child .MuiTableCell-root': {
    borderBottom: 'none',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  padding: theme.spacing(3, 0, 4),
  '& .MuiStepLabel-root': {
    padding: theme.spacing(0, 2),
  },
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.divider,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(3),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    },
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  fontWeight: 500,
  '&.MuiChip-colorPrimary': {
    backgroundColor: theme.palette.primary.main + '20',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}40`,
  },
}));

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

function TransferOrders() {
    const [locations, setLocations] = useState([]);
    const [locationsWithMissingItems, setLocationsWithMissingItems] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [availableTransfers, setAvailableTransfers] = useState([]);
    const [transferOrders, setTransferOrders] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [activeStep, setActiveStep] = useState(0);
    const [selectedSourceLocation, setSelectedSourceLocation] = useState(null);
    const [showAllItems, setShowAllItems] = useState(false);
    const [excessiveItems, setExcessiveItems] = useState([]);
    const [selectedExcessItems, setSelectedExcessItems] = useState({});
    const [expandedLocation, setExpandedLocation] = useState(null);
    const [totalNeededItems, setTotalNeededItems] = useState({});
    const [totalAvailableItems, setTotalAvailableItems] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [locationSelectionState, setLocationSelectionState] = useState({});
    const [tabValue, setTabValue] = useState(() => {
        const savedTab = localStorage.getItem('transferOrdersTab');
        return savedTab ? parseInt(savedTab, 10) : 0;
    });
    const [selectedLocations, setSelectedLocations] = useState(new Set());
    const [selectedItemsCount, setSelectedItemsCount] = useState(0);
    const [selectedItemsQuantity, setSelectedItemsQuantity] = useState(0);
    const [missingInventory, setMissingInventory] = useState(0);
    const [showConfetti, setShowConfetti] = useState(false);
    const [isCreatingOrder, setIsCreatingOrder] = useState(false);
    const [ignoreSourceLevels, setIgnoreSourceLevels] = useState(false);

    useEffect(() => {
        fetchInitialData();
    }, []);

    useEffect(() => {
        if (selectedLocation) {
            const initialMissingInventory = selectedLocation.missingItems.reduce((sum, item) => sum + item.needed_quantity, 0);
            setMissingInventory(initialMissingInventory);
        }
    }, [selectedLocation]);

    useEffect(() => {
        localStorage.setItem('transferOrdersTab', tabValue.toString());
    }, [tabValue]);

    const fetchInitialData = async () => {
        try {
            const [locationsData, missingItemsData, ordersData] = await Promise.all([
                getLocations(),
                getLocationsWithMissingItems(),
                getTransferOrders()
            ]);
            setLocations(locationsData);
            // Ensure we're using the correct property for missing quantity
            const parsedMissingItemsData = missingItemsData.map(location => ({
                ...location,
                missingItems: location.missingItems.map(item => ({
                    ...item,
                    needed_quantity: parseInt(item.missing_quantity) || 0
                }))
            }));
            console.log('Parsed missing items data:', parsedMissingItemsData);
            setLocationsWithMissingItems(parsedMissingItemsData);
            setTransferOrders(ordersData);
        } catch (error) {
            console.error('Error fetching initial data:', error);
            setSnackbar({ open: true, message: 'Failed to fetch initial data', severity: 'error' });
        }
    };

    const updateTotals = (items) => {
        const needed = {};
        const available = {};
        items.forEach(item => {
            needed[item.item_id] = item.needed_quantity;
            available[item.item_id] = item.available_locations.reduce((sum, loc) => sum + loc.excess_quantity, 0);
        });
        setTotalNeededItems(needed);
        setTotalAvailableItems(available);
    };

    const handleLocationSelect = async (location) => {
        setSelectedLocation(location);
        setActiveStep(1);
        try {
            const transferItems = ignoreSourceLevels 
                ? await getAvailableTransferItemsIgnoreSource(location.db_location_id)
                : await getAvailableTransferItems(location.db_location_id);
            
            setAvailableTransfers(transferItems);
            
            // Filter out items with no excess quantity
            const itemsWithExcess = transferItems.filter(item => 
                item.available_locations.some(loc => loc.excess_quantity > 0)
            );
            setExcessiveItems(itemsWithExcess);
            
            if (itemsWithExcess.length === 0) {
                setSnackbar({ open: true, message: 'No items available for transfer.', severity: 'info' });
            } else {
                updateTotals(itemsWithExcess);
            }
        } catch (error) {
            console.error('Error fetching available transfer items:', error);
            setSnackbar({ open: true, message: 'Failed to fetch available transfer items', severity: 'error' });
        }
    };

    const handleItemSelect = (itemId, locationId, quantity) => {
        setSelectedItems(prev => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                [locationId]: quantity
            }
        }));
    };

    const handleExcessItemSelect = (itemId, locationId, quantity) => {
        const item = excessiveItems.find(i => i.item_id === itemId);
        const neededQuantity = item.needed_quantity;
        
        setSelectedExcessItems(prev => {
            const newSelected = { ...prev };
            if (!newSelected[itemId]) newSelected[itemId] = {};
            
            const totalSelected = Object.values(newSelected[itemId]).reduce((sum, q) => sum + q, 0);
            const adjustedQuantity = Math.min(quantity, neededQuantity - (totalSelected - (newSelected[itemId][locationId] || 0)));
            
            const previousQuantity = newSelected[itemId][locationId] || 0;
            if (adjustedQuantity > 0) {
                newSelected[itemId][locationId] = adjustedQuantity;
            } else {
                delete newSelected[itemId][locationId];
            }
            
            if (Object.keys(newSelected[itemId]).length === 0) {
                delete newSelected[itemId];
            }
            
            // Update missing inventory
            setMissingInventory(prev => prev - (adjustedQuantity - previousQuantity));
            
            return newSelected;
        });
    };

    const handleCreateTransferOrder = async () => {
        console.log('Starting transfer order creation process');
        setIsCreatingOrder(true);
        const orderItems = Object.entries(selectedExcessItems).flatMap(([itemId, locations]) =>
            Object.entries(locations).map(([locationId, quantity]) => ({
                item_id: parseInt(itemId),
                from_location_id: parseInt(locationId),
                quantity
            }))
        );

        const orderData = {
            to_location_id: selectedLocation.db_location_id,
            items: orderItems
        };

        console.log('Prepared order data:', orderData);

        try {
            console.log('Sending create transfer order request');
            const result = await createTransferOrder(orderData);
            console.log('Transfer order creation result:', result);
            setSnackbar({ 
                open: true, 
                message: 'Transfer order created successfully. Google Sheets are being generated and may take a few moments to complete.', 
                severity: 'success' 
            });
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 5000);
            console.log('Fetching updated data');
            await fetchInitialData();
            console.log('Resetting order creation state');
            resetOrderCreation();
        } catch (error) {
            console.error('Error creating transfer order:', error);
            setSnackbar({ open: true, message: 'Failed to create transfer order', severity: 'error' });
        } finally {
            setIsCreatingOrder(false);
        }
    };

    const resetOrderCreation = () => {
        setSelectedLocation(null);
        setAvailableTransfers([]);
        setSelectedItems({});
        setActiveStep(0);
    };

    const handleUpdateStatus = async (orderId, newStatus) => {
        try {
            await updateTransferOrderStatus(orderId, newStatus);
            if (newStatus === 'Completed') {
                setSnackbar({ open: true, message: 'Transfer order completed successfully!', severity: 'success' });
                setShowConfetti(true);
                setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
            } else {
                setSnackbar({ open: true, message: 'Transfer order status updated successfully', severity: 'success' });
            }
            const updatedOrders = await getTransferOrders();
            setTransferOrders(updatedOrders);
        } catch (error) {
            console.error('Error updating transfer order status:', error);
            setSnackbar({ open: true, message: 'Failed to update transfer order status', severity: 'error' });
        }
    };

    const handleOpenDialog = (content, title = "Item Transfer Details") => {
        if (title === "Transfer Details" || title === "Order Details") {
            const formattedContent = formatDialogContent(content);
            setDialogContent({ content: formattedContent, title });
        } else if (title === "Missing Items") {
            const formattedContent = content.map(item => ({
                ...item,
                needed_quantity: item.missing_quantity !== undefined ? item.missing_quantity : 'Not specified'
            }));
            setDialogContent({ content: formattedContent, title });
        } else {
            setDialogContent({ content, title });
        }
        setOpenDialog(true);
    };

    const formatDialogContent = (content) => {
        if (!content) {
            console.error('Content is undefined in formatDialogContent');
            return [];
        }

        if (Array.isArray(content)) {
            // This is for missing items, no change needed
            return content;
        }
        
        // For order details
        if (content.items) {
            return content.items.map(item => ({
                itemName: item.item_name,
                quantity: item.quantity,
                fromLocation: content.from_locations.find(loc => loc.from_location_id === item.from_location_id)?.from_location_name || 'Unknown',
                toLocation: content.to_location_name,
                status: content.status_name,
                createdAt: new Date(content.created_at).toLocaleString()
            }));
        }

        // If content doesn't match expected format, return an empty array
        console.warn('Unexpected content format in formatDialogContent:', content);
        return [];
    };

    const handleSourceLocationSelect = (locationId) => {
        setSelectedSourceLocation(locationId);
        setShowAllItems(false);
    };

    const toggleShowAllItems = () => {
        setShowAllItems(!showAllItems);
    };

    const handleSelectAllFromLocation = async (locationId) => {
        setIsLoading(true);
        setLocationSelectionState(prev => ({ ...prev, [locationId]: 'all' }));
        setSelectedLocations(prev => new Set(prev).add(locationId));
        const newSelectedExcessItems = { ...selectedExcessItems };
        const newTotalNeededItems = { ...totalNeededItems };
        
        let totalSelectedQuantity = 0;
        excessiveItems.forEach(item => {
            const location = item.available_locations.find(loc => loc.db_location_id === locationId);
            if (location) {
                const currentTotal = Object.values(newSelectedExcessItems[item.item_id] || {}).reduce((sum, q) => sum + q, 0);
                const remainingNeeded = Math.max(0, newTotalNeededItems[item.item_id] - currentTotal);
                const quantityToAdd = Math.min(location.excess_quantity, remainingNeeded);
                
                if (quantityToAdd > 0) {
                    if (!newSelectedExcessItems[item.item_id]) newSelectedExcessItems[item.item_id] = {};
                    newSelectedExcessItems[item.item_id][locationId] = quantityToAdd;
                    newTotalNeededItems[item.item_id] -= quantityToAdd;
                    totalSelectedQuantity += quantityToAdd;
                }
            }
        });

        setSelectedExcessItems(newSelectedExcessItems);
        setTotalNeededItems(newTotalNeededItems);
        setMissingInventory(prev => prev - totalSelectedQuantity);

        // Simulate API call to recalculate needed items
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Update the totals
        updateTotals(excessiveItems.map(item => ({
            ...item,
            needed_quantity: newTotalNeededItems[item.item_id]
        })));
        setIsLoading(false);
    };

    const handleDeselectAllFromLocation = async (locationId) => {
        setIsLoading(true);
        setLocationSelectionState(prev => ({ ...prev, [locationId]: 'none' }));
        setSelectedLocations(prev => {
            const newSet = new Set(prev);
            newSet.delete(locationId);
            return newSet;
        });
        const newSelectedExcessItems = { ...selectedExcessItems };
        const newTotalNeededItems = { ...totalNeededItems };

        let totalDeselectedQuantity = 0;
        Object.keys(newSelectedExcessItems).forEach(itemId => {
            if (newSelectedExcessItems[itemId][locationId]) {
                totalDeselectedQuantity += newSelectedExcessItems[itemId][locationId];
                newTotalNeededItems[itemId] += newSelectedExcessItems[itemId][locationId];
                delete newSelectedExcessItems[itemId][locationId];
            }
            if (Object.keys(newSelectedExcessItems[itemId]).length === 0) {
                delete newSelectedExcessItems[itemId];
            }
        });

        setSelectedExcessItems(newSelectedExcessItems);
        setTotalNeededItems(newTotalNeededItems);
        setMissingInventory(prev => prev + totalDeselectedQuantity);

        // Simulate API call to recalculate needed items
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Update the totals
        updateTotals(excessiveItems.map(item => ({
            ...item,
            needed_quantity: newTotalNeededItems[item.item_id]
        })));
        setIsLoading(false);
    };

    const isAllSelected = (locationId) => {
        return excessiveItems.every(item => {
            const location = item.available_locations.find(loc => loc.db_location_id === locationId);
            if (!location) return true;
            const selectedQuantity = selectedExcessItems[item.item_id]?.[locationId] || 0;
            return selectedQuantity === Math.min(location.excess_quantity, totalNeededItems[item.item_id]);
        });
    };

    const isNoneSelected = (locationId) => {
        return Object.values(selectedExcessItems).every(locations => !locations[locationId]);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const steps = ['Select Destination', 'Choose Items', 'Review & Create'];

    const renderExistingTransferOrders = () => (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Order ID</TableCell>
                        <TableCell>From Locations</TableCell>
                        <TableCell>To Location</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transferOrders
                        .filter(order => order.status_name !== 'Cancelled')
                        .map((order) => (
                            <TableRow key={order.id}>
                                <TableCell>{order.id}</TableCell>
                                <TableCell>
                                    {order.from_locations && order.from_locations.map((loc, index) => (
                                        <div key={index}>
                                            {loc.from_location_name}
                                            {loc.sheet_url && (
                                                <IconButton 
                                                    size="small" 
                                                    href={loc.sheet_url} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                >
                                                    <DescriptionIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell>{order.to_location_name}</TableCell>
                                <TableCell>
                                    <Chip 
                                        label={order.status_name} 
                                        color={
                                            order.status_name === 'Completed' ? 'success' :
                                            order.status_name === 'In Progress' ? 'primary' :
                                            order.status_name === 'Cancelled' ? 'error' : 'default'
                                        }
                                    />
                                </TableCell>
                                <TableCell>{new Date(order.created_at).toLocaleString()}</TableCell>
                                <TableCell>
                                    <Select
                                        value={order.status_name}
                                        onChange={(e) => handleUpdateStatus(order.id, e.target.value)}
                                        size="small"
                                    >
                                        <MenuItem value="Pending">Pending</MenuItem>
                                        <MenuItem value="In Progress">In Progress</MenuItem>
                                        <MenuItem value="Completed">Completed</MenuItem>
                                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                                    </Select>
                                    <Tooltip title="View Details">
                                        <IconButton onClick={() => handleOpenDialog(order, "Order Details")}>
                                            <InventoryIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <FullScreenLayout
            header={
                <>
                    <Typography variant="h4" gutterBottom>Transfer Orders</Typography>
                    <Tabs value={tabValue} onChange={handleTabChange} centered style={{marginBottom: '20px'}}>
                        <Tab label="Create New Transfer" icon={<AddIcon />} />
                        <Tab label="Existing Transfer Orders" icon={<LocalShippingIcon />} />
                    </Tabs>
                </>
            }
            mainPanel={
                <PageContainer>
                    <Box hidden={tabValue !== 0}>
                        <Stepper activeStep={activeStep} alternativeLabel style={{marginBottom: '20px'}}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        {activeStep > 0 && (
                            <Button
                                variant="outlined"
                                startIcon={<ArrowBackIcon />}
                                onClick={() => setActiveStep(prev => prev - 1)}
                                style={{marginBottom: '20px'}}
                            >
                                Back
                            </Button>
                        )}

                        {activeStep === 0 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={ignoreSourceLevels}
                                                onChange={(e) => setIgnoreSourceLevels(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Use All Available Inventory (Ignore Source Replenish Levels)"
                                    />
                                </Grid>
                                {locationsWithMissingItems.map(location => (
                                    <Grid item xs={12} sm={6} md={4} key={location.db_location_id}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h6">{location.name}</Typography>
                                                <Typography variant="body2">Missing Items: {location.missingItems.length}</Typography>
                                                <Typography variant="body2">
                                                    Missing Inventory: {location.missingItems.reduce((sum, item) => sum + item.needed_quantity, 0)}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button 
                                                    size="small" 
                                                    variant="contained" 
                                                    color="primary"
                                                    startIcon={<SendIcon />}
                                                    onClick={() => handleLocationSelect(location)}
                                                >
                                                    Select as Destination
                                                </Button>
                                                <Button 
                                                    size="small" 
                                                    variant="outlined"
                                                    startIcon={<InventoryIcon />}
                                                    onClick={() => handleOpenDialog(location.missingItems, "Missing Items")}
                                                >
                                                    View Missing Items
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        {activeStep === 1 && selectedLocation && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Select Items to Transfer to {selectedLocation.name}</Typography>
                                    <Typography variant="body1">
                                        Items Needed for {selectedLocation.name}: {selectedLocation.missingItems.length}
                                    </Typography>
                                    <Typography variant="body1">
                                        Excess Items Available: {excessiveItems.length}
                                    </Typography>
                                    <Typography variant="body1">
                                        Missing Inventory for {selectedLocation.name}: {missingInventory}
                                    </Typography>
                                </Grid>
                                {excessiveItems.length > 0 ? (
                                    Object.values(excessiveItems.reduce((acc, item) => {
                                        item.available_locations.forEach(loc => {
                                            if (!acc[loc.db_location_id]) {
                                                acc[loc.db_location_id] = {
                                                    locationName: loc.location_name,
                                                    locationId: loc.db_location_id,
                                                    items: []
                                                };
                                            }
                                            acc[loc.db_location_id].items.push({
                                                ...item,
                                                excess_quantity: loc.excess_quantity
                                            });
                                        });
                                        return acc;
                                    }, {})).map(location => (
                                        <Grid item xs={12} key={location.locationId}>
                                            <Card>
                                                <CardContent>
                                                    <Typography variant="subtitle1">{location.locationName}</Typography>
                                                    <Typography variant="body2">
                                                        {location.items.length} items available
                                                    </Typography>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        onClick={() => handleSelectAllFromLocation(location.locationId)}
                                                        style={{marginRight: '10px'}}
                                                        startIcon={<AddIcon />}
                                                        disabled={isLoading || isAllSelected(location.locationId)}
                                                    >
                                                        {isLoading ? <CircularProgress size={24} /> : 'Choose All'}
                                                    </Button>
                                                    <Button 
                                                        variant="outlined" 
                                                        color="secondary" 
                                                        onClick={() => handleDeselectAllFromLocation(location.locationId)}
                                                        style={{marginRight: '10px'}}
                                                        startIcon={<RemoveIcon />}
                                                        disabled={isLoading || isNoneSelected(location.locationId)}
                                                    >
                                                        {isLoading ? <CircularProgress size={24} /> : 'None'}
                                                    </Button>
                                                    {selectedLocations.has(location.locationId) && (
                                                        <Chip 
                                                            label="Selected" 
                                                            color="primary" 
                                                            style={{marginLeft: '10px'}}
                                                        />
                                                    )}
                                                    <IconButton 
                                                        onClick={() => setExpandedLocation(expandedLocation === location.locationId ? null : location.locationId)}
                                                        disabled={isLoading}
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                </CardContent>
                                                <Collapse in={expandedLocation === location.locationId}>
                                                    <CardContent>
                                                        <Grid container spacing={2}>
                                                            {location.items.map(item => (
                                                                <Grid item xs={12} sm={6} md={4} key={item.item_id}>
                                                                    <Card>
                                                                        <CardContent>
                                                                            <Typography variant="subtitle1">{item.item_name}</Typography>
                                                                            <Typography variant="body2">Available: {item.excess_quantity}</Typography>
                                                                            <Typography variant="body2">Needed: {item.needed_quantity}</Typography>
                                                                            <TextField
                                                                                type="number"
                                                                                label="Quantity to Transfer"
                                                                                value={selectedExcessItems[item.item_id]?.[location.locationId] || ''}
                                                                                onChange={(e) => handleExcessItemSelect(item.item_id, location.locationId, parseInt(e.target.value) || 0)}
                                                                                InputProps={{ inputProps: { min: 0, max: Math.min(item.excess_quantity, item.needed_quantity) } }}
                                                                                disabled={isLoading}
                                                                                fullWidth
                                                                                margin="normal"
                                                                            />
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </CardContent>
                                                </Collapse>
                                            </Card>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">No excess items available for transfer.</Typography>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            onClick={() => setActiveStep(0)}
                                            style={{marginTop: '20px'}}
                                            startIcon={<RefreshIcon />}
                                        >
                                            Choose Another Location
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => setActiveStep(2)} 
                                        disabled={Object.keys(selectedExcessItems).length === 0}
                                        endIcon={<ArrowForwardIcon />}
                                    >
                                        Review Order
                                    </Button>
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 2 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Review Transfer Order</Typography>
                                    <Typography variant="subtitle1">Destination: {selectedLocation.name}</Typography>
                                    {Object.entries(selectedExcessItems).length > 0 ? (
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Source Location</TableCell>
                                                        <TableCell>Total Items</TableCell>
                                                        <TableCell>Total Quantity</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Object.entries(
                                                        Object.entries(selectedExcessItems).reduce((acc, [itemId, locations]) => {
                                                            Object.entries(locations).forEach(([locationId, quantity]) => {
                                                                if (!acc[locationId]) {
                                                                    acc[locationId] = { totalItems: 0, totalQuantity: 0 };
                                                                }
                                                                acc[locationId].totalItems++;
                                                                acc[locationId].totalQuantity += quantity;
                                                            });
                                                            return acc;
                                                        }, {})
                                                    ).map(([locationId, { totalItems, totalQuantity }]) => {
                                                        const location = locations.find(l => l.db_location_id === parseInt(locationId));
                                                        return (
                                                            <TableRow key={locationId}>
                                                                <TableCell>{location ? location.name : 'Unknown Location'}</TableCell>
                                                                <TableCell>{totalItems}</TableCell>
                                                                <TableCell>{totalQuantity}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : (
                                        <Typography variant="body1">No items selected for transfer.</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={handleCreateTransferOrder} 
                                        disabled={Object.keys(selectedExcessItems).length === 0}
                                        startIcon={<SendIcon />}
                                    >
                                        Create Transfer Order
                                    </Button>
                                    <Button 
                                        variant="outlined" 
                                        color="secondary" 
                                        onClick={() => setActiveStep(1)} 
                                        style={{marginLeft: '10px'}}
                                        startIcon={<ArrowBackIcon />}
                                    >
                                        Back to Item Selection
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Box>

                    <Box hidden={tabValue !== 1}>
                        <Typography variant="h6" gutterBottom>Existing Transfer Orders</Typography>
                        {renderExistingTransferOrders()}
                    </Box>

                    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                        <DialogTitle>{dialogContent?.title}</DialogTitle>
                        <DialogContent>
                            {dialogContent?.title === "Order Details" ? (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item Name</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                                <TableCell>From Location</TableCell>
                                                <TableCell>To Location</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Created At</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dialogContent.content.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {item.itemName}
                                                    </TableCell>
                                                    <TableCell align="right">{item.quantity}</TableCell>
                                                    <TableCell>{item.fromLocation}</TableCell>
                                                    <TableCell>{item.toLocation}</TableCell>
                                                    <TableCell>{item.status}</TableCell>
                                                    <TableCell>{item.createdAt}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <List>
                                    {Array.isArray(dialogContent?.content) ? (
                                        // For missing items
                                        dialogContent.content.map((item, index) => (
                                            <ListItem key={index}>
                                                <ListItemText 
                                                    primary={item.item_name} 
                                                    secondary={`Needed Quantity: ${item.missing_quantity}`} 
                                                />
                                            </ListItem>
                                        ))
                                    ) : (
                                        // For transfer details or order details
                                        dialogContent?.content.map((item, index) => (
                                            <ListItem key={index}>
                                                <ListItemText 
                                                    primary={item.itemName} 
                                                    secondary={
                                                        <React.Fragment>
                                                            {item.locations.map((loc, locIndex) => (
                                                                <Typography key={locIndex} component="div">
                                                                    {loc.locationName}: {loc.quantity}
                                                                </Typography>
                                                            ))}
                                                        </React.Fragment>
                                                    } 
                                                />
                                            </ListItem>
                                        ))
                                    )}
                                </List>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <CenteredSnackbar 
                        open={snackbar.open} 
                        autoHideDuration={6000} 
                        onClose={() => setSnackbar({...snackbar, open: false})}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert 
                            onClose={() => setSnackbar({...snackbar, open: false})} 
                            severity={snackbar.severity}
                            sx={{ width: '100%' }}
                        >
                            {snackbar.message}
                        </Alert>
                    </CenteredSnackbar>

                    {showConfetti && (
                        <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            recycle={false}
                            numberOfPieces={200}
                        />
                    )}

                    {isCreatingOrder && (
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <LinearProgress />
                            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                                Creating transfer order...
                            </Typography>
                        </Box>
                    )}
                </PageContainer>
            }
        />
    );
}

export default TransferOrders;