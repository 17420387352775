import React from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Box,
    CircularProgress
} from '@mui/material';

export default function GeneralInformationStep({ 
    generalInfo, 
    setGeneralInfo, 
    warehouses = [],
    locations = [],
    loading = false
}) {
    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    const handleChange = (field) => (event) => {
        setGeneralInfo(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={3}>
                {/* Source Warehouse */}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Source Warehouse</InputLabel>
                        <Select
                            value={generalInfo.sourceWarehouseId}
                            onChange={handleChange('sourceWarehouseId')}
                            label="Source Warehouse"
                        >
                            {warehouses.map(warehouse => (
                                <MenuItem 
                                    key={warehouse.warehouse_id} 
                                    value={warehouse.warehouse_id}
                                >
                                    {warehouse.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Destination Type */}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Destination Type</InputLabel>
                        <Select
                            value={generalInfo.destinationType}
                            onChange={handleChange('destinationType')}
                            label="Destination Type"
                        >
                            <MenuItem value="warehouse">Warehouse</MenuItem>
                            <MenuItem value="location">Location</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Destination */}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>
                            {generalInfo.destinationType === 'warehouse' ? 'Destination Warehouse' : 'Destination Location'}
                        </InputLabel>
                        <Select
                            value={generalInfo.destinationId}
                            onChange={handleChange('destinationId')}
                            label={generalInfo.destinationType === 'warehouse' ? 'Destination Warehouse' : 'Destination Location'}
                        >
                            {generalInfo.destinationType === 'warehouse' ? (
                                warehouses
                                    .filter(w => w.warehouse_id !== generalInfo.sourceWarehouseId)
                                    .map(warehouse => (
                                        <MenuItem 
                                            key={warehouse.warehouse_id} 
                                            value={warehouse.warehouse_id}
                                        >
                                            {warehouse.name}
                                        </MenuItem>
                                    ))
                            ) : (
                                locations.map(location => (
                                    <MenuItem 
                                        key={location.db_location_id} 
                                        value={location.db_location_id}
                                    >
                                        {location.name}
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Shipping Method */}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Shipping Method</InputLabel>
                        <Select
                            value={generalInfo.shippingMethod}
                            onChange={handleChange('shippingMethod')}
                            label="Shipping Method"
                        >
                            <MenuItem value="air">Air</MenuItem>
                            <MenuItem value="sea">Sea</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Scheduled Date */}
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        type="datetime-local"
                        label="Scheduled Date"
                        value={generalInfo.scheduledDate ? new Date(generalInfo.scheduledDate).toISOString().slice(0, 16) : ''}
                        onChange={(e) => {
                            const newDate = new Date(e.target.value);
                            setGeneralInfo(prev => ({
                                ...prev,
                                scheduledDate: newDate
                            }));
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                {/* Carrier */}
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Carrier"
                        value={generalInfo.carrier}
                        onChange={handleChange('carrier')}
                    />
                </Grid>

                {/* Tracking Number */}
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Tracking Number"
                        value={generalInfo.trackingNumber}
                        onChange={handleChange('trackingNumber')}
                    />
                </Grid>

                {/* Notes */}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Notes"
                        value={generalInfo.notes}
                        onChange={handleChange('notes')}
                    />
                </Grid>
            </Grid>
        </Box>
    );
} 