import api from './api';

// Remove duplicate 'api' from base URL
const BASE_PATH = '/inventory-checks';

export const getWarehouseInventoryChecks = async (warehouseId) => {
  try {
    const response = await api.get(`${BASE_PATH}/warehouse/${warehouseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse inventory checks:', error);
    throw error;
  }
};

export const getInventoryCheckById = async (checkId) => {
  try {
    const response = await api.get(`/inventory-checks/${checkId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory check:', error);
    throw error;
  }
};

export const createInventoryCheck = async (checkData) => {
  try {
    const response = await api.post(BASE_PATH, checkData);
    return response.data;
  } catch (error) {
    console.error('Error creating inventory check:', error);
    throw error;
  }
};

export const updateInventoryCheck = async (checkId, checkData) => {
  try {
    const response = await api.put(`/inventory-checks/${checkId}`, checkData);
    return response.data;
  } catch (error) {
    console.error('Error updating inventory check:', error);
    throw error;
  }
};

export const updateInventoryCheckStatus = async (checkId, status) => {
  try {
    const response = await api.patch(`/inventory-checks/${checkId}/status`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating inventory check status:', error);
    throw error;
  }
};

export const getWarehouseItemsWithBarcodes = async (warehouseId) => {
  try {
    const response = await api.get(`/inventory-checks/warehouse/${warehouseId}/items`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse items with barcodes:', error);
    throw error;
  }
};

export const getInventoryCheckSpreadsheetData = async (checkId) => {
  try {
    const response = await api.get(`/inventory-checks/${checkId}/spreadsheet-data`);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory check spreadsheet data:', error);
    throw error;
  }
};

export const getWarehouseInventorySpreadsheet = async (warehouseId) => {
  try {
    const response = await api.get(`/inventory-checks/warehouse/${warehouseId}/inventory-spreadsheet`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse inventory spreadsheet:', error);
    throw error;
  }
};

export default api; 