import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  Container,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { customerLogin } from '../services/customerapi';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  background: 'var(--color-background)',
  padding: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
}));

const StyledPaper = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(6),
  maxWidth: 450,
  width: '100%',
  backgroundColor: 'var(--color-surface)',
  borderRadius: 24,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
  animation: `${fadeIn} 0.6s ease-out`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, var(--color-primary), var(--color-accent))',
  },
}));

const LogoText = styled(motion.h1)(({ theme }) => ({
  fontSize: '2.8rem',
  fontWeight: 700,
  background: 'linear-gradient(45deg, var(--color-primary), var(--color-accent))',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(1),
  textAlign: 'center',
  letterSpacing: '0.05em',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  color: 'var(--color-primary)',
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  fontWeight: 500,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    transition: 'all 0.3s ease',
    borderRadius: 12,
    '& fieldset': {
      borderColor: 'var(--color-border)',
      transition: 'all 0.3s ease',
    },
    '&:hover fieldset': {
      borderColor: 'var(--color-primary)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--color-accent)',
      borderWidth: '2px',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'var(--color-text-secondary)',
    '&.Mui-focused': {
      color: 'var(--color-accent)',
    },
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontWeight: 600,
  borderRadius: 30,
  textTransform: 'none',
  fontSize: '1rem',
  backgroundColor: 'var(--color-primary)',
  color: 'white',
  marginTop: theme.spacing(2),
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'var(--color-primary-light)',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: 'var(--color-primary)',
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  '&:hover': {
    color: 'var(--color-accent)',
    textDecoration: 'underline',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

function CustomerLogin() {
  const [loginIdentifier, setLoginIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if already logged in
    const token = localStorage.getItem('customerToken');
    if (token) {
      navigate('/customer/dashboard');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await customerLogin({ 
        loginIdentifier: loginIdentifier.trim(),
        password 
      });
      
      if (response.data.token) {
        localStorage.setItem('customerToken', response.data.token);
        localStorage.setItem('customerData', JSON.stringify(response.data.customer));
        
        // Navigate to the intended destination or dashboard
        const from = location.state?.from?.pathname || '/customer/dashboard';
        navigate(from, { replace: true });
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Login failed. Please try again.');
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <Root>
      <StyledPaper
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={containerVariants}
      >
        <LogoText>PIM BY ELI</LogoText>
        <SubTitle>Customer Portal</SubTitle>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3, borderRadius: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="loginIdentifier"
            label="Email Address or Username"
            name="loginIdentifier"
            autoComplete="email"
            autoFocus
            value={loginIdentifier}
            onChange={(e) => setLoginIdentifier(e.target.value)}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <ButtonContainer>
            <LoginButton
              type="submit"
              fullWidth
              variant="contained"
            >
              Sign In
            </LoginButton>
            
            <Box textAlign="center" mt={2}>
              <StyledLink to="/customer/forgot-password">
                Forgot password?
              </StyledLink>
            </Box>
            
            <Box textAlign="center">
              <StyledLink to="/">
                Back to Main Login
              </StyledLink>
            </Box>
          </ButtonContainer>
        </form>
      </StyledPaper>
    </Root>
  );
}

export default CustomerLogin;