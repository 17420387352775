import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Paper,
  Chip,
  Tooltip,
  Alert,
  Snackbar,
  Grid,
  Card,
  CardContent,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Switch,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  QrCode as BarcodeIcon,
  Save as SaveIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import FullScreenLayout from './layouts/FullScreenLayout';
import * as itemsApi from '../services/itemsapi';

const AttributeCreationDialog = ({ 
  open, 
  onClose, 
  attributeType, 
  formData, 
  previewData,
  onConfirm,
  loading 
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        Create New {attributeType.charAt(0).toUpperCase() + attributeType.slice(1)}
      </DialogTitle>
      <DialogContent>
        {previewData && (
          <>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              This will create {previewData.length} new items:
            </Typography>
            <DataGrid
              rows={previewData}
              columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'barcode', headerName: 'Barcode', flex: 1 },
                { field: 'type_name', headerName: 'Type', flex: 1 },
                { field: 'size_name', headerName: 'Size', flex: 1 },
                { field: 'brim_name', headerName: 'Brim', flex: 1 },
                { field: 'shape_name', headerName: 'Shape', flex: 1 }
              ]}
              pageSize={5}
              autoHeight
              getRowId={(row) => row.name}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={onConfirm}
          variant="contained"
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Items'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ItemAttributeManager = ({ 
  title, 
  items, 
  onAdd, 
  onEdit, 
  loading,
  error,
  fetchData,
  setItems
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', barcode: '', alternative_lookups: [] });
  const [isEditing, setIsEditing] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [barcodeUpdateData, setBarcodeUpdateData] = useState(null);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);
  const [isUpdatingBarcodes, setIsUpdatingBarcodes] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [creationProgress, setCreationProgress] = useState({ current: 0, total: 0 });

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'barcode', headerName: 'Barcode', flex: 1 },
    { 
      field: 'active', 
      headerName: 'Active', 
      width: 120,
      renderCell: (params) => (
        <Box>
          <Switch
            checked={params.value}
            onChange={async (e) => {
              const newValue = e.target.checked;
              try {
                let updateFunction;
                let warningMessage = '';
                
                // Select the appropriate update function based on title
                switch (title.toLowerCase().replace('item ', '')) {
                  case 'types':
                    updateFunction = itemsApi.updateTypeActiveStatus;
                    warningMessage = "Deactivating a type will deactivate all associated items. Are you sure?";
                    break;
                  case 'shapes':
                    updateFunction = itemsApi.updateShapeActiveStatus;
                    warningMessage = "Deactivating a shape will deactivate all associated items. Are you sure?";
                    break;
                  case 'sizes':
                    updateFunction = itemsApi.updateSizeActiveStatus;
                    warningMessage = "Deactivating a size will deactivate all associated items. Are you sure?";
                    break;
                  case 'brims':
                    updateFunction = itemsApi.updateBrimActiveStatus;
                    warningMessage = "Deactivating a brim will deactivate all associated items. Are you sure?";
                    break;
                  default:
                    throw new Error('Invalid attribute type');
                }

                // Show warning only when deactivating
                if (!newValue && !window.confirm(warningMessage)) {
                  return;
                }

                await updateFunction(params.row.name, newValue);
                showSnackbar(`${title} status updated successfully`);
                fetchData(); // Refresh the data
              } catch (error) {
                showSnackbar(error.message, 'error');
              }
            }}
          />
        </Box>
      )
    },
    {
      field: 'alternative_lookups',
      headerName: 'Alternative Lookups',
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
          {params.value?.map((lookup, index) => (
            <Chip key={index} label={lookup} size="small" />
          ))}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleAdd = () => {
    setIsEditing(false);
    setFormData({ name: '', barcode: '', alternative_lookups: [] });
    setDialogOpen(true);
  };

  const handleEdit = (item) => {
    setIsEditing(true);
    setSelectedItem(item);
    setFormData({
      name: item.name,
      barcode: item.barcode || '',
      alternative_lookups: item.alternative_lookups || [],
    });
    setDialogOpen(true);
  };

  const handlePreviewItems = async (data) => {
    try {
      const preview = await itemsApi.previewNewAttributeItems(title.toLowerCase(), data);
      setPreviewData(preview.data);
      setCreationDialogOpen(true);
    } catch (error) {
      showSnackbar(error.message, 'error');
    }
  };

  const handleCreateItems = async () => {
    setIsCreating(true);
    try {
      await itemsApi.createItemsForAttribute(title.toLowerCase(), formData);
      showSnackbar('Items created successfully');
      setCreationDialogOpen(false);
      onAdd(formData);
    } catch (error) {
      showSnackbar(error.message, 'error');
    } finally {
      setIsCreating(false);
    }
  };

  const handleBarcodeChange = (newBarcode) => {
    setFormData({ ...formData, barcode: newBarcode });
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        const attributeType = title.replace('Item ', '').toLowerCase();
        const preview = await itemsApi.previewBarcodeUpdates(
          attributeType,
          formData.name,
          { barcode: formData.barcode }
        );
        setBarcodeUpdateData(preview.data);
        setBarcodeDialogOpen(true);
      } else {
        const preview = await itemsApi.previewNewAttributeItems(title.toLowerCase(), formData);
        setPreviewData(preview.items);
        setFormData({
          ...formData,
          alternative_lookups: preview.attributeToCreate.alternative_lookups
        });
        setPreviewDialogOpen(true);
      }
    } catch (error) {
      showSnackbar(error.message, 'error');
    }
  };

  const handleConfirmCreation = async () => {
    if (!previewData) return;
    
    setIsCreating(true);
    setCreationProgress({ current: 0, total: previewData.length });
    
    try {
      // Create the attribute and all items in one transaction
      await itemsApi.createItemsForAttribute(
        title.toLowerCase(),
        {
          ...formData,
          items: previewData
        }
      );
      
      showSnackbar(`Successfully created ${title.toLowerCase()} and ${previewData.length} items`);
      setPreviewDialogOpen(false);
      setDialogOpen(false);
      fetchData(); // Refresh all data
    } catch (error) {
      showSnackbar(error.message, 'error');
    } finally {
      setIsCreating(false);
      setCreationProgress({ current: 0, total: 0 });
    }
  };

  const handleUpdateBarcodes = async () => {
    setIsUpdatingBarcodes(true);
    try {
      const attributeType = title.replace('Item ', '').toLowerCase();
      
      await itemsApi.updateAffectedItemBarcodes(
        attributeType,
        formData.name,
        { barcode: formData.barcode }
      );
      showSnackbar('Items updated successfully');
      setBarcodeDialogOpen(false);
      onEdit(selectedItem.name, formData);
    } catch (error) {
      showSnackbar(error.message, 'error');
    } finally {
      setIsUpdatingBarcodes(false);
    }
  };

  const isFormValid = () => {
    return formData.name.trim() !== '' && formData.barcode.trim() !== '';
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">{title}</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Add New
          </Button>
        </Box>

        <Alert severity="warning" sx={{ mb: 2 }}>
          Changes to {title.toLowerCase()} can affect multiple items and may have unexpected results. Please review changes carefully.
        </Alert>

        <DataGrid
          rows={items}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.name}
          autoHeight
          loading={loading}
          error={error}
        />

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>{isEditing ? 'Edit' : 'Add'} {title}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              fullWidth
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              disabled={isEditing}
            />
            <TextField
              margin="dense"
              label="Barcode"
              fullWidth
              value={formData.barcode}
              onChange={(e) => handleBarcodeChange(e.target.value)}
              error={formData.barcode.trim() === ''}
              helperText={formData.barcode.trim() === '' ? 'Barcode is required' : ''}
            />
            <TextField
              margin="dense"
              label="Alternative Lookups (comma-separated)"
              fullWidth
              value={formData.alternative_lookups.join(', ')}
              onChange={(e) => setFormData({
                ...formData,
                alternative_lookups: e.target.value.split(',').map(s => s.trim()).filter(Boolean)
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleSubmit} 
              variant="contained"
              disabled={!isFormValid()}
            >
              {isEditing ? 'Preview Changes' : 'Preview Items'}
            </Button>
          </DialogActions>
        </Dialog>

        <AttributeCreationDialog
          open={creationDialogOpen}
          onClose={() => setCreationDialogOpen(false)}
          attributeType={title.toLowerCase()}
          formData={formData}
          previewData={previewData}
          onConfirm={handleCreateItems}
          loading={isCreating}
        />

        <BarcodeUpdateDialog
          open={barcodeDialogOpen}
          onClose={() => setBarcodeDialogOpen(false)}
          attributeType={title.toLowerCase()}
          affectedItems={barcodeUpdateData}
          onConfirm={handleUpdateBarcodes}
          loading={isUpdatingBarcodes}
        />

        <Dialog
          open={previewDialogOpen}
          onClose={() => !isCreating && setPreviewDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            Preview New Items
          </DialogTitle>
          <DialogContent>
            {previewData && (
              <>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  This will create {previewData.length} new items:
                </Typography>
                <DataGrid
                  rows={previewData}
                  columns={[
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'barcode', headerName: 'Barcode', flex: 1 },
                    { field: 'type_name', headerName: 'Type', flex: 1 },
                    { field: 'size_name', headerName: 'Size', flex: 1 },
                    { field: 'brim_name', headerName: 'Brim', flex: 1 },
                    { field: 'shape_name', headerName: 'Shape', flex: 1 }
                  ]}
                  pageSize={5}
                  autoHeight
                  getRowId={(row) => row.name}
                />
                {isCreating && (
                  <Box sx={{ mt: 2 }}>
                    <Typography>
                      Creating items: {creationProgress.current} of {creationProgress.total}
                    </Typography>
                    <LinearProgress 
                      variant="determinate" 
                      value={(creationProgress.current / creationProgress.total) * 100} 
                    />
                  </Box>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setPreviewDialogOpen(false)} 
              disabled={isCreating}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmCreation}
              variant="contained"
              disabled={isCreating}
            >
              {isCreating ? 'Creating...' : 'Create Items'}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};

const ItemsList = ({ loading, error, fetchData, items, setItems }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [types, setTypes] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [brims, setBrims] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [
          typesRes,
          shapesRes,
          sizesRes,
          brimsRes
        ] = await Promise.all([
          itemsApi.getAllItemTypes(),
          itemsApi.getAllItemShapes(),
          itemsApi.getAllItemSizes(),
          itemsApi.getAllItemBrims(),
        ]);

        setTypes(typesRes.data || []);
        setShapes(shapesRes.data || []);
        setSizes(sizesRes.data || []);
        setBrims(brimsRes.data || []);
      } catch (err) {
        console.error('Error fetching data:', err);
        setSnackbar({
          open: true,
          message: err.message || 'Error loading data. Please try again later.',
          severity: 'error'
        });
      }
    };

    fetchAllData();
  }, []);

  const handleUpdateAttributes = async (item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  const handleSaveAttributes = async (formData) => {
    try {
      await itemsApi.updateItemAttributes(selectedItem.id, formData);
      
      // Generate and update barcode
      const barcodeData = itemsApi.generateBarcodeFromAttributes({
        type_barcode: types.find(t => t.name === formData.type_name)?.barcode,
        shape_barcode: shapes.find(s => s.name === formData.shape_name)?.barcode,
        size_barcode: sizes.find(s => s.name === formData.size_name)?.barcode,
        brim_barcode: brims.find(b => b.name === formData.brim_name)?.barcode,
      });

      await itemsApi.updateItemBarcode(selectedItem.id, barcodeData);

      // Refresh items list
      const response = await itemsApi.getAllItems();
      setItems(response.data || []);

      setSnackbar({
        open: true,
        message: 'Item updated successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating item:', err);
      setSnackbar({
        open: true,
        message: 'Error updating item',
        severity: 'error'
      });
    }
    setDialogOpen(false);
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'type_name', headerName: 'Type', flex: 1 },
    { field: 'shape_name', headerName: 'Shape', flex: 1 },
    { field: 'size_name', headerName: 'Size', flex: 1 },
    { field: 'brim_name', headerName: 'Brim', flex: 1 },
    { field: 'barcode', headerName: 'Barcode', flex: 1 },
    {
      field: 'alternative_lookups',
      headerName: 'Alternative Lookups',
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
          {params.value?.map((lookup, index) => (
            <Chip key={index} label={lookup} size="small" />
          ))}
        </Box>
      ),
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 120,
      renderCell: (params) => (
        <Box>
          <Switch
            checked={params.value}
            onChange={async (e) => {
              const newValue = e.target.checked;
              if (!newValue) {
                const message = 
                  "It's recommended to manage item status through their attributes (Type, Shape, Size, Brim) " +
                  "for better organization. Do you still want to change just this item's status?";
                
                if (!window.confirm(message)) return;
              }
              
              try {
                if (!params.row.id) {
                  throw new Error('Invalid item ID');
                }
                await itemsApi.updateItemActiveStatus(params.row.id, newValue);
                showSnackbar('Item status updated successfully');
                const response = await itemsApi.getAllItems();
                setItems(response.data || []);
              } catch (error) {
                showSnackbar(error.message, 'error');
              }
            }}
          />
        </Box>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleUpdateAttributes(params.row)}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">All Items</Typography>
        </Box>

        <DataGrid
          rows={items}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          getRowId={(row) => row.id}
          autoHeight
          loading={loading}
          error={error}
        />

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>Update Item Attributes</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={selectedItem?.type_name || ''}
                    onChange={(e) => setSelectedItem({
                      ...selectedItem,
                      type_name: e.target.value
                    })}
                    label="Type"
                  >
                    <MenuItem value="">None</MenuItem>
                    {types.map((type) => (
                      <MenuItem key={type.name} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Shape</InputLabel>
                  <Select
                    value={selectedItem?.shape_name || ''}
                    onChange={(e) => setSelectedItem({
                      ...selectedItem,
                      shape_name: e.target.value
                    })}
                    label="Shape"
                  >
                    <MenuItem value="">None</MenuItem>
                    {shapes.map((shape) => (
                      <MenuItem key={shape.name} value={shape.name}>
                        {shape.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Size</InputLabel>
                  <Select
                    value={selectedItem?.size_name || ''}
                    onChange={(e) => setSelectedItem({
                      ...selectedItem,
                      size_name: e.target.value
                    })}
                    label="Size"
                  >
                    <MenuItem value="">None</MenuItem>
                    {sizes.map((size) => (
                      <MenuItem key={size.name} value={size.name}>
                        {size.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Brim</InputLabel>
                  <Select
                    value={selectedItem?.brim_name || ''}
                    onChange={(e) => setSelectedItem({
                      ...selectedItem,
                      brim_name: e.target.value
                    })}
                    label="Brim"
                  >
                    <MenuItem value="">None</MenuItem>
                    {brims.map((brim) => (
                      <MenuItem key={brim.name} value={brim.name}>
                        {brim.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={() => handleSaveAttributes({
                type_name: selectedItem?.type_name,
                shape_name: selectedItem?.shape_name,
                size_name: selectedItem?.size_name,
                brim_name: selectedItem?.brim_name,
              })}
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};

const BarcodeUpdateDialog = ({ 
  open, 
  onClose, 
  attributeType, 
  affectedItems,
  onConfirm,
  loading 
}) => {
  return (
    <Dialog 
      open={open} 
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        Update Affected Items
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          {affectedItems?.length} items will be updated:
        </Typography>
        <DataGrid
          rows={affectedItems || []}
          columns={[
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'current_barcode', headerName: 'Current Barcode', flex: 1 },
            { field: 'new_barcode', headerName: 'New Barcode', flex: 1 },
          ]}
          pageSize={5}
          autoHeight
          getRowId={(row) => row.name}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={onConfirm}
          variant="contained"
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Items'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Items = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [types, setTypes] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [brims, setBrims] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [items, setItems] = useState([]);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const [itemsRes, typesRes, shapesRes, sizesRes, brimsRes] = await Promise.all([
        itemsApi.getAllItems(),
        itemsApi.getAllItemTypes(),
        itemsApi.getAllItemShapes(),
        itemsApi.getAllItemSizes(),
        itemsApi.getAllItemBrims(),
      ]);
      setItems(itemsRes.data || []);
      setTypes(typesRes.data || []);
      setShapes(shapesRes.data || []);
      setSizes(sizesRes.data || []);
      setBrims(brimsRes.data || []);
    } catch (err) {
      setError(err.message);
      showSnackbar('Error loading data', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleAdd = (category) => {
    return async (data) => {
      try {
        let response;
        switch (category) {
          case 'types':
            response = await itemsApi.createItemType(data);
            break;
          case 'shapes':
            response = await itemsApi.createItemShape(data);
            break;
          case 'sizes':
            response = await itemsApi.createItemSize(data);
            break;
          case 'brims':
            response = await itemsApi.createItemBrim(data);
            break;
          default:
            throw new Error('Invalid category');
        }
        await fetchData();
        showSnackbar('Item added successfully');
      } catch (err) {
        showSnackbar(err.message || 'Error adding item', 'error');
      }
    };
  };

  const handleEdit = (category) => {
    return async (name, data) => {
      try {
        let response;
        switch (category) {
          case 'types':
            response = await itemsApi.updateItemType(name, data);
            break;
          case 'shapes':
            response = await itemsApi.updateItemShape(name, data);
            break;
          case 'sizes':
            response = await itemsApi.updateItemSize(name, data);
            break;
          case 'brims':
            response = await itemsApi.updateItemBrim(name, data);
            break;
          default:
            throw new Error('Invalid category');
        }
        await fetchData();
        showSnackbar('Item updated successfully');
      } catch (err) {
        showSnackbar(err.message || 'Error updating item', 'error');
      }
    };
  };

  return (
    <FullScreenLayout
      header={
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="All Items" />
            <Tab label="Types" />
            <Tab label="Shapes" />
            <Tab label="Sizes" />
            <Tab label="Brims" />
          </Tabs>
        </Box>
      }
      mainPanel={
        <Box sx={{ p: 3 }}>
          {currentTab === 0 && (
            <>
              <Alert severity="info" sx={{ mb: 3 }}>
                Changes to items and their attributes should be made with caution as they can affect inventory tracking and order processing.
              </Alert>
              <ItemsList
                loading={loading}
                error={error}
                fetchData={fetchData}
                items={items}
                setItems={setItems}
              />
            </>
          )}
          {currentTab === 1 && (
            <ItemAttributeManager
              title="Item Types"
              items={types}
              onAdd={handleAdd('types')}
              onEdit={handleEdit('types')}
              loading={loading}
              error={error}
              fetchData={fetchData}
              setItems={setItems}
            />
          )}
          {currentTab === 2 && (
            <ItemAttributeManager
              title="Item Shapes"
              items={shapes}
              onAdd={handleAdd('shapes')}
              onEdit={handleEdit('shapes')}
              loading={loading}
              error={error}
              fetchData={fetchData}
              setItems={setItems}
            />
          )}
          {currentTab === 3 && (
            <ItemAttributeManager
              title="Item Sizes"
              items={sizes}
              onAdd={handleAdd('sizes')}
              onEdit={handleEdit('sizes')}
              loading={loading}
              error={error}
              fetchData={fetchData}
              setItems={setItems}
            />
          )}
          {currentTab === 4 && (
            <ItemAttributeManager
              title="Item Brims"
              items={brims}
              onAdd={handleAdd('brims')}
              onEdit={handleEdit('brims')}
              loading={loading}
              error={error}
              fetchData={fetchData}
              setItems={setItems}
            />
          )}
        </Box>
      }
    />
  );
};

export default Items; 