import React, { useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { DRAWER_WIDTH, NAV_HEIGHT } from '../../constants/layout';
import { LayoutContext } from '../../App';

const FullPageContainer = muiStyled('div')(({ theme, hasNavigation, hasSideNav, isMinimized }) => ({
  height: hasNavigation ? `calc(100vh - ${NAV_HEIGHT}px)` : '100vh',
  width: '100%',
  position: 'fixed',
  top: hasNavigation ? NAV_HEIGHT : 0,
  left: 0,
  backgroundColor: '#f5f5f5',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  borderLeft: hasSideNav ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
  zIndex: 1000,
  transition: 'all 0.3s ease-in-out',
  [theme.breakpoints.up('md')]: {
    width: hasSideNav ? 
      (isMinimized ? 'calc(100vw - 65px)' : `calc(100vw - ${DRAWER_WIDTH}px)`) : 
      '100vw',
    left: hasSideNav ? (isMinimized ? '65px' : DRAWER_WIDTH) : 0,
  },
}));

const ShipmentSummary = muiStyled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: '#fff',
  borderBottom: '1px solid #e0e0e0',
}));

const SplitViewContainer = muiStyled('div')({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
});

const SidePanel = muiStyled(Box)(({ theme }) => ({
  width: '30%',
  height: '100%',
  overflow: 'auto',
  borderRight: '1px solid #e0e0e0',
  backgroundColor: '#fff',
}));

const MainPanel = muiStyled(Box)(({ theme }) => ({
  width: '70%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: '#fff',
}));

const NotificationContainer = muiStyled('div')(({ theme, hasSideNav, isMinimized }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1400,
  transition: 'all 0.3s ease-in-out',
  [theme.breakpoints.up('md')]: {
    left: hasSideNav ? 
      (isMinimized ? 'calc(50% + (65px / 2))' : `calc(50% + (${DRAWER_WIDTH}px / 2))`) : 
      '50%',
  },
}));

const FullScreenLayout = ({
  header,
  summary,
  sidePanel,
  mainPanel,
  footer,
  showSplitView = false,
  sx = {}
}) => {
  const { hasSideNav, hasNavigation, isMinimized } = useContext(LayoutContext);
  const theme = useTheme();
  
  return (
    <>
      <FullPageContainer 
        theme={theme}
        hasNavigation={hasNavigation}
        hasSideNav={hasSideNav}
        isMinimized={isMinimized}
        sx={sx}
      >
        {/* Header Section */}
        <Box>
          {header}
        </Box>

        {/* Summary Section (if provided) */}
        {summary && (
          <ShipmentSummary>
            {summary}
          </ShipmentSummary>
        )}

        {/* Main Content Area */}
        {showSplitView ? (
          <SplitViewContainer>
            <SidePanel>
              {sidePanel}
            </SidePanel>
            <MainPanel>
              {mainPanel}
            </MainPanel>
          </SplitViewContainer>
        ) : (
          <Box sx={{ overflow: 'auto', flex: 1 }}>
            {mainPanel}
          </Box>
        )}

        {/* Footer Section */}
        {footer && (
          <Box display="flex" justifyContent="space-between" p={2}>
            {footer}
          </Box>
        )}
      </FullPageContainer>

      {/* Notification Container */}
      <NotificationContainer 
        hasSideNav={hasSideNav} 
        isMinimized={isMinimized}
      />
    </>
  );
};

export default FullScreenLayout; 