import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    IconButton,
    Typography,
    CircularProgress,
    Stepper,
    Step,
    StepLabel,
    Paper,
    Divider,
    Alert,
    Chip,
    Checkbox,
    Tooltip,
    Collapse,
    LinearProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { metricsAndViewsApi } from '../../services/metricsAndViewsApi';
import { useSnackbar } from 'notistack';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
    DataGrid,
    GridToolbar,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import EditIcon from '@mui/icons-material/Edit';

const steps = [
    'Define Metric', 
    'Select Sources', 
    'Review & Create'
];

const CreateMetricDialog = ({ open, onClose, onSubmit }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [sources, setSources] = useState([{ 
        sourceTypeId: '', 
        referenceIds: [],
        operation: '+', 
        displayOrder: 0 
    }]);
    const [dataSources, setDataSources] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [filters, setFilters] = useState({
        status: '',
        customer_id: '',
        shipping_method: '',
        shipment_type: '',
        is_customer_request: '',
        request_status: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [filtersVisible, setFiltersVisible] = useState(false);

    useEffect(() => {
        if (open) {
            fetchDataSources();
        }
    }, [open, filters]);

    const fetchDataSources = async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await metricsAndViewsApi.getDataSources(filters);
            setDataSources(data);
        } catch (error) {
            setError(error.message);
            setDataSources({
                warehouses: [],
                locations: [],
                shipments: [],
                orders: [],
                customers: []
            });
        } finally {
            setLoading(false);
        }
    };

    const handleNext = () => {
        if (activeStep === 0 && !name.trim()) {
            enqueueSnackbar('Please enter a metric name', { variant: 'warning' });
            return;
        }
        
        if (activeStep === 1) {
            const validSources = sources.filter(source => 
                source.sourceTypeId && 
                source.referenceIds.length > 0 && 
                source.operation
            );
            
            if (validSources.length === 0) {
                enqueueSnackbar('Please add at least one valid source', { variant: 'warning' });
                return;
            }
        }
        
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = () => {
        const validSources = sources.filter(source => 
            source.sourceTypeId && 
            source.referenceIds?.length > 0 && 
            source.operation
        );

        const submitData = {
            name,
            description,
            sources: validSources.flatMap(source => 
                source.referenceIds.map(refId => ({
                    sourceTypeId: parseInt(source.sourceTypeId),
                    referenceId: parseInt(refId),
                    operation: source.operation,
                    displayOrder: source.displayOrder
                }))
            )
        };

        onSubmit(submitData);
    };

    const handleAddSource = () => {
        setSources([...sources, { 
            sourceTypeId: '', 
            referenceIds: [],
            operation: '+', 
            displayOrder: sources.length 
        }]);
    };

    const handleRemoveSource = (index) => {
        setSources(sources.filter((_, i) => i !== index));
    };

    const handleSourceChange = (index, field, value) => {
        const newSources = [...sources];
        if (field === 'referenceIds') {
            const currentIds = newSources[index].referenceIds || [];
            const newIds = currentIds.includes(value)
                ? currentIds.filter(id => id !== value)
                : [...currentIds, value];
            newSources[index] = {
                ...newSources[index],
                referenceIds: newIds
            };
        } else {
            newSources[index] = {
                ...newSources[index],
                [field]: value
            };
        }
        setSources(newSources);
    };

    const getSourceOptions = (sourceTypeId) => {
        if (!sourceTypeId || !dataSources) return [];
        
        switch (sourceTypeId) {
            case 1:
                return dataSources.warehouses.map(w => ({
                    id: w.warehouse_id,
                    name: w.name
                }));
            case 2:
                return dataSources.locations.map(l => ({
                    id: l.db_location_id,
                    name: l.name
                }));
            case 3:
                return dataSources.shipments.map(s => ({
                    id: s.shipment_id,
                    name: s.shipment_number
                }));
            case 4:
                return dataSources.orders.map(o => ({
                    id: o.order_id,
                    name: o.order_number
                }));
            default:
                return [];
        }
    };

    const getSourceTypeName = (sourceTypeId) => {
        switch (sourceTypeId) {
            case 1:
                return 'Warehouse';
            case 2:
                return 'Location';
            case 3:
                return 'Shipment';
            case 4:
                return 'Order';
            default:
                return 'Unknown';
        }
    };

    const handleSourceTypeChange = (index, value) => {
        const newSources = [...sources];
        newSources[index] = {
            ...newSources[index],
            sourceTypeId: parseInt(value),
            referenceIds: [],
            operation: newSources[index].operation || '+',
            displayOrder: index
        };
        setSources(newSources);
    };

    const handleFilterChange = (filterName, value) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: value
        }));
    };

    const getFilteredSourceOptions = (sourceTypeId) => {
        if (!sourceTypeId || !dataSources) return [];

        let options = [];
        const search = searchTerm.toLowerCase();

        switch (sourceTypeId) {
            case 1: // Warehouses
                options = dataSources.warehouses.map(warehouse => ({
                    id: warehouse.warehouse_id,
                    name: warehouse.name,
                    details: `${warehouse.code} - ${warehouse.current_total_inventory} items`
                }));
                break;

            case 2: // Locations
                options = dataSources.locations.map(location => ({
                    id: location.db_location_id,
                    name: location.name,
                    details: `${location.region}, ${location.country}`
                }));
                break;

            case 3: // Shipments
                options = dataSources.shipments.map(shipment => ({
                    id: shipment.shipment_id,
                    name: shipment.shipment_number,
                    details: `${shipment.source_warehouse_name} → ${shipment.destination_warehouse_name || shipment.destination_location_name}`
                }));
                break;

            case 4: // Orders
                options = dataSources.orders.map(order => ({
                    id: order.order_id,
                    name: order.order_number,
                    details: `${order.customer_name || 'No Customer'} - ${order.total_items} items`
                }));
                break;

            default:
                return [];
        }

        // Filter options based on search term
        if (search) {
            options = options.filter(option => 
                option.name.toLowerCase().includes(search) || 
                (option.details && option.details.toLowerCase().includes(search))
            );
        }

        // Sort options by name
        return options.sort((a, b) => a.name.localeCompare(b.name));
    };

    const SourceHeader = ({ title, subtitle, operation, onOperationChange }) => (
        <Box 
            sx={{ 
                p: 3,
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                borderRadius: '16px 16px 0 0'
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: 'primary.main', mb: 0.5 }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {subtitle}
                    </Typography>
                </Box>
                <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel>Operation</InputLabel>
                    <Select
                        value={operation}
                        onChange={onOperationChange}
                        label="Operation"
                    >
                        <MenuItem value="+">Add (+)</MenuItem>
                        <MenuItem value="-">Subtract (-)</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );

    const SourceSelectionTable = ({ 
        sourceType, 
        data, 
        onSelect, 
        selectedIds,
        loading,
        searchTerm,
        onSearchChange,
        filters,
        onFilterChange,
        operation,
        onOperationChange 
    }) => {
        const getSourceTypeInfo = () => {
            switch (sourceType) {
                case 1:
                    return {
                        title: 'Warehouse Selection',
                        subtitle: 'Select warehouses to include in your metric calculation'
                    };
                case 2:
                    return {
                        title: 'Location Selection',
                        subtitle: 'Choose locations to incorporate into your metric'
                    };
                case 3:
                    return {
                        title: 'Shipment Selection',
                        subtitle: 'Select shipments to track in your metric'
                    };
                case 4:
                    return {
                        title: 'Order Selection',
                        subtitle: 'Choose orders to include in your metric calculation'
                    };
                default:
                    return { title: '', subtitle: '' };
            }
        };

        const getColumns = () => {
            const baseColumns = [];

            switch (sourceType) {
                case 1: // Warehouses
                    return [
                        { field: 'name', headerName: 'Name', flex: 1 },
                        { field: 'code', headerName: 'Code', width: 130 },
                        { 
                            field: 'current_total_inventory', 
                            headerName: 'Current Inventory',
                            type: 'number',
                            width: 150 
                        },
                        { 
                            field: 'available_capacity', 
                            headerName: 'Available Capacity',
                            type: 'number',
                            width: 150 
                        },
                        { field: 'status', headerName: 'Status', width: 120 }
                    ];

                case 2: // Locations
                    return [
                        { field: 'name', headerName: 'Name', flex: 1 },
                        { field: 'location_type', headerName: 'Type', width: 130 },
                        { 
                            field: 'current_inventory', 
                            headerName: 'Current Inventory',
                            type: 'number',
                            width: 150 
                        },
                        { field: 'status', headerName: 'Status', width: 120 }
                    ];

                case 3: // Shipments
                    return [
                        { field: 'shipment_number', headerName: 'Shipment #', width: 150 },
                        { 
                            field: 'source_warehouse_name', 
                            headerName: 'From',
                            flex: 1
                        },
                        { 
                            field: 'destination_name',
                            headerName: 'To',
                            flex: 1,
                            valueGetter: (params) => 
                                params.row.destination_warehouse_name || 
                                params.row.destination_location_name
                        },
                        { field: 'status', headerName: 'Status', width: 120 },
                        { field: 'shipment_type', headerName: 'Type', width: 150 }
                    ];

                case 4: // Orders
                    return [
                        { field: 'order_number', headerName: 'Order #', width: 150 },
                        { field: 'customer_name', headerName: 'Customer', flex: 1 },
                        { 
                            field: 'total_items', 
                            headerName: 'Items',
                            type: 'number',
                            width: 100 
                        },
                        { field: 'status', headerName: 'Status', width: 120 },
                        { 
                            field: 'created_at', 
                            headerName: 'Created',
                            type: 'date',
                            width: 150,
                            valueGetter: (params) => new Date(params.row.created_at)
                        }
                    ];

                default:
                    return baseColumns;
            }
        };

        const getFilterInputs = () => {
            switch (sourceType) {
                case 3: // Shipments
                    return (
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <FormControl size="small" sx={{ minWidth: 200 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={filters.status || ''}
                                    onChange={(e) => onFilterChange('status', e.target.value)}
                                    label="Status"
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="processing">Processing</MenuItem>
                                    <MenuItem value="in_transit">In Transit</MenuItem>
                                    <MenuItem value="delivered">Delivered</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small" sx={{ minWidth: 200 }}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={filters.shipment_type || ''}
                                    onChange={(e) => onFilterChange('shipment_type', e.target.value)}
                                    label="Type"
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="customer_delivery">Customer Delivery</MenuItem>
                                    <MenuItem value="warehouse_delivery">Warehouse Delivery</MenuItem>
                                    <MenuItem value="general_delivery">General Delivery</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    );

                case 4: // Orders
                    return (
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <FormControl size="small" sx={{ minWidth: 200 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={filters.request_status || ''}
                                    onChange={(e) => onFilterChange('request_status', e.target.value)}
                                    label="Status"
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="processing">Processing</MenuItem>
                                    <MenuItem value="shipped">Shipped</MenuItem>
                                    <MenuItem value="delivered">Delivered</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    );

                default:
                    return null;
            }
        };

        const rows = data?.map(item => ({
            id: sourceType === 1 ? item.warehouse_id :
                sourceType === 2 ? item.db_location_id :
                sourceType === 3 ? item.shipment_id :
                sourceType === 4 ? item.order_id : null,
            ...item
        })) || [];

        return (
            <Paper 
                elevation={0} 
                sx={{ 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '16px',
                    overflow: 'hidden'
                }}
            >
                <SourceHeader 
                    {...getSourceTypeInfo()}
                    operation={operation}
                    onOperationChange={onOperationChange}
                />

                <Box sx={{ p: 3 }}>
                    <Box sx={{ 
                        display: 'flex', 
                        gap: 2, 
                        alignItems: 'center', 
                        mb: 2,
                        backgroundColor: 'background.paper',
                        p: 2,
                        borderRadius: '8px'
                    }}>
                        <TextField
                            size="small"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => onSearchChange(e.target.value)}
                            InputProps={{
                                startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                                sx: { backgroundColor: 'white' }
                            }}
                            sx={{ flex: 1 }}
                        />
                        <Tooltip title="Advanced filters">
                            <IconButton 
                                onClick={() => setFiltersVisible(!filtersVisible)}
                                color={filtersVisible ? 'primary' : 'default'}
                                sx={{ 
                                    border: '1px solid',
                                    borderColor: filtersVisible ? 'primary.main' : 'divider'
                                }}
                            >
                                <TuneIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Collapse in={filtersVisible}>
                        <Box sx={{ mb: 3 }}>
                            {getFilterInputs()}
                        </Box>
                    </Collapse>

                    <Box sx={{ height: 400, backgroundColor: 'white', borderRadius: '8px' }}>
                        <DataGrid
                            rows={rows}
                            columns={getColumns()}
                            loading={loading}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(newSelection) => {
                                onSelect(newSelection);
                            }}
                            selectionModel={selectedIds}
                            slots={{
                                toolbar: GridToolbar,
                                loadingOverlay: LinearProgress,
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 5 } },
                                sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'primary.main',
                                },
                                '& .MuiDataGrid-row.Mui-selected': {
                                    backgroundColor: 'primary.lighter',
                                },
                                '& .MuiDataGrid-row.Mui-selected:hover': {
                                    backgroundColor: 'primary.light',
                                },
                            }}
                        />
                    </Box>

                    {selectedIds.length > 0 && (
                        <Box sx={{ 
                            mt: 2, 
                            p: 2, 
                            backgroundColor: 'primary.lighter',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                        }}>
                            <InfoOutlinedIcon color="primary" fontSize="small" />
                            <Typography variant="body2" color="primary.main">
                                {selectedIds.length} items selected
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Paper>
        );
    };

    const MetricDefinitionStep = ({ name, description, setName, setDescription }) => (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom color="primary.main" sx={{ fontWeight: 600 }}>
                Define Your Metric
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                Give your metric a clear name and description to help others understand what it measures.
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <TextField
                    label="Metric Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    required
                    placeholder="E.g., Total Warehouse Capacity"
                    helperText="Choose a descriptive name that clearly indicates what this metric measures"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'background.paper'
                        }
                    }}
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Describe how this metric is calculated and what it represents..."
                    helperText="Provide context about what this metric measures and how it should be interpreted"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'background.paper'
                        }
                    }}
                />
            </Box>
        </Box>
    );

    const SourceSelectionStep = ({ 
        sources, 
        onSourceChange, 
        onAddSource, 
        onRemoveSource,
        loading 
    }) => (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom color="primary.main" sx={{ fontWeight: 600 }}>
                Configure Data Sources
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                Select the data sources that will be used to calculate this metric. You can combine multiple sources with different operations.
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {sources.map((source, index) => (
                    <Paper 
                        key={index} 
                        elevation={0}
                        sx={{ 
                            p: 3, 
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: '16px'
                        }}
                    >
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            mb: 3
                        }}>
                            <Box>
                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                    Source {index + 1}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Configure how this source contributes to the metric
                                </Typography>
                            </Box>
                            <IconButton 
                                onClick={() => onRemoveSource(index)}
                                color="error"
                                sx={{ 
                                    '&:hover': { 
                                        backgroundColor: 'error.lighter'
                                    }
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>

                        {/* Rest of your source selection UI */}
                    </Paper>
                ))}

                <Button
                    startIcon={<AddIcon />}
                    onClick={onAddSource}
                    variant="outlined"
                    sx={{ 
                        alignSelf: 'flex-start',
                        borderStyle: 'dashed',
                        '&:hover': {
                            borderStyle: 'dashed'
                        }
                    }}
                >
                    Add Another Source
                </Button>
            </Box>
        </Box>
    );

    const ReviewStep = ({ metric, onEdit }) => (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom color="primary.main" sx={{ fontWeight: 600 }}>
                Review Metric Configuration
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                Review your metric configuration before creating it. Make sure all the details are correct.
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Paper 
                    elevation={0}
                    sx={{ 
                        p: 3, 
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: '16px'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            Basic Information
                        </Typography>
                        <Button 
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => onEdit(0)}
                        >
                            Edit
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                Name
                            </Typography>
                            <Typography variant="body1">
                                {metric.name}
                            </Typography>
                        </Box>
                        {metric.description && (
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Description
                                </Typography>
                                <Typography variant="body1">
                                    {metric.description}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Paper>

                <Paper 
                    elevation={0}
                    sx={{ 
                        p: 3, 
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: '16px'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            Data Sources
                        </Typography>
                        <Button 
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => onEdit(1)}
                        >
                            Edit
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {metric.sources.map((source, index) => (
                            <Box 
                                key={index}
                                sx={{ 
                                    p: 2,
                                    backgroundColor: 'background.default',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }}
                            >
                                <Chip 
                                    label={source.operation === '+' ? 'Add' : 'Subtract'}
                                    color={source.operation === '+' ? 'success' : 'error'}
                                    size="small"
                                />
                                <Box>
                                    <Typography variant="subtitle2">
                                        {getSourceTypeName(source.sourceTypeId)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {source.referenceIds.length} items selected
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Paper>
            </Box>
        </Box>
    );

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="md" 
            fullWidth
            PaperProps={{
                sx: { 
                    minHeight: '70vh',
                    maxHeight: '90vh'
                }
            }}
        >
            <DialogTitle sx={{ pb: 1 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Create New Metric</Typography>
                <Stepper 
                    activeStep={activeStep} 
                    sx={{ 
                        '& .MuiStepLabel-label': {
                            fontSize: '0.875rem'
                        }
                    }}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </DialogTitle>
            
            <DialogContent dividers>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert severity="error" sx={{ my: 2 }}>
                        {error}
                    </Alert>
                ) : (
                    <Box sx={{ minHeight: 400 }}>
                        {activeStep === 0 && (
                            <MetricDefinitionStep 
                                name={name}
                                description={description}
                                setName={setName}
                                setDescription={setDescription}
                            />
                        )}
                        {activeStep === 1 && (
                            <SourceSelectionStep 
                                sources={sources}
                                onSourceChange={handleSourceChange}
                                onAddSource={handleAddSource}
                                onRemoveSource={handleRemoveSource}
                                loading={loading}
                            />
                        )}
                        {activeStep === 2 && (
                            <ReviewStep 
                                metric={{ name, description, sources }}
                                onEdit={setActiveStep}
                            />
                        )}
                    </Box>
                )}
            </DialogContent>

            <DialogActions sx={{ p: 2, gap: 1 }}>
                <Button onClick={onClose}>Cancel</Button>
                {activeStep > 0 && (
                    <Button onClick={handleBack}>
                        Back
                    </Button>
                )}
                {activeStep === steps.length - 1 ? (
                    <Button 
                        onClick={handleSubmit} 
                        variant="contained" 
                        color="primary"
                        disabled={loading}
                    >
                        Create Metric
                    </Button>
                ) : (
                    <Button 
                        onClick={handleNext} 
                        variant="contained" 
                        color="primary"
                        disabled={loading}
                    >
                        Continue
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CreateMetricDialog; 