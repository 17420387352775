import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Chip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Tabs,
  Tab,
  Card,
  CardContent,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LocalShipping as ShippingIcon,
  Assignment as AssignmentIcon,
  Comment as CommentIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Upload as UploadIcon,
  Attachment as AttachmentIcon,
  Download as DownloadIcon,
  Send as SendIcon,
  PictureAsPdf,
  Image as ImageIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { 
  getShipmentById, 
  updateShipmentStatus, 
  addShipmentComment,
  getShipmentComments,
  getShipmentFiles,
  uploadShipmentFile,
  getFilePreviewUrl,
  downloadShipmentFile,
  getShipmentSpreadsheetData,
  getShipmentTypes,
  getShipmentOrders,
  getConsolidatedShipmentData,
  downloadShippingList,
} from '../services/api';
import { format } from 'date-fns';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import SpreadsheetExportable from './spreadsheets/SpreadsheetExportable';
import SpreadsheetExportableV2 from './spreadsheets/SpreadsheetExportableV2';
import FullScreenLayout from './layouts/FullScreenLayout';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

const TimelineContainer = styled('div')({
  marginLeft: '16px',
  paddingLeft: '16px',
  borderLeft: '2px solid #e0e0e0',
});

const TimelineItem = styled('div')({
  position: 'relative',
  paddingBottom: '24px',
  '&:last-child': {
    paddingBottom: 0,
  },
});

const TimelineDot = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '-25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& svg': {
    fontSize: '12px',
  },
}));

const TimelineContent = styled('div')({
  marginLeft: '24px',
  padding: '8px 0',
});

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '400px',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  overflow: 'hidden',
});

const ChatMessages = styled('div')({
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column-reverse', // Show newest messages at bottom
});

const ChatInputContainer = styled('div')({
  padding: '16px',
  borderTop: '1px solid #e0e0e0',
  backgroundColor: '#fff',
});

const MessageBubble = styled('div')(({ theme, isCurrentUser }) => ({
  maxWidth: '70%',
  padding: '12px 16px',
  borderRadius: '12px',
  marginBottom: '8px',
  alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  backgroundColor: isCurrentUser ? theme.palette.primary.main : '#e0e0e0',
  color: isCurrentUser ? theme.palette.primary.contrastText : 'inherit',
}));

const FilePreview = styled('div')({
  width: '120px',
  height: '120px',
  marginRight: '16px',
  position: 'relative',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f5f5f5',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  '& object': {
    width: '100%',
    height: '100%',
  }
});

const UploadProgress = styled('div')({
  marginTop: '8px',
  width: '100%',
});

const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'completed':
    case 'delivered':
      return 'success';
    case 'processing':
    case 'in_transit':
      return 'primary';
    case 'cancelled':
      return 'error';
    case 'pending':
    case 'awaiting_approval':
      return 'warning';
    default:
      return 'default';
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`shipment-tabpanel-${index}`}
      aria-labelledby={`shipment-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ShipmentDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shipment, setShipment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortBy, setSortBy] = useState('item_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [files, setFiles] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploads, setFileUploads] = useState(new Map());
  const [previews, setPreviews] = useState(new Map());
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedType, setSelectedType] = useState('');
  const [types, setTypes] = useState([]);
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    item_name: { value: null, matchMode: 'contains' },
    item_type: { value: null, matchMode: 'contains' },
    warehouse_item_id: { value: null, matchMode: 'contains' },
    quantity: { value: null, matchMode: 'equals' },
    order_item_id: { value: null, matchMode: 'contains' },
    status: { value: null, matchMode: 'equals' }
  });

  const statusOptions = {
    pending: { label: 'Pending', color: 'default' },
    processing: { label: 'Processing', color: 'info' },
    in_transit: { label: 'In Transit', color: 'primary' },
    delivered: { label: 'Delivered', color: 'success' },
    cancelled: { label: 'Cancelled', color: 'error' },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchShipmentData(),
          fetchOrders()
        ]);
      } catch (err) {
        setError(err.message || 'Failed to fetch data');
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    files.forEach(file => {
      if (!previews.has(file.file_id)) {
        loadFilePreview(file);
      }
    });
  }, [files]);

  useEffect(() => {
    if (currentTab === 2) {
      loadSpreadsheetData();
    }
  }, [currentTab, id]);

  const fetchShipmentData = async () => {
    try {
      setLoading(true);
      const [shipmentData, commentsData, filesData] = await Promise.all([
        getShipmentById(id),
        getShipmentComments(id),
        getShipmentFiles(id)
      ]);
      setShipment(shipmentData);
      setComments(commentsData);
      setFiles(filesData);
    } catch (err) {
      setError(err.message || 'Failed to fetch shipment details');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async () => {
    try {
      await updateShipmentStatus(
        id, 
        selectedStatus, 
        selectedStatus === 'cancelled' ? cancellationReason : undefined
      );
      await fetchShipmentData();
      setStatusDialogOpen(false);
      setSelectedStatus('');
      setCancellationReason('');
    } catch (err) {
      setError(err.message || 'Failed to update status');
    }
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      await addShipmentComment(id, newComment);
      await fetchShipmentData();
      setNewComment('');
    } catch (err) {
      setError(err.message || 'Failed to add comment');
    }
  };

  const getFilteredAndSortedItems = () => {
    if (!shipment?.items) return [];
    
    let filteredItems = [...shipment.items];
    
    // Apply search filter
    if (searchTerm) {
      filteredItems = filteredItems.filter(item => 
        item.item_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.item_details?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Sort items
    filteredItems.sort((a, b) => {
      const aValue = a[sortBy] || '';
      const bValue = b[sortBy] || '';
      
      if (sortOrder === 'asc') {
        return aValue.toString().localeCompare(bValue.toString());
      } else {
        return bValue.toString().localeCompare(aValue.toString());
      }
    });

    return filteredItems;
  };

  const handleFileUpload = async () => {
    try {
      if (!selectedFile) return;

      setFileUploads(prev => new Map(prev).set(selectedFile.name, 0));

      const uploadedFile = await uploadShipmentFile(
        id, 
        selectedFile,
        (progress) => {
          setFileUploads(prev => new Map(prev).set(selectedFile.name, progress));
        }
      );

      setFileUploads(prev => {
        const newMap = new Map(prev);
        newMap.delete(selectedFile.name);
        return newMap;
      });

      await fetchShipmentData();
      setUploadDialogOpen(false);
      setSelectedFile(null);
    } catch (err) {
      setError(err.message || 'Failed to upload file');
      setFileUploads(prev => {
        const newMap = new Map(prev);
        newMap.delete(selectedFile.name);
        return newMap;
      });
    }
  };

  const handleFileDownload = async (file) => {
    try {
      const blob = await downloadShipmentFile(id, file.file_id);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.file_name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (err) {
      setError(err.message || 'Failed to download file');
    }
  };

  const loadFilePreview = async (file) => {
    try {
      if (previews.has(file.file_id)) return;
      const previewUrl = await getFilePreviewUrl(id, file.file_id);
      setPreviews(prev => new Map(prev).set(file.file_id, previewUrl));
    } catch (error) {
      setError(error.message || 'Failed to load file preview');
    }
  };

  const loadSpreadsheetData = async () => {
    try {
      setError(null);
      
      if (currentTab === 2) { // Spreadsheet tab
        const data = await getConsolidatedShipmentData(id);
        
        // Ensure data is an object before setting it
        if (data && typeof data === 'object' && !Array.isArray(data)) {
          setSpreadsheetData(data);
        } else {
          // If data is not in the correct format, initialize an empty object
          setSpreadsheetData({});
          console.error('Invalid spreadsheet data format received:', data);
        }
      }
    } catch (error) {
      setError(error.message || 'Failed to load spreadsheet data');
      setSpreadsheetData({}); // Set empty object on error
    }
  };

  const fetchOrders = async () => {
    try {
      const ordersData = await getShipmentOrders(id);
      setOrders(ordersData);
    } catch (err) {
      setError(err.message || 'Failed to fetch orders');
    }
  };

  const OrdersTab = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Orders in this Shipment
      </Typography>
      
      {orders.length > 0 ? (
        <Grid container spacing={3}>
          {orders.map((order) => (
            <Grid item xs={12} key={order.order_id}>
              <Card 
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 6,
                    transform: 'translateY(-2px)',
                    transition: 'all 0.2s ease-in-out'
                  }
                }}
                onClick={() => navigate(`/orders/${order.order_id}/details`)}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        Order {order.order_number}
                      </Typography>
                      <Chip 
                        label={order.order_status}
                        color={getStatusColor(order.order_status)}
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography>
                          <strong>Items:</strong> {order.total_items}
                        </Typography>
                        <Typography>
                          <strong>Shipped:</strong> {order.total_shipped_quantity} of {order.total_ordered_quantity}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 1 }}>
                        <LinearProgress 
                          variant="determinate" 
                          value={order.completion_percentage}
                          sx={{ height: 10, borderRadius: 5, mb: 1 }}
                        />
                        <Typography color="textSecondary">
                          {order.completion_percentage}% Complete
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert severity="info">No orders in this shipment</Alert>
      )}
    </Box>
  );

  const handleDownloadShippingList = async () => {
    try {
      await downloadShippingList(id);
    } catch (error) {
      setError(error.message || 'Failed to download shipping list');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!shipment) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="info">Shipment not found</Alert>
      </Container>
    );
  }

  const renderHeader = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h4">
        Shipment {shipment.shipment_number}
      </Typography>
      <Box>
        <Button 
          variant="outlined" 
          startIcon={<PictureAsPdfIcon />}
          onClick={handleDownloadShippingList}
          sx={{ mr: 2 }}
        >
          Download Shipping List
        </Button>
        <Button 
          variant="outlined" 
          onClick={() => setStatusDialogOpen(true)}
        >
          Update Status
        </Button>
      </Box>
    </Box>
  );

  const renderSummary = () => (
    <Box>
      <Chip 
        label={statusOptions[shipment.status]?.label || shipment.status}
        color={statusOptions[shipment.status]?.color || 'default'}
        sx={{ mr: 1 }}
      />
      <Typography component="span" sx={{ ml: 2 }}>
        <strong>From:</strong> {shipment.source_warehouse_name}
        {shipment.destination_warehouse_name && ` → ${shipment.destination_warehouse_name}`}
      </Typography>
    </Box>
  );

  const renderMainPanel = () => (
    <Box>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => setCurrentTab(newValue)}
        sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
      >
        <Tab label="Details" />
        <Tab label="Items Grid" />
        <Tab label="Items Spreadsheet" />
        <Tab label="Orders" />
        <Tab label="Comments & Files" />
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Shipment Details
            </Typography>
            <Box mb={2}>
              <Chip 
                label={statusOptions[shipment.status]?.label || shipment.status}
                color={statusOptions[shipment.status]?.color || 'default'}
                sx={{ mb: 1 }}
              />
            </Box>
            <Typography><strong>Shipment Number:</strong> {shipment.shipment_number}</Typography>
            <Typography><strong>From:</strong> {shipment.source_warehouse_name}</Typography>
            {shipment.destination_warehouse_name && (
              <Typography><strong>To Warehouse:</strong> {shipment.destination_warehouse_name}</Typography>
            )}
            {shipment.destination_location_name && (
              <Typography><strong>To Location:</strong> {shipment.destination_location_name}</Typography>
            )}
            <Typography><strong>Type:</strong> {shipment.shipment_type}</Typography>
            <Typography><strong>Method:</strong> {shipment.shipping_method}</Typography>
            {shipment.tracking_number && (
              <Typography><strong>Tracking:</strong> {shipment.tracking_number}</Typography>
            )}
            {shipment.carrier && (
              <Typography><strong>Carrier:</strong> {shipment.carrier}</Typography>
            )}
            {shipment.notes && (
              <Typography><strong>Notes:</strong> {shipment.notes}</Typography>
            )}
            {shipment.special_instructions && (
              <Typography><strong>Special Instructions:</strong> {shipment.special_instructions}</Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Dates
            </Typography>
            <Typography>
              <strong>Created:</strong> {format(new Date(shipment.created_at), 'PPpp')}
            </Typography>
            {shipment.scheduled_date && (
              <Typography>
                <strong>Scheduled:</strong> {format(new Date(shipment.scheduled_date), 'PPpp')}
              </Typography>
            )}
            {shipment.shipped_date && (
              <Typography>
                <strong>Shipped:</strong> {format(new Date(shipment.shipped_date), 'PPpp')}
              </Typography>
            )}
            {shipment.estimated_delivery_date && (
              <Typography>
                <strong>Estimated Delivery:</strong> {format(new Date(shipment.estimated_delivery_date), 'PPpp')}
              </Typography>
            )}
            {shipment.actual_delivery_date && (
              <Typography>
                <strong>Delivered:</strong> {format(new Date(shipment.actual_delivery_date), 'PPpp')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Items
          </Typography>
          
          <DataTable
            value={getFilteredAndSortedItems()}
            paginator
            rows={10}
            dataKey="shipment_item_id"
            filters={filters}
            filterDisplay="menu"
            loading={loading}
            globalFilterFields={['item_name', 'item_type', 'warehouse_item_id', 'order_item_id']}
            header={
              <div className="flex justify-content-between align-items-center">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search items..."
                  />
                </span>
                <div className="flex align-items-center">
                  <FormControl sx={{ minWidth: 120, mr: 2 }}>
                    <InputLabel>Sort By</InputLabel>
                    <Select
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      label="Sort By"
                      size="small"
                    >
                      <MenuItem value="item_name">Item Name</MenuItem>
                      <MenuItem value="warehouse_item_id">Item ID</MenuItem>
                      <MenuItem value="quantity">Quantity</MenuItem>
                      <MenuItem value="order_item_id">Order Reference</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Sort Order</InputLabel>
                    <Select
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}
                      label="Sort Order"
                      size="small"
                    >
                      <MenuItem value="asc">Ascending</MenuItem>
                      <MenuItem value="desc">Descending</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            }
            emptyMessage="No items found."
            responsiveLayout="scroll"
            scrollHeight="600px"
          >
            <Column 
              field="item_name" 
              header="Item Name" 
              sortable 
              filter
              filterPlaceholder="Search by name"
              body={(rowData) => (
                <Typography variant="body1">
                  {rowData.item_name || 'N/A'}
                </Typography>
              )}
            />
            <Column 
              field="item_details" 
              header="Details" 
              sortable
              filter
              filterPlaceholder="Search by details"
              body={(rowData) => (
                <Typography variant="body2" color="textSecondary">
                  {`${rowData.item_type} - ${rowData.item_size} - ${rowData.item_shape} ${rowData.item_brim ? `- ${rowData.item_brim}` : ''}`}
                </Typography>
              )}
            />
            <Column 
              field="warehouse_item_id" 
              header="Warehouse ID" 
              sortable
              filter
              filterPlaceholder="Search by ID"
            />
            <Column 
              field="quantity" 
              header="Quantity" 
              sortable
              filter
              filterPlaceholder="Search by quantity"
              body={(rowData) => (
                <Typography variant="body1" fontWeight="medium">
                  {rowData.quantity}
                </Typography>
              )}
            />
            <Column 
              field="order_item_id" 
              header="Order Reference" 
              sortable
              filter
              filterPlaceholder="Search by order"
              body={(rowData) => (
                rowData.is_part_of_order ? (
                  <Chip 
                    size="small" 
                    label={`Order Item #${rowData.order_item_id}`}
                    color="primary" 
                    sx={{ 
                      maxWidth: '200px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'primary.dark'
                      }
                    }}
                    onClick={() => {
                      if (rowData.order_id) {
                        navigate(`/orders/${rowData.order_id}/details`);
                      }
                    }}
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    N/A
                  </Typography>
                )
              )}
            />
            <Column 
              field="status" 
              header="Status" 
              sortable
              filter
              filterPlaceholder="Search by status"
              body={(rowData) => (
                <Chip
                  size="small"
                  label={shipment.status || 'Pending'}
                  color={getStatusColor(shipment.status)}
                />
              )}
            />
          </DataTable>
        </Box>
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Shipment Items Spreadsheet
          </Typography>
          
          <SpreadsheetExportableV2
            data={spreadsheetData}
            title="Shipment Items"
          />
        </Box>
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        <OrdersTab />
      </TabPanel>

      <TabPanel value={currentTab} index={4}>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Comments & History
          </Typography>
          <ChatContainer>
            <ChatMessages>
              {comments.map((comment) => (
                <Box key={comment.comment_id} sx={{ mb: 2 }}>
                  <MessageBubble 
                    isCurrentUser={comment.user_id === parseInt(localStorage.getItem('userId'))}
                  >
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      {comment.user_avatar && (
                        <Avatar src={comment.user_avatar} sx={{ width: 24, height: 24 }} />
                      )}
                      <Typography variant="subtitle2" component="span">
                        {comment.user_name}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {format(new Date(comment.created_at), 'PPpp')}
                      </Typography>
                    </Box>
                    <Typography>{comment.comment_text}</Typography>
                  </MessageBubble>
                </Box>
              ))}
            </ChatMessages>
            <ChatInputContainer>
              <form onSubmit={handleAddComment}>
                <Box display="flex" gap={1}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Type a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton 
                            type="submit"
                            disabled={!newComment.trim()}
                            color="primary"
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </form>
            </ChatInputContainer>
          </ChatContainer>
        </Paper>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Files & Attachments
          </Typography>
          <List>
            {files.map((file) => {
              const isUploading = fileUploads.has(file.file_name);
              const uploadProgress = fileUploads.get(file.file_name);
              const previewUrl = previews.get(file.file_id);

              return (
                <ListItem 
                  key={file.file_id}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    py: 2,
                  }}
                >
                  <FilePreview>
                    {file.file_type.startsWith('image/') ? (
                      previewUrl ? (
                        <img 
                          src={previewUrl} 
                          alt={file.file_name}
                          onError={(e) => {
                            console.error('Image preview failed to load');
                            e.target.parentElement.innerHTML = `
                              <Box sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'grey.100',
                                borderRadius: 1,
                              }}>
                                <ImageIcon color="action" />
                              </Box>
                            `;
                          }}
                        />
                      ) : (
                        <CircularProgress size={24} />
                      )
                    ) : file.file_type === 'application/pdf' ? (
                      previewUrl ? (
                        <iframe
                          src={previewUrl}
                          title={file.file_name}
                          width="100%"
                          height="100%"
                          style={{ border: 'none' }}
                          onError={(e) => {
                            console.error('PDF preview failed to load');
                            e.target.parentElement.innerHTML = `
                              <Box sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'grey.100',
                                borderRadius: 1,
                              }}>
                                <PictureAsPdf color="action" />
                              </Box>
                            `;
                          }}
                        />
                      ) : (
                        <PictureAsPdf color="action" />
                      )
                    ) : (
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'grey.100',
                          borderRadius: 1,
                        }}
                      >
                        <AttachmentIcon color="action" />
                      </Box>
                    )}
                  </FilePreview>
                  <ListItemText
                    primary={file.file_name}
                    secondary={
                      <>
                        <Typography variant="body2" color="textSecondary">
                          Uploaded by {file.uploaded_by_username} on {format(new Date(file.uploaded_at), 'PPpp')}
                        </Typography>
                        {isUploading && (
                          <UploadProgress>
                            <LinearProgress 
                              variant="determinate" 
                              value={uploadProgress} 
                              sx={{ mb: 1 }}
                            />
                            <Typography variant="caption" color="textSecondary">
                              Uploading: {uploadProgress}%
                            </Typography>
                          </UploadProgress>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      onClick={() => handleFileDownload(file)}
                      disabled={isUploading}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </TabPanel>

      {/* Status Update Dialog */}
      <Dialog open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
        <DialogTitle>Update Shipment Status</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            margin="normal"
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Select status</option>
            {Object.entries(statusOptions).map(([value, { label }]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </TextField>
          {selectedStatus === 'cancelled' && (
            <TextField
              fullWidth
              label="Cancellation Reason"
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
              margin="normal"
              multiline
              rows={3}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleStatusChange}
            disabled={!selectedStatus}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* File Upload Dialog */}
      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)}>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <input
            type="file"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Button
              variant="outlined"
              component="span"
              startIcon={<UploadIcon />}
              sx={{ mt: 2 }}
            >
              Choose File
            </Button>
          </label>
          {selectedFile && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected: {selectedFile.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleFileUpload}
            disabled={!selectedFile}
            variant="contained"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      {shipment?.status === 'draft' && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/shipments/create?draft=${shipment.shipment_id}`)}
          startIcon={<EditIcon />}
          sx={{ mr: 2 }}
        >
          Continue Creation
        </Button>
      )}
    </Box>
  );

  return (
    <FullScreenLayout
      header={renderHeader()}
      summary={renderSummary()}
      mainPanel={renderMainPanel()}
    />
  );
}

export default ShipmentDetails; 