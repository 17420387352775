import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { updateShipmentStatus } from '../../services/api';
import { shipmentCreationApi } from '../../services/shipment-creation-api';

export default function ReviewStep({ shipmentDetails, loading, onSuccess }) {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [error, setError] = useState(null);
    const [processingConfirmation, setProcessingConfirmation] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' },
    });
    const [itemStats, setItemStats] = useState({
        totalItems: 0,
        totalQuantity: 0
    });

    useEffect(() => {
        if (shipmentDetails?.items) {
            const stats = shipmentDetails.items.reduce((acc, item) => ({
                totalItems: acc.totalItems + 1,
                totalQuantity: acc.totalQuantity + parseInt(item.quantity || 0)
            }), { totalItems: 0, totalQuantity: 0 });
            setItemStats(stats);
        }
    }, [shipmentDetails]);

    const handleRemoveItems = async () => {
        if (!selectedItems.length) return;

        try {
            // Update quantities to 0 for selected items
            const updatePromises = selectedItems.map(item => 
                shipmentCreationApi.addOrderItems(shipmentDetails.shipment_id, [{
                    warehouseItemId: item.warehouse_item_id,
                    orderItemId: item.order_item_id,
                    quantity: 0
                }])
            );

            await Promise.all(updatePromises);
            
            // Refresh the shipment details
            if (onSuccess) {
                onSuccess();
            }
            
            setSelectedItems([]);
        } catch (error) {
            setError('Failed to remove items: ' + error.message);
        }
    };

    const handleConfirmShipment = async () => {
        setProcessingConfirmation(true);
        setError(null);

        try {
            // Update the shipment status to 'pending'
            await updateShipmentStatus(shipmentDetails.shipment_id, 'pending');
            
            if (onSuccess) {
                onSuccess();
            }
            setConfirmDialogOpen(false);
        } catch (error) {
            setError('Failed to confirm shipment: ' + error.message);
        } finally {
            setProcessingConfirmation(false);
        }
    };

    if (loading || !shipmentDetails) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Review Shipment Details
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}

            <DataTable
                value={shipmentDetails.items || []}
                selection={selectedItems}
                onSelectionChange={e => setSelectedItems(e.value)}
                dataKey="warehouse_item_id"
                paginator
                rows={10}
                filters={filters}
                filterDisplay="menu"
                globalFilterFields={['item_name', 'type', 'size', 'shape']}
                emptyMessage="No items found."
                header={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                value={filters.global?.value || ''}
                                onChange={(e) => setFilters({
                                    ...filters,
                                    global: { value: e.target.value, matchMode: 'contains' }
                                })}
                                placeholder="Search items..."
                            />
                        </span>
                        {selectedItems.length > 0 && (
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleRemoveItems}
                            >
                                Remove Selected Items
                            </Button>
                        )}
                    </Box>
                }
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="item_name" header="Item Name" sortable filter />
                <Column field="type" header="Type" sortable filter />
                <Column field="size" header="Size" sortable filter />
                <Column field="shape" header="Shape" sortable filter />
                <Column field="quantity" header="Quantity" sortable />
                <Column 
                    field="order_number" 
                    header="Order Reference" 
                    body={(rowData) => rowData.order_number ? `Order #${rowData.order_number}` : 'Additional Item'}
                />
            </DataTable>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setConfirmDialogOpen(true)}
                >
                    Complete Shipment Creation
                </Button>
            </Box>

            <Dialog
                open={confirmDialogOpen}
                onClose={() => !processingConfirmation && setConfirmDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Confirm Shipment Creation</DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Shipment Summary:
                        </Typography>
                        <Typography>
                            Total Items: {itemStats.totalItems}
                        </Typography>
                        <Typography>
                            Total Quantity: {itemStats.totalQuantity}
                        </Typography>
                        <Typography>
                            Source: {shipmentDetails.source_warehouse_name}
                        </Typography>
                        <Typography>
                            Destination: {shipmentDetails.destination_name}
                        </Typography>
                        {shipmentDetails.shipping_method && (
                            <Typography>
                                Shipping Method: {shipmentDetails.shipping_method}
                            </Typography>
                        )}
                    </Box>
                    <Typography color="warning.main">
                        Are you sure you want to complete this shipment creation? 
                        This will change the status from draft to pending.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setConfirmDialogOpen(false)}
                        disabled={processingConfirmation}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleConfirmShipment}
                        disabled={processingConfirmation}
                        startIcon={processingConfirmation && <CircularProgress size={20} />}
                    >
                        {processingConfirmation ? 'Confirming...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
} 