import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { getTrackingDetails, updateTracking } from '../services/systemTrackingService';
import FullScreenLayout from './layouts/FullScreenLayout';

const TrackingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tracking, setTracking] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    fetchTrackingDetails();
  }, [id]);

  const fetchTrackingDetails = async () => {
    try {
      const data = await getTrackingDetails(id);
      setTracking(data);
      setEditedData(data);
    } catch (error) {
      console.error('Error fetching tracking details:', error);
    }
  };

  const handleSave = async () => {
    try {
      await updateTracking(id, editedData);
      setIsEditing(false);
      fetchTrackingDetails();
    } catch (error) {
      console.error('Error updating tracking:', error);
    }
  };

  if (!tracking) return null;

  const renderHeader = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      p: 2,
      borderBottom: 1,
      borderColor: 'divider',
      bgcolor: 'background.paper'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton onClick={() => navigate('/management')} size="large">
          <ArrowBack />
        </IconButton>
        <Typography variant="h4">Tracking Details</Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => isEditing ? handleSave() : setIsEditing(true)}
      >
        {isEditing ? 'Save Changes' : 'Edit'}
      </Button>
    </Box>
  );

  const renderMainPanel = () => (
    <Box sx={{ p: 3, height: '100%', overflow: 'auto' }}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Title"
              value={isEditing ? editedData.title : tracking.title}
              onChange={(e) => setEditedData({ ...editedData, title: e.target.value })}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={isEditing ? editedData.priority : tracking.priority}
                onChange={(e) => setEditedData({ ...editedData, priority: e.target.value })}
                disabled={!isEditing}
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="urgent">Urgent</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Description"
              value={isEditing ? editedData.description : tracking.description}
              onChange={(e) => setEditedData({ ...editedData, description: e.target.value })}
              disabled={!isEditing}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>Type</Typography>
            <Typography>{tracking.type}</Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>Created At</Typography>
            <Typography>{new Date(tracking.created_at).toLocaleString()}</Typography>
          </Grid>

          {tracking.link_path && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                href={tracking.link_path}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Related Link
              </Button>
            </Grid>
          )}

          {tracking.tags && tracking.tags.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Tags</Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {tracking.tags.map((tag, index) => (
                  <Chip key={index} label={tag} />
                ))}
              </Box>
            </Grid>
          )}

          {tracking.metadata && Object.keys(tracking.metadata).length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Additional Information</Typography>
              <Box sx={{ 
                backgroundColor: 'background.default', 
                p: 2, 
                borderRadius: 1,
                '& > div': { mb: 1 }
              }}>
                {Object.entries(tracking.metadata).map(([key, value]) => (
                  <Box key={key}>
                    <Typography variant="subtitle2" component="span">{key}: </Typography>
                    <Typography component="span">
                      {typeof value === 'object' ? JSON.stringify(value) : value.toString()}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );

  return (
    <FullScreenLayout
      header={renderHeader()}
      mainPanel={renderMainPanel()}
    />
  );
};

export default TrackingDetails; 