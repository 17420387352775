import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Backdrop
} from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { shipmentCreationApi } from '../../services/shipment-creation-api';
import { debounce } from 'lodash';
import { Checkbox } from 'primereact/checkbox';

const MemoizedDetailsTemplate = React.memo(({ rowData }) => (
    `${rowData.type} - ${rowData.size} - ${rowData.shape}`
));

const MemoizedHeader = React.memo(({ globalFilter, setGlobalFilter }) => (
    <Box className="flex justify-content-between align-items-center">
        <Typography variant="h6">Available Items</Typography>
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search..."
                className="p-inputtext-sm"
            />
        </span>
    </Box>
));

export default React.memo(function AdditionalItemsStep({
    shipmentId,
    setAdditionalItems,
    onError,
    onSuccess
}) {
    const [loading, setLoading] = useState(false);
    const [addingItems, setAddingItems] = useState(false);
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedQuantities, setSelectedQuantities] = useState({});
    const [globalFilter, setGlobalFilter] = useState('');
    const [selection, setSelection] = useState([]);
    const [processingBatch, setProcessingBatch] = useState(false);
    const [processedItems, setProcessedItems] = useState(0);
    const [totalItemsToProcess, setTotalItemsToProcess] = useState(0);

    // Load available items from backend
    useEffect(() => {
        if (shipmentId) {
            loadAvailableItems();
        }
    }, [shipmentId]);

    const loadAvailableItems = async () => {
        setLoading(true);
        try {
            const response = await shipmentCreationApi.getRemainingInventory(shipmentId);
            if (response?.success) {
                setAvailableItems(response.data);
                // Initialize quantities to 0
                const initialQuantities = {};
                response.data.forEach(item => {
                    if (item?.warehouse_item_id) {
                        initialQuantities[item.warehouse_item_id] = 0;
                    }
                });
                setSelectedQuantities(initialQuantities);
            } else {
                onError(response?.error || 'Failed to load inventory');
            }
        } catch (error) {
            onError('Failed to load inventory');
        } finally {
            setLoading(false);
        }
    };

    const handleQuantityChange = debounce((warehouseItemId, value) => {
        setSelectedQuantities(prev => ({
            ...prev,
            [warehouseItemId]: parseInt(value) || 0
        }));
    }, 300);

    const handleAddItems = async () => {
        const BATCH_SIZE = 100;
        
        const itemsToAdd = Object.entries(selectedQuantities)
            .filter(([_, quantity]) => quantity > 0)
            .map(([warehouseItemId, quantity]) => ({
                warehouseItemId: parseInt(warehouseItemId),
                quantity
            }));

        if (itemsToAdd.length === 0) return;

        setAddingItems(true);
        setProcessingBatch(true);
        setTotalItemsToProcess(itemsToAdd.length);
        setProcessedItems(0);

        try {
            // Process items in batches
            for (let i = 0; i < itemsToAdd.length; i += BATCH_SIZE) {
                const batch = itemsToAdd.slice(i, i + BATCH_SIZE);
                const response = await shipmentCreationApi.addAdditionalItems(shipmentId, batch);
                
                if (!response.success) {
                    throw new Error(response.error || 'Failed to add items');
                }

                setProcessedItems(i + batch.length);
            }

            // Refresh available items after all batches are processed
            await loadAvailableItems();
            setAdditionalItems(itemsToAdd);
            onSuccess?.(`Successfully added ${itemsToAdd.length} additional items to shipment`);
        } catch (error) {
            onError('Failed to add items: ' + error.message);
        } finally {
            setAddingItems(false);
            setProcessingBatch(false);
            setProcessedItems(0);
            setTotalItemsToProcess(0);
        }
    };

    const handleAddMaxQuantities = () => {
        const newQuantities = { ...selectedQuantities };
        selection.forEach(item => {
            newQuantities[item.warehouse_item_id] = item.available_quantity;
        });
        setSelectedQuantities(newQuantities);
    };

    const selectionTemplate = (rowData) => {
        return (
            <Checkbox 
                checked={selection.some(item => item.warehouse_item_id === rowData.warehouse_item_id)}
                onChange={(e) => {
                    if (e.checked) {
                        setSelection([...selection, rowData]);
                    } else {
                        setSelection(selection.filter(item => 
                            item.warehouse_item_id !== rowData.warehouse_item_id
                        ));
                    }
                }}
            />
        );
    };

    const detailsTemplate = (rowData) => <MemoizedDetailsTemplate rowData={rowData} />;
    const header = <MemoizedHeader globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />;

    const quantityTemplate = (rowData) => (
        <InputNumber
            value={selectedQuantities[rowData.warehouse_item_id] || 0}
            onValueChange={(e) => handleQuantityChange(
                rowData.warehouse_item_id,
                e.value
            )}
            min={0}
            max={rowData.available_quantity}
            size="small"
        />
    );

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {loading && (
                <Box sx={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    zIndex: 1000,
                }}>
                    <CircularProgress />
                </Box>
            )}

            <Backdrop
                sx={{ 
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    position: 'absolute'
                }}
                open={addingItems}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress color="inherit" />
                    <Typography sx={{ mt: 2, color: 'white' }}>
                        {processingBatch 
                            ? `Adding items to shipment... (${processedItems}/${totalItemsToProcess})`
                            : 'Adding items to shipment...'}
                    </Typography>
                </Box>
            </Backdrop>

            <DataTable
                value={availableItems}
                selection={selection}
                onSelectionChange={(e) => setSelection(e.value)}
                dataKey="warehouse_item_id"  // Unique identifier for rows
                header={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box className="flex justify-content-between align-items-center">
                            <Typography variant="h6">Available Items</Typography>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText
                                    value={globalFilter}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                    placeholder="Search..."
                                    className="p-inputtext-sm"
                                />
                            </span>
                        </Box>
                        {selection.length > 0 && (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    const newQuantities = { ...selectedQuantities };
                                    selection.forEach(item => {
                                        newQuantities[item.warehouse_item_id] = item.available_quantity;
                                    });
                                    setSelectedQuantities(newQuantities);
                                }}
                                size="small"
                            >
                                Add Max Quantities for Selected
                            </Button>
                        )}
                    </Box>
                }
                globalFilter={globalFilter}
                globalFilterFields={['item_name', 'type', 'size', 'shape']}
                emptyMessage="No items available"
                scrollable
                scrollHeight="calc(100vh - 300px)"
                showGridlines
                stripedRows
                size="small"
                className="p-datatable-sm"
                virtualScrollerOptions={{ itemSize: 46 }}
                lazy={false}
                rows={10}
                paginator
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="item_name" header="Item" sortable />
                <Column header="Details" body={detailsTemplate} sortable />
                <Column field="available_quantity" header="Available" sortable align="right" />
                <Column header="Quantity" body={quantityTemplate} align="right" />
            </DataTable>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    onClick={handleAddItems}
                    disabled={!Object.values(selectedQuantities).some(q => q > 0) || addingItems}
                    startIcon={addingItems && <CircularProgress size={20} color="inherit" />}
                >
                    {addingItems ? 'Adding Items...' : 'Add Selected Items'}
                </Button>
            </Box>
        </Box>
    );
}, (prevProps, nextProps) => {
    return prevProps.shipmentId === nextProps.shipmentId;
}); 