import React, { useState, useEffect } from 'react';
import { 
  Container, Typography, Paper, FormControl, InputLabel, Select, MenuItem, Button, 
  TextField, Switch, FormControlLabel, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getMode, updateMode, getAllSettings, addOrUpdateSetting, getSetting, deleteAllSheets } from '../services/api';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const RecoverButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

function Settings() {
  const [mode, setMode] = useState('');
  const [newSetting, setNewSetting] = useState({ key: '', value: '', type: 'string' });
  const [allSettings, setAllSettings] = useState([]);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [useOldSales, setUseOldSales] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingUseOldSales, setPendingUseOldSales] = useState(false);
  const [disableCacheLocations, setDisableCacheLocations] = useState(false);
  const [pendingDisableCacheLocations, setPendingDisableCacheLocations] = useState(false);
  const [deleteSheetDialogOpen, setDeleteSheetDialogOpen] = useState(false);
  const [deleteSheetStatus, setDeleteSheetStatus] = useState('');
  const [openInNewTab, setOpenInNewTab] = useState(() => {
    return localStorage.getItem('openInNewTab') === 'true';
  });

  useEffect(() => {
    fetchMode();
    fetchAllSettings();
    fetchUseOldSales();
    fetchDisableCacheLocations();
  }, []);

  const fetchMode = async () => {
    try {
      const response = await getMode();
      setMode(response.data.mode);
    } catch (error) {
      console.error('Error fetching mode:', error);
    }
  };

  const fetchAllSettings = async () => {
    try {
      const response = await getAllSettings();
      setAllSettings(response.data);
    } catch (error) {
      console.error('Error fetching all settings:', error);
    }
  };

  const fetchUseOldSales = async () => {
    try {
      const response = await getSetting('UseOldSales');
      setUseOldSales(response.data.value === 'true');
    } catch (error) {
      console.error('Error fetching UseOldSales setting:', error);
    }
  };

  const fetchDisableCacheLocations = async () => {
    try {
      const response = await getSetting('Disable_Cache_Locations');
      setDisableCacheLocations(response.data.value === 'true');
    } catch (error) {
      console.error('Error fetching Disable_Cache_Locations setting:', error);
    }
  };

  const handleModeChange = async (event) => {
    const newMode = event.target.value;
    try {
      await updateMode(newMode);
      setMode(newMode);
      setMessage('Mode updated successfully');
    } catch (error) {
      console.error('Error updating mode:', error);
      setMessage('Failed to update mode');
    }
  };

  const handleNewSettingChange = (event) => {
    const { name, value } = event.target;
    setNewSetting(prev => ({ ...prev, [name]: value }));
  };

  const handleAddSetting = async (event) => {
    event.preventDefault();
    try {
      await addOrUpdateSetting(newSetting);
      setMessage('Setting added successfully');
      setNewSetting({ key: '', value: '', type: 'string' });
      fetchAllSettings();
    } catch (error) {
      console.error('Error adding setting:', error);
      setMessage('Failed to add setting');
    }
  };

  const handleRecoverLocationsClick = () => {
    navigate('/deleted-locations');
  };

  const handleUseOldSalesChange = (event) => {
    setPendingUseOldSales(event.target.checked);
    setConfirmDialogOpen(true);
  };

  const handleDisableCacheLocationsChange = (event) => {
    setPendingDisableCacheLocations(event.target.checked);
    setConfirmDialogOpen(true);
  };

  const handleConfirmChange = async () => {
    try {
      await addOrUpdateSetting({ key: 'Disable_Cache_Locations', value: pendingDisableCacheLocations.toString(), type: 'boolean' });
      setDisableCacheLocations(pendingDisableCacheLocations);
      setMessage('Disable_Cache_Locations setting updated successfully');
    } catch (error) {
      console.error('Error updating Disable_Cache_Locations setting:', error);
      setMessage('Failed to update Disable_Cache_Locations setting');
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const handleCancelChange = () => {
    setConfirmDialogOpen(false);
  };

  const handleDeleteAllSheets = () => {
    setDeleteSheetDialogOpen(true);
  };

  const handleConfirmDeleteSheets = async () => {
    try {
      setDeleteSheetStatus('Deleting sheets...');
      const result = await deleteAllSheets();
      
      if (result.success) {
        const successMessage = `Successfully deleted ${result.deleted.length} out of ${result.totalFound} sheets`;
        
        const failureMessage = result.failed.length > 0 
          ? `\n${result.failed.length} sheets failed to delete.` 
          : '';
          
        setDeleteSheetStatus(successMessage + failureMessage);
        
        console.log('Delete operation details:', {
          successful: result.deleted,
          failed: result.failed
        });
      } else {
        setDeleteSheetStatus('Failed to delete sheets: ' + result.message);
      }
    } catch (error) {
      setDeleteSheetStatus('Error deleting sheets: ' + (error.message || 'Unknown error'));
      console.error('Sheet deletion error:', error);
    } finally {
      setDeleteSheetDialogOpen(false);
      setTimeout(() => setDeleteSheetStatus(''), 5000);
    }
  };

  const handleOpenInNewTabChange = (event) => {
    const newValue = event.target.checked;
    setOpenInNewTab(newValue);
    localStorage.setItem('openInNewTab', newValue);
    setMessage('Navigation setting updated successfully');
  };

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        Settings
      </Title>
      
      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Application Mode
        </Title>
        <FormControl fullWidth>
          <InputLabel id="mode-select-label">Mode</InputLabel>
          <Select
            labelId="mode-select-label"
            value={mode}
            onChange={handleModeChange}
            label="Mode"
          >
            <MenuItem value="production">Production</MenuItem>
            <MenuItem value="development">Development</MenuItem>
          </Select>
        </FormControl>
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Use Old Sales
        </Title>
        <FormControlLabel
          control={
            <Switch
              checked={useOldSales}
              onChange={handleUseOldSalesChange}
              name="useOldSales"
              color="primary"
            />
          }
          label={useOldSales ? 'Enabled' : 'Disabled'}
        />
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Disable Cache Locations
        </Title>
        <FormControlLabel
          control={
            <Switch
              checked={disableCacheLocations}
              onChange={handleDisableCacheLocationsChange}
              name="disableCacheLocations"
              color="primary"
            />
          }
          label={disableCacheLocations ? 'Enabled' : 'Disabled'}
        />
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Navigation Settings
        </Title>
        <FormControlLabel
          control={
            <Switch
              checked={openInNewTab}
              onChange={handleOpenInNewTabChange}
              name="openInNewTab"
              color="primary"
            />
          }
          label={`Open links in ${openInNewTab ? 'new tab' : 'same tab'}`}
        />
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          When enabled, clicking on navigation items will open them in a new tab
        </Typography>
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Add New Setting
        </Title>
        <form onSubmit={handleAddSetting}>
          <TextField
            fullWidth
            label="Key"
            name="key"
            value={newSetting.key}
            onChange={handleNewSettingChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Value"
            name="value"
            value={newSetting.value}
            onChange={handleNewSettingChange}
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              name="type"
              value={newSetting.type}
              onChange={handleNewSettingChange}
              label="Type"
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
            </Select>
          </FormControl>
          <StyledButton type="submit" variant="contained" fullWidth>
            Add Setting
          </StyledButton>
        </form>
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          All Settings
        </Title>
        {allSettings.map((setting) => (
          <Box key={setting.key} mb={2}>
            <Typography variant="subtitle1">{setting.key}</Typography>
            {setting.type === 'boolean' ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={setting.value === 'true'}
                    onChange={() => {/* Implement update logic */}}
                    name={setting.key}
                    color="primary"
                  />
                }
                label={setting.value}
              />
            ) : (
              <Typography variant="body2">{setting.value}</Typography>
            )}
          </Box>
        ))}
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Recover Deleted Locations
        </Title>
        <RecoverButton variant="contained" color="primary" onClick={handleRecoverLocationsClick}>
          View Deleted Locations
        </RecoverButton>
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          System Status
        </Title>
        <StyledLink 
          href="https://stats.uptimerobot.com/OQ1epxzNfS"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
        >
          View System Uptime Dashboard
          <OpenInNewIcon fontSize="small" />
        </StyledLink>
      </StyledPaper>

      <StyledPaper>
        <Title variant="h6" gutterBottom>
          Google Sheets Management
        </Title>
        <Box display="flex" flexDirection="column" gap={2}>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteForeverIcon />}
            onClick={handleDeleteAllSheets}
          >
            Delete All Google Sheets
          </Button>
          {deleteSheetStatus && (
            <Typography color="info" mt={1}>
              {deleteSheetStatus}
            </Typography>
          )}
        </Box>
      </StyledPaper>

      {message && (
        <Typography color="primary" align="center" mt={2}>
          {message}
        </Typography>
      )}

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelChange}
      >
        <DialogTitle>Confirm Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the {pendingDisableCacheLocations ? 'Disable Cache Locations' : 'Use Old Sales'} setting to {pendingDisableCacheLocations ? 'Enabled' : 'Disabled'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelChange} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteSheetDialogOpen}
        onClose={() => setDeleteSheetDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete All Sheets</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete all Google Sheets? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteSheetDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDeleteSheets} color="error">
            Delete All
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
}

export default Settings;