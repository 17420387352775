import { getAllCachedItems } from './itemsapi';

const CACHE_KEY = 'itemsCache';
const CACHE_TIMESTAMP_KEY = 'itemsCacheTimestamp';
const CONFIG_CACHE_KEY = 'itemsConfigCache';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

class ItemsCacheService {
    static buildItemConfig(items) {
        // First pass: collect all items by brim to determine is_new status
        const brimStatusMap = new Map();
        
        items.forEach(item => {
            if (!item.active || !item.is_valid) return;
            
            if (!brimStatusMap.has(item.brim_name)) {
                brimStatusMap.set(item.brim_name, {
                    totalItems: 0,
                    newItems: 0
                });
            }
            
            const brimStats = brimStatusMap.get(item.brim_name);
            brimStats.totalItems++;
            if (item.is_new) {
                brimStats.newItems++;
            }
        });

        const config = {
            types: new Set(),
            sizes: new Set(),
            brims: new Map(), // Changed to Map to store brim objects with is_new status
            shapes: new Set(),
            itemMap: new Map()
        };

        // Process items and build config
        items.forEach(item => {
            if (!item.active || !item.is_valid) return;

            config.types.add(item.type_name);
            config.sizes.add(item.size_name);
            config.shapes.add(item.shape_name);
            
            // Add brim with is_new status if not already added
            if (!config.brims.has(item.brim_name)) {
                const brimStats = brimStatusMap.get(item.brim_name);
                const is_new = brimStats.newItems === brimStats.totalItems; // All items must be new
                
                config.brims.set(item.brim_name, {
                    id: item.brim_name,
                    label: item.brim_name,
                    is_new: is_new
                });
            }
            
            const cellKey = `${item.type_name}-${item.size_name}-${item.brim_name}-${item.shape_name}`;
            config.itemMap.set(item.id, {
                type: item.type_name,
                size: item.size_name,
                brim: item.brim_name,
                shape: item.shape_name,
                cellKey
            });
        });

        // Convert to final format
        const finalConfig = {
            types: Array.from(config.types).sort(),
            sizes: Array.from(config.sizes)
                .sort((a, b) => parseInt(a) - parseInt(b))
                .map(size => ({ id: size, label: size })),
            brims: Array.from(config.brims.values())
                .sort((a, b) => parseInt(a.id) - parseInt(b.id)),
            shapes: Array.from(config.shapes).sort(),
            itemMap: Object.fromEntries(config.itemMap)
        };

        // Log brim status for debugging
        console.log('Brim Status Summary:');
        brimStatusMap.forEach((stats, brimName) => {
            console.log(`${brimName}: ${stats.newItems}/${stats.totalItems} new items (${config.brims.get(brimName).is_new ? 'NEW' : 'OLD'})`);
        });

        return finalConfig;
    }

    static async getItems() {
        const now = Date.now();
        const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
        const cachedItems = localStorage.getItem(CACHE_KEY);

        // Check if cache exists and is still valid
        if (cachedItems && cachedTimestamp && (now - parseInt(cachedTimestamp) < CACHE_DURATION)) {
            return JSON.parse(cachedItems);
        }

        return this.refreshCache();
    }

    static async refreshCache() {
        try {
            const response = await getAllCachedItems();
            if (response.success) {
                const items = response.data;
                
                // Store items in cache
                localStorage.setItem(CACHE_KEY, JSON.stringify(items));
                localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());
                
                // Build and store config
                const config = this.buildItemConfig(items);
                localStorage.setItem(CONFIG_CACHE_KEY, JSON.stringify(config));
                
                return items;
            }
            throw new Error('Failed to fetch items');
        } catch (error) {
            console.error('Error refreshing cache:', error);
            // If refresh fails, try to return existing cached data as fallback
            const cachedItems = this.getCachedItems();
            if (cachedItems) {
                return cachedItems;
            }
            throw error;
        }
    }

    static clearCache() {
        localStorage.removeItem(CACHE_KEY);
        localStorage.removeItem(CACHE_TIMESTAMP_KEY);
        localStorage.removeItem(CONFIG_CACHE_KEY);
        // Immediately refresh the cache after clearing
        return this.refreshCache().catch(error => {
            console.error('Error refreshing cache after clear:', error);
        });
    }

    static getCachedItems() {
        const items = localStorage.getItem(CACHE_KEY);
        return items ? JSON.parse(items) : null;
    }

    static getCachedConfig() {
        const config = localStorage.getItem(CONFIG_CACHE_KEY);
        if (!config) return null;

        const parsedConfig = JSON.parse(config);
        // Convert itemMap back to Map
        parsedConfig.itemMap = new Map(Object.entries(parsedConfig.itemMap));
        return parsedConfig;
    }

    static isCacheValid() {
        const now = Date.now();
        const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
        return cachedTimestamp && (now - parseInt(cachedTimestamp) < CACHE_DURATION);
    }

    static getCacheAge() {
        const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
        if (!cachedTimestamp) return null;
        return Date.now() - parseInt(cachedTimestamp);
    }
}

export default ItemsCacheService; 