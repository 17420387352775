import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ShipmentItemsPopup({ open, onClose, shipmentDetails, loading }) {
    if (!shipmentDetails && !loading) return null;

    const orderItems = shipmentDetails?.items?.filter(item => item.order_number) || [];
    const additionalItems = shipmentDetails?.items?.filter(item => !item.order_number) || [];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Current Shipment Items</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box display="flex" justifyContent="center" p={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {orderItems.length > 0 && (
                            <>
                                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Order Items
                                </Typography>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Order #</TableCell>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Size</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderItems.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{item.order_number}</TableCell>
                                                    <TableCell>{item.item_id}</TableCell>
                                                    <TableCell>{item.type}</TableCell>
                                                    <TableCell>{item.size}</TableCell>
                                                    <TableCell align="right">{item.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}

                        {additionalItems.length > 0 && (
                            <>
                                <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                                    Additional Items
                                </Typography>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Size</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {additionalItems.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{item.item_id}</TableCell>
                                                    <TableCell>{item.type}</TableCell>
                                                    <TableCell>{item.size}</TableCell>
                                                    <TableCell align="right">{item.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}

                        {!orderItems.length && !additionalItems.length && (
                            <Typography color="text.secondary" sx={{ textAlign: 'center', py: 3 }}>
                                No items have been added to this shipment yet.
                            </Typography>
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
} 