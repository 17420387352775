import React, { useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';

function SpreadsheetEditable({ 
  data, 
  title, 
  onCellValueChanged,
  readOnlyFields = ['sizeLabel'], // Fields that shouldn't be editable
  isLoading = false,
  readOnly,
  hideZeros,
  fullScreen
}) {
  // Generate sizes and brims from the data
  const sizes = useMemo(() => {
    if (!data) return [];
    return Object.keys(data).map(size => ({
      id: size,
      label: size
    }));
  }, [data]);

  const brims = useMemo(() => {
    if (!data) return [];
    const allBrims = new Set();
    Object.values(data).forEach(sizeData => {
      Object.keys(sizeData).forEach(brim => allBrims.add(brim));
    });
    
    return Array.from(allBrims).sort((a, b) => parseInt(a) - parseInt(b)).map(brim => ({
      id: brim,
      label: brim,
      shapes: ['XR', 'R', 'L', 'X']
    }));
  }, [data]);

  // Handle cell value changes
  const handleCellValueChanged = useCallback((params) => {
    if (onCellValueChanged) {
      const { data: rowData, colDef, newValue, oldValue } = params;
      
      // Extract size and shape information from the field
      const [brimId, shape] = colDef.field.split('-');
      
      onCellValueChanged({
        size: rowData.sizeLabel,
        brim: brimId,
        shape: shape,
        newValue: newValue,
        oldValue: oldValue,
        rowData: rowData
      });
    }
  }, [onCellValueChanged]);

  // Generate column definitions
  const columnDefs = useMemo(() => {
    if (!brims.length) return [];

    const columns = [
      {
        headerName: 'Size',
        field: 'sizeLabel',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'size-column',
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressResize: true,
        width: 80,
        headerClass: 'size-header',
        editable: false
      }
    ];

    // Create column groups for each brim and its shapes
    brims.forEach(brim => {
      const groupChildren = brim.shapes.map(shape => ({
        headerName: shape,
        field: `${brim.id}-${shape}`,
        cellClass: 'data-cell editable-cell',
        headerClass: 'data-header',
        suppressMovable: true,
        suppressResize: true,
        width: 80,
        editable: true,
        valueParser: params => {
          const newValue = Number(params.newValue);
          return isNaN(newValue) ? params.oldValue : newValue;
        },
        valueFormatter: params => {
          return params.value === undefined || params.value === null ? '' : params.value;
        }
      }));

      columns.push({
        headerName: brim.label,
        children: groupChildren,
        headerClass: 'brim-group-header'
      });
    });

    return columns;
  }, [brims]);

  // Prepare row data
  const rowData = useMemo(() => {
    if (!data || !sizes.length || !brims.length) return [];

    return sizes.map(size => {
      const row = { sizeLabel: size.label };
      brims.forEach(brim => {
        const brimData = data[size.id]?.[brim.id] || {};
        brim.shapes.forEach(shape => {
          row[`${brim.id}-${shape}`] = brimData[shape] || 0;
        });
      });
      return row;
    });
  }, [data, sizes, brims]);

  const defaultColDef = {
    resizable: false,
    sortable: false,
    filter: false,
    suppressMovable: true,
    valueFormatter: params => {
      return params.value === undefined || params.value === null ? '' : params.value;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ 
      height: fullScreen ? '100%' : 'auto',
      '& .spreadsheet-container': {
        height: fullScreen ? '100%' : 'auto',
        margin: 0,
        border: 'none',
        borderRadius: 0,
      }
    }}>
      {title && <h1 style={{ textAlign: 'center', margin: '20px 0' }}>{title}</h1>}
      <div 
        className="ag-theme-alpine custom-grid" 
        style={{ width: '100%', height: '600px' }}
      >
        <AgGridReact 
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onCellValueChanged={handleCellValueChanged}
          suppressHorizontalScroll={false}
          headerHeight={40}
          groupHeaderHeight={45}
          rowHeight={35}
          suppressMenuHide={true}
          stopEditingWhenCellsLoseFocus={true}
        />
      </div>
      <style>
        {`
          .custom-grid {
            --ag-header-height: 40px;
            --ag-header-foreground-color: #181D1F;
            --ag-header-background-color: #f8f9fa;
            --ag-header-cell-hover-background-color: #f8f9fa;
            --ag-header-cell-moving-background-color: #f8f9fa;
            --ag-borders: solid 1px;
            --ag-border-color: #181D1F26;
            --ag-cell-horizontal-border: solid #181D1F26;
            --ag-row-border-color: #181D1F26;
          }

          .ag-theme-alpine .ag-header-group-cell {
            border-right: 2px solid #181D1F40 !important;
          }

          .size-column {
            background-color: #f8f9fa;
            font-weight: 600;
            text-align: center;
            border-right: 2px solid #181D1F40 !important;
          }

          .data-cell {
            text-align: center;
          }

          .editable-cell {
            background-color: rgba(255, 255, 255, 0.9);
          }

          .editable-cell:hover {
            background-color: rgba(240, 240, 240, 0.9);
            cursor: pointer;
          }

          .ag-cell-focus {
            border: 1px solid #2196f3 !important;
          }

          .ag-header-cell {
            font-weight: 600;
            text-align: center;
          }

          .brim-group-header {
            background-color: #f1f3f5 !important;
            font-weight: 700;
            text-align: center;
            border-bottom: 2px solid #181D1F40;
          }

          .size-header {
            background-color: #f1f3f5 !important;
            font-weight: 700;
            border-bottom: 2px solid #181D1F40;
          }

          .ag-header-row {
            background-color: #f8f9fa;
          }
        `}
      </style>
    </Box>
  );
}

export default SpreadsheetEditable;