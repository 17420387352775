import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Chip,
  Tabs,
  Tab,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Snackbar,
} from '@mui/material';
import {
  Edit as EditIcon,
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { format, isValid, parseISO } from 'date-fns';
import {
  getInventoryCheckById,
  updateInventoryCheckStatus,
} from '../services/api';
import SpreadsheetExportableV2 from './spreadsheets/SpreadsheetExportableV2';
import FullScreenLayout from './layouts/FullScreenLayout';
import OptimizedSpreadsheet from './spreadsheets/OptimizedSpreadsheet';
import { styled } from '@mui/material/styles';

const INVENTORY_CHECK_METRICS = ['previous_quantity', 'counted_quantity', 'quantity_difference'];

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  transition: 'all 0.2s ease',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`inventory-check-tabpanel-${index}`}
      aria-labelledby={`inventory-check-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function InventoryCheckDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [check, setCheck] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('item_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [spreadsheetLoading, setSpreadsheetLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    fetchCheckData();
  }, [id]);

  const fetchCheckData = async () => {
    try {
      setLoading(true);
      const data = await getInventoryCheckById(id);
      
      if (!data) {
        throw new Error('No data received from server');
      }
      
      // Validate required fields
      if (!data.check_id || !data.warehouse_id) {
        throw new Error('Invalid data structure received from server');
      }
      
      setCheck(data);
      
      // Transform items data for spreadsheet view
      const transformedData = {};
      
      if (data.items && Array.isArray(data.items)) {
        data.items.forEach(item => {
          const { 
            item_type,
            item_size,
            item_shape,
            item_brim,
            previous_quantity,
            counted_quantity,
            quantity_difference
          } = item;
          
          if (!item_type || !item_size) {
            console.warn('Skipping item due to missing required fields:', item);
            return;
          }

          if (!transformedData[item_type]) {
            transformedData[item_type] = {};
          }
          
          if (!transformedData[item_type][item_size]) {
            transformedData[item_type][item_size] = {};
          }
          
          const brimKey = (item_brim || 'N/A').toString();
          if (!transformedData[item_type][item_size][brimKey]) {
            transformedData[item_type][item_size][brimKey] = {};
          }
          
          const shapeKey = item_shape || 'N/A';
          transformedData[item_type][item_size][brimKey][shapeKey] = {
            previous: previous_quantity || 0,
            counted: counted_quantity || 0,
            difference: quantity_difference || 0
          };
        });
      }
      
      console.log('Transformed spreadsheet data:', transformedData);
      setSpreadsheetData(transformedData);
      
    } catch (err) {
      console.error('Error fetching inventory check data:', err);
      setError(err.message || 'Failed to fetch inventory check details');
      setCheck(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (check?.items) {
      setSpreadsheetLoading(true);
      try {
        // Transform check items into spreadsheet format
        const transformedData = check.items.reduce((acc, item) => {
          if (!item.item_id) return acc;
          
          // Create data object with all metrics
          acc[item.item_id] = {
            previous_quantity: item.previous_quantity || 0,
            counted_quantity: item.counted_quantity || 0,
            quantity_difference: item.quantity_difference || 0
          };
          
          return acc;
        }, {});

        setSpreadsheetData(transformedData);
      } catch (error) {
        console.error('Error preparing spreadsheet data:', error);
        setError('Failed to prepare spreadsheet data');
      } finally {
        setSpreadsheetLoading(false);
      }
    }
  }, [check]);

  const handleStatusUpdate = async (newStatus) => {
    try {
      setConfirmDialog(false);
      setCancelDialog(false);
      
      // Show processing message
      setSnackbar({
        open: true,
        message: `Processing ${newStatus} status update. This may take a few minutes...`,
        severity: 'info',
      });
      
      // Fire and forget - don't wait for response
      updateInventoryCheckStatus(id, newStatus)
        .then(() => {
          setSnackbar({
            open: true,
            message: `Inventory check ${newStatus} successfully. The page will refresh in a moment.`,
            severity: 'success',
          });
          // Refresh the page after 3 seconds to show updated status
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          setSnackbar({
            open: true,
            message: err.message || `Failed to update status to ${newStatus}`,
            severity: 'error',
          });
        });
        
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || `Failed to update status to ${newStatus}`,
        severity: 'error',
      });
    }
  };

  const getFilteredAndSortedItems = () => {
    if (!check?.items || !Array.isArray(check.items)) {
      console.warn('No items array found in check:', check);
      return [];
    }
    
    let filteredItems = check.items.filter(item => 
      item && typeof item === 'object' && item.item_type && item.item_size
    );
    
    console.log('Filtered items before search:', filteredItems);
    
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filteredItems = filteredItems.filter(item => 
        (item.item_name?.toLowerCase().includes(searchLower) ||
         item.item_type?.toLowerCase().includes(searchLower))
      );
    }

    filteredItems.sort((a, b) => {
      const aValue = a[sortBy] || '';
      const bValue = b[sortBy] || '';
      
      return sortOrder === 'asc' 
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    console.log('Final filtered and sorted items:', filteredItems);
    return filteredItems;
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate) ? format(parsedDate, 'PPpp') : 'Invalid date';
  };

  const renderStatusChip = (status) => (
    <Chip 
      label={status.toUpperCase()}
      color={
        status === 'confirmed' ? 'success' :
        status === 'cancelled' ? 'error' :
        status === 'pending' ? 'warning' :
        'default'
      }
      sx={{ 
        fontWeight: 'bold',
        fontSize: '0.9rem',
        py: 0.5,
        px: 1
      }}
    />
  );

  const renderHeader = () => (
    <Box 
      display="flex" 
      justifyContent="space-between" 
      alignItems="center"
      sx={{ 
        mb: 3,
        p: 3,
        borderRadius: 2,
        bgcolor: 'background.paper',
        boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
      }}
    >
      <Box>
        <Typography variant="h4" sx={{ mb: 1, fontWeight: 600 }}>
          Inventory Check #{check.check_id}
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          {renderStatusChip(check.status)}
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {check.warehouse_name}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {formatDate(check.check_date)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" gap={1}>
        {check.status === 'pending' && (
          <>
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckCircleIcon />}
              onClick={() => setConfirmDialog(true)}
              size="large"
              sx={{ 
                px: 3,
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
              }}
            >
              Confirm Check
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={() => setCancelDialog(true)}
              size="large"
              sx={{ 
                px: 3,
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
              }}
            >
              Cancel Check
            </Button>
          </>
        )}
        {check.status === 'draft' && (
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/warehouses/${check.warehouse_id}/inventory-checks/${id}/edit`)}
            size="large"
            sx={{ 
              px: 3,
              py: 1,
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            Edit Check
          </Button>
        )}
      </Box>
    </Box>
  );

  const renderDetailsPanel = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
          <Typography variant="h6" gutterBottom color="primary">
            Check Information
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color="text.secondary">Status</Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {renderStatusChip(check.status)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="text.secondary">Warehouse</Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {check.warehouse_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="text.secondary">Created By</Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {check.created_by_username}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="text.secondary">Created At</Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {formatDate(check.created_at)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary">Reset Uncounted Items</Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {check.reset_uncounted_items ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              {check.notes && (
                <Grid item xs={12}>
                  <Typography color="text.secondary">Notes</Typography>
                  <Typography variant="body1" sx={{ mt: 0.5 }}>
                    {check.notes}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
          <Typography variant="h6" gutterBottom color="primary">
            Processing Details
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {check.confirmed_by_username && (
                <>
                  <Grid item xs={6}>
                    <Typography color="text.secondary">Confirmed By</Typography>
                    <Typography variant="body1" sx={{ mt: 0.5 }}>
                      {check.confirmed_by_username}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="text.secondary">Confirmed At</Typography>
                    <Typography variant="body1" sx={{ mt: 0.5 }}>
                      {formatDate(check.confirmation_date)}
                    </Typography>
                  </Grid>
                </>
              )}
              {check.cancelled_by_username && (
                <>
                  <Grid item xs={6}>
                    <Typography color="text.secondary">Cancelled By</Typography>
                    <Typography variant="body1" sx={{ mt: 0.5 }}>
                      {check.cancelled_by_username}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="text.secondary">Cancelled At</Typography>
                    <Typography variant="body1" sx={{ mt: 0.5 }}>
                      {formatDate(check.cancellation_date)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderItemsGrid = () => {
    const items = getFilteredAndSortedItems();
    console.log('Rendering items grid with:', items);
    
    return (
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search items..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                label="Sort By"
              >
                <MenuItem value="item_name">Item Name</MenuItem>
                <MenuItem value="item_type">Type</MenuItem>
                <MenuItem value="previous_quantity">Previous Quantity</MenuItem>
                <MenuItem value="counted_quantity">Counted Quantity</MenuItem>
                <MenuItem value="quantity_difference">Difference</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Sort Order</InputLabel>
              <Select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                label="Sort Order"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Shape</TableCell>
                <TableCell>Brim</TableCell>
                <TableCell align="right">Previous Qty</TableCell>
                <TableCell align="right">Counted Qty</TableCell>
                <TableCell align="right">Difference</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.check_item_id}>
                  <TableCell>{item.item_name || 'N/A'}</TableCell>
                  <TableCell>{item.item_type || 'N/A'}</TableCell>
                  <TableCell>{item.item_size || 'N/A'}</TableCell>
                  <TableCell>{item.item_shape || 'N/A'}</TableCell>
                  <TableCell>{item.item_brim || 'N/A'}</TableCell>
                  <TableCell align="right">{item.previous_quantity || 0}</TableCell>
                  <TableCell align="right">{item.counted_quantity || 0}</TableCell>
                  <TableCell 
                    align="right"
                    sx={{ 
                      color: item.quantity_difference > 0 ? 'success.main' : 
                             item.quantity_difference < 0 ? 'error.main' : 
                             'inherit'
                    }}
                  >
                    {item.quantity_difference || 0}
                  </TableCell>
                  <TableCell>{item.notes || ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderSpreadsheet = () => {
    if (spreadsheetLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={{ mb: 3 }}>
        <OptimizedSpreadsheet
          data={spreadsheetData}
          metrics={INVENTORY_CHECK_METRICS}
          title={`Inventory Check #${check.check_id}`}
          readOnly={check.status !== 'draft'}
          fullScreen={false}
          hideZeros={false}
          readOnlyMetrics={check.status === 'draft' ? [] : INVENTORY_CHECK_METRICS}
          isLoading={spreadsheetLoading}
        />
      </Box>
    );
  };

  const renderDialogs = () => (
    <>
      <Dialog 
        open={confirmDialog} 
        onClose={() => setConfirmDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>Confirm Inventory Check</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Are you sure you want to confirm this inventory check? 
            This action cannot be undone.
          </Typography>
          <Alert severity="info" sx={{ mb: 2 }}>
            The confirmation process will update inventory quantities in the backend.
            This may take a few minutes to complete.
          </Alert>
        </DialogContent>
        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button 
            onClick={() => setConfirmDialog(false)}
            variant="outlined"
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleStatusUpdate('confirmed')} 
            variant="contained" 
            color="success"
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={cancelDialog} 
        onClose={() => setCancelDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>Cancel Inventory Check</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this inventory check?
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button 
            onClick={() => setCancelDialog(false)}
            variant="outlined"
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
            }}
          >
            No
          </Button>
          <Button 
            onClick={() => handleStatusUpdate('cancelled')} 
            variant="contained" 
            color="error"
            sx={{ 
              borderRadius: 2,
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar?.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar?.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!check) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">Inventory check not found</Alert>
      </Box>
    );
  }

  return (
    <FullScreenLayout
      header={renderHeader()}
      mainPanel={
        <PageContainer>
          <ContentPaper elevation={0}>
            {renderDetailsPanel()}
            <Box sx={{ mt: 4 }}>
              <Tabs
                value={currentTab}
                onChange={(e, newValue) => setCurrentTab(newValue)}
                sx={{ 
                  borderBottom: 1, 
                  borderColor: 'divider',
                  mb: 3
                }}
              >
                <Tab 
                  label="Items Grid" 
                  sx={{ 
                    textTransform: 'none',
                    fontWeight: 600,
                  }}
                />
                <Tab 
                  label="Items Spreadsheet" 
                  sx={{ 
                    textTransform: 'none',
                    fontWeight: 600,
                  }}
                />
              </Tabs>

              <TabPanel value={currentTab} index={0}>
                {check?.items?.length > 0 ? (
                  renderItemsGrid()
                ) : (
                  <Alert severity="info">No items found in this inventory check</Alert>
                )}
              </TabPanel>

              <TabPanel value={currentTab} index={1}>
                {Object.keys(spreadsheetData).length > 0 ? (
                  renderSpreadsheet()
                ) : (
                  <Alert severity="info">No data available for spreadsheet view</Alert>
                )}
              </TabPanel>
            </Box>
            {renderDialogs()}
          </ContentPaper>
        </PageContainer>
      }
    />
  );
}

export default InventoryCheckDetails; 