import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  IconButton,
  Box,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
  InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Chart } from 'primereact/chart';
import {
  getWarehouseById,
  getWarehouseItems,
  updateWarehouse,
  updateWarehouseItem,
  batchUpdateWarehouseItems,
  getProductionEntriesByWarehouse,
  confirmProductionEntry,
  cancelProductionEntry,
  getWarehouseInventoryChecks,
  updateInventoryCheckStatus,
} from '../services/api';
import { shipmentPackingApi } from '../services/shipmentPackingApi';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import LoadingOverlay from './LoadingOverlay';
import { format } from 'date-fns';
import { Add as AddIcon, CheckCircle as CheckCircleIcon, Send as SendIcon, Assignment as AssignmentIcon } from '@mui/icons-material';
import FullScreenLayout from './layouts/FullScreenLayout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TableChartIcon from '@mui/icons-material/TableChart';
import BatchProcessingAnimation from './BatchProcessingAnimation';
import { styled } from '@mui/material/styles';
import InventoryIcon from '@mui/icons-material/Inventory';
import { motion, AnimatePresence } from 'framer-motion';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import OptimizedSpreadsheet from './spreadsheets/OptimizedSpreadsheet';
import ItemsCacheService from '../services/itemsCacheService';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#ffffff',
  borderRadius: 8,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
  padding: theme.spacing(2),
}));

const InventoryCategory = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  color: '#2c3e50',
}));

const InventoryCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(135deg, #fff 0%, #f5f5f5 100%)',
  borderRadius: 16,
  padding: theme.spacing(3),
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    transform: 'translateY(-2px)',
    borderColor: theme.palette.primary.main,
  },
}));

const TotalInventory = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#2c3e50',
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const TypeGrid = styled(motion.div)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const TypeCard = styled(motion.div)(({ theme }) => ({
  background: '#fff',
  padding: theme.spacing(2),
  borderRadius: 12,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  transition: 'all 0.2s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    transform: 'translateY(-2px)',
    borderColor: theme.palette.primary.main,
  },
}));

const INVENTORY_METRICS = ['quantity'];

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'warning';
    case 'processing':
      return 'info';
    case 'in_transit':
      return 'primary';
    case 'delivered':
      return 'success';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3, 4),
  marginBottom: theme.spacing(4),
  boxShadow: '0 1px 3px rgba(0,0,0,0.02)',
}));

const HeaderButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '8px 16px',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiTableHead-root': {
    '& .MuiTableCell-root': {
      backgroundColor: theme.palette.background.paper,
      fontWeight: 600,
    }
  },
  '& .MuiTableBody-root': {
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiTableCell-root': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      }
    }
  }
}));

const StyledDataTable = styled(DataTable)`
  .p-datatable-wrapper {
    border-radius: 12px;
    border: 1px solid ${props => props.theme.palette.divider};
  }
  
  .p-datatable-header {
    background: transparent;
    border: none;
    padding: ${props => props.theme.spacing(2)};
  }
  
  .p-datatable-thead > tr > th {
    background: ${props => props.theme.palette.background.paper};
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    padding: ${props => props.theme.spacing(1.5, 2)};
    font-weight: 600;
  }
  
  .p-datatable-tbody > tr > td {
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    padding: ${props => props.theme.spacing(1.5, 2)};
  }
  
  .p-datatable-tbody > tr:hover {
    background-color: ${props => props.theme.palette.action.hover};
  }
`;

const WarehouseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState(null);
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    item_name: { value: null, matchMode: 'contains' },
    item_type: { value: null, matchMode: 'contains' },
    quantity: { value: null, matchMode: 'equals' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [batchEditMode, setBatchEditMode] = useState(false);
  const [batchEditValues, setBatchEditValues] = useState({
    quantity: { value: '', touched: false },
    minimum_quantity: { value: '', touched: false },
    maximum_quantity: { value: '', touched: false },
    reorder_level: { value: '', touched: false }
  });
  const [visibleColumns, setVisibleColumns] = useState(() => ({
    item_name: true,
    item_type: true,
    size: true,
    shape: true,
    brim: true,
    quantity: true,
    minimum_quantity: true,
    maximum_quantity: true,
    reorder_level: true
  }));
  const [columnMenuAnchor, setColumnMenuAnchor] = useState(null);
  const [updateProgress, setUpdateProgress] = useState({ show: false, current: 0, total: 0, status: '' });
  const [productionEntries, setProductionEntries] = useState([]);
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('warehouseDetailsActiveTab') || 'items';
  });
  const [inventoryChecks, setInventoryChecks] = useState([]);
  const [loadingCheckId, setLoadingCheckId] = useState(null);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [selectedCheckId, setSelectedCheckId] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [inventoryExpanded, setInventoryExpanded] = useState(false);
  const [checksExpanded, setChecksExpanded] = useState(false);
  const [productionExpanded, setProductionExpanded] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [shipmentsLoading, setShipmentsLoading] = useState(true);
  const [shipmentsError, setShipmentsError] = useState(null);
  const [debouncedBatchValues, setDebouncedBatchValues] = useState({
    quantity: { value: '', touched: false },
    minimum_quantity: { value: '', touched: false },
    maximum_quantity: { value: '', touched: false },
    reorder_level: { value: '', touched: false }
  });
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [spreadsheetLoading, setSpreadsheetLoading] = useState(true);

  const handleBatchFieldChange = useCallback((field, value) => {
    setBatchEditValues(prev => ({
      ...prev,
      [field]: {
        value: value,
        touched: true
      }
    }));
  }, []);

  useEffect(() => {
    console.log('Current warehouse ID:', id);
  }, [id]);

  useEffect(() => {
    fetchWarehouseDetails();
    fetchWarehouseItems();
    fetchProductionEntries();
    fetchInventoryChecks();
  }, [id]);

  useEffect(() => {
    const fetchWarehouseShipments = async () => {
      setShipmentsLoading(true);
      try {
        const response = await shipmentPackingApi.getWarehouseShipments(id);
        setShipments(Array.isArray(response) ? response : []);
        setShipmentsError(null);
      } catch (error) {
        console.error('Error fetching warehouse shipments:', error);
        setShipmentsError('Failed to load shipments');
        setShipments([]);
      } finally {
        setShipmentsLoading(false);
      }
    };

    if (id) {
      fetchWarehouseShipments();
    }
  }, [id]);

  useEffect(() => {
    const prepareSpreadsheetData = async () => {
      setSpreadsheetLoading(true);
      try {
        // Get all items from cache service
        const allItems = await ItemsCacheService.getItems();
        
        // Filter items for this warehouse and format data for spreadsheet
        const warehouseItems = items.reduce((acc, item) => {
          // Find the corresponding item in allItems
          const baseItem = allItems.find(i => i.id === item.item_id);
          if (baseItem) {
            // Create data object with quantity
            acc[item.item_id] = {
              quantity: item.quantity
            };
          }
          return acc;
        }, {});

        setSpreadsheetData(warehouseItems);
      } catch (error) {
        console.error('Error preparing spreadsheet data:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load inventory spreadsheet',
          severity: 'error'
        });
      } finally {
        setSpreadsheetLoading(false);
      }
    };

    if (items.length > 0) {
      prepareSpreadsheetData();
    }
  }, [items]);

  const fetchWarehouseDetails = async () => {
    try {
      const data = await getWarehouseById(id);
      setWarehouse(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch warehouse details',
        severity: 'error',
      });
    }
  };

  const fetchWarehouseItems = async () => {
    try {
      const data = await getWarehouseItems(id);
      setItems(data);
      setLoading(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch warehouse items',
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const fetchProductionEntries = async () => {
    try {
      const data = await getProductionEntriesByWarehouse(id);
      setProductionEntries(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch production entries',
        severity: 'error',
      });
    }
  };

  const fetchInventoryChecks = async () => {
    try {
      const data = await getWarehouseInventoryChecks(id);
      setInventoryChecks(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch inventory checks',
        severity: 'error',
      });
    }
  };

  const handleEditItem = (item) => {
    setEditingItem(item.warehouse_item_id);
    setEditedValues({
      quantity: item.quantity,
      minimum_quantity: item.minimum_quantity,
      maximum_quantity: item.maximum_quantity,
      reorder_level: item.reorder_level,
    });
  };

  const handleSaveItem = async () => {
    try {
      await updateWarehouseItem(id, editingItem, editedValues);
      setEditingItem(null);
      fetchWarehouseItems();
      setSnackbar({
        open: true,
        message: 'Item updated successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update item',
        severity: 'error',
      });
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleBatchUpdate = async () => {
    const BATCH_SIZE = 100;
    const totalItems = selectedItems.length;
    
    // Only include fields that were actually modified
    const updateFields = Object.entries(batchEditValues)
      .filter(([_, field]) => field.touched && field.value !== '')
      .reduce((acc, [key, field]) => ({
        ...acc,
        [key]: parseInt(field.value)
      }), {});
    
    // Validate that we have items selected and at least one field to update
    if (selectedItems.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please select items to update',
        severity: 'warning'
      });
      return;
    }

    // Check if at least one field has been touched
    if (Object.keys(updateFields).length === 0) {
      setSnackbar({
        open: true,
        message: 'Please modify at least one field to update',
        severity: 'warning'
      });
      return;
    }

    setUpdateProgress({
      show: true,
      current: 0,
      total: totalItems,
      status: 'Preparing batch update...'
    });

    try {
      // Create update items array with proper structure
      const updateItems = selectedItems.map(itemId => ({
        warehouse_item_id: typeof itemId === 'object' ? itemId.warehouse_item_id : itemId,
        ...updateFields
      }));

      // Process items in batches
      for (let i = 0; i < updateItems.length; i += BATCH_SIZE) {
        const batch = updateItems.slice(i, i + BATCH_SIZE);
        await batchUpdateWarehouseItems(id, batch);
        
        const currentProgress = Math.min(i + BATCH_SIZE, totalItems);
        setUpdateProgress(prev => ({
          ...prev,
          current: currentProgress,
          status: `Processing items ${currentProgress} of ${totalItems}`
        }));
      }

      setSnackbar({
        open: true,
        message: `Successfully updated ${totalItems} items`,
        severity: 'success'
      });
      
      // Reset states
      setBatchEditMode(false);
      setBatchEditValues({
        quantity: { value: '', touched: false },
        minimum_quantity: { value: '', touched: false },
        maximum_quantity: { value: '', touched: false },
        reorder_level: { value: '', touched: false }
      });
      setSelectedItems([]);
      
      // Refresh data
      await fetchWarehouseItems();
    } catch (error) {
      console.error('Batch update error:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to update items',
        severity: 'error'
      });
    } finally {
      setUpdateProgress({ show: false, current: 0, total: 0, status: '' });
    }
  };

  const handleColumnVisibilityChange = (columnName) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnName]: !prev[columnName]
    }));
  };

  const columns = [
    { field: 'item_name', header: 'Item Name', sortable: true },
    { field: 'item_type', header: 'Type', sortable: true },
    { field: 'size', header: 'Size', sortable: true },
    { field: 'shape', header: 'Shape', sortable: true },
    { field: 'brim', header: 'Brim', sortable: true },
    { field: 'quantity', header: 'Quantity', sortable: true, editable: true },
    { field: 'minimum_quantity', header: 'Min Quantity', sortable: true, editable: true },
    { field: 'maximum_quantity', header: 'Max Quantity', sortable: true, editable: true },
    { field: 'reorder_level', header: 'Reorder Level', sortable: true, editable: true }
  ];

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div className="flex align-items-center">
          <IconButton
            onClick={(e) => setColumnMenuAnchor(e.currentTarget)}
            style={{ marginLeft: '1rem' }}
          >
            <ViewColumnIcon />
          </IconButton>
        </div>
        <div className="flex align-items-center">
          {selectedItems.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setBatchEditMode(true)}
              style={{ marginRight: '1rem' }}
            >
              Batch Edit ({selectedItems.length})
            </Button>
          )}
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const getCapacityChartData = () => {
    if (!warehouse || !items) return null;

    const itemsByType = items.reduce((acc, item) => {
      if (!acc[item.item_type]) {
        acc[item.item_type] = 0;
      }
      acc[item.item_type] += item.quantity;
      return acc;
    }, {});

    const colors = [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#4BC0C0',
      '#9966FF',
      '#FF9F40',
      '#47B39C',
      '#EC6B56'
    ];

    const data = {
      labels: Object.keys(itemsByType),
      datasets: [
        {
          data: Object.values(itemsByType),
          backgroundColor: colors.slice(0, Object.keys(itemsByType).length),
        }
      ]
    };

    return data;
  };

  const capacityChartOptions = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: true,
        text: 'Warehouse Capacity Usage by Item Type',
        font: {
          size: 16
        }
      }
    },
    maintainAspectRatio: false
  };

  const editItemTemplate = (rowData) => {
    if (editingItem === rowData.warehouse_item_id) {
      return (
        <div className="flex gap-2">
          <IconButton onClick={handleSaveItem} color="primary" size="small">
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => setEditingItem(null)} color="secondary" size="small">
            <CancelIcon />
          </IconButton>
        </div>
      );
    }
    return (
      <IconButton onClick={() => handleEditItem(rowData)} color="primary" size="small">
        <EditIcon />
      </IconButton>
    );
  };

  const quantityTemplate = (rowData) => {
    if (editingItem === rowData.warehouse_item_id) {
      return (
        <TextField
          type="number"
          value={editedValues.quantity}
          onChange={(e) => setEditedValues({
            ...editedValues,
            quantity: parseInt(e.target.value)
          })}
          size="small"
        />
      );
    }
    return rowData.quantity;
  };

  const capacityChartData = useMemo(() => getCapacityChartData(), [items]);

  const handleCreateProduction = () => {
    navigate(`/warehouses/${id}/production/create`);
  };

  const handleConfirmProduction = async (productionId) => {
    try {
      await confirmProductionEntry(productionId);
      fetchProductionEntries();
      setSnackbar({
        open: true,
        message: 'Production entry confirmed successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to confirm production entry',
        severity: 'error',
      });
    }
  };

  const handleCancelProduction = async (productionId) => {
    try {
      await cancelProductionEntry(productionId);
      fetchProductionEntries();
      setSnackbar({
        open: true,
        message: 'Production entry cancelled successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to cancel production entry',
        severity: 'error',
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem('warehouseDetailsActiveTab', newValue);
  };

  const handleStatusUpdate = async (checkId, newStatus) => {
    // Set loading state for this specific check
    setLoadingCheckId(checkId);
    
    try {
      await updateInventoryCheckStatus(checkId, newStatus);
      
      // Optimistically update the UI for this specific check
      setInventoryChecks(prev => prev.map(check => 
        check.check_id === checkId 
          ? { ...check, status: newStatus }
          : check
      ));
      
      setSnackbar({
        open: true,
        message: `Inventory check ${newStatus} successfully`,
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || `Failed to update inventory check status to ${newStatus}`,
        severity: 'error',
      });
    } finally {
      // Clear loading state
      setLoadingCheckId(null);
    }
  };

  const handleActionClick = (event, id, type) => {
    setSelectedCheckId(id);
    setSelectedItemType(type);
    setActionMenuAnchor(event.currentTarget);
  };

  const handleActionClose = () => {
    setActionMenuAnchor(null);
    setSelectedCheckId(null);
  };

  const handleShareClick = () => {
    setActionMenuAnchor(null);
    const itemType = selectedItemType === 'production' ? 'production entry' : 'inventory check';
    setSnackbar({
      open: true,
      message: `🚀 Sharing ${itemType} feature coming soon! Stay tuned for collaborative inventory management.`,
      severity: 'info'
    });
  };

  const handleExportGoogleSheets = () => {
    setActionMenuAnchor(null);
    const itemType = selectedItemType === 'production' ? 'production entry' : 'inventory check';
    setSnackbar({
      open: true,
      message: `📊 Export ${itemType} to Google Sheets feature is on its way! Get ready for seamless spreadsheet integration.`,
      severity: 'info'
    });
  };

  const handleExportExcel = () => {
    setActionMenuAnchor(null);
    const itemType = selectedItemType === 'production' ? 'production entry' : 'inventory check';
    setSnackbar({
      open: true,
      message: `📑 Export ${itemType} to Excel capability coming soon! Your data will be just a click away.`,
      severity: 'info'
    });
  };

  const getStats = () => {
    const inventoryStats = {
      total: inventoryChecks.length,
      draft: inventoryChecks.filter(check => check.status === 'draft').length,
      pending: inventoryChecks.filter(check => check.status === 'pending').length,
      confirmed: inventoryChecks.filter(check => check.status === 'confirmed').length,
      cancelled: inventoryChecks.filter(check => check.status === 'cancelled').length
    };

    const productionStats = {
      total: productionEntries.length,
      pending: productionEntries.filter(entry => entry.status === 'pending').length,
      confirmed: productionEntries.filter(entry => entry.status === 'confirmed').length,
      cancelled: productionEntries.filter(entry => entry.status === 'cancelled').length
    };

    return { inventoryStats, productionStats };
  };

  const handleProductionClick = (productionId) => {
    navigate(`/warehouses/${id}/production/${productionId}`);
  };

  const handleInventoryCheckClick = (checkId, e) => {
    if (e) {
      e.stopPropagation(); // Stop event propagation
    }
    console.log('Clicking inventory check:', checkId); // Add debugging
    navigate(`/warehouses/${id}/inventory-checks/${checkId}`);
  };

  const renderInventorySection = () => {
    const totalInventory = items.reduce((sum, item) => sum + (item.quantity || 0), 0);
    const { inventoryStats, productionStats } = getStats();

    // Get unique item types from items array
    const itemTypes = [...new Set(items.map(item => item.item_type))].sort();
    
    // Calculate inventory per type
    const typeInventories = itemTypes.map(type => ({
      type,
      amount: items.filter(item => item.item_type === type)
        .reduce((sum, item) => sum + (item.quantity || 0), 0)
    }));

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InventoryCard
              onClick={() => {
                setInventoryExpanded(!inventoryExpanded);
                setChecksExpanded(false);
                setProductionExpanded(false);
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <InventoryIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                    Total Inventory
                  </Typography>
                  <TotalInventory variant="h3">
                    {totalInventory.toLocaleString()}
                    <IconButton size="small">
                      {inventoryExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TotalInventory>
                </Box>
              </Box>
            </InventoryCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <InventoryCard
              onClick={() => {
                setChecksExpanded(!checksExpanded);
                setInventoryExpanded(false);
                setProductionExpanded(false);
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <AssignmentIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                    Inventory Checks
                  </Typography>
                  <TotalInventory variant="h3">
                    {inventoryStats.total}
                    <IconButton size="small">
                      {checksExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TotalInventory>
                </Box>
              </Box>
            </InventoryCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <InventoryCard
              onClick={() => {
                setProductionExpanded(!productionExpanded);
                setInventoryExpanded(false);
                setChecksExpanded(false);
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <TableChartIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                    Production Entries
                  </Typography>
                  <TotalInventory variant="h3">
                    {productionStats.total}
                    <IconButton size="small">
                      {productionExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TotalInventory>
                </Box>
              </Box>
            </InventoryCard>
          </Grid>
        </Grid>

        <AnimatePresence>
          {inventoryExpanded && (
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Paper 
                  sx={{ 
                    p: 3, 
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)'
                  }}
                >
                  <TypeGrid>
                    {typeInventories.map((item, index) => (
                      <TypeCard
                        key={item.type}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        <Typography variant="h6" color="primary">
                          Type {item.type}
                        </Typography>
                        <Typography variant="h4" fontWeight="bold">
                          {item.amount.toLocaleString()}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {totalInventory > 0 ? 
                            ((item.amount / totalInventory) * 100).toFixed(1) : 
                            '0'}% of total
                        </Typography>
                        <motion.div
                          style={{
                            width: '100%',
                            height: 4,
                            background: '#edf2f7',
                            borderRadius: 2,
                            marginTop: 8,
                            overflow: 'hidden'
                          }}
                        >
                          <motion.div
                            initial={{ width: 0 }}
                            animate={{ width: `${(item.amount / totalInventory) * 100}%` }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            style={{
                              height: '100%',
                              background: 'linear-gradient(90deg, #3498db, #2980b9)',
                              borderRadius: 2
                            }}
                          />
                        </motion.div>
                      </TypeCard>
                    ))}
                  </TypeGrid>
                </Paper>
              </Grid>
            </Grid>
          )}

          {checksExpanded && (
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Paper 
                  sx={{ 
                    p: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)'
                  }}
                >
                  <Grid container spacing={3}>
                    {Object.entries(inventoryStats).map(([status, count]) => (
                      status !== 'total' && (
                        <Grid item xs={12} sm={6} md={3} key={status}>
                          <Paper sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant="h6" color="primary">
                              {status.charAt(0).toUpperCase() + status.slice(1)}
                            </Typography>
                            <Typography variant="h4" fontWeight="bold">
                              {count}
                            </Typography>
                          </Paper>
                        </Grid>
                      )
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}

          {productionExpanded && (
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Paper 
                  sx={{ 
                    p: 3,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)'
                  }}
                >
                  <Grid container spacing={3}>
                    {Object.entries(productionStats).map(([status, count]) => (
                      status !== 'total' && (
                        <Grid item xs={12} sm={6} md={3} key={status}>
                          <Paper sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant="h6" color="primary">
                              {status.charAt(0).toUpperCase() + status.slice(1)}
                            </Typography>
                            <Typography variant="h4" fontWeight="bold">
                              {count}
                            </Typography>
                          </Paper>
                        </Grid>
                      )
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </AnimatePresence>
      </>
    );
  };

  const handlePackShipment = () => {
    setSnackbar({
      open: true,
      message: '📦 Pack Shipment feature is currently in development! Soon you\'ll be able to manage your shipments efficiently.',
      severity: 'info'
    });
  };

  const renderBatchEditDialog = () => {
    const fields = {
      quantity: {
        label: "Quantity",
        placeholder: "Enter quantity",
        helperText: "Set new quantity for selected items"
      },
      minimum_quantity: {
        label: "Minimum Quantity",
        placeholder: "Enter minimum quantity",
        helperText: "Set minimum stock level"
      },
      maximum_quantity: {
        label: "Maximum Quantity",
        placeholder: "Enter maximum quantity",
        helperText: "Set maximum stock level"
      },
      reorder_level: {
        label: "Reorder Level",
        placeholder: "Enter reorder level",
        helperText: "Set level to trigger reorder"
      }
    };

    return (
      <Dialog 
        open={batchEditMode} 
        onClose={() => setBatchEditMode(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            '& .MuiDialogTitle-root': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              pb: 2
            }
          }
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={1}>
            <EditIcon color="primary" />
            <Typography variant="h6">Batch Edit Items</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <DialogContentText sx={{ mb: 3 }}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography color="primary" variant="subtitle1" fontWeight="medium">
                {selectedItems.length}
              </Typography>
              <Typography>
                items selected for batch update. Leave fields empty to keep current values.
              </Typography>
            </Box>
          </DialogContentText>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 2.5,
            '& .MuiTextField-root': {
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-1px)',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
              }
            }
          }}>
            {Object.entries(fields).map(([field, config]) => (
              <TextField
                key={field}
                label={config.label}
                type="number"
                value={batchEditValues[field].value}
                onChange={(e) => {
                  handleBatchFieldChange(field, e.target.value);
                }}
                placeholder={config.placeholder}
                helperText={batchEditValues[field].touched ? 
                  `Will update ${selectedItems.length} items` : 
                  config.helperText
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: batchEditValues[field].touched && (
                    <InputAdornment position="start">
                      <CheckCircleIcon 
                        color="primary" 
                        sx={{ 
                          fontSize: 20,
                          animation: 'fadeIn 0.3s ease-in'
                        }} 
                      />
                    </InputAdornment>
                  )
                }}
                FormHelperTextProps={{
                  sx: {
                    color: batchEditValues[field].touched ? 'primary.main' : 'text.secondary',
                    transition: 'color 0.2s ease-in-out'
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: batchEditValues[field].touched ? 
                      'rgba(25, 118, 210, 0.03)' : 
                      'transparent',
                    transition: 'background-color 0.2s ease-in-out'
                  }
                }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, gap: 1 }}>
          <Button 
            onClick={() => {
              setBatchEditMode(false);
              setBatchEditValues({
                quantity: { value: '', touched: false },
                minimum_quantity: { value: '', touched: false },
                maximum_quantity: { value: '', touched: false },
                reorder_level: { value: '', touched: false }
              });
            }}
            variant="outlined"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleBatchUpdate} 
            variant="contained" 
            color="primary"
            disabled={!Object.values(batchEditValues).some(field => field.touched)}
            startIcon={<SaveIcon />}
          >
            Update {selectedItems.length} Items
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (!warehouse) return <Typography>Loading...</Typography>;

  return (
    <>
      <FullScreenLayout
        header={
          <HeaderContainer>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
                    {warehouse?.name}
                  </Typography>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="subtitle1" color="text.secondary">
                      <strong>Location:</strong> {warehouse?.location || 'N/A'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="flex-end" gap={2}>
                  <HeaderButton
                    variant="outlined"
                    onClick={() => navigate('/warehouses')}
                    sx={{
                      borderColor: 'divider',
                      '&:hover': {
                        borderColor: 'primary.main',
                        backgroundColor: 'primary.50',
                      }
                    }}
                  >
                    Back to Warehouses
                  </HeaderButton>
                </Box>
              </Grid>
            </Grid>
          </HeaderContainer>
        }
        mainPanel={
          <PageContainer>
            {renderInventorySection()}
            
            <Box sx={{ mt: 3, mb: 2, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <HeaderButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigate(`/warehouses/${id}/production/create`)}
              >
                Create Production Entry
              </HeaderButton>
              <HeaderButton
                variant="contained"
                color="primary"
                startIcon={<AssignmentIcon />}
                onClick={() => navigate(`/warehouses/${id}/inventory-checks/create`)}
              >
                Create Inventory Check
              </HeaderButton>
            </Box>

            <ContentPaper>
              <Box sx={{ width: '100%' }}>
                <Tabs 
                  value={activeTab} 
                  onChange={handleTabChange}
                  variant="fullWidth"
                  aria-label="warehouse management tabs"
                  sx={{ 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontWeight: 500,
                      fontSize: '1rem',
                      minWidth: 100,
                      '&.Mui-selected': {
                        fontWeight: 600
                      }
                    }
                  }}
                >
                  <Tab label="Warehouse Items" value="items" />
                  <Tab label="Production Entries" value="production" />
                  <Tab label="Inventory Checks" value="inventory-checks" />
                  <Tab label="Shipments" value="shipments" />
                  <Tab label="Inventory Grid" value="inventory-grid" />
                </Tabs>

                <Box sx={{ mt: 3 }}>
                  {/* Warehouse Items Tab */}
                  {activeTab === 'items' && (
                    <Box sx={{ p: 2 }}>
                      <StyledDataTable
                        value={items}
                        paginator
                        rows={10}
                        dataKey="warehouse_item_id"
                        filters={filters}
                        filterDisplay="menu"
                        loading={loading}
                        globalFilterFields={['item_name', 'item_type', 'size', 'shape', 'brim']}
                        header={renderHeader()}
                        emptyMessage="No items found."
                        responsiveLayout="scroll"
                        scrollHeight="600px"
                        selection={selectedItems}
                        onSelectionChange={(e) => setSelectedItems(e.value)}
                        selectionMode="multiple"
                        className="warehouse-items-table"
                      >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                        {columns.map(col => (
                          visibleColumns[col.field] && (
                            <Column
                              key={col.field}
                              field={col.field}
                              header={col.header}
                              sortable={col.sortable}
                              filter
                              filterPlaceholder={`Search by ${col.header.toLowerCase()}`}
                              body={col.editable ? (rowData) => (
                                editingItem === rowData.warehouse_item_id ? (
                                  <TextField
                                    type="number"
                                    value={editedValues[col.field] || ''}
                                    onChange={(e) => setEditedValues({
                                      ...editedValues,
                                      [col.field]: parseInt(e.target.value)
                                    })}
                                    size="small"
                                  />
                                ) : (
                                  rowData[col.field]
                                )
                              ) : undefined}
                            />
                          )
                        ))}
                        <Column
                          header="Actions"
                          body={(rowData) => editItemTemplate(rowData)}
                          style={{ width: '100px' }}
                        />
                      </StyledDataTable>
                    </Box>
                  )}

                  {/* Production Entries Tab */}
                  {activeTab === 'production' && (
                    <Box sx={{ p: 2 }}>
                      <StyledTableContainer>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Created At</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Items Count</TableCell>
                              <TableCell>Notes</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {productionEntries.map((entry) => (
                              <TableRow 
                                key={entry.production_id}
                                hover
                                sx={{ 
                                  cursor: 'pointer',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                  }
                                }}
                                onClick={() => handleProductionClick(entry.production_id)}
                              >
                                <TableCell>{entry.production_id}</TableCell>
                                <TableCell>
                                  {format(new Date(entry.created_at), 'MMM d, yyyy HH:mm')}
                                </TableCell>
                                <TableCell>
                                  <Chip 
                                    label={entry.status}
                                    color={
                                      entry.status === 'pending' ? 'warning' :
                                      entry.status === 'confirmed' ? 'success' :
                                      entry.status === 'cancelled' ? 'error' :
                                      'default'
                                    }
                                  />
                                </TableCell>
                                <TableCell>{entry.items?.length || 0}</TableCell>
                                <TableCell>{entry.notes}</TableCell>
                                <TableCell>
                                  <Box sx={{ display: 'flex', gap: 1 }}>
                                    {entry.status === 'pending' && (
                                      <>
                                        <IconButton 
                                          color="success"
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click
                                            handleConfirmProduction(entry.production_id);
                                          }}
                                        >
                                          <CheckCircleIcon />
                                        </IconButton>
                                        <IconButton 
                                          color="error"
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click
                                            handleCancelProduction(entry.production_id);
                                          }}
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      </>
                                    )}
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent row click
                                        handleActionClick(e, entry.production_id, 'production');
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    </Box>
                  )}

                  {/* Inventory Checks Tab */}
                  {activeTab === 'inventory-checks' && (
                    <Box sx={{ p: 2 }}>
                      <StyledTableContainer>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Created At</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Items Count</TableCell>
                              <TableCell>Reset Uncounted</TableCell>
                              <TableCell>Notes</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {inventoryChecks.map((check) => (
                              <TableRow 
                                key={check.check_id}
                                hover
                                onClick={(e) => handleInventoryCheckClick(check.check_id, e)}
                                sx={{ 
                                  cursor: 'pointer',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                  }
                                }}
                              >
                                <TableCell>{check.check_id}</TableCell>
                                <TableCell>
                                  {format(new Date(check.created_at), 'MMM d, yyyy HH:mm')}
                                </TableCell>
                                <TableCell>
                                  <Chip 
                                    label={check.status}
                                    color={
                                      check.status === 'draft' ? 'default' :
                                      check.status === 'pending' ? 'warning' :
                                      check.status === 'confirmed' ? 'success' :
                                      check.status === 'cancelled' ? 'error' :
                                      'default'
                                    }
                                  />
                                </TableCell>
                                <TableCell>{check.items?.length || 0}</TableCell>
                                <TableCell>
                                  <Chip 
                                    label={check.reset_uncounted_items ? 'Yes' : 'No'}
                                    color={check.reset_uncounted_items ? 'warning' : 'default'}
                                  />
                                </TableCell>
                                <TableCell>{check.notes}</TableCell>
                                <TableCell>
                                  <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton 
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/warehouses/${id}/inventory-checks/${check.check_id}/edit`);
                                      }}
                                      disabled={check.status !== 'draft'}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    {check.status === 'pending' && (
                                      <>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          color="success"
                                          onClick={() => handleStatusUpdate(check.check_id, 'confirmed')}
                                          disabled={loadingCheckId === check.check_id}
                                          startIcon={loadingCheckId === check.check_id ? 
                                            <CircularProgress size={20} color="inherit" /> : 
                                            <CheckCircleIcon />
                                          }
                                        >
                                          {loadingCheckId === check.check_id ? 'Confirming...' : 'Confirm'}
                                        </Button>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          color="error"
                                          onClick={() => handleStatusUpdate(check.check_id, 'cancelled')}
                                          disabled={loadingCheckId === check.check_id}
                                          startIcon={loadingCheckId === check.check_id ? 
                                            <CircularProgress size={20} color="inherit" /> : 
                                            <CancelIcon />
                                          }
                                        >
                                          {loadingCheckId === check.check_id ? 'Cancelling...' : 'Cancel'}
                                        </Button>
                                      </>
                                    )}
                                    {check.status === 'draft' && (
                                      <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleStatusUpdate(check.check_id, 'pending')}
                                        disabled={loadingCheckId === check.check_id}
                                        startIcon={loadingCheckId === check.check_id ? 
                                          <CircularProgress size={20} color="inherit" /> : 
                                          <SendIcon />
                                        }
                                      >
                                        {loadingCheckId === check.check_id ? 'Submitting...' : 'Submit'}
                                      </Button>
                                    )}
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleActionClick(e, check.check_id, 'inventory');
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    </Box>
                  )}

                  {/* Shipments Tab */}
                  {activeTab === 'shipments' && (
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Undelivered Shipments
                        </Typography>
                      </Box>

                      {shipmentsLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                          <CircularProgress />
                        </Box>
                      ) : shipmentsError ? (
                        <Alert severity="error" sx={{ mb: 2 }}>
                          {shipmentsError}
                        </Alert>
                      ) : shipments.length === 0 ? (
                        <Alert severity="info">
                          No undelivered shipments found
                        </Alert>
                      ) : (
                        <TableContainer component={Paper}>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell>Shipment ID</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Items Count</TableCell>
                                <TableCell>Destination</TableCell>
                                <TableCell>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {shipments.map((shipment) => (
                                <TableRow key={shipment.shipment_id}>
                                  <TableCell>{shipment.shipment_number}</TableCell>
                                  <TableCell>
                                    <Chip 
                                      label={shipment.status} 
                                      color={getStatusColor(shipment.status)}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {new Date(shipment.created_at).toLocaleDateString()}
                                  </TableCell>
                                  <TableCell>{shipment.items_count}</TableCell>
                                  <TableCell>{shipment.destination_name}</TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={() => navigate(`/shipments/${shipment.shipment_id}/pack`)}
                                      startIcon={<LocalShippingIcon />}
                                      disabled={!['pending', 'processing'].includes(shipment.status)}
                                    >
                                      Start Packing
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Box>
                  )}

                  {/* Inventory Grid Tab */}
                  {activeTab === 'inventory-grid' && (
                    <Box sx={{ p: 2 }}>
                      {spreadsheetLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <OptimizedSpreadsheet
                          data={spreadsheetData}
                          metrics={INVENTORY_METRICS}
                          title={`${warehouse.name} - Inventory Grid`}
                          readOnly={true}
                          fullScreen={false}
                          hideZeros={false}
                          readOnlyMetrics={['quantity']}
                          isLoading={spreadsheetLoading}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </ContentPaper>
          </PageContainer>
        }
      />
      <Menu
        anchorEl={actionMenuAnchor}
        open={Boolean(actionMenuAnchor)}
        onClose={handleActionClose}
        PaperProps={{
          elevation: 3,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1,
              gap: 2,
            },
          },
        }}
      >
        <MenuItem onClick={handleShareClick}>
          <ShareIcon color="primary" />
          Share
        </MenuItem>
        <MenuItem onClick={handleExportGoogleSheets}>
          <TableChartIcon color="success" />
          Export to Google Sheets
        </MenuItem>
        <MenuItem onClick={handleExportExcel}>
          <CloudDownloadIcon color="info" />
          Export to Excel
        </MenuItem>
      </Menu>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {renderBatchEditDialog()}
      <BatchProcessingAnimation 
        open={updateProgress.show}
        current={updateProgress.current}
        total={updateProgress.total}
        status={updateProgress.status}
      />
    </>
  );
};

export default WarehouseDetails; 