import React, { useState, useCallback } from 'react';
import { Box, Button, TextField, Paper, Stack, IconButton, Chip, Grid, Card, CardContent, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MultiValueSpreadsheetV3 from './MultiValueSpreadsheetV3';

function TestSpreadsheet6() {
  const [newMetric, setNewMetric] = useState('');
  const [newSize, setNewSize] = useState('');
  const [newBrim, setNewBrim] = useState('');
  const [metrics, setMetrics] = useState(['inventory', 'replenish', 'order']);
  const [sizes, setSizes] = useState(['6 7/8', '7', '7 1/8']);
  const [brims, setBrims] = useState(['2', '2 1/8']);
  const [changes, setChanges] = useState([]);
  const shapes = ['XR', 'R', 'L', 'X'];
  const types = ['Felt', 'Straw'];

  // Initialize spreadsheet data with flat structure
  const [spreadsheetData, setSpreadsheetData] = useState(() => {
    const items = [];
    
    types.forEach(type => {
      sizes.forEach(size => {
        brims.forEach(brim => {
          shapes.forEach(shape => {
            // Create an item with random values for each metric
            const values = {};
            metrics.forEach(metric => {
              values[metric] = Math.floor(Math.random() * 100);
            });

            items.push({
              type,
              size,
              brim,
              shape,
              values,
              metadata: {
                itemId: `${type}-${size}-${brim}-${shape}`,
                lastUpdated: new Date().toISOString()
              }
            });
          });
        });
      });
    });

    return {
      items,
      valueFields: metrics
    };
  });

  const handleAddBrim = () => {
    if (newBrim && !brims.includes(newBrim)) {
      setBrims([...brims, newBrim]);
      
      // Add new items for the new brim
      const newItems = [...spreadsheetData.items];
      
      types.forEach(type => {
        sizes.forEach(size => {
          shapes.forEach(shape => {
            const values = {};
            metrics.forEach(metric => {
              values[metric] = Math.floor(Math.random() * 100);
            });

            newItems.push({
              type,
              size,
              brim: newBrim,
              shape,
              values,
              metadata: {
                itemId: `${type}-${size}-${newBrim}-${shape}`,
                lastUpdated: new Date().toISOString()
              }
            });
          });
        });
      });

      setSpreadsheetData({
        items: newItems,
        valueFields: metrics
      });
      setNewBrim('');
    }
  };

  const handleDeleteBrim = (brimToDelete) => {
    if (brims.length <= 1) {
      alert("Cannot delete the last brim size");
      return;
    }
    
    setBrims(brims.filter(brim => brim !== brimToDelete));
    
    // Remove items with the deleted brim
    setSpreadsheetData({
      items: spreadsheetData.items.filter(item => item.brim !== brimToDelete),
      valueFields: metrics
    });
  };

  const handleAddMetric = () => {
    if (newMetric && !metrics.includes(newMetric)) {
      setMetrics([...metrics, newMetric]);
      
      // Add the new metric to all existing items
      const updatedItems = spreadsheetData.items.map(item => ({
        ...item,
        values: {
          ...item.values,
          [newMetric]: Math.floor(Math.random() * 100)
        }
      }));

      setSpreadsheetData({
        items: updatedItems,
        valueFields: [...metrics, newMetric]
      });
      setNewMetric('');
    }
  };

  const handleAddSize = () => {
    if (newSize && !sizes.includes(newSize)) {
      setSizes([...sizes, newSize]);
      
      // Add new items for the new size
      const newItems = [...spreadsheetData.items];
      
      types.forEach(type => {
        brims.forEach(brim => {
          shapes.forEach(shape => {
            const values = {};
            metrics.forEach(metric => {
              values[metric] = Math.floor(Math.random() * 100);
            });

            newItems.push({
              type,
              size: newSize,
              brim,
              shape,
              values,
              metadata: {
                itemId: `${type}-${newSize}-${brim}-${shape}`,
                lastUpdated: new Date().toISOString()
              }
            });
          });
        });
      });

      setSpreadsheetData({
        items: newItems,
        valueFields: metrics
      });
      setNewSize('');
    }
  };

  const handleDeleteMetric = (metricToDelete) => {
    if (metrics.length <= 1) {
      alert("Cannot delete the last metric");
      return;
    }

    setMetrics(metrics.filter(metric => metric !== metricToDelete));
    
    // Remove the metric from all items
    const updatedItems = spreadsheetData.items.map(item => {
      const newValues = { ...item.values };
      delete newValues[metricToDelete];
      return {
        ...item,
        values: newValues
      };
    });

    setSpreadsheetData({
      items: updatedItems,
      valueFields: metrics.filter(m => m !== metricToDelete)
    });
  };

  const handleDeleteSize = (sizeToDelete) => {
    if (sizes.length <= 1) {
      alert("Cannot delete the last size");
      return;
    }
    
    setSizes(sizes.filter(size => size !== sizeToDelete));
    
    // Remove items with the deleted size
    setSpreadsheetData({
      items: spreadsheetData.items.filter(item => item.size !== sizeToDelete),
      valueFields: metrics
    });
  };

  const handleCellValueChanged = useCallback(({ type, size, brim, shape, valueField, newValue, oldValue, metadata }) => {
    // Update the value in our flat data structure
    setSpreadsheetData(prevData => {
      const updatedItems = prevData.items.map(item => {
        if (item.type === type && 
            item.size === size && 
            item.brim === brim && 
            item.shape === shape) {
          return {
            ...item,
            values: {
              ...item.values,
              [valueField]: newValue
            },
            metadata: {
              ...item.metadata,
              lastUpdated: new Date().toISOString()
            }
          };
        }
        return item;
      });

      return {
        items: updatedItems,
        valueFields: prevData.valueFields
      };
    });

    // Add change to history
    setChanges(prevChanges => [{
      timestamp: new Date().toLocaleTimeString(),
      type,
      size,
      brim,
      shape,
      valueField,
      oldValue,
      newValue,
      metadata
    }, ...prevChanges]);
  }, []);

  const handleRandomize = () => {
    const updatedItems = spreadsheetData.items.map(item => ({
      ...item,
      values: Object.fromEntries(
        metrics.map(metric => [metric, Math.floor(Math.random() * 100)])
      ),
      metadata: {
        ...item.metadata,
        lastUpdated: new Date().toISOString()
      }
    }));

    setSpreadsheetData({
      items: updatedItems,
      valueFields: metrics
    });
  };

  return (
    <Grid container spacing={2} sx={{ p: 3 }}>
      <Grid item xs={12} md={9}>
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Stack spacing={3}>
            <Box>
              <h3>Metrics</h3>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  label="New Metric"
                  value={newMetric}
                  onChange={(e) => setNewMetric(e.target.value)}
                  size="small"
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddMetric}
                >
                  Add Metric
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {metrics.map(metric => (
                  <Chip
                    key={metric}
                    label={metric}
                    onDelete={() => handleDeleteMetric(metric)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>

            <Box>
              <h3>Sizes</h3>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  label="New Size"
                  value={newSize}
                  onChange={(e) => setNewSize(e.target.value)}
                  size="small"
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddSize}
                >
                  Add Size
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {sizes.map(size => (
                  <Chip
                    key={size}
                    label={size}
                    onDelete={() => handleDeleteSize(size)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>

            <Box>
              <h3>Brims</h3>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  label="New Brim"
                  value={newBrim}
                  onChange={(e) => setNewBrim(e.target.value)}
                  size="small"
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddBrim}
                >
                  Add Brim
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {brims.map(brim => (
                  <Chip
                    key={brim}
                    label={brim}
                    onDelete={() => handleDeleteBrim(brim)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>

            <Button
              variant="outlined"
              onClick={handleRandomize}
              sx={{ alignSelf: 'flex-start' }}
            >
              Randomize All Values
            </Button>
          </Stack>
        </Paper>

        <MultiValueSpreadsheetV3
          data={spreadsheetData}
          title="Test Multi-Value Spreadsheet V3"
          onCellValueChanged={handleCellValueChanged}
          hideZeros={false}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Card sx={{ position: 'sticky', top: 16 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Change History
            </Typography>
            <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
              {changes.map((change, index) => (
                <Paper 
                  key={index} 
                  sx={{ 
                    p: 1, 
                    mb: 1, 
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    borderLeft: '3px solid #1a2b3c'
                  }}
                >
                  <Typography variant="caption" display="block" color="text.secondary">
                    {change.timestamp}
                  </Typography>
                  <Typography variant="body2">
                    Type: {change.type}, Size: {change.size}
                  </Typography>
                  <Typography variant="body2">
                    Brim: {change.brim}, Shape: {change.shape}
                  </Typography>
                  <Typography variant="body2">
                    {change.valueField}: {change.oldValue} → {change.newValue}
                  </Typography>
                  <Typography variant="caption" display="block" color="text.secondary">
                    Last Updated: {new Date(change.metadata.lastUpdated).toLocaleString()}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default TestSpreadsheet6; 