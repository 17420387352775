import api from './api';

export const getSystemTrackings = async (showAll = false) => {
  try {
    const response = await api.get(`/system-tracking${showAll ? '?showAll=true' : ''}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTrackingDetails = async (trackingId) => {
  try {
    const response = await api.get(`/system-tracking/${trackingId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const markTrackingAsViewed = async (trackingId) => {
  try {
    const response = await api.post(`/system-tracking/${trackingId}/view`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTracking = async (trackingId, data) => {
  try {
    const response = await api.put(`/system-tracking/${trackingId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}; 