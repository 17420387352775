import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Grid,
  Card,
  Container,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getAllOrders, deleteOrder } from '../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import FullScreenLayout from './layouts/FullScreenLayout';
import PendingIcon from '@mui/icons-material/Pending';
import LoopIcon from '@mui/icons-material/Loop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const CreateOrderButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '10px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

const StatsCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  transition: 'all 0.2s ease',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
}));

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    order_number: { value: null, matchMode: 'contains' },
    company_name: { value: null, matchMode: 'contains' },
    status: { value: null, matchMode: 'equals' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const data = await getAllOrders();
      setOrders(data);
    } catch (error) {
      toast.error('Failed to fetch orders');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleEdit = (orderId) => {
    navigate(`/orders/${orderId}/edit`);
  };

  const handleCreateOrder = () => {
    navigate('/orders/create');
  };

  const getStatusColor = (status) => {
    const colors = {
      pending: 'warning',
      processing: 'info',
      completed: 'success',
      cancelled: 'error',
    };
    return colors[status] || 'default';
  };

  const getRequestStatusColor = (status) => {
    const colors = {
      awaiting_approval: 'warning',
      rejected: 'error',
      processing: 'info',
      shipped: 'primary',
      delivered: 'success',
    };
    return colors[status] || 'default';
  };

  const getOrderStats = () => {
    return {
      pending: orders.filter(order => order.status === 'pending').length,
      processing: orders.filter(order => order.status === 'processing').length,
      completed: orders.filter(order => order.status === 'completed').length,
      total: orders.length
    };
  };

  const stats = getOrderStats();

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
          Orders
        </Typography>
        <CreateOrderButton
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleCreateOrder}
        >
          Create New Order
        </CreateOrderButton>
      </Box>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <Chip
        label={rowData.status}
        color={getStatusColor(rowData.status)}
        size="small"
      />
    );
  };

  const requestStatusTemplate = (rowData) => {
    if (!rowData.is_customer_request) return null;
    return (
      <Chip
        label={rowData.request_status}
        color={getRequestStatusColor(rowData.request_status)}
        size="small"
      />
    );
  };

  const actionsTemplate = (rowData) => {
    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/orders/${rowData.order_id}/details`);
        }}
        color="primary"
      >
        <VisibilityIcon />
      </IconButton>
    );
  };

  return (
    <FullScreenLayout
      mainPanel={
        <PageContainer>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={3}>
              <StatsCard>
                <Typography variant="h3" sx={{ mb: 1, color: 'text.primary', fontWeight: 600 }}>
                  {stats.total}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Total Orders
                </Typography>
              </StatsCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <StatsCard>
                <Typography variant="h3" sx={{ mb: 1, color: 'text.primary', fontWeight: 600 }}>
                  {stats.pending}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Pending Orders
                </Typography>
              </StatsCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <StatsCard>
                <Typography variant="h3" sx={{ mb: 1, color: 'text.primary', fontWeight: 600 }}>
                  {stats.processing}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Processing Orders
                </Typography>
              </StatsCard>
            </Grid>
            <Grid item xs={12} md={3}>
              <StatsCard>
                <Typography variant="h3" sx={{ mb: 1, color: 'text.primary', fontWeight: 600 }}>
                  {stats.completed}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Completed Orders
                </Typography>
              </StatsCard>
            </Grid>
          </Grid>

          <ContentPaper elevation={0}>
            <DataTable
              value={orders}
              paginator
              rows={10}
              dataKey="order_id"
              filters={filters}
              filterDisplay="menu"
              loading={loading}
              globalFilterFields={['order_number', 'company_name', 'status']}
              header={renderHeader()}
              emptyMessage="No orders found."
              responsiveLayout="scroll"
              scrollHeight="600px"
              onRowClick={(e) => navigate(`/orders/${e.data.order_id}/details`)}
              rowHover
              className="orders-table"
              style={{ 
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <Column 
                field="order_number" 
                header="Order Number" 
                sortable 
                filter 
                filterPlaceholder="Search by order number"
              />
              <Column 
                field="company_name" 
                header="Customer" 
                sortable 
                filter 
                filterPlaceholder="Search by customer"
              />
              <Column 
                field="status" 
                header="Status" 
                sortable 
                filter 
                filterPlaceholder="Search by status"
                body={statusTemplate}
              />
              <Column 
                field="request_status" 
                header="Request Status" 
                sortable 
                filter 
                filterPlaceholder="Search by request status"
                body={requestStatusTemplate}
              />
              <Column 
                field="created_at" 
                header="Created At" 
                sortable
                body={(rowData) => format(new Date(rowData.created_at), 'MMM d, yyyy HH:mm')}
              />
              <Column 
                field="items" 
                header="Items" 
                sortable
                body={(rowData) => `${rowData.items?.length || 0} items`}
              />
              <Column 
                header="Actions" 
                body={actionsTemplate} 
                style={{ width: '100px' }}
              />
            </DataTable>
          </ContentPaper>
        </PageContainer>
      }
    />
  );
};

export default Orders; 