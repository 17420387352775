import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    Chip,
    Divider,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StarIcon from '@mui/icons-material/Star';

const MetricSourceInfo = ({ source }) => (
    <Box 
        sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            p: 1,
            backgroundColor: 'background.default',
            borderRadius: '4px'
        }}
    >
        <Chip 
            label={source.operation === '+' ? 'Add' : 'Subtract'}
            size="small"
            color={source.operation === '+' ? 'success' : 'error'}
        />
        <Typography variant="body2">
            {source.sourceName}: {source.reference_name}
        </Typography>
    </Box>
);

const ViewDetailsDialog = ({ open, onClose, view, navigate }) => (
    <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
    >
        <DialogTitle>
            <Typography variant="h6">View Details: {view.name}</Typography>
        </DialogTitle>
        <DialogContent dividers>
            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Description
                </Typography>
                <Typography>
                    {view.description || 'No description provided'}
                </Typography>
            </Box>

            <Typography variant="h6" gutterBottom color="primary.main">
                Metrics Configuration
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {view.metrics?.map((metric, index) => (
                    <Box 
                        key={index}
                        sx={{ 
                            p: 3, 
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: '8px'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Box>
                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                    {metric.name}
                                </Typography>
                                {metric.description && (
                                    <Typography variant="body2" color="text.secondary">
                                        {metric.description}
                                    </Typography>
                                )}
                            </Box>
                            <Chip 
                                label={`${metric.sources?.length || 0} sources`}
                                size="small"
                                color="primary"
                                variant="outlined"
                            />
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Typography variant="subtitle2" gutterBottom>
                            Data Sources
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {metric.sources?.map((source, idx) => (
                                <MetricSourceInfo key={idx} source={source} />
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Close</Button>
            <Button 
                variant="contained" 
                onClick={() => {
                    onClose();
                    navigate(`/views/${view.view_id}/data`);
                }}
            >
                View Data
            </Button>
        </DialogActions>
    </Dialog>
);

const ViewCard = ({ view, onDelete }) => {
    const [expanded, setExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const navigate = useNavigate();

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleMenuClose();
        onDelete(view, 'view');
    };

    return (
        <Card sx={{
            borderRadius: '16px',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)'
            }
        }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a73e8' }}>
                        {view.name}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {view.is_favorite && <StarIcon sx={{ color: '#fbbc04' }} />}
                        <IconButton 
                            size="small"
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <IconButton 
                            size="small"
                            onClick={handleMenuOpen}
                            sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                </Box>
                <CardActions>
                    <Button 
                        size="small" 
                        onClick={() => setDetailsOpen(true)}
                        sx={{ ml: 'auto', color: '#1a73e8' }}
                    >
                        View Data
                    </Button>
                </CardActions>
            </CardContent>

            <ViewDetailsDialog
                open={detailsOpen}
                onClose={() => setDetailsOpen(false)}
                view={view}
                navigate={navigate}
            />

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                    <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                    Delete
                </MenuItem>
            </Menu>
        </Card>
    );
};

export default ViewCard; 