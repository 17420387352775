import axios from 'axios';
import ItemsCacheService from './itemsCacheService';

const API_URL = 'https://pimbyeli.com/api';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 60000,
  headers: {
    'Accept': 'application/json'
  }
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add this error handling helper
const handleApiError = (error, defaultMessage) => {
  if (error.response) {
    // Server responded with error
    throw new Error(error.response.data.message || defaultMessage);
  } else if (error.request) {
    // Request made but no response
    throw new Error('No response from server. Please try again.');
  } else {
    // Something else went wrong
    throw new Error(error.message || defaultMessage);
  }
};

// Item Types
export const getAllItemTypes = async () => {
  try {
    const response = await api.get('/items/types');
    return response.data;
  } catch (error) {
    console.error('Error fetching item types:', error);
    throw error;
  }
};

export const getItemType = async (name) => {
  try {
    const response = await api.get(`/items/types/${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching item type:', error);
    throw error;
  }
};

export const createItemType = async (typeData) => {
  try {
    const response = await api.post('/items/types', typeData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error creating item type');
  }
};

export const updateItemType = async (name, typeData) => {
  try {
    const response = await api.put(`/items/types/${name}`, typeData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error updating item type:', error);
    throw error;
  }
};

export const deleteItemType = async (name) => {
  try {
    const response = await api.delete(`/items/types/${name}`);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error deleting item type:', error);
    throw error;
  }
};

// Item Shapes
export const getAllItemShapes = async () => {
  try {
    const response = await api.get('/items/shapes');
    return response.data;
  } catch (error) {
    console.error('Error fetching item shapes:', error);
    throw error;
  }
};

export const getItemShape = async (name) => {
  try {
    const response = await api.get(`/items/shapes/${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching item shape:', error);
    throw error;
  }
};

export const createItemShape = async (shapeData) => {
  try {
    const response = await api.post('/items/shapes', shapeData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error creating item shape:', error);
    throw error;
  }
};

export const updateItemShape = async (name, shapeData) => {
  try {
    const response = await api.put(`/items/shapes/${name}`, shapeData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error updating item shape:', error);
    throw error;
  }
};

export const deleteItemShape = async (name) => {
  try {
    const response = await api.delete(`/items/shapes/${name}`);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error deleting item shape:', error);
    throw error;
  }
};

// Item Sizes
export const getAllItemSizes = async () => {
  try {
    const response = await api.get('/items/sizes');
    return response.data;
  } catch (error) {
    console.error('Error fetching item sizes:', error);
    throw error;
  }
};

export const getItemSize = async (name) => {
  try {
    const response = await api.get(`/items/sizes/${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching item size:', error);
    throw error;
  }
};

export const createItemSize = async (sizeData) => {
  try {
    const response = await api.post('/items/sizes', sizeData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error creating item size:', error);
    throw error;
  }
};

export const updateItemSize = async (name, sizeData) => {
  try {
    const response = await api.put(`/items/sizes/${name}`, sizeData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error updating item size:', error);
    throw error;
  }
};

export const deleteItemSize = async (name) => {
  try {
    const response = await api.delete(`/items/sizes/${name}`);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error deleting item size:', error);
    throw error;
  }
};

// Item Brims
export const getAllItemBrims = async () => {
  try {
    const response = await api.get('/items/brims');
    return response.data;
  } catch (error) {
    console.error('Error fetching item brims:', error);
    throw error;
  }
};

export const getItemBrim = async (name) => {
  try {
    const response = await api.get(`/items/brims/${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching item brim:', error);
    throw error;
  }
};

export const createItemBrim = async (brimData) => {
  try {
    const response = await api.post('/items/brims', brimData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error creating item brim:', error);
    throw error;
  }
};

export const updateItemBrim = async (name, brimData) => {
  try {
    const response = await api.put(`/items/brims/${name}`, brimData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error updating item brim:', error);
    throw error;
  }
};

export const deleteItemBrim = async (name) => {
  try {
    const response = await api.delete(`/items/brims/${name}`);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error deleting item brim:', error);
    throw error;
  }
};

// Item Barcodes
export const getItemBarcode = async (id) => {
  try {
    const response = await api.get(`/items/barcode/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching item barcode:', error);
    throw error;
  }
};

export const updateItemBarcode = async (id, barcodeData) => {
  try {
    const response = await api.put(`/items/barcode/${id}`, barcodeData);
    return response.data;
  } catch (error) {
    console.error('Error updating item barcode:', error);
    throw error;
  }
};

// Helper function to generate barcode and alternative lookups
export const generateBarcodeFormats = (components) => {
  const { type, shape, size, brim } = components;
  const parts = [type, shape, size, brim].filter(Boolean);
  
  // Generate main barcode
  const mainBarcode = parts.join('-');
  
  // Generate alternative lookups with different combinations
  const alternatives = new Set();
  
  // Add variations with different separators
  alternatives.add(parts.join('-'));
  alternatives.add(parts.join('_'));
  alternatives.add(parts.join(' '));
  alternatives.add(parts.join(''));
  
  // Add variations with different cases
  alternatives.add(parts.map(p => p?.toUpperCase()).join('-'));
  alternatives.add(parts.map(p => p?.toLowerCase()).join('-'));
  alternatives.add(parts.map(p => p?.toUpperCase()).join(''));
  alternatives.add(parts.map(p => p?.toLowerCase()).join(''));
  
  return {
    barcode: mainBarcode,
    alternative_lookups: Array.from(alternatives)
  };
};

// Items with Attributes
export const getAllItems = async () => {
  try {
    const response = await api.get('/items/all');
    return response.data;
  } catch (error) {
    console.error('Error fetching all items:', error);
    throw error;
  }
};

export const updateItemAttributes = async (id, attributeData) => {
  try {
    const response = await api.put(`/items/${id}/attributes`, attributeData);
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    console.error('Error updating item attributes:', error);
    throw error;
  }
};

// Helper function to generate barcode format based on item attributes
export const generateBarcodeFromAttributes = (item) => {
  const mainBarcode = `${item.type_barcode || ''}${item.size_barcode || ''}${item.brim_barcode || ''}${item.shape_barcode || ''}`;
  const itemName = `${item.type_name || ''}-${item.size_name || ''}-${item.brim_name || ''}-${item.shape_name || ''}`;
  
  const alternatives = new Set();
  alternatives.add(mainBarcode);
  alternatives.add(itemName);
  alternatives.add(itemName.replace(/-/g, ''));
  alternatives.add(itemName.toLowerCase());
  alternatives.add(itemName.toUpperCase());
  
  return {
    barcode: mainBarcode,
    alternative_lookups: Array.from(alternatives)
  };
};

// Add these new functions

// Preview items that would be created for a new attribute
export const previewNewAttributeItems = async (attributeType, attributeData) => {
  try {
    // Remove 'item ' prefix if it exists and ensure proper endpoint format
    const endpoint = attributeType.replace('item ', '').toLowerCase();
    const response = await api.post(`/items/${endpoint}/preview`, attributeData);
    return {
      ...response.data,
      attributeToCreate: response.data.attribute,
      items: response.data.data
    };
  } catch (error) {
    handleApiError(error, 'Error previewing new items');
  }
};

// Create items for new attribute
export const createItemsForAttribute = async (attributeType, data) => {
  try {
    // Remove 'item ' prefix if it exists and ensure proper endpoint format
    const endpoint = attributeType.replace('item ', '').toLowerCase();
    
    // Ensure we have the required data
    if (!data.name || !data.barcode || !data.items) {
      throw new Error('Missing required data for creating items');
    }
    
    const response = await api.post(
      `/items/${endpoint}/create-items`,
      {
        name: data.name,
        barcode: data.barcode,
        items: data.items
      },
      {
        timeout: 300000 // 5 minutes timeout for large batches
      }
    );
    
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error creating items');
  }
};

// Preview barcode updates
export const previewBarcodeUpdates = async (attributeType, name, data) => {
  try {
    // Convert attributeType to the correct format (remove spaces and make singular)
    const formattedType = attributeType.replace(/\s+/g, '_').replace(/s$/, '');
    
    const response = await api.post(
      `/items/${formattedType}s/${encodeURIComponent(name)}/preview-barcode-updates`,
      data
    );
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error previewing barcode updates');
  }
};

// Update barcodes for all affected items
export const updateAffectedItemBarcodes = async (attributeType, name, data) => {
  try {
    const formattedType = attributeType.replace(/\s+/g, '_').replace(/s$/, '');
    
    const response = await api.post(
      `/items/${formattedType}s/${encodeURIComponent(name)}/update-barcodes`,
      data
    );
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating barcodes');
  }
};

export const updateTypeActiveStatus = async (name, active) => {
  try {
    const response = await api.put(`/items/types/${encodeURIComponent(name)}/active-status`, { active });
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to update type status');
    }
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating type active status');
  }
};

export const updateShapeActiveStatus = async (name, active) => {
  try {
    const response = await api.put(`/items/shapes/${encodeURIComponent(name)}/active-status`, { active });
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to update shape status');
    }
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating shape active status');
  }
};

export const updateSizeActiveStatus = async (name, active) => {
  try {
    const response = await api.put(`/items/sizes/${encodeURIComponent(name)}/active-status`, { active });
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to update size status');
    }
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating size active status');
  }
};

export const updateBrimActiveStatus = async (name, active) => {
  try {
    const response = await api.put(`/items/brims/${encodeURIComponent(name)}/active-status`, { active });
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to update brim status');
    }
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating brim active status');
  }
};

export const updateItemActiveStatus = async (id, active) => {
  try {
    const response = await api.put(`/items/${id}/active-status`, { active });
    ItemsCacheService.clearCache();
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating item active status');
  }
};

export const getAllCachedItems = async () => {
  try {
    const response = await api.get('/items/cached');
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error fetching cached items');
  }
};

export default api; 