import React, { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function SpreadsheetExportableV2({ data = {}, title }) {
  // State for active type tab
  const [activeType, setActiveType] = useState(null);

  // Extract all types, sizes, brims, and shapes from the data
  const {types, sizes, brims, shapes} = useMemo(() => {
    // Ensure data is an object
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
      console.error('Invalid data format received:', data);
      return { types: [], sizes: [], brims: [], shapes: [] };
    }

    const typesSet = new Set();
    const sizesSet = new Set();
    const brimsSet = new Set();
    const shapesSet = new Set();

    try {
      // Traverse the data structure to collect all unique values
      Object.entries(data).forEach(([type, typeData]) => {
        if (typeData && typeof typeData === 'object') {
          typesSet.add(type);
          Object.entries(typeData).forEach(([size, sizeData]) => {
            if (sizeData && typeof sizeData === 'object') {
              sizesSet.add(size);
              Object.entries(sizeData).forEach(([brim, brimData]) => {
                if (brimData && typeof brimData === 'object') {
                  brimsSet.add(brim);
                  Object.keys(brimData).forEach(shape => shapesSet.add(shape));
                }
              });
            }
          });
        }
      });
    } catch (error) {
      console.error('Error processing spreadsheet data:', error);
    }

    return {
      types: Array.from(typesSet),
      sizes: Array.from(sizesSet).map(size => ({
        id: size,
        label: size
      })),
      brims: Array.from(brimsSet).sort((a, b) => parseInt(a) - parseInt(b)).map(brim => ({
        id: brim,
        label: brim
      })),
      shapes: Array.from(shapesSet)
    };
  }, [data]);

  // Set initial active type
  React.useEffect(() => {
    if (types.length && !activeType) {
      setActiveType(types[0]);
    }
  }, [types]);

  // Generate column definitions
  const columnDefs = useMemo(() => {
    if (!brims.length) return [];

    const columns = [
      {
        headerName: 'Size',
        field: 'sizeLabel',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'size-column',
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressResize: true,
        width: 80,
        headerClass: 'size-header'
      }
    ];

    // Create column groups for each brim and its shapes
    brims.forEach(brim => {
      const groupChildren = shapes.map(shape => ({
        headerName: shape,
        field: `${brim.id}-${shape}`,
        cellClass: 'data-cell',
        headerClass: 'data-header',
        suppressMovable: true,
        suppressResize: true,
        width: 80,
        valueFormatter: params => {
          return params.value === undefined || params.value === null ? '' : params.value;
        }
      }));

      columns.push({
        headerName: brim.label,
        marryChildren: true,
        suppressMovable: true,
        children: groupChildren,
        headerClass: 'brim-group-header'
      });
    });

    return columns;
  }, [brims, shapes]);

  // Prepare row data for the active type
  const rowData = useMemo(() => {
    if (!data || !activeType || !sizes.length || !brims.length) return [];

    const typeData = data[activeType] || {};
    return sizes.map(size => {
      const row = { sizeLabel: size.label };
      brims.forEach(brim => {
        const brimData = typeData[size.id]?.[brim.id] || {};
        shapes.forEach(shape => {
          row[`${brim.id}-${shape}`] = brimData[shape] || 0;
        });
      });
      return row;
    });
  }, [data, activeType, sizes, brims, shapes]);

  const gridOptions = {
    defaultColDef: {
      resizable: false,
      sortable: false,
      filter: false,
      suppressMovable: true,
      valueFormatter: params => {
        return params.value === undefined || params.value === null ? '' : params.value;
      }
    },
    suppressHorizontalScroll: false,
    columnDefs: columnDefs,
    rowData: rowData,
    headerHeight: 40,
    groupHeaderHeight: 45,
    rowHeight: 35,
    suppressMenuHide: true
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="inventory-container">
      {title && <h1 style={{ textAlign: 'center', margin: '20px 0' }}>{title}</h1>}
      
      {/* Type Tabs */}
      <div className="type-tabs">
        {types.map(type => (
          <button
            key={type}
            className={`type-tab ${activeType === type ? 'active' : ''}`}
            onClick={() => setActiveType(type)}
          >
            {type}
          </button>
        ))}
      </div>

      <div 
        className="ag-theme-quartz custom-grid" 
        style={{ width: '100%', height: '600px' }}
      >
        <AgGridReact {...gridOptions} />
      </div>
      
      <style>
        {`
          .type-tabs {
            display: flex;
            gap: 8px;
            margin-bottom: 16px;
            padding: 0 16px;
          }

          .type-tab {
            padding: 8px 16px;
            border: 1px solid #dee2e6;
            border-radius: 4px;
            background: white;
            cursor: pointer;
            transition: all 0.2s;
          }

          .type-tab:hover {
            background: #f8f9fa;
          }

          .type-tab.active {
            background: #e9ecef;
            border-color: #adb5bd;
            font-weight: 600;
          }

          .custom-grid {
            --ag-header-height: 40px;
            --ag-header-foreground-color: #181D1F;
            --ag-header-background-color: #f8f9fa;
            --ag-header-cell-hover-background-color: #f8f9fa;
            --ag-header-cell-moving-background-color: #f8f9fa;
            --ag-borders: solid 1px;
            --ag-border-color: #181D1F26;
            --ag-cell-horizontal-border: solid #181D1F26;
            --ag-row-border-color: #181D1F26;
          }

          .ag-theme-quartz .ag-header-group-cell {
            border-right: 2px solid #181D1F40 !important;
          }

          .size-column {
            background-color: #f8f9fa;
            font-weight: 600;
            text-align: center;
            border-right: 2px solid #181D1F40 !important;
          }

          .data-cell {
            text-align: center;
          }

          .ag-header-cell {
            font-weight: 600;
            text-align: center;
          }

          .brim-group-header {
            background-color: #f1f3f5 !important;
            font-weight: 700;
            text-align: center;
            border-bottom: 2px solid #181D1F40;
          }

          .size-header {
            background-color: #f1f3f5 !important;
            font-weight: 700;
            border-bottom: 2px solid #181D1F40;
          }

          .ag-header-row {
            background-color: #f8f9fa;
          }
        `}
      </style>
    </div>
  );
}

export default SpreadsheetExportableV2; 