import React from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, Dialog, DialogContent } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const ProcessingAnimation = ({ open, checkId, message = 'Processing Your Data' }) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(8px)',
          overflow: 'hidden',
          borderRadius: '16px',
        }
      }}
    >
      <DialogContent 
        sx={{ 
          textAlign: 'center', 
          py: 6,
          position: 'relative',
          cursor: 'pointer',
          userSelect: 'none',
          overflow: 'hidden'
        }}
      >
        {/* Animated background gradient */}
        <Box
          component={motion.div}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(45deg, rgba(25,118,210,0.1), rgba(25,118,210,0.2), rgba(25,118,210,0.1))',
            filter: 'blur(40px)',
            zIndex: 0,
          }}
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'linear',
          }}
        />

        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              perspective: '1000px',
            }}
          >
            {/* 3D rotating cube */}
            <motion.div
              style={{
                width: 100,
                height: 100,
                position: 'relative',
                transformStyle: 'preserve-3d',
              }}
              animate={{
                rotateX: [0, 360],
                rotateY: [0, 360],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: 'linear',
              }}
            >
              {/* Cube faces */}
              {[...Array(6)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: 'rgba(25,118,210,0.1)',
                    border: '2px solid rgba(25,118,210,0.3)',
                    borderRadius: '8px',
                    transform: [
                      'rotateX(0deg) translateZ(50px)',
                      'rotateX(180deg) translateZ(50px)',
                      'rotateY(90deg) translateZ(50px)',
                      'rotateY(-90deg) translateZ(50px)',
                      'rotateX(90deg) translateZ(50px)',
                      'rotateX(-90deg) translateZ(50px)',
                    ][index],
                  }}
                />
              ))}

              {/* Center icon */}
              <motion.div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.7, 1, 0.7],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              >
                <CheckCircleIcon 
                  sx={{ 
                    fontSize: 40,
                    color: 'primary.main',
                    filter: 'drop-shadow(0 0 10px rgba(25,118,210,0.5))'
                  }} 
                />
              </motion.div>
            </motion.div>

            {/* Floating particles */}
            {[...Array(12)].map((_, i) => (
              <motion.div
                key={i}
                style={{
                  position: 'absolute',
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(25,118,210,0.2)',
                }}
                animate={{
                  y: [-20, -100],
                  x: Math.sin(i) * 30,
                  opacity: [0, 1, 0],
                  scale: [1, 1.5, 1],
                }}
                transition={{
                  duration: 2 + Math.random(),
                  repeat: Infinity,
                  delay: i * 0.1,
                  ease: "easeOut",
                }}
                initial={{
                  x: -50 + i * 10,
                  y: 100,
                }}
              />
            ))}
          </Box>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 2, 
                fontWeight: 'bold',
                background: 'linear-gradient(45deg, #1976d2, #42a5f5)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {message}
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{
                opacity: 0.8,
                maxWidth: 300,
                mx: 'auto',
              }}
            >
              Please wait while we process your data...
            </Typography>
          </motion.div>
        </Box>

        {/* Progress indicator */}
        <motion.div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: '4px',
            background: 'linear-gradient(90deg, #1976d2, #42a5f5)',
          }}
          animate={{
            width: ['0%', '100%'],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: 'linear',
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProcessingAnimation; 