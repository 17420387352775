import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MapPin, Package, Zap, BarChart2, Bell, MessageSquare } from 'lucide-react';
import { getTotalLocations, getTotalOptimization, getRecentUpdates, getInventoryHistory } from '../services/api';
import { formatDistanceToNow } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import FullScreenLayout from './layouts/FullScreenLayout';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
    borderColor: theme.palette.primary.main,
  },
  cursor: 'pointer',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,
  marginBottom: theme.spacing(2.5),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: theme.spacing(0, 1),
  color: theme.palette.text.primary,
}));

const CardContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  fontSize: '2.5rem',
  textAlign: 'center',
  padding: theme.spacing(2, 0),
  borderBottom: `2px solid ${theme.palette.divider}`,
}));

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

function Home() {
  const navigate = useNavigate();
  const [totalLocations, setTotalLocations] = useState(null);
  const [totalInventory, setTotalInventory] = useState(null);
  const [optimizationLevel, setOptimizationLevel] = useState(null);
  const [recentUpdates, setRecentUpdates] = useState([]);
  const [inventoryHistory, setInventoryHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTotalLocationsAndInventory();
    fetchOptimizationLevel();
    fetchRecentUpdates();
    fetchInventoryHistory();
  }, []);

  const fetchTotalLocationsAndInventory = async () => {
    try {
      const data = await getTotalLocations();
      setTotalLocations(data.totalLocations);
      setTotalInventory(data.totalInventory);
    } catch (error) {
      console.error('Error fetching data:', error);
      setTotalLocations(0);
      setTotalInventory(0);
    } finally {
      setLoading(false);
    }
  };

  const fetchOptimizationLevel = async () => {
    try {
      const data = await getTotalOptimization();
      setOptimizationLevel(data.optimizationLevel);
    } catch (error) {
      console.error('Error fetching optimization level:', error);
      setOptimizationLevel('0%');
    } finally {
      setLoading(false);
    }
  };

  const fetchRecentUpdates = async () => {
    try {
      const data = await getRecentUpdates();
      setRecentUpdates(data);
    } catch (error) {
      console.error('Error fetching recent updates:', error);
      setRecentUpdates([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchInventoryHistory = async () => {
    try {
      const data = await getInventoryHistory();
      setInventoryHistory(data);
    } catch (error) {
      console.error('Error fetching inventory history:', error);
      setInventoryHistory([]);
    } finally {
      setLoading(false);
    }
  };

  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const inventoryChartOption = {
    title: { text: 'Total Inventory History' },
    tooltip: { trigger: 'axis' },
    xAxis: { 
      type: 'category', 
      data: inventoryHistory.map(item => item.date).sort((a, b) => new Date(a) - new Date(b))
    },
    yAxis: { type: 'value', name: 'Total Inventory' },
    series: [{
      data: inventoryHistory
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map(item => parseInt(item.total_inventory)),
      type: 'line',
      smooth: true
    }]
  };

  const handleCardClick = (route) => {
    navigate(route);
  };

  const renderHeader = () => (
    <Title variant="h4" component="h1" gutterBottom align="center">
      Inventory Management Dashboard
    </Title>
  );

  const renderMainContent = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <StyledCard onClick={() => handleCardClick('/locations')}>
          <CardTitle>
            <MapPin size={24} />
            Total Locations
          </CardTitle>
          <CardContent>
            <Typography variant="h3" align="center">
              {totalLocations !== null ? totalLocations : <CircularProgress size={24} />}
            </Typography>
          </CardContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledCard onClick={() => handleCardClick('/optimize')}>
          <CardTitle>
            <Package size={24} />
            Total Inventory
          </CardTitle>
          <CardContent>
            <Typography variant="h3" align="center">
              {totalInventory !== null ? totalInventory : <CircularProgress size={24} />}
            </Typography>
          </CardContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledCard>
          <CardTitle>
            <Zap size={24} />
            Optimization Level
          </CardTitle>
          <CardContent>
            <Typography variant="h3" align="center">
              {optimizationLevel !== null ? optimizationLevel : <CircularProgress size={24} />}
            </Typography>
          </CardContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={8}>
        <StyledCard>
          <CardTitle>
            <BarChart2 size={24} />
            Inventory Overview
          </CardTitle>
          <CardContent>
            {inventoryHistory.length > 0 ? (
              <ReactECharts option={inventoryChartOption} style={{ height: 300 }} />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height={300}>
                <CircularProgress />
              </Box>
            )}
          </CardContent>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledCard>
          <CardTitle>
            <Bell size={24} />
            Recent Updates
          </CardTitle>
          <CardContent>
            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
              {recentUpdates.length > 0 ? (
                recentUpdates.map((update) => (
                  <Box key={update.created_at} mb={2}>
                    <Typography variant="body2">{update.text}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatRelativeTime(update.created_at)}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography color="textSecondary">No recent updates available</Typography>
              )}
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>
    </Grid>
  );

  if (loading) return <CircularProgress />;

  return (
    <FullScreenLayout
      header={renderHeader()}
      mainPanel={
        <PageContainer>
          {renderMainContent()}
        </PageContainer>
      }
      hasNavigation={true}
    />
  );
}

export default Home;
