import api from './api';

export const shipmentPackingApi = {
  // Get shipment details with items for packing
  getShipmentForPacking: async (shipmentId) => {
    try {
      const response = await api.get(`/shipments/${shipmentId}/packing`);
      return response.data;
    } catch (error) {
      console.error('Error fetching shipment for packing:', error);
      throw new Error(error.response?.data?.error || 'Failed to fetch shipment details');
    }
  },

  // Create a new box for a shipment
  createBox: async (shipmentId, boxData) => {
    try {
      const response = await api.post(`/shipments/${shipmentId}/boxes`, boxData);
      return response.data;
    } catch (error) {
      console.error('Error creating box:', error);
      throw new Error(error.response?.data?.error || 'Failed to create box');
    }
  },

  // Add items to a box
  addItemsToBox: async (boxId, items) => {
    try {
      const response = await api.post(`/boxes/${boxId}/items`, { items });
      return response.data;
    } catch (error) {
      console.error('Error adding items to box:', error);
      throw new Error(error.response?.data?.error || 'Failed to add items to box');
    }
  },

  // Get all boxes for a shipment
  getShipmentBoxes: async (shipmentId) => {
    try {
      const response = await api.get(`/shipments/${shipmentId}/boxes`);
      return response.data;
    } catch (error) {
      console.error('Error fetching shipment boxes:', error);
      throw error;
    }
  },

  // Remove item from box
  removeItemFromBox: async (boxId, contentId) => {
    try {
      const response = await api.delete(`/boxes/${boxId}/items/${contentId}`);
      return response.data;
    } catch (error) {
      console.error('Error removing item from box:', error);
      throw error;
    }
  },

  // Complete box packing
  completeBoxPacking: async (boxId) => {
    try {
      const response = await api.post(`/boxes/${boxId}/complete`);
      return response.data;
    } catch (error) {
      console.error('Error completing box packing:', error);
      throw error;
    }
  },

  // Get warehouse shipments
  getWarehouseShipments: async (warehouseId) => {
    try {
      const response = await api.get(`/shipments/warehouse/${warehouseId}/undelivered`);
      
      // Ensure we're always returning an array and format the data
      const shipments = Array.isArray(response.data) ? response.data : [];
      
      return shipments.map(shipment => ({
        ...shipment,
        items_count: parseInt(shipment.items_count || '0'),
        destination_name: shipment.destination_name || 'N/A',
        created_at: new Date(shipment.created_at).toLocaleString()
      }));
    } catch (error) {
      console.error('Error fetching warehouse shipments:', error);
      throw new Error(`Failed to fetch warehouse shipments: ${error.message}`);
    }
  }
}; 