import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Stepper,
    Step,
    StepLabel,
    Button,
    Grid,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Chip,
    IconButton,
    Tooltip,
    Alert,
    Snackbar,
    LinearProgress,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Switch,
    FormControlLabel,
    InputAdornment,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {
    Add as AddIcon,
    Remove as RemoveIcon,
    Refresh as RefreshIcon,
    Description as DescriptionIcon,
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon,
    Search as SearchIcon,
    ViewColumn as ViewColumnIcon,
    Percent as PercentIcon,
    AutoAwesome as AutoAwesomeIcon,
    Numbers as NumbersIcon,
    Balance as BalanceIcon,
    Info as InfoIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getLocations } from '../services/api';
import {
    getAvailableInventory,
    createDistribution,
    getDistributions,
    updateDistributionStatus,
    getLocationsForDistribution,
    calculateDistributionPreview
} from '../services/inventory-distribution-api';
import Confetti from 'react-confetti';
import FullScreenLayout from './layouts/FullScreenLayout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer,
    PieChart, Pie, Cell, Sector,
    LineChart, Line,
    RadialBarChart, RadialBar,
} from 'recharts';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    border: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
    },
}));

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    border: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
    },
}));

const StyledDataTable = styled(DataTable)(({ theme }) => ({
    '& .p-datatable-header': {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0 0`,
    },
    '& .p-datatable-thead > tr > th': {
        background: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: theme.spacing(1.5),
        fontWeight: 600,
    },
    '& .p-datatable-tbody > tr': {
        background: theme.palette.background.paper,
        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
    '& .p-datatable-tbody > tr > td': {
        padding: theme.spacing(1.5),
    },
}));

const StatCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
    },
}));

const steps = ['Select Source', 'Choose Items', 'Set Destinations', 'Review & Create'];

const distributionMethods = [
    { 
        value: 'percentage', 
        label: 'Percentage Based',
        description: 'Distributes inventory based on specified percentages for each destination. Each destination must be assigned a percentage, and the total must equal 100%. Best when you want precise control over distribution ratios.',
        requiredInput: 'Percentage for each destination (must sum to 100%)',
        icon: <PercentIcon />
    },
    { 
        value: 'smart', 
        label: 'Smart Distribution',
        description: 'Automatically distributes inventory by prioritizing locations with zero inventory first, then allocates remaining items based on replenishment levels. Best for optimizing inventory levels across all locations.',
        requiredInput: 'None - distribution is calculated automatically based on current inventory levels',
        icon: <AutoAwesomeIcon />
    },
    { 
        value: 'zero-first', 
        label: 'Zero Inventory First',
        description: 'Prioritizes locations with zero inventory, distributing items evenly among them first. Any remaining items are then distributed evenly among other locations. Best for quickly addressing stock-outs.',
        requiredInput: 'None - distribution is calculated automatically based on current inventory levels',
        icon: <NumbersIcon />
    },
    { 
        value: 'replenish-level', 
        label: 'Replenish Level Based',
        description: 'Distributes inventory proportionally based on each location\'s replenishment level. Locations with higher replenishment levels receive proportionally more inventory. Best for maintaining relative stock levels.',
        requiredInput: 'None - distribution is calculated automatically based on replenishment levels',
        icon: <BalanceIcon />
    }
];

function InventoryDistribution() {
    const [activeStep, setActiveStep] = useState(0);
    const [locations, setLocations] = useState([]);
    const [selectedSource, setSelectedSource] = useState(null);
    const [distributionType, setDistributionType] = useState('excess');
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [distributions, setDistributions] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [loading, setLoading] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' },
        item_name: { value: null, matchMode: 'contains' },
        item_type: { value: null, matchMode: 'contains' },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showRecommended, setShowRecommended] = useState(true);
    const [selectedDestinations, setSelectedDestinations] = useState([]);
    const [expandedDestinations, setExpandedDestinations] = useState({});
    const [distributionMethod, setDistributionMethod] = useState('percentage');
    const [distributionPreview, setDistributionPreview] = useState(null);
    const [selectedProcessedItems, setSelectedProcessedItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchInitialData();
    }, [distributionType]);

    useEffect(() => {
        if (selectedProcessedItems.length > 0 && destinations.length > 0) {
            if (distributionMethod === 'percentage') {
                const preview = calculateDistributionPreview(selectedProcessedItems, destinations, distributionMethod);
                setDistributionPreview(preview);
            } else {
                setDistributionPreview(null);
            }
        } else {
            setDistributionPreview(null);
        }
    }, [selectedProcessedItems, destinations, distributionMethod]);

    const fetchInitialData = async () => {
        try {
            const [locationsData, distributionsData] = await Promise.all([
                getLocationsForDistribution(distributionType),
                getDistributions()
            ]);
            setLocations(locationsData);
            setDistributions(distributionsData);
        } catch (error) {
            console.error('Error fetching initial data:', error);
            setSnackbar({
                open: true,
                message: 'Failed to fetch initial data',
                severity: 'error'
            });
        }
    };

    const handleSourceSelect = async (locationId) => {
        setSelectedSource(locationId);
        try {
            setLoading(true);
            const items = await getAvailableInventory(locationId, distributionType);
            setAvailableItems(items);
            setSelectedItems([]);
            setActiveStep(1);
        } catch (error) {
            console.error('Error fetching available inventory:', error);
            setSnackbar({
                open: true,
                message: 'Failed to fetch available inventory',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleItemSelect = (item) => {
        const existingItem = selectedItems.find(i => i.itemId === item.item_id);
        if (existingItem) {
            setSelectedItems(selectedItems.filter(i => i.itemId !== item.item_id));
        } else {
            setSelectedItems([...selectedItems, {
                itemId: item.item_id,
                itemName: item.item_name,
                quantity: item.available_quantity,
                maxQuantity: item.available_quantity
            }]);
        }
    };

    const handleQuantityChange = (itemId, value) => {
        setSelectedItems(selectedItems.map(item => 
            item.itemId === itemId 
                ? { ...item, quantity: Math.min(Math.max(0, value), item.maxQuantity) }
                : item
        ));
    };

    const handleAddDestination = () => {
        setDestinations([...destinations, { locationId: '', percentage: 0 }]);
    };

    const handleRemoveDestination = (index) => {
        setDestinations(destinations.filter((_, i) => i !== index));
    };

    const handleDestinationChange = (index, field, value) => {
        const updatedDestinations = destinations.map((dest, i) => 
            i === index ? { ...dest, [field]: value } : dest
        );
        setDestinations(updatedDestinations);
        
        if (selectedProcessedItems.length > 0 && updatedDestinations.length > 0) {
            const preview = calculateDistributionPreview(selectedProcessedItems, updatedDestinations, distributionMethod);
            setDistributionPreview(preview);
        }
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleSelectAllMatchingItems = () => {
        const itemsWithNeeds = availableItems.filter(item => 
            item.destination_matches.some(dest => dest.needs_replenish)
        );
        
        const processedItems = itemsWithNeeds.map(item => ({
            itemId: item.item_id,
            itemName: item.item_name,
            quantity: parseInt(item.available_quantity),
            maxQuantity: parseInt(item.available_quantity),
            destinationMatches: item.destination_matches
        }));
        
        setSelectedItems(itemsWithNeeds);
        setSelectedProcessedItems(processedItems);
    };

    const handleSelectAllItems = () => {
        const processedItems = availableItems.map(item => ({
            itemId: item.item_id,
            itemName: item.item_name,
            quantity: parseInt(item.available_quantity),
            maxQuantity: parseInt(item.available_quantity),
            destinationMatches: item.destination_matches
        }));
        
        setSelectedItems(availableItems);
        setSelectedProcessedItems(processedItems);
    };

    const handleSelectionChange = (e) => {
        const selectedRows = e.value;
        setSelectedItems(selectedRows);
        
        const processedItems = selectedRows.map(item => ({
            itemId: item.item_id,
            itemName: item.item_name,
            quantity: parseInt(item.available_quantity),
            maxQuantity: parseInt(item.available_quantity),
            destinationMatches: item.destination_matches
        }));
        setSelectedProcessedItems(processedItems);
    };

    const getRecommendedDestinations = () => {
        if (!selectedProcessedItems.length) return [];
        
        const destinationNeeds = {};
        
        selectedProcessedItems.forEach(item => {
            const matches = availableItems.find(ai => ai.item_id === item.itemId)?.destination_matches || [];
            matches.forEach(dest => {
                if (dest.needs_replenish) {
                    if (!destinationNeeds[dest.destination_id]) {
                        destinationNeeds[dest.destination_id] = {
                            locationId: dest.destination_id,
                            locationName: dest.destination_name,
                            items: []
                        };
                    }
                    const neededQuantity = dest.replenish_level - dest.inventory_level;
                    destinationNeeds[dest.destination_id].items.push({
                        itemId: item.itemId,
                        itemName: item.itemName,
                        neededQuantity,
                        availableQuantity: item.quantity
                    });
                }
            });
        });
        
        return Object.values(destinationNeeds);
    };

    const getDestinationSummary = (dest) => {
        const totalNeeded = dest.items.reduce((sum, item) => sum + item.neededQuantity, 0);
        const totalAvailable = dest.items.reduce((sum, item) => sum + item.availableQuantity, 0);
        const itemCount = dest.items.length;
        
        return {
            totalNeeded,
            totalAvailable,
            itemCount
        };
    };

    const renderHeader = () => {
        return (
            <Box className="flex justify-content-between align-items-center">
                <h5 className="m-0">Available Items</h5>
                <span className="p-input-icon-left">
                    <SearchIcon />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Search items..."
                    />
                </span>
            </Box>
        );
    };

    const handleCreateDistribution = async () => {
        try {
            setLoading(true);
            const response = await createDistribution({
                sourceLocationId: selectedSource,
                destinations,
                items: selectedProcessedItems,
                distributionType,
                distributionMethod
            });
            
            setSnackbar({
                open: true,
                message: 'Distribution created successfully',
                severity: 'success'
            });
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 5000);
            resetForm();
        } catch (error) {
            console.error('Error creating distribution:', error);
            setSnackbar({
                open: true,
                message: error.response?.data?.error || 'Failed to create distribution',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateStatus = async (distributionId, newStatus) => {
        try {
            await updateDistributionStatus(distributionId, newStatus);
            await fetchInitialData();
            setSnackbar({
                open: true,
                message: 'Status updated successfully',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error updating status:', error);
            setSnackbar({
                open: true,
                message: 'Failed to update status',
                severity: 'error'
            });
        }
    };

    const resetForm = () => {
        setActiveStep(0);
        setSelectedSource(null);
        setDistributionType('excess');
        setAvailableItems([]);
        setSelectedItems([]);
        setDestinations([]);
    };

    const renderSourceLocationCard = (location) => (
        <Grid item xs={12} sm={6} md={4} key={location.db_location_id}>
            <StyledCard>
                <CardContent>
                    <Typography variant="h6" gutterBottom>{location.name}</Typography>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body2" color="textSecondary">
                            Total Items: {location.total_items}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Total Inventory: {location.total_inventory}
                        </Typography>
                        <Typography variant="body2" color={location.excess_inventory > 0 ? "success.main" : "text.secondary"}>
                            Excess Inventory: {location.excess_inventory}
                        </Typography>
                        <LinearProgress 
                            variant="determinate" 
                            value={100 - location.replenish_needed_percentage}
                            color={location.replenish_needed_percentage > 50 ? "error" : "primary"}
                            sx={{ mt: 1, mb: 1 }}
                        />
                        <Typography variant="body2" color={location.replenish_needed_percentage > 50 ? "error.main" : "text.secondary"}>
                            {location.items_below_replenish} items below replenish level ({location.replenish_needed_percentage}%)
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleSourceSelect(location.db_location_id)}
                        disabled={distributionType === 'excess' && location.excess_inventory === 0}
                    >
                        Select as Source
                    </Button>
                </CardContent>
            </StyledCard>
        </Grid>
    );

    const renderAvailableItemsTable = () => (
        <Box>
            <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSelectAllMatchingItems}
                    startIcon={<AddIcon />}
                >
                    Select All Items with Needs
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSelectAllItems}
                    startIcon={<AddIcon />}
                >
                    Select All Items
                </Button>
            </Box>
            <DataTable
                value={availableItems}
                paginator
                rows={10}
                filters={filters}
                globalFilterFields={['item_name', 'item_type']}
                header={renderHeader}
                emptyMessage="No items available for distribution"
                className="p-datatable-sm"
                selection={selectedItems}
                onSelectionChange={handleSelectionChange}
                dataKey="item_id"
                selectionMode="multiple"
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                <Column field="item_name" header="Item" sortable />
                <Column field="item_type" header="Type" sortable />
                <Column field="available_quantity" header="Available Qty" sortable 
                    body={(rowData) => (
                        <Typography>
                            {rowData.available_quantity}
                            {rowData.destination_matches.some(dest => dest.needs_replenish) && (
                                <Chip
                                    size="small"
                                    label="Needed"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                />
                            )}
                        </Typography>
                    )}
                />
                <Column header="Destinations in Need" 
                    body={(rowData) => {
                        const needyDests = rowData.destination_matches.filter(dest => dest.needs_replenish);
                        return needyDests.map(dest => (
                            <Chip
                                key={dest.destination_id}
                                label={`${dest.destination_name} (${dest.replenish_level - dest.inventory_level} needed)`}
                                size="small"
                                sx={{ mr: 0.5, mb: 0.5 }}
                            />
                        ));
                    }}
                />
            </DataTable>
        </Box>
    );

    const renderDistributionMethodSelect = () => (
        <Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Distribution Method</InputLabel>
                <Select
                    value={distributionMethod}
                    onChange={(e) => setDistributionMethod(e.target.value)}
                    label="Distribution Method"
                    startAdornment={
                        distributionMethods.find(m => m.value === distributionMethod)?.icon
                    }
                >
                    {distributionMethods.map(method => (
                        <MenuItem key={method.value} value={method.value}>
                            <ListItemIcon>
                                {method.icon}
                            </ListItemIcon>
                            <ListItemText 
                                primary={method.label}
                                secondary={
                                    <Tooltip 
                                        title={
                                            <Box>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    {method.description}
                                                </Typography>
                                                <Typography variant="body2" color="info.light">
                                                    Required Input: {method.requiredInput}
                                                </Typography>
                                            </Box>
                                        }
                                        placement="right"
                                    >
                                        <IconButton size="small" sx={{ ml: 1 }}>
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Paper sx={{ p: 2, mb: 2, bgcolor: 'background.default' }}>
                <Typography variant="subtitle2" gutterBottom>
                    {distributionMethods.find(m => m.value === distributionMethod)?.label} Distribution
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {distributionMethods.find(m => m.value === distributionMethod)?.description}
                </Typography>
                <Typography variant="body2" color="primary">
                    Required Input: {distributionMethods.find(m => m.value === distributionMethod)?.requiredInput}
                </Typography>
            </Paper>

            {distributionMethod === 'percentage' && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    <Typography variant="body2">
                        Please ensure the total percentage across all destinations equals 100%. 
                        For items with odd quantities, the first destination will receive any remaining units 
                        to ensure fair distribution.
                    </Typography>
                </Alert>
            )}
        </Box>
    );

    const renderDistributionPreview = () => {
        if (!selectedProcessedItems?.length || !destinations?.length || !distributionPreview) {
            return null;
        }

        // Filter out destinations without locationId or percentage
        const validDestinations = destinations.filter(d => d.locationId && d.percentage);

        return (
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Distribution Preview
                </Typography>
                <StyledDataTable
                    value={selectedProcessedItems}
                    paginator
                    rows={10}
                    stripedRows
                    showGridlines
                    className="p-datatable-sm"
                >
                    <Column 
                        field="itemName" 
                        header="Item" 
                        sortable 
                    />
                    <Column 
                        field="quantity" 
                        header="Total Quantity" 
                        sortable 
                        body={(rowData) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography>{rowData.quantity}</Typography>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={(rowData.quantity / rowData.maxQuantity) * 100}
                                    sx={{ width: 100 }}
                                />
                            </Box>
                        )}
                    />
                    {validDestinations.map(dest => {
                        const locationName = locations.find(loc => loc.db_location_id === dest.locationId)?.name || 'Unknown';
                        return (
                            <Column
                                key={dest.locationId}
                                header={`${locationName} (${dest.percentage}%)`}
                                body={(rowData) => {
                                    const itemId = rowData.itemId || rowData.item_id;
                                    const quantity = distributionPreview[itemId]?.[dest.locationId] || 0;
                                    const percentage = rowData.quantity > 0 
                                        ? ((quantity / rowData.quantity) * 100).toFixed(1) 
                                        : 0;
                                    return (
                                        <Box>
                                            <Typography variant="body1">
                                                {quantity} units
                                                <Typography component="span" color="textSecondary">
                                                    {' '}({percentage}%)
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    );
                                }}
                            />
                        );
                    })}
                </StyledDataTable>
            </Box>
        );
    };

    const calculateDistributionStats = (items, destinations) => {
        if (!items || !destinations || !distributionPreview) {
            console.warn('Missing required data for distribution stats calculation');
            return {
                totalItems: 0,
                totalQuantity: 0,
                byDestination: {},
                byItemType: {},
                percentageByDestination: [],
                itemTypeDistribution: [],
            };
        }

        const stats = {
            totalItems: items.length,
            totalQuantity: items.reduce((sum, item) => sum + parseInt(item?.quantity || item?.available_quantity || 0), 0),
            byDestination: {},
            byItemType: {},
            percentageByDestination: [],
            itemTypeDistribution: [],
        };

        // Initialize destination stats with location names
        const validDestinations = destinations.filter(d => d.locationId && d.percentage);
        validDestinations.forEach(dest => {
            if (dest && dest.locationId) {
                const locationName = locations.find(loc => loc.db_location_id === dest.locationId)?.name || 'Unknown';
                stats.byDestination[dest.locationId] = {
                    locationName,
                    quantity: 0,
                    percentage: dest.percentage || 0,
                    byItemType: {}
                };
            }
        });

        // Process items and build detailed type distribution
        items.forEach(item => {
            const itemId = item.itemId || item.item_id;
            const itemType = item.item_type || 'Uncategorized';
            const itemQuantity = parseInt(item.quantity || item.available_quantity);

            // Initialize item type if not exists
            if (!stats.byItemType[itemType]) {
                stats.byItemType[itemType] = {
                    totalQuantity: 0,
                    items: 0,
                    byDestination: {}
                };
                
                validDestinations.forEach(dest => {
                    stats.byItemType[itemType].byDestination[dest.locationId] = {
                        locationName: locations.find(loc => loc.db_location_id === dest.locationId)?.name || 'Unknown',
                        quantity: 0,
                        percentage: 0
                    };
                });
            }

            // Update item type stats
            stats.byItemType[itemType].items += 1;
            stats.byItemType[itemType].totalQuantity += itemQuantity;

            // Update quantities by destination using preview data
            if (distributionPreview[itemId]) {
                validDestinations.forEach(dest => {
                    const quantity = distributionPreview[itemId][dest.locationId] || 0;
                    
                    // Update destination totals
                    stats.byDestination[dest.locationId].quantity += quantity;
                    
                    // Update item type distribution by destination
                    stats.byItemType[itemType].byDestination[dest.locationId].quantity += quantity;
                });
            }
        });

        // Calculate percentages
        Object.keys(stats.byItemType).forEach(itemType => {
            const typeStats = stats.byItemType[itemType];
            Object.keys(typeStats.byDestination).forEach(destId => {
                const destStats = typeStats.byDestination[destId];
                destStats.percentage = typeStats.totalQuantity > 0 
                    ? (destStats.quantity / typeStats.totalQuantity) * 100 
                    : 0;
            });
        });

        // Convert to arrays for charts
        stats.percentageByDestination = Object.entries(stats.byDestination).map(([locationId, data]) => ({
            name: data.locationName,
            value: stats.totalQuantity > 0 ? (data.quantity / stats.totalQuantity) * 100 : 0,
            quantity: data.quantity,
        }));

        // Create item type distribution data
        stats.itemTypeDistribution = Object.entries(stats.byItemType).map(([itemType, data]) => {
            const destinationData = Object.entries(data.byDestination).map(([destId, destData]) => ({
                locationName: destData.locationName,
                quantity: destData.quantity,
                percentage: destData.percentage
            }));

            return {
                itemType,
                totalQuantity: data.totalQuantity,
                itemCount: data.items,
                destinations: destinationData
            };
        });

        return stats;
    };

    const renderDistributionAnalytics = () => {
        if (!selectedItems?.length || !destinations?.length || !distributionPreview) {
            return null;
        }

        const stats = calculateDistributionStats(selectedItems, destinations);
        if (!stats) return null;

        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

        return (
            <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                    Distribution Analytics
                </Typography>
                
                {/* Summary Statistics */}
                <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatCard>
                            <Typography color="textSecondary" gutterBottom>
                                Total Items
                            </Typography>
                            <Typography variant="h4">
                                {stats.totalItems}
                            </Typography>
                        </StatCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatCard>
                            <Typography color="textSecondary" gutterBottom>
                                Total Quantity
                            </Typography>
                            <Typography variant="h4">
                                {stats.totalQuantity}
                            </Typography>
                        </StatCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatCard>
                            <Typography color="textSecondary" gutterBottom>
                                Destinations
                            </Typography>
                            <Typography variant="h4">
                                {destinations.length}
                            </Typography>
                        </StatCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <StatCard>
                            <Typography color="textSecondary" gutterBottom>
                                Item Types
                            </Typography>
                            <Typography variant="h4">
                                {Object.keys(stats.byItemType).length}
                            </Typography>
                        </StatCard>
                    </Grid>
                </Grid>

                {/* Distribution by Type */}
                <StyledPaper sx={{ mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Distribution by Item Type
                    </Typography>
                    <StyledDataTable
                        value={stats.itemTypeDistribution}
                        paginator
                        rows={5}
                        stripedRows
                        showGridlines
                        className="p-datatable-sm"
                        sortField="totalQuantity"
                        sortOrder={-1}
                    >
                        <Column 
                            field="itemType" 
                            header="Item Type" 
                            sortable 
                            body={(rowData) => (
                                <Box>
                                    <Typography variant="subtitle2">
                                        {rowData.itemType}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {rowData.itemCount} items, {rowData.totalQuantity} units total
                                    </Typography>
                                </Box>
                            )}
                        />
                        {destinations.map(dest => {
                            const locationName = locations.find(loc => loc.db_location_id === dest.locationId)?.name || 'Unknown';
                            return (
                                <Column
                                    key={dest.locationId}
                                    header={locationName}
                                    sortable
                                    body={(rowData) => {
                                        const destData = rowData.destinations.find(d => d.locationName === locationName);
                                        return (
                                            <Box>
                                                <Typography variant="body2">
                                                    {destData?.quantity || 0} units
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    ({(destData?.percentage || 0).toFixed(1)}%)
                                                </Typography>
                                            </Box>
                                        );
                                    }}
                                />
                            );
                        })}
                    </StyledDataTable>
                </StyledPaper>

                {/* Distribution by Location */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <StyledPaper>
                            <Typography variant="h6" gutterBottom>
                                Distribution by Location
                            </Typography>
                            <Box sx={{ height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={stats.percentageByDestination}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={({ name, value }) => `${name} (${value.toFixed(1)}%)`}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {stats.percentageByDestination.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Box>
                        </StyledPaper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <StyledPaper>
                            <Typography variant="h6" gutterBottom>
                                Location Distribution Details
                            </Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Location</TableCell>
                                            <TableCell align="right">Total Quantity</TableCell>
                                            <TableCell align="right">Distribution %</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stats.percentageByDestination.map((location) => (
                                            <TableRow key={location.name}>
                                                <TableCell>{location.name}</TableCell>
                                                <TableCell align="right">{location.quantity}</TableCell>
                                                <TableCell align="right">{location.value.toFixed(1)}%</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </StyledPaper>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const renderStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={distributionType === 'all'}
                                        onChange={(e) => {
                                            const newType = e.target.checked ? 'all' : 'excess';
                                            setDistributionType(newType);
                                            setSelectedSource(null);
                                            setSelectedItems([]);
                                            setAvailableItems([]);
                                        }}
                                    />
                                }
                                label="Include All Inventory (Not Just Excess)"
                            />
                        </Grid>
                        {locations.map(location => renderSourceLocationCard(location))}
                    </Grid>
                );

            case 1:
                return renderAvailableItemsTable();

            case 2:
                return (
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {renderDistributionMethodSelect()}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showRecommended}
                                            onChange={(e) => setShowRecommended(e.target.checked)}
                                        />
                                    }
                                    label="Show Recommended Destinations"
                                />
                            </Grid>
                        </Grid>
                        
                        {showRecommended && getRecommendedDestinations().length > 0 && (
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Recommended Destinations
                                </Typography>
                                <Grid container spacing={2}>
                                    {getRecommendedDestinations().map((dest) => {
                                        const summary = getDestinationSummary(dest);
                                        const isExpanded = expandedDestinations[dest.locationId];
                                        
                                        return (
                                            <Grid item xs={12} md={6} key={dest.locationId}>
                                                <StyledCard>
                                                    <CardContent>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                                            <Typography variant="h6">
                                                                {dest.locationName}
                                                            </Typography>
                                                            <Button
                                                                size="small"
                                                                onClick={() => setExpandedDestinations(prev => ({
                                                                    ...prev,
                                                                    [dest.locationId]: !prev[dest.locationId]
                                                                }))}
                                                            >
                                                                {isExpanded ? 'Hide Details' : 'Show Details'}
                                                            </Button>
                                                        </Box>
                                                        
                                                        <Box sx={{ mb: 2 }}>
                                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                                                {summary.itemCount} items need replenishment
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                                                Total needed: {summary.totalNeeded}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                                                Total available: {summary.totalAvailable}
                                                            </Typography>
                                                        </Box>
                                                        
                                                        {isExpanded && (
                                                            <Box sx={{ mb: 2 }}>
                                                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                                                    Item Details:
                                                                </Typography>
                                                                {dest.items.map((item) => (
                                                                    <Typography key={item.itemId} variant="body2" sx={{ mb: 1 }}>
                                                                        {item.itemName}: {item.neededQuantity} needed 
                                                                        (max available: {item.availableQuantity})
                                                                    </Typography>
                                                                ))}
                                                            </Box>
                                                        )}
                                                        
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            onClick={() => {
                                                                if (!destinations.find(d => d.locationId === dest.locationId)) {
                                                                    setDestinations([...destinations, {
                                                                        locationId: dest.locationId,
                                                                        percentage: 0
                                                                    }]);
                                                                }
                                                            }}
                                                            disabled={destinations.some(d => d.locationId === dest.locationId)}
                                                        >
                                                            Add Destination
                                                        </Button>
                                                    </CardContent>
                                                </StyledCard>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        )}
                        
                        <Box sx={{ mb: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleAddDestination}
                                startIcon={<AddIcon />}
                            >
                                Add Destination
                            </Button>
                        </Box>
                        
                        {destinations.map((dest, index) => (
                            <Box key={index} sx={{ mb: 2 }}>
                                {renderDestinationInputs(dest, index)}
                            </Box>
                        ))}

                        {renderDistributionPreview()}
                        
                        {renderDistributionAnalytics()}
                    </Box>
                );

            case 3:
                return (
                    <StyledPaper>
                        <Typography variant="h6" gutterBottom>Review Distribution</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Source Location</Typography>
                                <Typography>
                                    {locations.find(loc => loc.db_location_id === selectedSource)?.name || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Selected Items</Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                                <TableCell align="right">Available</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedItems.map(item => {
                                                const availableItem = availableItems.find(ai => ai.item_id === (item.itemId || item.item_id));
                                                return (
                                                    <TableRow key={item.itemId || item.item_id}>
                                                        <TableCell>{item.itemName || availableItem?.item_name}</TableCell>
                                                        <TableCell>{availableItem?.item_type}</TableCell>
                                                        <TableCell align="right">{item.quantity || item.available_quantity}</TableCell>
                                                        <TableCell align="right">{availableItem?.available_quantity}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Destinations</Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Location</TableCell>
                                                <TableCell align="right">Percentage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {destinations.map((dest, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {locations.find(loc => loc.db_location_id === dest.locationId)?.name || 'N/A'}
                                                    </TableCell>
                                                    <TableCell align="right">{dest.percentage}%</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                );

            default:
                return null;
        }
    };

    const renderDestinationInputs = (dest, index) => (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={distributionMethod === 'percentage' ? 5 : 10}>
                <FormControl fullWidth>
                    <InputLabel>Destination Location</InputLabel>
                    <Select
                        value={dest.locationId}
                        onChange={(e) => handleDestinationChange(index, 'locationId', e.target.value)}
                        label="Destination Location"
                    >
                        {locations.filter(loc => loc.db_location_id !== selectedSource).map((loc) => (
                            <MenuItem 
                                key={loc.db_location_id} 
                                value={loc.db_location_id}
                            >
                                {loc.name}
                                {getRecommendedDestinations().find(rd => rd.locationId === loc.db_location_id) && (
                                    <Chip
                                        size="small"
                                        label="Recommended"
                                        color="primary"
                                        sx={{ ml: 1 }}
                                    />
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {distributionMethod === 'percentage' && (
                <Grid item xs={12} md={5}>
                    <TextField
                        fullWidth
                        type="number"
                        label="Distribution Percentage"
                        value={dest.percentage || ''}
                        onChange={(e) => handleDestinationChange(index, 'percentage', Number(e.target.value))}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={2}>
                <IconButton 
                    color="error"
                    onClick={() => handleRemoveDestination(index)}
                >
                    <RemoveIcon />
                </IconButton>
            </Grid>
        </Grid>
    );

    const canProceed = () => {
        switch (activeStep) {
            case 0:
                return selectedSource !== null;
            case 1:
                return selectedItems.length > 0;
            case 2:
                if (distributionMethod === 'percentage') {
                    return destinations.length > 0 &&
                        destinations.every(dest => dest.locationId && dest.percentage > 0) &&
                        destinations.reduce((sum, dest) => sum + dest.percentage, 0) === 100;
                }
                return destinations.length > 0 && destinations.every(dest => dest.locationId);
            default:
                return true;
        }
    };

    const handleStartCreating = () => {
        setIsCreating(true);
        setActiveStep(0);
        resetForm();
    };

    const handleCancelCreating = () => {
        setIsCreating(false);
        resetForm();
    };

    return (
        <FullScreenLayout
            header={
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h4" gutterBottom>Inventory Distribution</Typography>
                    {isCreating && (
                        <>
                            <Button
                                startIcon={<ArrowBackIcon />}
                                onClick={handleCancelCreating}
                                sx={{ mb: 2 }}
                            >
                                Back to Distributions
                            </Button>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </>
                    )}
                </Box>
            }
            mainPanel={
                <Container maxWidth="lg">
                    {loading && <LinearProgress sx={{ mb: 2 }} />}

                    {!isCreating ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                                <Typography variant="h5">Recent Distributions</Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleStartCreating}
                                >
                                    Create New Distribution
                                </Button>
                            </Box>

                            <TableContainer component={StyledPaper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Source</TableCell>
                                            <TableCell>Destinations</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Created At</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {distributions.map(dist => (
                                            <TableRow 
                                                key={dist.id}
                                                hover
                                                onClick={() => navigate(`/inventory-distribution/${dist.id}`)}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <TableCell>{dist.id}</TableCell>
                                                <TableCell>{dist.source_location_name}</TableCell>
                                                <TableCell>
                                                    {Array.from(new Set(dist.destinations.map(dest => dest.location_id))).map(locationId => {
                                                        const destination = dist.destinations.find(d => d.location_id === locationId);
                                                        const itemCount = dist.items.filter(item => 
                                                            item.destination_id === destination.destination_id
                                                        ).length;
                                                        const totalQuantity = dist.items.reduce((sum, item) => 
                                                            item.destination_id === destination.destination_id ? sum + item.quantity : sum, 
                                                        0);
                                                        const percentage = (totalQuantity / dist.items.reduce((sum, item) => sum + item.quantity, 0) * 100).toFixed(1);
                                                        
                                                        return (
                                                            <Chip
                                                                key={locationId}
                                                                label={`${destination.location_name} (${percentage}%)`}
                                                                sx={{ m: 0.5 }}
                                                                title={`${itemCount} items, ${totalQuantity} units`}
                                                            />
                                                        );
                                                    })}
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        value={dist.status_name}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleUpdateStatus(dist.id, e.target.value);
                                                        }}
                                                        size="small"
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <MenuItem value="Pending">Pending</MenuItem>
                                                        <MenuItem value="In Progress">In Progress</MenuItem>
                                                        <MenuItem value="Completed">Completed</MenuItem>
                                                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(dist.created_at).toLocaleString()}
                                                </TableCell>
                                                <TableCell>
                                                    {dist.destinations.map(dest => (
                                                        dest.sheet_url && (
                                                            <Tooltip title={`View Sheet for ${dest.location_name}`} key={dest.destination_id}>
                                                                <IconButton
                                                                    href={dest.sheet_url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={(e) => e.stopPropagation()}
                                                                >
                                                                    <DescriptionIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            {activeStep > 0 && (
                                <Button
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => setActiveStep(prev => prev - 1)}
                                    sx={{ mb: 2 }}
                                >
                                    Back
                                </Button>
                            )}

                            {renderStepContent()}

                            {activeStep < steps.length - 1 ? (
                                <Button
                                    variant="contained"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => setActiveStep(prev => prev + 1)}
                                    disabled={!canProceed()}
                                    sx={{ mt: 3 }}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreateDistribution}
                                    disabled={!canProceed() || loading}
                                    sx={{ mt: 3 }}
                                >
                                    Create Distribution
                                </Button>
                            )}
                        </>
                    )}

                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={6000}
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert
                            onClose={() => setSnackbar({ ...snackbar, open: false })}
                            severity={snackbar.severity}
                            sx={{ width: '100%' }}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>

                    {showConfetti && (
                        <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            recycle={false}
                            numberOfPieces={200}
                        />
                    )}
                </Container>
            }
        />
    );
}

export default InventoryDistribution; 