import React, { useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';

function DynamicSpreadsheet({
  data,
  title,
  onCellValueChanged,
  readOnly = false,
  isLoading = false,
  fullScreen = false
}) {
  // Dynamically extract all possible data fields from the data
  const dataFields = useMemo(() => {
    if (!data) return [];
    const fields = new Set();
    
    Object.values(data).forEach(sizeData => {
      Object.values(sizeData).forEach(brimData => {
        Object.values(brimData).forEach(shapeData => {
          Object.values(shapeData).forEach(locationData => {
            Object.keys(locationData).forEach(field => {
              fields.add(field);
            });
          });
        });
      });
    });
    
    return Array.from(fields);
  }, [data]);

  // Generate column definitions
  const columnDefs = useMemo(() => {
    const baseColumns = [
      {
        headerName: 'Size',
        field: 'size',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'base-column',
        suppressMovable: true,
        width: 80,
        rowSpan: params => params.data.rowSpan,
        cellClassRules: {
          'cell-span': params => params.data.rowSpan > 1
        }
      },
      {
        headerName: 'Brim',
        field: 'brim',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'base-column',
        suppressMovable: true,
        width: 80
      },
      {
        headerName: 'Shape',
        field: 'shape',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'base-column',
        suppressMovable: true,
        width: 80
      },
      {
        headerName: 'Location',
        field: 'location',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'base-column',
        suppressMovable: true,
        width: 120
      }
    ];

    // Add dynamic data columns based on discovered fields
    const dataColumns = dataFields.map(field => ({
      headerName: field.split('_').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' '),
      field: `value_${field}`,
      editable: !readOnly,
      cellClass: 'data-cell editable-cell',
      width: 120,
      valueParser: params => {
        const newValue = Number(params.newValue);
        return isNaN(newValue) ? params.oldValue : newValue;
      }
    }));

    return [...baseColumns, ...dataColumns];
  }, [dataFields, readOnly]);

  // Transform data into row data format
  const rowData = useMemo(() => {
    if (!data) return [];

    const rows = [];
    
    Object.entries(data).forEach(([size, sizeData]) => {
      Object.entries(sizeData).forEach(([brim, brimData]) => {
        Object.entries(brimData).forEach(([shape, shapeData]) => {
          Object.entries(shapeData).forEach(([location, values]) => {
            const row = {
              size,
              brim,
              shape,
              location,
            };
            
            // Add values for each data field
            dataFields.forEach(field => {
              row[`value_${field}`] = values[field] || 0;
            });

            rows.push(row);
          });
        });
      });
    });

    // Calculate row spans for size cells
    let currentSize = null;
    let spanCount = 0;
    rows.forEach((row, index) => {
      if (row.size !== currentSize) {
        if (spanCount > 0) {
          rows[index - spanCount].rowSpan = spanCount;
        }
        currentSize = row.size;
        spanCount = 1;
      } else {
        spanCount++;
        row.size = ''; // Clear size for spanned rows
      }
    });
    // Handle last group
    if (spanCount > 0) {
      rows[rows.length - spanCount].rowSpan = spanCount;
    }

    return rows;
  }, [data, dataFields]);

  // Handle cell value changes
  const handleCellValueChanged = useCallback((params) => {
    if (onCellValueChanged) {
      const { data: rowData, colDef, newValue, oldValue } = params;
      const dataField = colDef.field.replace('value_', '');
      
      onCellValueChanged({
        size: rowData.size || params.node.parent,
        brim: rowData.brim,
        shape: rowData.shape,
        location: rowData.location,
        field: dataField,
        newValue,
        oldValue
      });
    }
  }, [onCellValueChanged]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    valueFormatter: params => {
      return params.value === undefined || params.value === null ? '' : params.value;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ 
      height: fullScreen ? '100%' : 'auto',
      width: '100%',
      '& .dynamic-spreadsheet': {
        height: fullScreen ? '100%' : '600px',
      }
    }}>
      {title && <h2>{title}</h2>}
      <div className="ag-theme-alpine dynamic-spreadsheet">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          onCellValueChanged={handleCellValueChanged}
          suppressRowTransform={true}
          rowHeight={35}
          headerHeight={40}
          suppressHorizontalScroll={false}
          enableCellChangeFlash={true}
          animateRows={true}
        />
      </div>
      <style>
        {`
          .ag-theme-alpine {
            --ag-header-height: 40px;
            --ag-header-foreground-color: #181D1F;
            --ag-header-background-color: #f8f9fa;
            --ag-odd-row-background-color: #f8f9fa;
          }

          .base-column {
            background-color: #f8f9fa;
            font-weight: 600;
          }

          .data-cell {
            text-align: right;
          }

          .editable-cell:hover {
            background-color: rgba(240, 240, 240, 0.9);
            cursor: pointer;
          }

          .cell-span {
            background-color: #f1f3f5;
          }

          .ag-cell-focus {
            border: 1px solid #2196f3 !important;
          }
        `}
      </style>
    </Box>
  );
}

export default DynamicSpreadsheet; 