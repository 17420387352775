import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import {
  Box,
  TextField,
  Autocomplete,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import useSound from 'use-sound';
import ItemsCacheService from '../../services/itemsCacheService';

const successSound = '/success.wav';
const errorSound = '/error.wav';

// Optimized search index builder using cached items
const buildSearchIndex = (items) => {
  const index = new Map();
  
  items?.forEach(item => {
    if (!item?.active || !item?.is_valid) return;

    // Create searchable text combining all relevant fields
    const searchableText = [
      item.barcode,
      item.item_name,
      item.type_name,
      item.size_name,
      item.shape_name,
      item.brim_name,
      ...(item.alternative_lookups || [])
    ]
      .filter(Boolean)
      .join(' ')
      .toLowerCase();
      
    // Store the processed item with its searchable text
    index.set(item.id, {
      item,
      searchableText,
      tokens: new Set([
        ...searchableText.split(/\s+/).filter(Boolean),
        // Add partial barcode matches
        ...(item.barcode?.toString().split('').map((_, idx, arr) => 
          arr.slice(0, idx + 1).join('')
        ) || [])
      ])
    });
  });

  return index;
};

const OptimizedItemSearch = ({
  onItemSelect,
  onSearchChange,
  placeholder = "Search items...",
  autoFocus = true,
  searchRef,
  clearOnSelect = true,
  showQuantity = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const [playSuccess] = useSound(successSound, { volume: 0.5 });
  const [playError] = useSound(errorSound, { volume: 0.5 });
  
  const localSearchRef = useRef(null);
  const actualSearchRef = searchRef || localSearchRef;

  // Load cached items on mount
  useEffect(() => {
    const loadCachedItems = async () => {
      try {
        // First try to get from cache
        let cachedItems = ItemsCacheService.getCachedItems();
        
        if (!cachedItems) {
          // If no cache, fetch fresh items
          cachedItems = await ItemsCacheService.getItems();
        }
        
        setItems(cachedItems.filter(item => item.active && item.is_valid));
      } catch (err) {
        console.error('Error loading items:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadCachedItems();
  }, []);
  
  // Memoize search index creation
  const searchIndex = useMemo(() => buildSearchIndex(items), [items]);

  const clearSearch = useCallback(() => {
    setSearchTerm('');
    setSearchResults([]);
    setSelectedIndex(-1);
    if (actualSearchRef.current) {
      actualSearchRef.current.value = '';
      actualSearchRef.current.focus();
    }
  }, [actualSearchRef]);

  const performSearch = useCallback((term) => {
    if (!term) return [];
    
    const searchTermLower = term.toString().toLowerCase().trim();
    const searchTokens = searchTermLower.split(/\s+/).filter(Boolean);

    if (!searchTokens.length) return [];

    // First try exact or partial barcode match
    const barcodeMatches = Array.from(searchIndex.values())
      .filter(({ item }) => {
        const barcode = item.barcode?.toString() || '';
        return barcode.startsWith(term) || 
               item.alternative_lookups?.some(lookup => lookup.startsWith(term));
      });
    
    if (barcodeMatches.length) {
      return barcodeMatches.map(({ item }) => item);
    }

    // Score-based search with optimized relevance calculation
    const results = Array.from(searchIndex.values())
      .map(({ item, searchableText, tokens }) => {
        let score = 0;
        
        // Quick initial check if any token matches
        const hasAnyMatch = searchTokens.some(token => 
          searchableText.includes(token)
        );
        
        if (!hasAnyMatch) return null;

        // Detailed scoring
        searchTokens.forEach(token => {
          if (!token) return;

          // Exact token matches
          if (tokens.has(token)) score += 10;
          
          // Partial matches at word boundaries
          if (searchableText.includes(` ${token}`)) score += 5;
          
          // Contains match
          if (searchableText.includes(token)) score += 3;
          
          // Special field bonuses
          if (item.item_name?.toLowerCase().includes(token)) score += 2;
          if (item.barcode?.toString().includes(token)) score += 4;
        });

        return score > 0 ? { item, score } : null;
      })
      .filter(Boolean)
      .sort((a, b) => b.score - a.score)
      .map(({ item }) => item);

    return results.slice(0, 50);
  }, [searchIndex]);

  const handleSearch = useCallback((event) => {
    const term = event.target.value;
    setSearchTerm(term);
    setIsSearching(true);
    setSelectedIndex(-1);

    // Use requestAnimationFrame for smooth UI updates
    requestAnimationFrame(() => {
      const results = performSearch(term);
      setSearchResults(results);
      setIsSearching(false);
      if (onSearchChange) {
        onSearchChange(results);
      }
    });
  }, [performSearch, onSearchChange]);

  const handleKeyDown = useCallback((event) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        
        if (!searchTerm) {
          onItemSelect?.(null, true);
          return;
        }

        const results = performSearch(searchTerm);
        const selectedItem = selectedIndex >= 0 ? results[selectedIndex] : results[0];
        
        if (selectedItem) {
          onItemSelect(selectedItem);
          playSuccess();
          if (clearOnSelect) clearSearch();
        } else {
          playError();
          onItemSelect?.(null, false);
        }
        break;

      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex(prev => 
          prev < searchResults.length - 1 ? prev + 1 : prev
        );
        break;

      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : -1);
        break;

      default:
        break;
    }
  }, [searchTerm, selectedIndex, searchResults, performSearch, onItemSelect, clearOnSelect, playSuccess, playError]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        freeSolo
        fullWidth
        loading={isSearching}
        options={searchResults}
        value={searchTerm}
        inputValue={searchTerm}
        filterOptions={(x) => x}
        getOptionLabel={(option) => 
          typeof option === 'string' ? option : 
          `${option.barcode || ''} ${option.item_name}${
            showQuantity ? ` (${option.current_quantity} available)` : ''
          }`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={actualSearchRef}
            fullWidth
            placeholder={placeholder}
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            autoFocus={autoFocus}
            size="large"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isSearching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              sx: { 
                fontSize: '1.2rem',
                py: 1,
                '& input': {
                  fontFamily: /^\d+$/.test(searchTerm) ? 'monospace' : 'inherit',
                  letterSpacing: /^\d+$/.test(searchTerm) ? '0.1em' : 'inherit',
                }
              },
              startAdornment: (
                <SearchIcon color="action" sx={{ ml: 1, mr: 0.5, fontSize: '1.5rem' }} />
              ),
            }}
          />
        )}
        onChange={(event, newValue) => {
          if (newValue && typeof newValue === 'object') {
            onItemSelect(newValue);
            playSuccess();
            if (clearOnSelect) clearSearch();
          }
        }}
        renderOption={(props, option, { selected }) => (
          <li 
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? 'rgba(25, 118, 210, 0.08)' : undefined,
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {option.item_name}
                {option.barcode && 
                  <Typography 
                    component="span" 
                    sx={{ 
                      ml: 1,
                      color: 'text.secondary',
                      fontFamily: 'monospace',
                      fontSize: '0.9em'
                    }}
                  >
                    ({option.barcode})
                  </Typography>
                }
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: 1,
                  '& > span': {
                    '&:not(:last-child):after': {
                      content: '"•"',
                      marginLeft: 1
                    }
                  }
                }}
              >
                {[
                  option.type_name,
                  option.size_name,
                  option.shape_name,
                  option.brim_name,
                  showQuantity && `${option.current_quantity} available`
                ]
                  .filter(Boolean)
                  .map((text, i) => (
                    <span key={i}>{text}</span>
                  ))
                }
              </Typography>
            </Box>
          </li>
        )}
        componentsProps={{
          popper: {
            sx: {
              zIndex: 1300
            }
          }
        }}
      />
    </Box>
  );
};

export default OptimizedItemSearch; 