import api from './api';
import axios from 'axios';

export const getAvailableInventory = async (locationId, type) => {
    try {
        const response = await api.get(`/inventory-distribution/available/${locationId}/${type}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching available inventory:', error);
        throw error;
    }
};

export const createDistribution = async (data) => {
    try {
        const response = await api.post('/inventory-distribution/create', {
            sourceLocationId: data.sourceLocationId,
            destinations: data.destinations,
            items: data.items,
            distributionType: data.distributionType,
            distributionMethod: data.distributionMethod
        });
        return response.data;
    } catch (error) {
        console.error('Error creating distribution:', error);
        throw error;
    }
};

export const getDistributions = async () => {
    try {
        const response = await api.get('/inventory-distribution');
        return response.data;
    } catch (error) {
        console.error('Error fetching distributions:', error);
        throw error;
    }
};

export const getDistributionById = async (id) => {
    try {
        const response = await api.get(`/inventory-distribution/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching distribution details:', error);
        throw error;
    }
};

export const updateDistributionStatus = async (distributionId, statusName) => {
    try {
        const response = await api.put(`/inventory-distribution/${distributionId}/status`, { statusName });
        return response.data;
    } catch (error) {
        console.error('Error updating distribution status:', error);
        throw error;
    }
};

export const getLocationsForDistribution = async (type) => {
    try {
        const response = await api.get(`/inventory-distribution/locations/${type}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching locations for distribution:', error);
        throw error;
    }
};

export const calculateDistributionPreview = (items, destinations, method) => {
    if (!items?.length || !destinations?.length) return null;
    
    const preview = {};
    
    items.forEach(item => {
        const itemId = item.itemId || item.item_id;
        preview[itemId] = {};
        const itemQuantity = parseInt(item.quantity || item.available_quantity);
        
        if (method === 'percentage') {
            let remainingQuantity = itemQuantity;
            
            // Sort destinations to ensure consistent order
            const sortedDests = [...destinations].filter(d => d.locationId && d.percentage)
                .sort((a, b) => {
                    if (a.percentage === b.percentage) return 0;
                    return b.percentage - a.percentage;
                });
            
            if (sortedDests.length === 0) return;
            
            // First pass: calculate floor values
            sortedDests.forEach(dest => {
                const exactQuantity = (itemQuantity * dest.percentage) / 100;
                preview[itemId][dest.locationId] = Math.floor(exactQuantity);
                remainingQuantity -= preview[itemId][dest.locationId];
            });
            
            // Second pass: add remainder to first destination
            if (remainingQuantity > 0 && sortedDests.length > 0) {
                const firstDestId = sortedDests[0].locationId;
                preview[itemId][firstDestId] = (preview[itemId][firstDestId] || 0) + remainingQuantity;
            }
        }
    });
    
    return preview;
};