import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Box,
    Typography,
    Divider
} from '@mui/material';

export default function ShipmentSettingsPopup({
    open,
    onClose,
    settings,
    onSettingsChange
}) {
    const handleChange = (field, value) => {
        onSettingsChange({
            ...settings,
            [field]: value
        });
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Order Settings</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 1 }}>
                    <Box>
                        <Typography variant="subtitle2" gutterBottom>Order Status</Typography>
                        <FormControl fullWidth size="small">
                            <Select
                                multiple
                                value={settings.orderStatuses}
                                onChange={(e) => handleChange('orderStatuses', e.target.value)}
                            >
                                <MenuItem value="processing">Processing</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="confirmed">Confirmed</MenuItem>
                                <MenuItem value="awaiting_approval">Awaiting Approval</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Divider />

                    <Box>
                        <Typography variant="subtitle2" gutterBottom>Availability Settings</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.showOnlyAvailable}
                                    onChange={(e) => handleChange('showOnlyAvailable', e.target.checked)}
                                />
                            }
                            label="Show only available items"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.subtractShipments}
                                    onChange={(e) => handleChange('subtractShipments', e.target.checked)}
                                />
                            }
                            label="Subtract items in other shipments"
                        />
                    </Box>

                    <Box>
                        <Typography variant="subtitle2" gutterBottom>Shipment Scope</Typography>
                        <FormControl fullWidth size="small">
                            <Select
                                value={settings.shipmentScope}
                                onChange={(e) => handleChange('shipmentScope', e.target.value)}
                            >
                                <MenuItem value="current_warehouse">Current Warehouse Only</MenuItem>
                                <MenuItem value="all_warehouses">All Warehouses</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {settings.subtractShipments && (
                        <Box>
                            <Typography variant="subtitle2" gutterBottom>
                                Shipment Statuses to Consider
                            </Typography>
                            <FormControl fullWidth size="small">
                                <Select
                                    multiple
                                    value={settings.shipmentStatusesToSubtract}
                                    onChange={(e) => handleChange('shipmentStatusesToSubtract', e.target.value)}
                                >
                                    <MenuItem value="draft">Draft</MenuItem>
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="processing">Processing</MenuItem>
                                    <MenuItem value="shipped">Shipped</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
} 