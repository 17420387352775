import api from './api';

/**
 * Extract data from a Google Sheet
 * @param {string} sheetUrl - The URL of the Google Sheet to extract data from
 * @returns {Promise<{success: boolean, data: Array, totalItems: number}>}
 */
export const extractSheetData = async (sheetUrl) => {
  try {
    const response = await api.post('/sheet-extractor/extract', { sheetUrl });
    return response.data;
  } catch (error) {
    console.error('Error extracting sheet data:', error);
    throw error;
  }
}; 