import React from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, Dialog, DialogContent, LinearProgress } from '@mui/material';
import { AutoMode as AutoModeIcon } from '@mui/icons-material';

const BatchProcessingAnimation = ({ open, current, total, status }) => {
  const progress = total > 0 ? (current / total) * 100 : 0;

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          background: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(8px)',
          overflow: 'hidden',
        }
      }}
    >
      <DialogContent 
        sx={{ 
          textAlign: 'center', 
          py: 6,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Rotating outer ring */}
          <motion.div
            style={{
              position: 'absolute',
              width: '150px',
              height: '150px',
              borderRadius: '50%',
              border: '4px solid rgba(25, 118, 210, 0.1)',
            }}
            animate={{ rotate: 360 }}
            transition={{
              duration: 3,
              ease: "linear",
              repeat: Infinity,
            }}
          />

          {/* Progress circle */}
          <motion.div
            style={{
              position: 'absolute',
              width: '120px',
              height: '120px',
              borderRadius: '50%',
              background: `conic-gradient(#1976d2 ${progress}%, transparent ${progress}%)`,
            }}
          />

          {/* Center circle with icon */}
          <motion.div
            style={{
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              backgroundColor: '#1976d2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              zIndex: 1,
            }}
            animate={{
              scale: [1, 1.1, 1],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            >
              <AutoModeIcon sx={{ color: 'white', fontSize: 40 }} />
            </motion.div>
          </motion.div>
        </Box>

        <Box sx={{ mt: 4, px: 3 }}>
          <Typography variant="h6" gutterBottom>
            Processing Batch Update
          </Typography>
          
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {status}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              {current}
            </Typography>
            <Typography variant="h5" sx={{ mx: 1, color: 'text.secondary' }}>
              /
            </Typography>
            <Typography variant="h4" sx={{ color: 'text.secondary' }}>
              {total}
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <LinearProgress 
              variant="determinate" 
              value={progress} 
              sx={{ 
                height: 8, 
                borderRadius: 4,
                backgroundColor: 'rgba(25, 118, 210, 0.1)',
                '& .MuiLinearProgress-bar': {
                  borderRadius: 4,
                }
              }} 
            />
          </Box>
        </Box>

        {/* Floating particles */}
        {[...Array(8)].map((_, i) => (
          <motion.div
            key={i}
            style={{
              position: 'absolute',
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: 'rgba(25, 118, 210, 0.2)',
            }}
            animate={{
              y: [-20, -60],
              x: Math.sin(i) * 30,
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: i * 0.2,
              ease: "easeOut",
            }}
            initial={{
              x: 20 + i * 30,
              y: 100,
            }}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default BatchProcessingAnimation; 