import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    InputAdornment,
    Chip
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { shipmentCreationApi } from '../../services/shipment-creation-api';

export default function CurrentShipmentItems({ open, onClose, shipmentId }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [shipmentData, setShipmentData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (open && shipmentId) {
            loadShipmentDetails();
        }
    }, [open, shipmentId]);

    const loadShipmentDetails = async () => {
        setLoading(true);
        try {
            const response = await shipmentCreationApi.getShipmentDetails(shipmentId);
            console.log('Loaded shipment details:', response);
            
            if (response.success && response.data) {
                // Extract the actual data from the nested structure
                const actualData = response.data.data || response.data;
                console.log('Actual data:', actualData);
                
                // Ensure we're using the correct data structure
                const processedData = {
                    ...actualData,
                    items: Array.isArray(actualData.items) ? actualData.items : []
                };
                console.log('Processed shipment data:', processedData);
                setShipmentData(processedData);
            } else {
                throw new Error(response.error || 'Failed to load shipment details');
            }
        } catch (error) {
            console.error('Error loading shipment details:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getFilteredItems = () => {
        if (!shipmentData?.items) {
            console.log('No items found in shipment data');
            return [];
        }
        
        let items = shipmentData.items;
        console.log('Original items:', items);
        
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            items = items.filter(item => 
                (item.item_id?.toString().toLowerCase().includes(term)) ||
                (item.type?.toLowerCase().includes(term)) ||
                (item.size?.toLowerCase().includes(term)) ||
                (item.shape?.toLowerCase().includes(term))
            );
            console.log('Filtered items:', items);
        }
        
        // Map items to consistent format
        return items.map(item => ({
            ...item,
            item_name: item.item_id, // Backend sends item_name as item_id
            item_id: item.item_id,
            quantity: parseInt(item.quantity) || 0,
            ordered_quantity: parseInt(item.ordered_quantity) || 0,
            total_quantity: parseInt(item.total_quantity) || 0,
            available_quantity: parseInt(item.available_quantity) || 0
        }));
    };

    const getTotalItems = () => {
        if (!shipmentData?.items) return 0;
        const total = shipmentData.items.reduce((sum, item) => sum + (parseInt(item.quantity) || 0), 0);
        console.log('Total items:', total);
        return total;
    };

    const orderItems = getFilteredItems().filter(item => item.order_number);
    const additionalItems = getFilteredItems().filter(item => !item.order_number);

    console.log('Order items:', orderItems);
    console.log('Additional items:', additionalItems);

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Current Shipment Items</Typography>
                    <TextField
                        size="small"
                        placeholder="Search items..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Total Items: {getTotalItems()}
                            </Typography>
                        </Box>

                        {orderItems.length > 0 && (
                            <>
                                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Order Items ({orderItems.length})
                                </Typography>
                                <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Order #</TableCell>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Size</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderItems.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <Chip 
                                                            label={item.order_number}
                                                            size="small"
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell>{item.item_name}</TableCell>
                                                    <TableCell>{item.type}</TableCell>
                                                    <TableCell>{item.size}</TableCell>
                                                    <TableCell align="right">{item.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}

                        {additionalItems.length > 0 && (
                            <>
                                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Additional Items ({additionalItems.length})
                                </Typography>
                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Size</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {additionalItems.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{item.item_name}</TableCell>
                                                    <TableCell>{item.type}</TableCell>
                                                    <TableCell>{item.size}</TableCell>
                                                    <TableCell align="right">{item.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}

                        {!orderItems.length && !additionalItems.length && (
                            <Typography color="text.secondary" sx={{ textAlign: 'center', py: 3 }}>
                                No items have been added to this shipment yet.
                            </Typography>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
} 