import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Tabs, Tab } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useNavigate } from 'react-router-dom';
import MetricCard from './MetricCard';
import ViewCard from './ViewCard';

const SectionContainer = ({ title, children, showViewAll, onViewAll }) => {
    return (
        <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {title}
                </Typography>
                {showViewAll && (
                    <Button 
                        onClick={onViewAll}
                        sx={{ 
                            color: '#1a73e8',
                            '&:hover': { backgroundColor: 'rgba(26, 115, 232, 0.04)' }
                        }}
                    >
                        View All
                    </Button>
                )}
            </Box>
            {children}
        </Box>
    );
};

const ViewsGrid = ({ views, emptyMessage, emptyIcon, onDelete }) => {
    if (views?.length === 0 || !views) {
        return (
            <Box 
                sx={{ 
                    textAlign: 'center', 
                    py: 8,
                    backgroundColor: 'white',
                    borderRadius: '16px',
                    border: '2px dashed rgba(0, 0, 0, 0.08)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                {emptyIcon && React.cloneElement(emptyIcon, { 
                    sx: { 
                        fontSize: 48, 
                        color: 'text.secondary',
                        opacity: 0.5
                    } 
                })}
                <Typography variant="h6" color="text.secondary">
                    {emptyMessage}
                </Typography>
            </Box>
        );
    }

    return (
        <Grid container spacing={3}>
            {views.map((view) => (
                <Grid item xs={12} sm={6} md={4} key={view.view_id || Math.random()}>
                    <ViewCard 
                        view={view} 
                        onDelete={onDelete}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

const MetricsGrid = ({ metrics, emptyMessage, emptyIcon, onDelete }) => {
    if (metrics?.length === 0 || !metrics) {
        return (
            <Box 
                sx={{ 
                    textAlign: 'center', 
                    py: 8,
                    backgroundColor: 'white',
                    borderRadius: '16px',
                    border: '2px dashed rgba(0, 0, 0, 0.08)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                {emptyIcon && React.cloneElement(emptyIcon, { 
                    sx: { 
                        fontSize: 48, 
                        color: 'text.secondary',
                        opacity: 0.5
                    } 
                })}
                <Typography variant="h6" color="text.secondary">
                    {emptyMessage}
                </Typography>
            </Box>
        );
    }

    return (
        <Grid container spacing={3}>
            {metrics.map(metric => (
                <Grid item xs={12} sm={6} md={4} key={metric.metric_id}>
                    <MetricCard 
                        metric={metric} 
                        onDelete={onDelete}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

const MetricsDashboard = ({ 
    views = [], 
    metrics = [],
    onCreateMetric, 
    onCreateView,
    onDeleteMetric,
    onDeleteView,
    showAllContent = false 
}) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);

    const renderContent = () => {
        // If showAllContent is true, show both metrics and views
        if (showAllContent) {
            return (
                <>
                    <SectionContainer
                        title="Metrics"
                        showViewAll={false}
                    >
                        <MetricsGrid 
                            metrics={metrics} 
                            emptyMessage="No metrics found"
                            emptyIcon={<BarChartIcon />}
                            onDelete={onDeleteMetric}
                        />
                    </SectionContainer>

                    <SectionContainer
                        title="Views"
                        showViewAll={false}
                    >
                        <ViewsGrid 
                            views={views}
                            emptyMessage="No views found"
                            emptyIcon={<TableChartIcon />}
                            onDelete={onDeleteView}
                        />
                    </SectionContainer>
                </>
            );
        }

        // Otherwise, show based on active tab
        return activeTab === 0 ? (
            <SectionContainer title="Metrics">
                <MetricsGrid 
                    metrics={metrics} 
                    emptyMessage="No metrics found"
                    emptyIcon={<BarChartIcon />}
                    onDelete={onDeleteMetric}
                />
            </SectionContainer>
        ) : (
            <SectionContainer title="Views">
                <ViewsGrid 
                    views={views}
                    emptyMessage="No views found"
                    emptyIcon={<TableChartIcon />}
                    onDelete={onDeleteView}
                />
            </SectionContainer>
        );
    };

    return (
        <Box>
            {!showAllContent && (
                <Tabs 
                    value={activeTab} 
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    sx={{ mb: 3 }}
                >
                    <Tab label="Metrics" />
                    <Tab label="Views" />
                </Tabs>
            )}
            {renderContent()}
        </Box>
    );
};

export default MetricsDashboard; 