import React, { useState, useEffect } from 'react';
import OptimizedSpreadsheet from './OptimizedSpreadsheet';
import { Box, Typography, CircularProgress, Paper, List, ListItem, ListItemText, Divider } from '@mui/material';
import FullScreenLayout from '../layouts/FullScreenLayout';

const TestV2Spreadsheet = () => {
  const [loading, setLoading] = useState(true);
  const [testData, setTestData] = useState({});
  const [error, setError] = useState(null);
  const [changes, setChanges] = useState([]);

  // Sample test data metrics
  const metrics = ['Quantity', 'Price', 'Cost', 'Margin'];
  
  useEffect(() => {
    // Simulate loading test data with a network delay
    const generateTestData = () => {
      try {
        const data = {};
        for (let i = 1; i <= 20; i++) {
          data[`item${i}`] = {
            Quantity: Math.floor(Math.random() * 100),
            Price: Math.floor(Math.random() * 1000) / 10,
            Cost: Math.floor(Math.random() * 500) / 10,
            Margin: Math.floor(Math.random() * 50),
          };
        }
        setTestData(data);
        setLoading(false);
      } catch (err) {
        console.error('Error generating test data:', err);
        setError('Failed to generate test data');
        setLoading(false);
      }
    };

    setTimeout(generateTestData, 1000);
  }, []);

  const handleCellValueChanged = ({ itemId, metric, newValue, oldValue }) => {
    console.log('Cell value changed:', { itemId, metric, newValue, oldValue });
    
    // Update test data without triggering unnecessary grid re-renders.
    setTestData(prevData => ({
      ...prevData,
      [itemId]: {
        ...prevData[itemId],
        [metric]: newValue
      }
    }));

    // Update change history
    setChanges(prevChanges => [{
      timestamp: new Date().toLocaleTimeString(),
      itemId,
      metric,
      oldValue,
      newValue
    }, ...prevChanges]);
  };

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  const header = (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4">
        V2 Optimized Spreadsheet Test
      </Typography>
    </Box>
  );

  const mainPanel = (
    <Box sx={{ height: '100%', display: 'flex' }}>
      <Box sx={{ flex: 1, pr: 2 }}>
        <OptimizedSpreadsheet
          data={testData}
          metrics={metrics}
          title="Test Spreadsheet"
          onCellValueChanged={handleCellValueChanged}
          readOnly={false}
          hideZeros={false}
          fullScreen={true}
          isLoading={loading}
          readOnlyMetrics={['Margin']}
        />
      </Box>
      <Paper sx={{ 
        width: 300, 
        p: 2, 
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant="h6" gutterBottom>
          Changes History
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <List sx={{ flex: 1, overflowY: 'auto' }}>
          {changes.map((change, index) => (
            <React.Fragment key={index}>
              <ListItem sx={{ 
                flexDirection: 'column', 
                alignItems: 'flex-start',
                bgcolor: index === 0 ? 'action.hover' : 'transparent',
                borderRadius: 1
              }}>
                <Typography variant="caption" color="text.secondary">
                  {change.timestamp}
                </Typography>
                <ListItemText
                  primary={
                    <Typography variant="body2">
                      Item: {change.itemId}<br />
                      {change.metric}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      {change.oldValue} → {change.newValue}
                    </Typography>
                  }
                />
              </ListItem>
              {index !== changes.length - 1 && <Divider />}
            </React.Fragment>
          ))}
          {changes.length === 0 && (
            <ListItem>
              <ListItemText 
                secondary="No changes yet. Edit cells to see the history."
                sx={{ textAlign: 'center' }}
              />
            </ListItem>
          )}
        </List>
      </Paper>
    </Box>
  );

  return (
    <FullScreenLayout
      header={header}
      mainPanel={mainPanel}
      hasNavigation={true}
      hasSideNav={true}
    />
  );
};

export default TestV2Spreadsheet;
