import axios from 'axios';

// Use an environment variable to set the API base URL
const API_URL = 'https://pimbyeli.com/api';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 60000, // 60 seconds timeout
  headers: {
    'Accept': 'application/json'
    // Don't set Content-Type here - let it be set automatically for multipart/form-data
  }
});

// Remove the API key from the default headers

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const login = async (credentials) => {
  const response = await api.post('/auth/login', credentials);
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }
  return response;
};

export const loginWithVerification = async (credentials) => {
  try {
    const response = await api.post('/auth/login-with-verification', credentials);
    console.log('Login with verification response:', response.data);
    // We don't set the token here because this is just the first step
    // The token will be set after the verification code is confirmed
    return response;
  } catch (error) {
    console.error('Login with verification error:', error.response?.data || error.message);
    throw error;
  }
};

export const verifyCode = async (verificationData) => {
  try {
    const response = await api.post('/auth/verify-code', verificationData);
    console.log('Verify code response:', response.data);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    }
    return response;
  } catch (error) {
    console.error('Verify code error:', error.response?.data || error.message);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const getLocations = async (type = 'all') => {
    try {
        const response = await api.get(`/locations?type=${type}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching locations:', error);
        throw error;
    }
};

export const getMode = () => api.get('/settings/mode'); // Expected format: Object
export const updateMode = (mode) => api.put('/settings/mode', { mode }); // Expected format: Object
export const addOrUpdateSetting = (settingData) => api.post('/settings', settingData); // Expected format: Object


export const updateReplenishLevelsById = async (id, updatedLevels) => {
    try {
      const response = await api.put('/locations/change-replenish-levels', 
        { 
          db_location_id: id, 
          recommended_inventory_levels: updatedLevels 
        },
       // 5-minute timeout for this specific request
      );
      return response.data;
    } catch (error) {
      console.error('Error updating replenish levels:', error);
      throw error;
    }
  };

export const getAllReplenishLevels = () => api.get('/replenish-levels'); // Expected format: Array


export const updateReplenishLevelsAndFetchLocations = async () => {
  await api.post('/replenish-levels/update'); // Expected format: Object
  const response = await api.get('/locations'); // Expected format: Array
  return response.data; // Ensure this returns the data array
};

export const fetchUsers = () => api.get('/users'); // Expected format: Array
export const addUser = async (userData) => {
  try {
    const response = await api.post('/users', userData);
    return response;
  } catch (error) {
    console.error('Error adding user:', error);
    throw error;
  }
};
export const updateUser = (userId, userData) => api.put(`/users/${userId}`, userData); // Expected format: Object
export const deleteUser = (userId) => api.delete(`/users/${userId}`); // Expected format: Object
export const fetchRoles = () => api.get('/users/roles'); // Expected format: Array
export const getPosSystems = () => api.get('/pos'); // Expected format: Array
export const getAllSettings = () => api.get('/settings'); // Expected format: Array

export const getSetting = async (key) => {
  const response = await api.get(`/settings/${key}`); // Ensure this is correct
  return response;
};

export const getPOSData = async () => {
  return api.get('/pos');
};

export const syncPOSData = async () => {
  return api.post('/pos/sync');
};

export const deletePos = async (posId) => {
  try {
    console.log(`Deleting POS with ID: ${posId}`);
    const response = await api.delete(`/pos/${posId}`);
    console.log(`Successfully deleted POS with ID: ${posId}`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error deleting POS with ID ${posId}:`, error.response ? error.response.data : error.message);
    throw error;
  }
};

export const addPos = (posData) => api.post('/pos', posData);

export const getLocationById = async (id) => {
  try {
    console.log(`Sending request to fetch location by ID: ${id}`);
    const response = await api.get(`/locations/${id}`);
    console.log(`Received response for location ID ${id}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching location by ID ${id}:`, error.response ? error.response.data : error.message);
    throw error;
  }
};

export const runUpdateReplenishLevels = async () => {
  try {
    const response = await api.post('/api/run-update-replenish-levels', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to run update replenish levels script');
    }
    return await response.json();
  } catch (error) {
    console.error('Error running update replenish levels script:', error);
    throw error;
  }
};

export const getTotalLocations = async () => {
  try {
    console.log('Fetching total locations and inventory');
    const response = await api.get('/locations/total');
    console.log('Received response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching total locations and inventory:', error);
    return { totalLocations: 0, totalInventory: 0 };
  }
};

export const getTotalOptimization = async () => {
  try {
    const response = await api.get('/optimization/total');
    return response.data;
  } catch (error) {
    console.error('Error fetching total optimization:', error);
    return { optimizationLevel: '0%' };
  }
};

export const getRecentUpdates = async () => {
  try {
    const response = await api.get('/updates/recent');
    console.log('API Response for recent updates:', response.data); // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error fetching recent updates:', error);
    throw error;
  }
};

export const getInventoryHistory = async () => {
  console.log('Fetching inventory history');
  try {
    const response = await api.get('/inventory/history');
    console.log('Inventory history response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory history:', error);
    throw error;
  }
};

export const runReloadProcess = async () => {
  try {
    const response = await api.post('/reload-process');
    return response.data;
  } catch (error) {
    console.error('Error running reload process:', error);
    throw error;
  }
};

export const deleteLocation = async (locationId) => {
  try {
    const response = await api.delete(`/locations/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting location:', error);
    throw error;
  }
};

export const getSalesData = async (startDate, endDate, itemTypes, locations) => {
    try {
        const response = await api.get('/sales', {
            params: { startDate, endDate, itemTypes: itemTypes.join(','), locations: locations.join(',') }
        });
        console.log('Raw API response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error in getSalesData:', error);
        throw error;
    }
};

export const updateLocationType = async (locationId, locationType) => {
  try {
    const response = await api.put(`/locations/${locationId}/type`, { locationType });
    return response.data;
  } catch (error) {
    console.error('Error updating location type:', error);
    throw error;
  }
};

export const generateOrderSheet = async (id, save = true) => {
  try {
    const response = await api.post(`/generate/order/${id}`, { save });
    return response.data.sheetLink;
  } catch (error) {
    console.error('Error generating order sheet:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const generateReplenishSheet = async (id, save = true) => {
  try {
    const response = await api.post(`/generate/replenish/${id}`, { save });
    return response.data.sheetLink;
  } catch (error) {
    console.error('Error generating replenish sheet:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const generateInventorySheet = async (id, save = true) => {
  try {
    const response = await api.post(`/generate/inventory/${id}`, { save });
    return response.data.sheetLink;
  } catch (error) {
    console.error('Error generating inventory sheet:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getReplenishMode = async (locationId) => {
  try {
    const response = await api.get(`/replenish-levels/${locationId}/mode`);
    return response.data;
  } catch (error) {
    console.error('Error fetching replenish mode:', error);
    throw error;
  }
};

export const updateReplenishMode = async (locationId, isAutomatic) => {
  try {
    const response = await api.put(`/replenish-levels/${locationId}/mode`, { is_automatic: isAutomatic });
    return response.data;
  } catch (error) {
    console.error('Error updating replenish mode:', error);
    throw error;
  }
};

export const fetchDeletedLocations = async () => {
  try {
    console.log('Sending request to fetch deleted locations');
    const response = await api.get('/deleted'); // Ensure this endpoint matches the backend route
    console.log(`Received response with status: ${response.status}`);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    console.log(`Parsed response data: ${JSON.stringify(data)}`);
    return data;
  } catch (error) {
    console.error(`Error in fetchDeletedLocations: ${error.message}`, error);
    throw error;
  }
};

export const recoverLocation = async (locationId) => {
  try {
    const response = await api.post(`/recover/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error recovering location:', error);
    throw new Error('Failed to recover location');
  }
};

export const calculatePercentages = async () => {
  try {
    const response = await api.post('/optimization/calculate-percentages');
    return response.data;
  } catch (error) {
    console.error('Error calculating percentages:', error);
    throw error;
  }
};

// Add this new function
export const getAllItems = async () => {
  try {
    const response = await api.get('/optimization/all-items');
    return response.data;
  } catch (error) {
    console.error('Error fetching all items:', error);
    throw error;
  }
};

export const getItemsByLocation = async () => {
  try {
    const response = await api.get('/optimization/items-by-location');
    return response.data;
  } catch (error) {
    console.error('Error fetching items by location:', error);
    throw error;
  }
};

// Add functions to interact with backend API
export const getMissingInventory = async (locationId) => {
    console.log(`Fetching missing inventory for location ${locationId}`);
    try {
        const response = await api.get(`/locations/${locationId}/missing-inventory`);
        console.log(`Successfully fetched missing inventory for location ${locationId}`, response.data);
        return response.data;
    } catch (error) {
        console.error(`Error fetching missing inventory for location ${locationId}:`, error);
        throw error
    }
};


export const createTransferOrder = async (orderData) => {
    console.log('Creating transfer order', orderData);
    try {
        const response = await api.post('/transfer-orders', orderData);
        console.log('Successfully created transfer order', response.data);
        return response.data;
    } catch (error) {
        console.error('Error creating transfer order:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getTransferOrders = async () => {
    console.log('Fetching transfer orders');
    try {
        const response = await api.get('/transfer-orders');
        console.log('Successfully fetched transfer orders', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching transfer orders:', error);
        throw error;
    }
};

export const updateTransferOrderStatus = async (orderId, statusName) => {
    console.log(`Updating status of transfer order ${orderId} to ${statusName}`);
    try {
        const response = await api.put(`/transfer-orders/${orderId}/status`, { statusName });
        console.log(`Successfully updated status of transfer order ${orderId}`, response.data);
        return response.data;
    } catch (error) {
        console.error(`Error updating status of transfer order ${orderId}:`, error);
        throw error;
    }
};

export const getLocationsWithMissingItems = async () => {
  try {
    const response = await api.get('/locations/missing-items');
    console.log('Raw missing items data:', response.data);  // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error fetching locations with missing items:', error);
    throw error;
  }
};

export const getAvailableTransferItems = async (locationId) => {
  try {
    const response = await api.get(`/locations/${locationId}/available-transfers`);
    return response.data;
  } catch (error) {
    console.error('Error fetching available transfer items:', error);
    throw error;
  }
};

export const updateAutoReplenishSettings = async (locationId, autoReplenishType, inventoryAmount, monthsOfSales) => {
    try {
        const response = await api.put(`/locations/${locationId}/auto-replenish`, {
            autoReplenishType,
            inventoryAmount,
            monthsOfSales
        });
        return response.data;
    } catch (error) {
        console.error('Error updating auto replenish settings:', error);
        throw error;
    }
};

export const updateInventoryForLocation = async (locationId) => {
  try {
    const response = await api.post(`/locations/${locationId}/update-inventory`);
    return response.data;
  } catch (error) {
    console.error('Error starting inventory update for location:', error);
    throw error;
  }
};

export const getInventoryUpdateStatus = async (locationId) => {
  try {
    const response = await api.get(`/locations/${locationId}/inventory-update-status`);
    return response.data;
  } catch (error) {
    console.error('Error getting inventory update status:', error);
    throw error;
  }
};

export const updateReplenishLevel = async (itemId, locationId, replenishLevel) => {
    try {
        const response = await api.put(`/optimization/items-by-location/${itemId}`, { locationId, replenishLevel });
        return response.data;
    } catch (error) {
        console.error('Error updating replenish level:', error);
        throw error;
    }
};

export const resetManualReplenishLevels = async () => {
  try {
    const response = await api.post('/optimization/reset-manual-replenish-levels');
    return response.data;
  } catch (error) {
    console.error('Error resetting manual replenish levels:', error);
    throw error;
  }
};

export const changeReplenishToAutomatic = async (itemId, locationId) => {
  try {
    const response = await api.put(`/optimization/items-by-location/${itemId}/automatic`, { locationId });
    return response.data;
  } catch (error) {
    console.error('Error changing replenish to automatic:', error);
    throw error;
  }
};

export const getSalesDataForLocation = async (locationId) => {
  try {
    const response = await api.get(`/inventory/sales-data/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sales data for location:', error);
    throw error;
  }
};

export const getLocationsWithSalesData = async () => {
  try {
    const response = await api.get('/inventory/locations-with-sales');
    return response.data.map(location => ({
      ...location,
      sales: typeof location.sales === 'number' ? location.sales : parseInt(location.sales, 10) || 0
    }));
  } catch (error) {
    console.error('Error fetching locations with sales data:', error);
    throw error;
  }
};

export const calculateAutoReplenishLevels = async (params) => {
  try {
    const response = await api.post('/inventory/calculate-auto-replenish', params);
    return response.data;
  } catch (error) {
    console.error('Error calculating auto replenish levels:', error);
    throw error;
  }
};

export const getAutoReplenishDetails = async (locationId) => {
  try {
    const response = await api.get(`/inventory/auto-replenish-details/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching auto replenish details:', error);
    throw error;
  }
};

// Add these new methods to api.js

// Get all item forwarders
export const getAllItemForwarders = async () => {
  try {
    const response = await api.get('/inventory/item-forwarders');
    return response.data;
  } catch (error) {
    console.error('Error fetching item forwarders:', error);
    throw error;
  }
};

// Create a single item forwarder
export const createItemForwarder = async (sourceItemId, targetItemId) => {
  try {
    const response = await api.post('/inventory/item-forwarders', {
      source_item_id: sourceItemId,
      target_item_id: targetItemId
    });
    return response.data;
  } catch (error) {
    console.error('Error creating item forwarder:', error);
    throw error;
  }
};

// Delete a single item forwarder
export const deleteItemForwarder = async (forwarderId) => {
  try {
    const response = await api.delete(`/inventory/item-forwarders/${forwarderId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting item forwarder:', error);
    throw error;
  }
};

// Create batch item forwarders
export const createBatchItemForwarders = async (oldBrim, newBrim, sizes) => {
  try {
    const response = await api.post('/inventory/item-forwarders/batch', {
      oldBrim,
      newBrim,
      sizes
    });
    return response.data;
  } catch (error) {
    console.error('Error creating batch item forwarders:', error);
    throw error;
  }
};

// Delete batch item forwarders
export const deleteBatchItemForwarders = async (forwarderIds) => {
  try {
    const response = await api.delete('/inventory/item-forwarders/batch', {
      data: { ids: forwarderIds }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting batch item forwarders:', error);
    throw error;
  }
};

// Enterprise Customer API functions
export const getAllCustomers = async () => {
  try {
    const response = await api.get('/enterprise-customers');
    return response;
  } catch (error) {
    console.error('Error fetching customers:', error);
    throw error;
  }
};

export const getCustomerById = async (id) => {
  try {
    const response = await api.get(`/enterprise-customers/${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching customer:', error);
    throw error;
  }
};

export const createCustomer = async (customerData) => {
  try {
    const response = await api.post('/enterprise-customers', customerData);
    return response;
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
};

export const updateCustomer = async (id, customerData) => {
  try {
    const response = await api.put(`/enterprise-customers/${id}`, customerData);
    return response;
  } catch (error) {
    console.error('Error updating customer:', error);
    throw error;
  }
};

export const deleteCustomer = async (id) => {
  try {
    const response = await api.delete(`/enterprise-customers/${id}`);
    return response;
  } catch (error) {
    console.error('Error deleting customer:', error);
    throw error;
  }
};

// Portal Access API functions
export const getAllPortalAccess = async () => {
  try {
    const response = await api.get('/enterprise-customers/portal-access/all');
    return response;
  } catch (error) {
    console.error('Error fetching portal access details:', error);
    throw error;
  }
};

export const createPortalAccess = async (customerId, portalData) => {
  try {
    const response = await api.post(`/enterprise-customers/${customerId}/portal-access`, portalData);
    return response;
  } catch (error) {
    console.error('Error creating portal access:', error);
    throw error;
  }
};

export const updatePortalAccess = async (customerId, portalData) => {
  try {
    const response = await api.put(`/enterprise-customers/${customerId}/portal-access`, portalData);
    return response;
  } catch (error) {
    console.error('Error updating portal access:', error);
    throw error;
  }
};

// Order-related API functions
export const getAllOrders = async () => {
  try {
    const response = await api.get('/orders');
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const getCustomerOrders = async (customerId) => {
  try {
    const response = await api.get(`/orders/customer/${customerId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customer orders:', error);
    throw error;
  }
};

export const getOrderById = async (orderId) => {
  try {
    const response = await api.get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const createOrder = async (orderData) => {
  try {
    const response = await api.post('/orders', orderData);
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const updateOrder = async (orderId, orderData) => {
  try {
    const response = await api.put(`/orders/${orderId}`, orderData);
    return response.data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

export const deleteOrder = async (orderId) => {
  try {
    const response = await api.delete(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting order:', error);
    throw error;
  }
};

// Warehouse API functions
export const getAllWarehouses = async () => {
  try {
    const response = await api.get('/warehouses');
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouses:', error);
    throw error;
  }
};

export const getAllWarehousesIncludingInactive = async () => {
  try {
    const response = await api.get('/warehouses/all');
    return response.data;
  } catch (error) {
    console.error('Error fetching all warehouses:', error);
    throw error;
  }
};

export const getInactiveWarehouses = async () => {
  try {
    const response = await api.get('/warehouses/inactive');
    return response.data;
  } catch (error) {
    console.error('Error fetching inactive warehouses:', error);
    throw error;
  }
};

export const getWarehouseById = async (id) => {
  try {
    const response = await api.get(`/warehouses/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse:', error);
    throw error;
  }
};

export const createWarehouse = async (warehouseData) => {
  try {
    const response = await api.post('/warehouses', warehouseData);
    return response.data;
  } catch (error) {
    console.error('Error creating warehouse:', error);
    throw error;
  }
};

export const updateWarehouse = async (id, warehouseData) => {
  try {
    const response = await api.put(`/warehouses/${id}`, warehouseData);
    return response.data;
  } catch (error) {
    console.error('Error updating warehouse:', error);
    throw error;
  }
};

export const getWarehouseItems = async (id) => {
  try {
    const response = await api.get(`/warehouses/${id}/items`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse items:', error);
    throw error;
  }
};

export const batchUpdateWarehouseItems = async (id, items) => {
  try {
    // Log the incoming data for debugging
    console.log('Batch update items input:', items);

    // Ensure we're only sending the required fields with proper validation
    const formattedItems = items.map(item => {
      // Ensure warehouse_item_id is a number
      const warehouseItemId = typeof item.warehouse_item_id === 'object' 
        ? parseInt(item.warehouse_item_id.warehouse_item_id)
        : parseInt(item.warehouse_item_id);

      return {
        warehouse_item_id: warehouseItemId,
        quantity: item.quantity !== undefined && item.quantity !== '' ? parseInt(item.quantity) : null,
        minimum_quantity: item.minimum_quantity !== undefined && item.minimum_quantity !== '' ? parseInt(item.minimum_quantity) : null,
        maximum_quantity: item.maximum_quantity !== undefined && item.maximum_quantity !== '' ? parseInt(item.maximum_quantity) : null,
        reorder_level: item.reorder_level !== undefined && item.reorder_level !== '' ? parseInt(item.reorder_level) : null
      };
    });

    // Filter out any items where all update fields are null or warehouse_item_id is invalid
    const validItems = formattedItems.filter(item => 
      !isNaN(item.warehouse_item_id) && 
      item.warehouse_item_id > 0 && (
        item.quantity !== null ||
        item.minimum_quantity !== null ||
        item.maximum_quantity !== null ||
        item.reorder_level !== null
      )
    );

    if (validItems.length === 0) {
      throw new Error('No valid items to update - please ensure at least one field is filled');
    }

    console.log('Sending formatted items:', validItems);

    const response = await api.put(`/warehouses/${id}/items`, { items: validItems });
    return response.data;
  } catch (error) {
    console.error('Error updating warehouse items:', error);
    throw error;
  }
};

export const updateWarehouseItem = async (warehouseId, itemId, itemData) => {
  try {
    const response = await api.put(`/warehouses/${warehouseId}/items/${itemId}`, itemData);
    return response.data;
  } catch (error) {
    console.error('Error updating warehouse item:', error);
    throw error;
  }
};

// Add these production-related API functions
export const getAllProductionEntries = async () => {
  try {
    const response = await api.get('/production');
    return response.data;
  } catch (error) {
    console.error('Error fetching production entries:', error);
    throw error;
  }
};

export const getProductionEntriesByWarehouse = async (warehouseId) => {
  try {
    const response = await api.get(`/production/warehouse/${warehouseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching production entries:', error);
    throw error;
  }
};

export const getWarehouseItemsForProduction = async (warehouseId) => {
  try {
    const response = await api.get(`/production/warehouse-items/${warehouseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse items for production:', error);
    throw error;
  }
};

export const getProductionEntryById = async (id) => {
  try {
    const response = await api.get(`/production/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching production entry:', error);
    throw error;
  }
};

export const createProductionEntry = async (productionData) => {
  try {
    const response = await api.post('/production', productionData);
    return response.data;
  } catch (error) {
    console.error('Error creating production entry:', error);
    throw error;
  }
};

export const updateProductionEntry = async (id, productionData) => {
  try {
    const response = await api.put(`/production/${id}`, productionData);
    return response.data;
  } catch (error) {
    console.error('Error updating production entry:', error);
    throw error;
  }
};

export const confirmProductionEntry = async (id) => {
  try {
    const response = await api.post(`/production/${id}/confirm`);
    return response.data;
  } catch (error) {
    console.error('Error confirming production entry:', error);
    throw error;
  }
};

export const cancelProductionEntry = async (id) => {
  try {
    const response = await api.post(`/production/${id}/cancel`);
    return response.data;
  } catch (error) {
    console.error('Error cancelling production entry:', error);
    throw error;
  }
};

// Add these inventory check-related functions
export const getWarehouseInventoryChecks = async (warehouseId) => {
  try {
    const response = await api.get(`/inventory-checks/warehouse/${warehouseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse inventory checks:', error);
    throw error;
  }
};

export const getInventoryCheckById = async (checkId) => {
  try {
    console.log('Fetching inventory check:', checkId);
    const response = await api.get(`/inventory-checks/${checkId}`);
    console.log('Received inventory check data:', response.data);
    if (!response.data) {
      throw new Error('No data received from server');
    }
    return response.data;
  } catch (error) {
    console.error('Error in getInventoryCheckById:', error);
    throw error.response?.data || error.message;
  }
};

export const createInventoryCheck = async (checkData) => {
  try {
    const response = await api.post('/inventory-checks', checkData);
    return response.data;
  } catch (error) {
    console.error('Error creating inventory check:', error);
    throw error;
  }
};

export const updateInventoryCheck = async (checkId, checkData) => {
  try {
    const response = await api.put(`/inventory-checks/${checkId}`, checkData);
    return response.data;
  } catch (error) {
    console.error('Error updating inventory check:', error);
    throw error;
  }
};

export const updateInventoryCheckStatus = async (checkId, status) => {
  try {
    const response = await api.patch(`/inventory-checks/${checkId}/status`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating inventory check status:', error);
    throw error;
  }
};

// Add these shipment-related functions to api.js
export const getAllShipments = async () => {
  try {
    const response = await api.get('/shipments');
    return response.data;
  } catch (error) {
    console.error('Error fetching shipments:', error);
    throw error;
  }
};

export const getShipmentById = async (id) => {
  try {
    const response = await api.get(`/shipments/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment:', error);
    throw error;
  }
};

export const createShipment = async (shipmentData) => {
  try {
    // Ensure all IDs are properly converted to integers
    const formattedData = {
      ...shipmentData,
      source_warehouse_id: parseInt(shipmentData.source_warehouse_id),
      destination_warehouse_id: shipmentData.destination_warehouse_id ? 
        parseInt(shipmentData.destination_warehouse_id) : null,
      destination_location_id: shipmentData.destination_location_id ? 
        parseInt(shipmentData.destination_location_id) : null,
      shipment_items: shipmentData.shipment_items.map(item => ({
        ...item,
        warehouse_item_id: parseInt(item.warehouse_item_id),
        order_item_id: item.order_item_id ? parseInt(item.order_item_id) : null,
        quantity: parseInt(item.quantity),
        is_part_of_order: Boolean(item.is_part_of_order)
      }))
    };

    const response = await api.post('/shipments', formattedData);
    return response.data;
  } catch (error) {
    console.error('Error creating shipment:', error);
    throw error;
  }
};

export const updateShipmentStatus = async (id, status, reason = '') => {
  try {
    const response = await api.patch(`/shipments/${id}/status`, { status, reason });
    return response.data;
  } catch (error) {
    console.error('Error updating shipment status:', error);
    throw error;
  }
};

export const addShipmentComment = async (id, comment_text) => {
  try {
    const response = await api.post(`/shipments/${id}/comments`, { comment_text });
    return response.data;
  } catch (error) {
    console.error('Error adding shipment comment:', error);
    throw error;
  }
};

export const getShipmentComments = async (id) => {
  try {
    const response = await api.get(`/shipments/${id}/comments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment comments:', error);
    throw error;
  }
};

export const getWarehouseInventory = async (warehouseId) => {
  try {
    const response = await api.get(`/warehouses/${warehouseId}/items`);
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouse inventory:', error);
    throw error;
  }
};

export const getAvailableOrderItems = async (warehouseId) => {
  try {
    const response = await api.get(`/shipments/warehouse/${warehouseId}/available-orders`);
    return response.data;
  } catch (error) {
    console.error('Error fetching available order items:', error);
    throw error;
  }
};

export const getWarehouseInventorySpreadsheet = async (warehouseId, type = null) => {
    try {
        const url = type 
            ? `/warehouses/${warehouseId}/inventory-spreadsheet?type=${type}`
            : `/warehouses/${warehouseId}/inventory-spreadsheet`;
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching warehouse inventory spreadsheet:', error);
        throw error;
    }
};

export const getSpreadsheetTypes = async () => {
  try {
    const response = await api.get('/spreadsheets/types');
    return response.data;
  } catch (error) {
    console.error('Error fetching spreadsheet types:', error);
    throw error;
  }
};

export const getShipmentFiles = async (id) => {
  try {
    const response = await api.get(`/shipments/${id}/files`);
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment files:', error);
    throw error;
  }
};

export const uploadShipmentFile = async (id, fileData, onProgress) => {
  try {
    console.log('Starting file upload process:', {
      shipmentId: id,
      fileName: fileData.name,
      fileSize: fileData.size,
      fileType: fileData.type
    });

    const formData = new FormData();
    formData.append('file', fileData);

    const response = await api.post(`/shipments/${id}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (onProgress) {
          onProgress(percentCompleted);
        }
      },
    });

    console.log('Upload response received:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in uploadShipmentFile:', error);
    throw error;
  }
};

export const getFilePreviewUrl = async (id, fileId) => {
  try {
    const response = await api.get(`/shipments/${id}/files/${fileId}/preview`);
    if (response.data && response.data.previewUrl) {
      // Add cache buster to prevent caching issues
      return `${response.data.previewUrl}?t=${Date.now()}`;
    }
    throw new Error('Invalid preview URL received');
  } catch (error) {
    console.error('Error getting file preview:', error);
    throw new Error('Failed to get file preview');
  }
};

export const downloadShipmentFile = async (id, fileId) => {
  try {
    const response = await api.get(`/shipments/${id}/files/${fileId}/download`, {
      responseType: 'blob'  // Important: Set responseType to blob
    });
    return response.data;
  } catch (error) {
    console.error('Error downloading shipment file:', error);
    throw new Error('Failed to download shipment file');
  }
};

export const getShipmentSpreadsheetData = async (warehouseId, orderId, selectedItems = []) => {
  try {
    const response = await api.get(`/shipments/warehouse/${warehouseId}/order/${orderId}/spreadsheet`, {
      params: { selectedItems: JSON.stringify(selectedItems) }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment spreadsheet data:', error);
    throw error;
  }
};

export const getShipmentTypes = async (shipmentId) => {
  try {
    const response = await api.get(`/shipments/${shipmentId}/types`);
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment types:', error);
    throw error;
  }
};

export const getOrderComments = async (orderId) => {
  try {
    const response = await api.get(`/orders/${orderId}/comments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order comments:', error);
    throw error;
  }
};

export const addOrderComment = async (orderId, comment_text) => {
  try {
    const response = await api.post(`/orders/${orderId}/comments`, { comment_text });
    return response.data;
  } catch (error) {
    console.error('Error adding order comment:', error);
    throw error;
  }
};

export const updateOrderStatus = async (orderId, status, rejectionReason = null) => {
  try {
    const response = await api.patch(`/orders/${orderId}/status`, { 
      status, 
      rejection_reason: rejectionReason 
    });
    return response.data;
  } catch (error) {
    console.error('Error updating order status:', error);
    throw error;
  }
};

export const getOrderSpreadsheetData = async (orderId, type, view = 'ordered') => {
  try {
    const response = await api.get(`/orders/${orderId}/spreadsheet/${type}`, {
      params: { view }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order spreadsheet data:', error);
    throw new Error('Failed to fetch spreadsheet data');
  }
};

export const getOrderTypes = async (orderId) => {
  try {
    const response = await api.get(`/orders/${orderId}/types`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order types:', error);
    throw error;
  }
};

export const getAvailableTransferItemsIgnoreSource = async (locationId) => {
    try {
        const response = await api.get(`/locations/${locationId}/available-transfers-ignore-source`);
        return response.data;
    } catch (error) {
        console.error('Error fetching available transfer items (ignore source):', error);
        throw error;
    }
};

export const getShipmentOrders = async (id) => {
  try {
    const response = await api.get(`/shipments/${id}/orders`);
    return response.data;
  } catch (error) {
    console.error('Error fetching shipment orders:', error);
    throw error;
  }
};

export const getOrderMultiSpreadsheetData = async (warehouseId, orderId, selectedItems = [], type = null) => {
  try {
    const response = await api.get(`/shipments/warehouse/${warehouseId}/order/${orderId}/multi-spreadsheet`, {
      params: { 
        selectedItems: JSON.stringify(selectedItems),
        type
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order multi-spreadsheet data:', error);
    throw error;
  }
};

export const getShipmentsByCustomer = async (customerId) => {
  try {
    const response = await api.get(`/enterprise-customers/${customerId}/shipments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customer shipments:', error);
    throw error;
  }
};

export const getCustomerUpdates = async (customerId) => {
  try {
    const response = await api.get(`/enterprise-customers/${customerId}/updates`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customer updates:', error);
    throw error;
  }
};

export const createCustomerUpdate = async (customerId, updateData) => {
  try {
    const response = await api.post(`/enterprise-customers/${customerId}/updates`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error creating customer update:', error);
    throw error;
  }
};

export const deleteCustomerUpdate = async (customerId, updateId) => {
  try {
    const response = await api.delete(`/enterprise-customers/${customerId}/updates/${updateId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting customer update:', error);
    throw error;
  }
};

export const approveCustomerUpdate = async (customerId, updateId) => {
  try {
    const response = await api.put(`/enterprise-customers/${customerId}/updates/${updateId}/approve`);
    return response.data;
  } catch (error) {
    console.error('Error approving customer update:', error);
    throw error;
  }
};

export const getConsolidatedShipmentData = async (shipmentId) => {
  try {
    const response = await api.get(`/shipments/${shipmentId}/consolidated-spreadsheet`);
    return response.data;
  } catch (error) {
    console.error('Error fetching consolidated shipment data:', error);
    throw error;
  }
};

export const downloadShippingList = async (shipmentId) => {
  try {
    const response = await api.get(`/shipments/${shipmentId}/shipping-list`, {
      responseType: 'blob'  // Important for handling binary data
    });

    // Create download link
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `shipping-list-${shipmentId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading shipping list:', error);
    throw new Error('Failed to download shipping list: ' + (error.response?.data?.message || error.message));
  }
};

export const deleteAllSheets = async () => {
  try {
    const response = await api.delete('/settings/sheets');
    return response.data;
  } catch (error) {
    console.error('Error deleting all sheets:', error);
    throw error;
  }
};

export const deactivateWarehouse = async (id) => {
  try {
    const response = await api.post(`/warehouses/${id}/deactivate`);
    return response.data;
  } catch (error) {
    console.error('Error deactivating warehouse:', error);
    throw error;
  }
};

export const activateWarehouse = async (id) => {
  try {
    const response = await api.post(`/warehouses/${id}/activate`);
    return response.data;
  } catch (error) {
    console.error('Error activating warehouse:', error);
    throw error;
  }
};

export default api;
