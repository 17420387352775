import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { PrimeReactProvider } from 'primereact/api';
import Login from './components/Login';
import Home from './components/Home';
import Navigation from './components/Navigation'; // Update this import
import Locations from './components/Locations';
import Settings from './components/Settings';
import POSManagement from './components/POSManagement';
import UserManagement from './components/UserManagement';
import { logout } from './services/api';
import LocationDetails from './components/LocationDetails';
import { jwtDecode } from 'jwt-decode';
import Sales from './components/Sales';
import ReplenishLevels from './components/ReplenishLevels';
import DeletedLocations from './components/DeletedLocations';
import Optimize from './components/Optimize';
import TransferOrders from './components/TransferOrders'; // Import the new component
import EmbeddedSheet from './components/EmbeddedSheet';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ItemForwarders from './components/ItemForwarders';
import CustomerManagement from './components/CustomerManagement';
import Orders from './components/Orders';
import OrderForm from './components/OrderForm';
import Warehouses from './components/Warehouses';
import WarehouseDetails from './components/WarehouseDetails';
import ProductionEntry from './components/ProductionEntry';
import InventoryCheckForm from './components/InventoryCheckForm';
import Shipments from './components/Shipments';
import CreateShipment from './components/CreateShipment';
import ShipmentDetails from './components/ShipmentDetails';
import TestSpreadsheet from './components/spreadsheets/testspreadsheet';
import TestSpreadsheet2 from './components/spreadsheets/testspreadsheet2';
import TestSpreadsheet3 from './components/spreadsheets/testspreadsheet3';
import OrderDetails from './components/OrderDetails';
import CustomerLogin from './components/CustomerLogin';
import CustomerDashboard from './components/CustomerDashboard';
import CustomerProtectedRoute from './components/CustomerProtectedRoute';
import CustomerForgotPassword from './components/CustomerForgotPassword';
import CustomerResetPassword from './components/CustomerResetPassword';
import CustomerLayout from './components/layouts/CustomerLayout';
import PublicCustomerRoute from './components/PublicCustomerRoute';
import CustomerOrderForm from './components/CustomerOrderForm';
import CustomerOrderDetails from './components/CustomerOrderDetails';
import SideNav from './components/SideNav';
import { DRAWER_WIDTH } from './constants/layout';
import Items from './components/Items';
import TestSpreadsheet4 from './components/spreadsheets/testspreadsheet4';
import TestSpreadsheet5 from './components/spreadsheets/testspreadsheet5';
import CustomerDetails from './components/CustomerDetails';
import { SnackbarProvider } from 'notistack';
import Management from './components/Management';
import TrackingDetails from './components/TrackingDetails';
import ProductionDetails from './components/ProductionDetails';
import InventoryCheckDetails from './components/InventoryCheckDetails';
import ShipmentPacking from './components/ShipmentPacking';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import TestSpreadsheet6 from './components/spreadsheets/testspreadsheet6';
import TestOptimizedSpreadsheet from './components/spreadsheets/TestOptimizedSpreadsheet';
import ItemsCacheService from './services/itemsCacheService';
import MetricsAndViews from './components/metrics/MetricsAndViews';
import ViewData from './components/metrics/ViewData';
import TestV2Spreadsheet from './components/spreadsheets/TestV2Spreadsheet';
import InventoryDistribution from './components/InventoryDistribution';
import DistributionDetails from './components/DistributionDetails';

// Hard code the frontend port if needed
const FRONTEND_PORT = 3000;

// Create a context for layout configuration
export const LayoutContext = createContext({
  hasSideNav: false,
  hasNavigation: false,
  isMinimized: false
});

const theme = createTheme({
  palette: {
    mode: 'light', // Add this line
    primary: {
      main: '#1a2b3c',
    },
    secondary: {
      main: '#20b2aa',
    },
    background: {
      default: '#f5f7fa',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});

// Helper function to check if user has access
const hasAccess = (user, requiredRole) => {
  if (!user) return false;
  return user.role === requiredRole || user.role === 'admin' || user.role_id === 3 || user.role === 'test';
};

// Update the route protection logic
// Replace the existing route protection checks with this new helper function
const isAuthorizedUser = (user) => {
  return user && (user.role === 'admin' || user.role_id === 3 || user.role === 'test');
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            const user = JSON.parse(localStorage.getItem('user'));
            setUser(user);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          handleLogout();
        }
      }
      setLoading(false);
    };

    checkToken();
  }, []);

  useEffect(() => {
    if (user) {
      console.log('Current user:', user);
      console.log('Is test user:', user.role_id === 3 || user.role === 'test');
      console.log('User role_id:', user.role_id);
      console.log('User role:', user.role);
    }
  }, [user]);

  const handleLogin = async (userData) => {
    setUser(userData);
    try {
        // Initialize items cache after successful login
        await ItemsCacheService.getItems();
    } catch (error) {
        console.error('Error initializing items cache:', error);
    }
  };

  const handleLogout = async () => {
    try {
        await logout();
        // Clear items cache on logout
        ItemsCacheService.clearCache();
        setUser(null);
        localStorage.clear();
        window.location.href = '/login';
    } catch (error) {
        console.error('Error during logout:', error);
        ItemsCacheService.clearCache();
        setUser(null);
        localStorage.clear();
        window.location.href = '/login';
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const isTestUser = user?.role_id === 3 || user?.role === 'test';
  const layoutConfig = {
    hasSideNav: isTestUser,
    hasNavigation: !isTestUser,
    isMinimized: isMinimized
  };

  return (
    <SnackbarProvider 
      maxSnack={3} 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      style={{
        marginBottom: '16px',
        transform: isTestUser ? 
          (isMinimized ? 'translateX(32.5px)' : `translateX(${DRAWER_WIDTH/2}px)`) : 
          'none'
      }}
    >
      <ThemeProvider theme={theme}>
        <PrimeReactProvider>
          <CssBaseline />
          <Router>
            <LayoutContext.Provider value={layoutConfig}>
              <Box sx={{ display: 'flex' }}>
                {isTestUser && (
                  <SideNav 
                    isMinimized={isMinimized} 
                    setIsMinimized={setIsMinimized}
                  />
                )}
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    minHeight: '100vh',
                    marginLeft: 0,
                    transition: 'margin 0.3s ease',
                    backgroundColor: '#f5f7fa',
                    width: '100%',
                    borderLeft: isTestUser ? '1px solid rgba(0, 0, 0, 0.05)' : 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.breakpoints.up('md')]: {
                      marginLeft: isTestUser ? (isMinimized ? '65px' : `${DRAWER_WIDTH}px`) : 0,
                      width: isTestUser ? 
                        (isMinimized ? `calc(100% - 65px)` : `calc(100% - ${DRAWER_WIDTH}px)`) : 
                        '100%',
                    },
                  }}
                >
                  {user && !window.location.pathname.startsWith('/customer') && !isTestUser && (
                    <Navigation 
                      user={user} 
                      onLogout={handleLogout} 
                      isSideNavPresent={isTestUser}
                    />
                  )}
                  <Box 
                    sx={{ 
                      p: 0.5,
                      width: '100%',
                      height: '100%',
                      overflow: 'auto'
                    }}
                  >
                    <Routes>
                      <Route path="/customer/*" element={<CustomerLayout />}>
                        <Route path="login" element={
                          <PublicCustomerRoute>
                            <CustomerLogin />
                          </PublicCustomerRoute>
                        } />
                        <Route path="forgot-password" element={
                          <PublicCustomerRoute>
                            <CustomerForgotPassword />
                          </PublicCustomerRoute>
                        } />
                        <Route path="reset-password" element={
                          <PublicCustomerRoute>
                            <CustomerResetPassword />
                          </PublicCustomerRoute>
                        } />
                        <Route path="dashboard" element={
                          <CustomerProtectedRoute>
                            <CustomerDashboard />
                          </CustomerProtectedRoute>
                        } />
                        <Route path="orders/create" element={
                          <CustomerProtectedRoute>
                            <CustomerOrderForm />
                          </CustomerProtectedRoute>
                        } />
                        <Route path="orders/:orderId" element={
                          <CustomerProtectedRoute>
                            <CustomerOrderDetails />
                          </CustomerProtectedRoute>
                        } />
                      </Route>

                      <Route path="/login" element={user ? <Navigate to="/retail/home" /> : <Login onLogin={handleLogin} />} />
                      <Route path="/" element={user ? <Navigate to="/retail/home" /> : <Navigate to="/login" />} />
                      <Route path="/retail">
                        <Route path="home" element={user ? <Home /> : <Navigate to="/login" />} />
                      </Route>
                      <Route path="/locations" element={user ? <Locations /> : <Navigate to="/login" />} />
                      <Route path="/locations/:id" element={user ? <LocationDetails /> : <Navigate to="/login" />} />
                      <Route path="/locations/:id/replenish-levels" element={user ? <ReplenishLevels /> : <Navigate to="/login" />} />
                      <Route 
                        path="/settings" 
                        element={isAuthorizedUser(user) ? <Settings /> : <Navigate to="/" />} 
                      />
                      <Route 
                        path="/pos-management" 
                        element={isAuthorizedUser(user) ? <POSManagement /> : <Navigate to="/" />} 
                      />
                      <Route 
                        path="/user-management" 
                        element={isAuthorizedUser(user) ? <UserManagement /> : <Navigate to="/" />} 
                      />
                      <Route 
                        path="/sales" 
                        element={isAuthorizedUser(user) ? <Sales /> : <Navigate to="/" />} 
                      />
                      <Route path="/deleted-locations" element={<DeletedLocations />} />
                      <Route 
                        path="/optimize" 
                        element={user ? <Optimize /> : <Navigate to="/login" />}
                      />
                      <Route 
                        path="/transfer-orders"
                        element={isAuthorizedUser(user) ? <TransferOrders /> : <Navigate to="/" />}
                      />
                      <Route path="/locations/:id/embedded-sheet/:sheetType" element={<EmbeddedSheet />} />
                      <Route path="/privacy" element={<PrivacyPolicy />} />
                      <Route path="/terms" element={<TermsOfService />} />
                      <Route 
                        path="/item-forwarders" 
                        element={isAuthorizedUser(user) ? <ItemForwarders /> : <Navigate to="/" />}
                      />
                      <Route 
                        path="/items" 
                        element={isAuthorizedUser(user) ? <Items /> : <Navigate to="/" />} 
                      />
                      <Route path="/customers" element={<CustomerManagement />} />
                      <Route 
                        path="/orders" 
                        element={user ? <Orders /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/orders/create" 
                        element={user ? <OrderForm /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/orders/:orderId/edit" 
                        element={user ? <OrderForm /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/orders/:orderId/details" 
                        element={user ? <OrderDetails /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/warehouses" 
                        element={user ? <Warehouses /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/warehouses/:id" 
                        element={user ? <WarehouseDetails /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/warehouses/:warehouseId/production/create" 
                        element={user ? <ProductionEntry /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/warehouses/:warehouseId/production/:productionId/edit" 
                        element={user ? <ProductionEntry /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/warehouses/:warehouseId/inventory-checks/create" 
                        element={user ? <InventoryCheckForm /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/warehouses/:warehouseId/inventory-checks/:checkId/edit" 
                        element={user ? <InventoryCheckForm /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/shipments" 
                        element={user ? <Shipments /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/shipments/create" 
                        element={user ? <CreateShipment /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/shipments/:id" 
                        element={user ? <ShipmentDetails /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/testspreadsheet/1" 
                        element={user ? <TestSpreadsheet /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/testspreadsheet/2" 
                        element={user ? <TestSpreadsheet2 /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/testspreadsheet/3" 
                        element={user ? <TestSpreadsheet3 /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/testspreadsheet/4" 
                        element={user ? <TestSpreadsheet4 /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/testspreadsheet/5" 
                        element={user ? <TestSpreadsheet5 /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/testspreadsheet/6" 
                        element={user ? <TestSpreadsheet6 /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/production-plan" 
                        element={isAuthorizedUser(user) ? <Navigate to="/" /> : <Navigate to="/" />} 
                      />
                      <Route 
                        path="/automation" 
                        element={isAuthorizedUser(user) ? <Navigate to="/" /> : <Navigate to="/" />} 
                      />
                      <Route 
                        path="/insights" 
                        element={isAuthorizedUser(user) ? <Navigate to="/" /> : <Navigate to="/" />} 
                      />
                      <Route 
                        path="/customers/:id" 
                        element={isAuthorizedUser(user) ? <CustomerDetails /> : <Navigate to="/" />}
                      />
                      <Route 
                        path="/management" 
                        element={user ? <Management /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/management/tracking/:id" 
                        element={user ? <TrackingDetails /> : <Navigate to="/login" />} 
                      />
                      <Route path="/warehouses/:warehouseId/production/:id" element={<ProductionDetails />} />
                      <Route 
                        path="/warehouses/:warehouseId/inventory-checks/:id" 
                        element={<InventoryCheckDetails />} 
                      />
                      <Route 
                        path="/shipments/:shipmentId/pack" 
                        element={user ? <ShipmentPacking /> : <Navigate to="/login" />} 
                      />
                      <Route path="/newoptimzed" element={<TestOptimizedSpreadsheet />} />
                      <Route 
                        path="/metrics-and-views" 
                        element={isAuthorizedUser(user) ? <MetricsAndViews /> : <Navigate to="/" />}
                      />
                      <Route path="/views/:viewId/data" element={<ViewData />} />
                      <Route 
                        path="/v2spreadsheet" 
                        element={user ? <TestV2Spreadsheet /> : <Navigate to="/login" />} 
                      />
                      <Route 
                        path="/inventory-distribution" 
                        element={isAuthorizedUser(user) ? <InventoryDistribution /> : <Navigate to="/" />}
                      />
                      <Route 
                        path="/inventory-distribution/:id" 
                        element={isAuthorizedUser(user) ? <DistributionDetails /> : <Navigate to="/" />}
                      />
                    </Routes>
                  </Box>
                </Box>
              </Box>
            </LayoutContext.Provider>
          </Router>
        </PrimeReactProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
