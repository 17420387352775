import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const LoadingAnimation = () => {
  const letters = 'LOADING'.split('');
  
  return (
    <Box 
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        backdropFilter: 'blur(8px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        overflow: 'hidden'
      }}
    >
      {/* Background gradient animation */}
      <Box
        component={motion.div}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '200%',
          height: '200%',
          background: 'radial-gradient(circle, rgba(25,118,210,0.1) 0%, rgba(0,0,0,0) 50%)',
          transform: 'translate(-50%, -50%)',
          zIndex: 0,
        }}
        animate={{
          scale: [1, 1.5, 1],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
      />

      {/* Main loading text */}
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Box 
          sx={{ 
            display: 'flex', 
            gap: 1,
            mb: 4
          }}
        >
          {letters.map((letter, index) => (
            <motion.div
              key={index}
              initial={{ y: 0 }}
              animate={{ 
                y: [-20, 0, -20],
                color: ['#1976d2', '#90caf9', '#1976d2'],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                delay: index * 0.1,
                ease: "easeInOut"
              }}
              style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                fontFamily: 'monospace',
                color: '#1976d2',
                textShadow: '0 0 20px rgba(25,118,210,0.5)',
              }}
            >
              {letter}
            </motion.div>
          ))}
        </Box>
      </Box>

      {/* Animated dots */}
      <Box 
        sx={{ 
          display: 'flex', 
          gap: 1,
          mt: 2 
        }}
      >
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: '#1976d2',
              borderRadius: '50%',
            }}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              delay: i * 0.2,
            }}
          />
        ))}
      </Box>

      {/* Floating particles */}
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          style={{
            position: 'absolute',
            width: '4px',
            height: '4px',
            backgroundColor: 'rgba(25,118,210,0.5)',
            borderRadius: '50%',
          }}
          animate={{
            y: [0, -1000],
            x: Math.sin(i) * 500,
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 5 + Math.random() * 5,
            repeat: Infinity,
            delay: i * 0.2,
            ease: "linear",
          }}
          initial={{
            x: Math.random() * window.innerWidth,
            y: window.innerHeight + 10,
          }}
        />
      ))}

      {/* Progress bar */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '4px',
          overflow: 'hidden',
        }}
      >
        <motion.div
          style={{
            height: '100%',
            background: 'linear-gradient(90deg, #1976d2, #90caf9)',
          }}
          animate={{
            x: ['-100%', '100%'],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: 'linear',
          }}
        />
      </Box>
    </Box>
  );
};

export default LoadingAnimation; 