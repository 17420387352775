import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Paper,
  InputAdornment,
  Alert,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Store as StoreIcon,
  Link as LinkIcon,
  VpnKey as VpnKeyIcon,
  InfoOutlined as InfoOutlinedIcon,
  PointOfSale as PointOfSaleIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getPosSystems, deletePos, addPos } from '../services/api';
import FullScreenLayout from './layouts/FullScreenLayout';

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  backgroundColor: theme.palette.background.default,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 12px rgba(0,0,0,0.03)',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  },
}));

const CreatePOSButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '10px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
    transform: 'translateY(-1px)',
  },
  transition: 'all 0.2s ease',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid #e2e8f0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.15)',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#64748b',
    fontWeight: 500,
  },
  '& .MuiInputBase-input': {
    padding: '16px',
  },
}));

const InfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#f1f5f9',
  border: '1px solid #e2e8f0',
  borderRadius: '12px',
  marginTop: theme.spacing(2),
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(6),
  textAlign: 'center',
}));

function POSManagement() {
  const [posSystems, setPosSystems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedPos, setSelectedPos] = useState(null);
  const [newPos, setNewPos] = useState({ name: '', current_api_url: '', current_api_key: '' });

  useEffect(() => {
    fetchPosSystems();
  }, []);

  const fetchPosSystems = async () => {
    try {
      const response = await getPosSystems();
      setPosSystems(response.data);
    } catch (error) {
      console.error('Error fetching POS systems:', error);
    }
  };

  const handleDeleteClick = (pos) => {
    setSelectedPos(pos);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePos(selectedPos.pos_id);
      setPosSystems(posSystems.filter(pos => pos.pos_id !== selectedPos.pos_id));
      setOpenDialog(false);
    } catch (error) {
      console.error('Error deleting POS system:', error);
    }
  };

  const handleAddPos = async () => {
    try {
      await addPos(newPos);
      fetchPosSystems();
      setOpenAddDialog(false);
      setNewPos({ name: '', current_api_url: '', current_api_key: '' });
    } catch (error) {
      console.error('Error adding POS system:', error);
    }
  };

  const renderHeader = () => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      mb: 3,
      flexWrap: 'wrap',
      gap: 2
    }}>
      <Typography variant="h4" sx={{ fontWeight: 600, color: 'text.primary' }}>
        POS Systems
      </Typography>
      <CreatePOSButton
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setOpenAddDialog(true)}
      >
        Add POS System
      </CreatePOSButton>
    </Box>
  );

  const mainContent = (
    <>
      <Grid container spacing={3}>
        {posSystems.length > 0 ? (
          posSystems.map((pos) => (
            <Grid item xs={12} sm={6} md={4} key={pos.pos_id}>
              <StyledCard>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <PointOfSaleIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6" component="h2">
                      {pos.name}
                    </Typography>
                  </Box>
                  <Typography color="text.secondary" sx={{ mb: 1 }}>
                    <LinkIcon sx={{ fontSize: 16, mr: 0.5, verticalAlign: 'middle' }} />
                    {pos.current_api_url}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <IconButton
                      onClick={() => handleDeleteClick(pos)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <EmptyState>
              <PointOfSaleIcon sx={{ fontSize: 64, color: 'primary.light', mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                No POS Systems Connected
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Connect your first POS system to start managing inventory
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddDialog(true)}
              >
                Add POS System
              </Button>
            </EmptyState>
          </Grid>
        )}
      </Grid>

      <StyledDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <WarningIcon color="warning" />
          <Typography variant="h6">Confirm Deletion</Typography>
        </DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mb: 2 }}>
            This action cannot be undone. All data associated with this POS system will be permanently deleted.
          </Alert>
          <Typography>
            Are you sure you want to delete the POS system "{selectedPos?.name}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </StyledDialog>

      <StyledDialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PointOfSaleIcon />
          Add New POS System
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Connect a new Point of Sale system to your inventory management
            </Typography>
          </Box>
          <StyledTextField
            autoFocus
            label="POS Name"
            fullWidth
            value={newPos.name}
            onChange={(e) => setNewPos({ ...newPos, name: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StoreIcon sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <StyledTextField
            label="API URL"
            fullWidth
            value={newPos.current_api_url}
            onChange={(e) => setNewPos({ ...newPos, current_api_url: e.target.value })}
            helperText="Enter the base URL for the POS API"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <StyledTextField
            label="API Key"
            fullWidth
            value={newPos.current_api_key}
            onChange={(e) => setNewPos({ ...newPos, current_api_key: e.target.value })}
            type="password"
            helperText="Enter the authentication key for API access"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyIcon sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
          />
          <InfoPaper elevation={0}>
            <Typography variant="subtitle2" color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <InfoOutlinedIcon fontSize="small" />
              Connection Information
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Make sure you have the following information from your POS provider:
              <Box component="ul" sx={{ mt: 1, mb: 0 }}>
                <li>API base URL (usually starts with https://)</li>
                <li>Valid API key or token for authentication</li>
                <li>Necessary permissions configured in your POS system</li>
              </Box>
            </Typography>
          </InfoPaper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Cancel</Button>
          <Button
            onClick={handleAddPos}
            variant="contained"
            color="primary"
            disabled={!newPos.name || !newPos.current_api_url || !newPos.current_api_key}
          >
            Add POS System
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );

  return (
    <FullScreenLayout
      header={renderHeader()}
      mainPanel={
        <PageContainer>
          {renderHeader()}
          <ContentPaper elevation={0}>
            {mainContent}
          </ContentPaper>
        </PageContainer>
      }
    />
  );
}

export default POSManagement;
