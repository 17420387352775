import React, { useState, useCallback } from 'react';
import { Box, Button, TextField, Paper, Stack, IconButton, Chip, Grid, Card, CardContent, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MultiValueSpreadsheet from './MultiValueSpreadsheet';

function TestSpreadsheet5() {
  const [newMetric, setNewMetric] = useState('');
  const [newSize, setNewSize] = useState('');
  const [newBrim, setNewBrim] = useState('');
  const [metrics, setMetrics] = useState(['inventory', 'replenish', 'order']);
  const [sizes, setSizes] = useState(['6 7/8', '7', '7 1/8']);
  const [brims, setBrims] = useState(['2', '2 1/8']);
  const [changes, setChanges] = useState([]);

  // Initialize spreadsheet data with the current brims
  const [spreadsheetData, setSpreadsheetData] = useState(() => {
    const initialData = {};
    sizes.forEach(size => {
      initialData[size] = {};
      brims.forEach(brim => {
        initialData[size][brim] = {
          "XR": {},
          "R": {},
          "L": {},
          "X": {}
        };
        metrics.forEach(metric => {
          ["XR", "R", "L", "X"].forEach(shape => {
            initialData[size][brim][shape][metric] = Math.floor(Math.random() * 100);
          });
        });
      });
    });
    return initialData;
  });

  const handleAddBrim = () => {
    if (newBrim && !brims.includes(newBrim)) {
      setBrims([...brims, newBrim]);
      
      // Add the new brim to all sizes with random values
      const updatedData = { ...spreadsheetData };
      Object.keys(updatedData).forEach(size => {
        updatedData[size][newBrim] = {
          "XR": {},
          "R": {},
          "L": {},
          "X": {}
        };
        metrics.forEach(metric => {
          ["XR", "R", "L", "X"].forEach(shape => {
            updatedData[size][newBrim][shape][metric] = Math.floor(Math.random() * 100);
          });
        });
      });
      
      setSpreadsheetData(updatedData);
      setNewBrim('');
    }
  };

  const handleDeleteBrim = (brimToDelete) => {
    if (brims.length <= 1) {
      alert("Cannot delete the last brim size");
      return;
    }
    
    setBrims(brims.filter(brim => brim !== brimToDelete));
    
    // Remove the brim from all sizes
    const updatedData = { ...spreadsheetData };
    Object.keys(updatedData).forEach(size => {
      delete updatedData[size][brimToDelete];
    });
    setSpreadsheetData(updatedData);
  };

  const handleAddMetric = () => {
    if (newMetric && !metrics.includes(newMetric)) {
      setMetrics([...metrics, newMetric]);
      
      const updatedData = { ...spreadsheetData };
      Object.keys(updatedData).forEach(size => {
        Object.keys(updatedData[size]).forEach(brim => {
          Object.keys(updatedData[size][brim]).forEach(shape => {
            updatedData[size][brim][shape][newMetric] = Math.floor(Math.random() * 100);
          });
        });
      });
      
      setSpreadsheetData(updatedData);
      setNewMetric('');
    }
  };

  const handleAddSize = () => {
    if (newSize && !sizes.includes(newSize)) {
      setSizes([...sizes, newSize]);
      
      const updatedData = { ...spreadsheetData };
      updatedData[newSize] = {};
      brims.forEach(brim => {
        updatedData[newSize][brim] = {
          "XR": {},
          "R": {},
          "L": {},
          "X": {}
        };
        metrics.forEach(metric => {
          ["XR", "R", "L", "X"].forEach(shape => {
            updatedData[newSize][brim][shape][metric] = Math.floor(Math.random() * 100);
          });
        });
      });
      
      setSpreadsheetData(updatedData);
      setNewSize('');
    }
  };

  const handleDeleteMetric = (metricToDelete) => {
    if (metrics.length <= 1) {
      alert("Cannot delete the last metric");
      return;
    }
    setMetrics(metrics.filter(metric => metric !== metricToDelete));
    
    const updatedData = { ...spreadsheetData };
    Object.keys(updatedData).forEach(size => {
      Object.keys(updatedData[size]).forEach(brim => {
        Object.keys(updatedData[size][brim]).forEach(shape => {
          delete updatedData[size][brim][shape][metricToDelete];
        });
      });
    });
    
    setSpreadsheetData(updatedData);
  };

  const handleDeleteSize = (sizeToDelete) => {
    if (sizes.length <= 1) {
      alert("Cannot delete the last size");
      return;
    }
    setSizes(sizes.filter(size => size !== sizeToDelete));
    
    const updatedData = { ...spreadsheetData };
    delete updatedData[sizeToDelete];
    setSpreadsheetData(updatedData);
  };

  const handleCellValueChanged = useCallback(({ size, brim, shape, metric, newValue, oldValue }) => {
    setSpreadsheetData(prevData => {
      const newData = { ...prevData };
      newData[size][brim][shape][metric] = newValue;
      return newData;
    });

    // Add change to history
    setChanges(prevChanges => [{
      timestamp: new Date().toLocaleTimeString(),
      size,
      brim,
      shape,
      metric,
      oldValue,
      newValue
    }, ...prevChanges]);
  }, []);

  const handleRandomize = () => {
    const updatedData = { ...spreadsheetData };
    Object.keys(updatedData).forEach(size => {
      Object.keys(updatedData[size]).forEach(brim => {
        Object.keys(updatedData[size][brim]).forEach(shape => {
          metrics.forEach(metric => {
            updatedData[size][brim][shape][metric] = Math.floor(Math.random() * 100);
          });
        });
      });
    });
    setSpreadsheetData(updatedData);
  };

  return (
    <Grid container spacing={2} sx={{ p: 3 }}>
      <Grid item xs={12} md={9}>
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Stack spacing={3}>
            <Box>
              <h3>Metrics</h3>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  label="New Metric"
                  value={newMetric}
                  onChange={(e) => setNewMetric(e.target.value)}
                  size="small"
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddMetric}
                >
                  Add Metric
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {metrics.map(metric => (
                  <Chip
                    key={metric}
                    label={metric}
                    onDelete={() => handleDeleteMetric(metric)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>

            <Box>
              <h3>Sizes</h3>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  label="New Size"
                  value={newSize}
                  onChange={(e) => setNewSize(e.target.value)}
                  size="small"
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddSize}
                >
                  Add Size
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {sizes.map(size => (
                  <Chip
                    key={size}
                    label={size}
                    onDelete={() => handleDeleteSize(size)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>

            <Box>
              <h3>Brims</h3>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  label="New Brim"
                  value={newBrim}
                  onChange={(e) => setNewBrim(e.target.value)}
                  size="small"
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddBrim}
                >
                  Add Brim
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {brims.map(brim => (
                  <Chip
                    key={brim}
                    label={brim}
                    onDelete={() => handleDeleteBrim(brim)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>

            <Button
              variant="outlined"
              onClick={handleRandomize}
              sx={{ alignSelf: 'flex-start' }}
            >
              Randomize All Values
            </Button>
          </Stack>
        </Paper>

        <MultiValueSpreadsheet
          data={spreadsheetData}
          title="Test Multi-Value Spreadsheet"
          onCellValueChanged={handleCellValueChanged}
          hideZeros={false}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Card sx={{ position: 'sticky', top: 16 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Change History
            </Typography>
            <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
              {changes.map((change, index) => (
                <Paper 
                  key={index} 
                  sx={{ 
                    p: 1, 
                    mb: 1, 
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    borderLeft: '3px solid #1a2b3c'
                  }}
                >
                  <Typography variant="caption" display="block" color="text.secondary">
                    {change.timestamp}
                  </Typography>
                  <Typography variant="body2">
                    Size: {change.size}, Brim: {change.brim}, Shape: {change.shape}
                  </Typography>
                  <Typography variant="body2">
                    {change.metric}: {change.oldValue} → {change.newValue}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default TestSpreadsheet5; 