import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Paper,
    Grid,
    Typography,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Chip,
    TextField,
    InputAdornment,
    Backdrop,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Search as SearchIcon, GridView, TableChart, Settings as SettingsIcon } from '@mui/icons-material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { shipmentCreationApi } from '../../services/shipment-creation-api';
import { Checkbox } from 'primereact/checkbox';
import api from '../../services/api';
import { getAllCustomers } from '../../services/api';
import OptimizedSpreadsheet from '../spreadsheets/OptimizedSpreadsheet';
import ShipmentSettingsPopup from './ShipmentSettingsPopup';
import CurrentShipmentItems from './CurrentShipmentItems';

export default function OrderItemsStep({
    shipmentId,
    availableOrders,
    selectedOrderItems,
    setSelectedOrderItems,
    onError,
    setAvailableOrders,
    onSuccess
}) {
    // Add logging
    useEffect(() => {
        console.log('OrderItemsStep received availableOrders:', availableOrders);
    }, [availableOrders]);

    const [loading, setLoading] = useState(false);
    const [addingItems, setAddingItems] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [availableOrderItems, setAvailableOrderItems] = useState([]);
    const [selectedQuantities, setSelectedQuantities] = useState({});
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selection, setSelection] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState(['processing']);
    const [availabilityMode, setAvailabilityMode] = useState('current_only');
    const [viewMode, setViewMode] = useState('grid');
    const [searchTerm, setSearchTerm] = useState('');
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [currentShipmentItems, setCurrentShipmentItems] = useState(null);
    const [settings, setSettings] = useState({
        orderStatuses: ['processing'],
        selectedCustomers: [],
        showOnlyAvailable: true,
        subtractShipments: true,
        shipmentStatusesToSubtract: ['draft', 'pending', 'processing'],
        shipmentScope: 'current_warehouse'
    });

    // Add new state for selection
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    // Keep only the memoized version
    const orders = useMemo(() => {
        if (!availableOrders) return [];
        return Array.isArray(availableOrders) ? availableOrders : [availableOrders];
    }, [availableOrders]);

    // Add logging for orders
    useEffect(() => {
        console.log('Processed orders:', orders);
    }, [orders]);

    // Load available items when an order is selected
    useEffect(() => {
        if (selectedOrder) {
            loadAvailableItems();
        }
    }, [selectedOrder, availabilityMode]);

    const loadAvailableItems = async () => {
        setLoading(true);
        try {
            const response = await shipmentCreationApi.getAvailableOrderItems(
                shipmentId,
                selectedOrder.order_id,
                { availabilityMode }
            );
            
            console.log('Available items response:', response); // Debug log
            
            if (response?.success && Array.isArray(response.data)) {
                const items = response.data.map(item => ({
                    ...item,
                    order_item_id: parseInt(item.order_item_id),
                    warehouse_item_id: parseInt(item.warehouse_item_id)
                }));
                
                console.log('Processed available items:', items); // Debug log
                
                setAvailableOrderItems(items);
                
                // Initialize quantities
                const initialQuantities = {};
                items.forEach(item => {
                    initialQuantities[item.order_item_id] = 0;
                });
                setSelectedQuantities(initialQuantities);
            } else {
                throw new Error('Invalid response format from server');
            }
        } catch (error) {
            console.error('Error loading order items:', error);
            onError(error.message || 'Failed to load order items');
        } finally {
            setLoading(false);
        }
    };

    const handleQuantityChange = (orderItemId, value) => {
        const newValue = parseInt(value) || 0;
        const item = availableOrderItems.find(item => item.order_item_id === orderItemId);
        
        if (item) {
            // If quantity is set to 0, remove from selection
            if (newValue === 0) {
                setSelectedRows(prev => prev.filter(row => row.order_item_id !== orderItemId));
            } 
            // If quantity is set > 0 and item not selected, add to selection
            else if (newValue > 0 && !selectedRows.find(row => row.order_item_id === orderItemId)) {
                setSelectedRows(prev => [...prev, item]);
            }
        }

        setSelectedQuantities(prev => ({
            ...prev,
            [orderItemId]: newValue
        }));
    };

    const [currentItemsOpen, setCurrentItemsOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleAddItems = async () => {
        setAddingItems(true);
        try {
            // Add logging to debug available items
            console.log('Available Order Items:', availableOrderItems);
            
            const itemsToAdd = Object.entries(selectedQuantities)
                .filter(([_, quantity]) => quantity > 0)
                .map(([orderItemId, quantity]) => {
                    // Find the corresponding item in availableOrderItems
                    const item = availableOrderItems.find(
                        item => item.order_item_id.toString() === orderItemId.toString()
                    );
                    
                    // Add logging to debug item matching
                    console.log('Matching item found:', item);
                    console.log('For order item ID:', orderItemId);
                    
                    if (!item || !item.warehouse_item_id) {
                        throw new Error(`No warehouse item found for order item ${orderItemId}`);
                    }

                    return {
                        orderItemId: parseInt(orderItemId),
                        warehouseItemId: parseInt(item.warehouse_item_id),
                        quantity: parseInt(quantity)
                    };
                });

            // Log the final items being sent
            console.log('Items being sent to API:', itemsToAdd);

            const response = await shipmentCreationApi.addOrderItems(shipmentId, itemsToAdd);
            if (response.success) {
                const totalQuantity = itemsToAdd.reduce((sum, item) => sum + item.quantity, 0);
                setSuccessMessage(`Successfully added ${itemsToAdd.length} items (${totalQuantity} total quantity) to shipment`);
                setSuccessDialogOpen(true);
                setSelectedOrderItems(prev => [...prev, ...itemsToAdd]);
                setSelectedOrder(null);
                setAvailableOrderItems([]);
                setSelectedQuantities({});
                
                // Refresh current items data
                const shipmentResponse = await shipmentCreationApi.getShipmentDetails(shipmentId);
                if (shipmentResponse.success) {
                    setCurrentShipmentItems(shipmentResponse.data);
                }
            } else {
                onError(response.error);
            }
        } catch (error) {
            console.error('Error in handleAddItems:', error);
            onError(error.message || 'Failed to add items to shipment');
        } finally {
            setAddingItems(false);
        }
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = (title) => {
        return (
            <Box className="flex justify-content-between align-items-center" sx={{ mb: 1 }}>
                <Typography variant="h6">{title}</Typography>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Search..."
                        className="p-inputtext-sm"
                    />
                </span>
            </Box>
        );
    };

    const detailsTemplate = (rowData) => {
        const details = [];
        if (rowData.item_name) details.push(rowData.item_name);
        if (rowData.type) details.push(rowData.type);
        if (rowData.size) details.push(rowData.size);
        if (rowData.shape) details.push(rowData.shape);
        if (rowData.brim) details.push(rowData.brim);
        return details.filter(Boolean).join(' - ');
    };

    // Add new helper function for number formatting
    const formatNumber = (num) => {
        if (num >= 1000000) {
            return `${(num / 1000000).toFixed(1)}M`;
        } else if (num >= 1000) {
            return `${(num / 1000).toFixed(1)}K`;
        }
        return num.toString();
    };

    const orderedQuantityTemplate = (rowData) => {
        return (
            <Typography>
                {formatNumber(rowData.ordered_quantity || 0)}
            </Typography>
        );
    };

    const availableQuantityTemplate = (rowData) => {
        return (
            <Typography color={rowData.available_quantity > 0 ? 'textPrimary' : 'error'}>
                {formatNumber(rowData.available_quantity || 0)}
            </Typography>
        );
    };

    const quantityTemplate = (rowData) => {
        if (rowData.available_quantity <= 0) {
            return (
                <Typography color="error" variant="caption">
                    Not Available
                </Typography>
            );
        }
        return (
            <InputNumber
                value={selectedQuantities[rowData.order_item_id] || 0}
                onValueChange={(e) => handleQuantityChange(
                    rowData.order_item_id,
                    e.value
                )}
                min={0}
                max={rowData.available_quantity}
                size="small"
            />
        );
    };

    const getFilteredOrders = () => {
        if (!orders || !Array.isArray(orders)) return [];
        
        // Only apply search filter
        if (globalFilterValue) {
            const searchTerm = globalFilterValue.toLowerCase();
            return orders.filter(order => 
                (order.order_number?.toLowerCase().includes(searchTerm)) ||
                (order.status?.toLowerCase().includes(searchTerm)) ||
                (order.customer_name?.toLowerCase().includes(searchTerm))
            );
        }
        
        return orders;
    };

    const handleFilterChange = async () => {
        if (!shipmentId) return;
        
        setLoading(true);
        try {
            const response = await shipmentCreationApi.getAvailableOrders(
                shipmentId,
                selectedStatuses
            );
            
            if (response?.success) {
                const ordersData = response.data || [];
                setAvailableOrders(ordersData);
            } else {
                throw new Error(response?.error || 'Failed to load orders');
            }
        } catch (error) {
            onError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Update where filteredOrders is used
    const filteredOrders = useMemo(() => getFilteredOrders(), [
        orders,
        globalFilterValue
    ]);

    // Add debug logging for filtered results
    useEffect(() => {
        console.log('Filtered orders result:', {
            totalOrders: orders?.length,
            filteredCount: filteredOrders?.length,
            globalFilterValue
        });
    }, [filteredOrders]);

    // Update the useEffect to only depend on selectedStatuses
    useEffect(() => {
        handleFilterChange();
    }, [selectedStatuses]);

    const handleViewModeChange = (event, newMode) => {
        if (newMode !== null) {
            setViewMode(newMode);
        }
    };

    const handleSettingsChange = async (newSettings) => {
        setSettings(newSettings);
        await refreshOrders(newSettings);
    };

    const refreshOrders = async (currentSettings = settings) => {
        if (!shipmentId) return;
        
        setLoading(true);
        try {
            const response = await shipmentCreationApi.getAvailableOrders(
                shipmentId,
                currentSettings
            );
            
            if (response?.success) {
                setAvailableOrders(response.data || []);
            } else {
                throw new Error(response?.error || 'Failed to load orders');
            }
        } catch (error) {
            onError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            field: 'order_number',
            headerName: 'Order Number',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'customer_name',
            headerName: 'Customer',
            flex: 1,
            minWidth: 200
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 120
        },
        {
            field: 'total_items',
            headerName: 'Items',
            flex: 0.5,
            minWidth: 100,
            type: 'number'
        },
        {
            field: 'availability_percentage',
            headerName: 'Available',
            flex: 0.5,
            minWidth: 120,
            renderCell: (params) => `${params.value}%`
        }
    ];

    const handleSelectAll = (checked) => {
        if (checked) {
            // Select all available items
            const availableItems = availableOrderItems.filter(item => item.available_quantity > 0);
            setSelectedRows(availableItems);
            
            // Set quantities to maximum available
            const newQuantities = {};
            availableItems.forEach(item => {
                newQuantities[item.order_item_id] = item.available_quantity;
            });
            setSelectedQuantities(newQuantities);
        } else {
            // Clear all selections
            setSelectedRows([]);
            setSelectedQuantities({});
        }
        setSelectAll(checked);
    };

    const handleRowSelect = (item, checked) => {
        let newSelectedRows;
        if (checked) {
            newSelectedRows = [...selectedRows, item];
            // Set quantity to maximum available when selected
            setSelectedQuantities(prev => ({
                ...prev,
                [item.order_item_id]: item.available_quantity
            }));
        } else {
            newSelectedRows = selectedRows.filter(row => row.order_item_id !== item.order_item_id);
            // Clear quantity when deselected
            setSelectedQuantities(prev => ({
                ...prev,
                [item.order_item_id]: 0
            }));
        }
        setSelectedRows(newSelectedRows);
        
        // Update selectAll state
        setSelectAll(newSelectedRows.length === availableOrderItems.filter(item => item.available_quantity > 0).length);
    };

    const getSelectedItemsCount = () => {
        return Object.values(selectedQuantities).filter(q => q > 0).length;
    };

    // Update the getTotalSelectedQuantity function
    const getTotalSelectedQuantity = () => {
        const total = Object.values(selectedQuantities).reduce((sum, q) => sum + (q || 0), 0);
        return formatNumber(total);
    };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Grid container spacing={3} sx={{ height: '100%', p: 2 }}>
                {/* Left Panel - Available Orders (30%) */}
                <Grid item xs={12} md={3.6} sx={{ height: '100%' }}>
                    <Paper 
                        elevation={2} 
                        sx={{ 
                            p: 3, 
                            height: '100%',
                            overflow: 'auto'
                        }}
                    >
                        <Box sx={{ mb: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                <Typography variant="h6" gutterBottom>
                                    Available Orders
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setCurrentItemsOpen(true)}
                                    >
                                        View Current Items
                                    </Button>
                                    <Tooltip title="Order Settings">
                                        <IconButton onClick={() => setSettingsOpen(true)} size="small">
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <TextField
                                fullWidth
                                size="small"
                                placeholder="Search orders..."
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <List sx={{ overflow: 'auto' }}>
                            {getFilteredOrders().map((order) => (
                                <ListItem
                                    key={order.order_id}
                                    button
                                    selected={selectedOrder?.order_id === order.order_id}
                                    onClick={() => setSelectedOrder(order)}
                                    sx={{
                                        mb: 1,
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider'
                                    }}
                                >
                                    <ListItemText
                                        primary={order.order_number}
                                        secondary={
                                            <>
                                                {order.customer_name}
                                                <Chip
                                                    size="small"
                                                    label={order.status}
                                                    sx={{ ml: 1 }}
                                                    color={order.status === 'processing' ? 'primary' : 'default'}
                                                />
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>

                {/* Right Panel - Selected Order Items (70%) */}
                <Grid item xs={12} md={8.4} sx={{ height: '100%' }}>
                    <Paper 
                        elevation={2} 
                        sx={{ 
                            p: 3, 
                            height: '100%',
                            overflow: 'auto'
                        }}
                    >
                        {selectedOrder ? (
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6">
                                            Order Items for {selectedOrder.order_number}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setSelectedOrder(null)}
                                        >
                                            Back to Orders
                                        </Button>
                                    </Box>
                                    
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        alignItems: 'center',
                                        bgcolor: 'background.paper',
                                        p: 2,
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        mb: 2
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                indeterminate={selectedRows.length > 0 && selectedRows.length < availableOrderItems.length}
                                            />
                                            <Typography>
                                                {selectedRows.length > 0 ? (
                                                    `${selectedRows.length} items selected (${getTotalSelectedQuantity()})`
                                                ) : (
                                                    'Select items'
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            {selectedRows.length > 0 && (
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            // Set quantities to maximum available up to ordered amount
                                                            const newQuantities = { ...selectedQuantities };
                                                            selectedRows.forEach(item => {
                                                                if (item.available_quantity > 0) {
                                                                    newQuantities[item.order_item_id] = Math.min(
                                                                        item.available_quantity,
                                                                        item.ordered_quantity
                                                                    );
                                                                }
                                                            });
                                                            setSelectedQuantities(newQuantities);
                                                        }}
                                                        disabled={addingItems}
                                                    >
                                                        Add Maximum
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleAddItems}
                                                        disabled={addingItems || !Object.values(selectedQuantities).some(q => q > 0)}
                                                        startIcon={addingItems && <CircularProgress size={20} />}
                                                        color="primary"
                                                    >
                                                        {addingItems ? 'Adding...' : `Add ${getSelectedItemsCount()} Items`}
                                                    </Button>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>

                                <DataTable
                                    value={availableOrderItems}
                                    loading={loading}
                                    filters={filters}
                                    header={renderHeader('Available Items')}
                                    emptyMessage="No items available"
                                    responsiveLayout="scroll"
                                    selection={selectedRows}
                                    onSelectionChange={(e) => {
                                        setSelectedRows(e.value);
                                        // Update quantities when selection changes
                                        const newQuantities = { ...selectedQuantities };
                                        if (e.value.length > selectedRows.length) {
                                            // Items were added to selection
                                            e.value.forEach(item => {
                                                if (!selectedRows.includes(item)) {
                                                    newQuantities[item.order_item_id] = item.available_quantity;
                                                }
                                            });
                                        } else {
                                            // Items were removed from selection
                                            selectedRows.forEach(item => {
                                                if (!e.value.includes(item)) {
                                                    newQuantities[item.order_item_id] = 0;
                                                }
                                            });
                                        }
                                        setSelectedQuantities(newQuantities);
                                    }}
                                    selectionMode="multiple"
                                >
                                    <Column 
                                        selectionMode="multiple" 
                                        headerStyle={{ width: '3rem' }}
                                        exportable={false}
                                    />
                                    <Column field="item_name" header="Item Details" body={detailsTemplate} sortable filter />
                                    <Column 
                                        field="ordered_quantity" 
                                        header="Ordered" 
                                        body={orderedQuantityTemplate}
                                        sortable 
                                        style={{ width: '100px', textAlign: 'center' }}
                                    />
                                    <Column 
                                        field="available_quantity" 
                                        header="Available" 
                                        body={availableQuantityTemplate}
                                        sortable 
                                        style={{ width: '100px', textAlign: 'center' }}
                                    />
                                    <Column 
                                        field="quantity" 
                                        header="Select Quantity" 
                                        body={quantityTemplate}
                                        style={{ width: '150px', textAlign: 'center' }}
                                    />
                                </DataTable>
                            </Box>
                        ) : (
                            <Box sx={{ 
                                height: '100%', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center' 
                            }}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Select an order to view available items
                                </Typography>
                            </Box>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Current Shipment Items Dialog */}
            <CurrentShipmentItems
                open={currentItemsOpen}
                onClose={() => setCurrentItemsOpen(false)}
                shipmentId={shipmentId}
            />

            {/* Success Dialog */}
            <Dialog
                open={successDialogOpen}
                onClose={() => {
                    setSuccessDialogOpen(false);
                    // Refresh current items when closing success dialog
                    const loadCurrentItems = async () => {
                        try {
                            const response = await shipmentCreationApi.getShipmentDetails(shipmentId);
                            console.log('Refreshed shipment details:', response);
                            if (response.success) {
                                setCurrentShipmentItems(response.data);
                            }
                        } catch (error) {
                            console.error('Error refreshing shipment details:', error);
                        }
                    };
                    loadCurrentItems();
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <Typography>{successMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setSuccessDialogOpen(false);
                        setCurrentItemsOpen(true);
                    }}>View Current Items</Button>
                    <Button onClick={() => setSuccessDialogOpen(false)} variant="contained">Continue</Button>
                </DialogActions>
            </Dialog>

            {/* Settings Dialog */}
            <ShipmentSettingsPopup
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}
                settings={settings}
                onSettingsChange={handleSettingsChange}
            />
        </Box>
    );
};