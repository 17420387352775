import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Typography,
    CircularProgress,
    Fade,
    Paper,
    Grid
} from '@mui/material';
import MetricsDashboard from './MetricsDashboard';
import CreateMetricDialog from './CreateMetricDialog';
import CreateViewDialog from './CreateViewDialog';
import { metricsAndViewsApi } from '../../services/metricsAndViewsApi';
import { useSnackbar } from 'notistack';
import FullScreenLayout from '../layouts/FullScreenLayout';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const MetricsAndViews = () => {
    const [metrics, setMetrics] = useState([]);
    const [views, setViews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createMetricOpen, setCreateMetricOpen] = useState(false);
    const [createViewOpen, setCreateViewOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [deleteType, setDeleteType] = useState(null); // 'metric' or 'view'
    const [metricUsage, setMetricUsage] = useState([]);
    const [dataSources, setDataSources] = useState(null);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [metricsData, viewsData] = await Promise.all([
                metricsAndViewsApi.getAllMetrics(),
                metricsAndViewsApi.getAllViews()
            ]);
            
            setMetrics(Array.isArray(metricsData) ? metricsData : []);
            setViews(Array.isArray(viewsData) ? viewsData : []);
        } catch (error) {
            console.error('Error fetching data:', error);
            enqueueSnackbar('Error fetching data', { variant: 'error' });
            setMetrics([]);
            setViews([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCreateMetric = async (metricData) => {
        try {
            setLoading(true);
            await metricsAndViewsApi.createMetric(metricData);
            enqueueSnackbar('Metric created successfully', { variant: 'success' });
            await fetchData();
            setCreateMetricOpen(false);
        } catch (error) {
            console.error('Error creating metric:', error);
            enqueueSnackbar(error.message || 'Error creating metric', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCreateView = async (viewData) => {
        try {
            await metricsAndViewsApi.createView(viewData);
            enqueueSnackbar('View created successfully', { variant: 'success' });
            fetchData();
            setCreateViewOpen(false);
        } catch (error) {
            console.error('Error creating view:', error);
            enqueueSnackbar(error.message || 'Error creating view', { variant: 'error' });
        }
    };

    const handleDeleteClick = async (item, type) => {
        setItemToDelete(item);
        setDeleteType(type);
        
        if (type === 'metric') {
            try {
                const usage = await metricsAndViewsApi.getMetricUsage(item.metric_id);
                setMetricUsage(usage.views || []);
            } catch (error) {
                console.error('Error checking metric usage:', error);
                enqueueSnackbar('Error checking metric usage', { variant: 'error' });
            }
        }
        
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            if (deleteType === 'metric') {
                await metricsAndViewsApi.deleteMetric(itemToDelete.metric_id);
                enqueueSnackbar('Metric deleted successfully', { variant: 'success' });
            } else if (deleteType === 'view') {
                await metricsAndViewsApi.deleteView(itemToDelete.view_id);
                enqueueSnackbar('View deleted successfully', { variant: 'success' });
            }
            await fetchData();
        } catch (error) {
            console.error(`Error deleting ${deleteType}:`, error);
            enqueueSnackbar(`Error deleting ${deleteType}`, { variant: 'error' });
        } finally {
            setDeleteDialogOpen(false);
            setItemToDelete(null);
            setDeleteType(null);
            setMetricUsage([]);
        }
    };

    const header = (
        <Box 
            sx={{ 
                mb: 6,
                background: 'linear-gradient(145deg, #f7f9fc 0%, #ffffff 100%)',
                borderRadius: '24px',
                p: 4,
                boxShadow: '0 4px 24px rgba(0, 0, 0, 0.06)'
            }}
        >
            <Typography 
                variant="h3" 
                component="h1"
                sx={{ 
                    fontWeight: 600,
                    background: 'linear-gradient(90deg, #1a73e8 0%, #34a853 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 3
                }}
            >
                Analytics Dashboard
            </Typography>

            <Typography 
                variant="h6" 
                color="text.secondary" 
                sx={{ mb: 4, maxWidth: '600px' }}
            >
                Monitor your key metrics and create custom views to track what matters most to your business.
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                    variant="contained"
                    onClick={() => setCreateMetricOpen(true)}
                    sx={{ 
                        py: 1.5,
                        px: 4,
                        borderRadius: '12px',
                        textTransform: 'none',
                        fontSize: '1rem',
                        backgroundColor: '#1a73e8',
                        '&:hover': { 
                            backgroundColor: '#1557b0',
                            transform: 'translateY(-2px)',
                            transition: 'all 0.2s'
                        }
                    }}
                >
                    Create New Metric
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => setCreateViewOpen(true)}
                    sx={{ 
                        py: 1.5,
                        px: 4,
                        borderRadius: '12px',
                        textTransform: 'none',
                        fontSize: '1rem',
                        borderColor: '#34a853',
                        color: '#34a853',
                        '&:hover': { 
                            borderColor: '#2d8544',
                            backgroundColor: 'rgba(52, 168, 83, 0.04)',
                            transform: 'translateY(-2px)',
                            transition: 'all 0.2s'
                        }
                    }}
                >
                    Create New View
                </Button>
            </Box>
        </Box>
    );

    const mainContent = loading ? (
        <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
        </Box>
    ) : (
        <Fade in={!loading}>
            <Box>
                <MetricsDashboard
                    views={views}
                    metrics={metrics}
                    onCreateMetric={() => setCreateMetricOpen(true)}
                    onCreateView={() => setCreateViewOpen(true)}
                    onDeleteMetric={handleDeleteClick}
                    onDeleteView={handleDeleteClick}
                    showAllContent={true}
                />

                <CreateMetricDialog
                    open={createMetricOpen}
                    onClose={() => setCreateMetricOpen(false)}
                    onSubmit={handleCreateMetric}
                    dataSources={dataSources}
                    loading={loading}
                    error={error}
                    enqueueSnackbar={enqueueSnackbar}
                />
                <CreateViewDialog
                    open={createViewOpen}
                    onClose={() => setCreateViewOpen(false)}
                    onSubmit={handleCreateView}
                    availableMetrics={metrics}
                />
            </Box>
        </Fade>
    );

    const deleteDialog = (
        <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
        >
            <DialogTitle>
                {`Delete ${deleteType === 'metric' ? 'Metric' : 'View'}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {deleteType === 'metric' ? (
                        <>
                            <Typography color="error" paragraph>
                                Warning: This metric is used in the following views:
                            </Typography>
                            {metricUsage.length > 0 ? (
                                <Box sx={{ mb: 2 }}>
                                    {metricUsage.map((view, index) => (
                                        <Typography key={index} variant="body2">
                                            • {view.name}
                                        </Typography>
                                    ))}
                                </Box>
                            ) : (
                                <Typography paragraph>
                                    This metric is not used in any views.
                                </Typography>
                            )}
                            <Typography>
                                Deleting this metric will remove it from all views. This action cannot be undone.
                            </Typography>
                        </>
                    ) : (
                        <Typography>
                            Are you sure you want to delete this view? This action cannot be undone.
                        </Typography>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                <Button 
                    onClick={handleDeleteConfirm} 
                    color="error" 
                    variant="contained"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <FullScreenLayout
            header={header}
            mainPanel={
                <>
                    <MetricsDashboard
                        views={views}
                        metrics={metrics}
                        onCreateMetric={() => setCreateMetricOpen(true)}
                        onCreateView={() => setCreateViewOpen(true)}
                        onDeleteMetric={handleDeleteClick}
                        onDeleteView={handleDeleteClick}
                        showAllContent={true}
                    />
                    
                    <CreateMetricDialog
                        open={createMetricOpen}
                        onClose={() => setCreateMetricOpen(false)}
                        onSubmit={handleCreateMetric}
                        dataSources={dataSources}
                        loading={loading}
                        error={error}
                        enqueueSnackbar={enqueueSnackbar}
                    />
                    <CreateViewDialog
                        open={createViewOpen}
                        onClose={() => setCreateViewOpen(false)}
                        onSubmit={handleCreateView}
                        availableMetrics={metrics}
                    />
                    {deleteDialog}
                </>
            }
        />
    );
};

export default MetricsAndViews;