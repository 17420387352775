import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Paper,
    Grid,
    Card,
    CardContent,
    Chip,
    IconButton,
    Button,
    LinearProgress,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Alert,
    Menu,
    MenuItem,
    Fade,
    CircularProgress,
} from '@mui/material';
import {
    Description as DescriptionIcon,
    ArrowBack as ArrowBackIcon,
    AccessTime as AccessTimeIcon,
    Person as PersonIcon,
    LocalShipping as LocalShippingIcon,
    Inventory as InventoryIcon,
    Note as NoteIcon,
    Search as SearchIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { getDistributionById, updateDistributionStatus } from '../services/inventory-distribution-api';
import { useSnackbar } from 'notistack';
import FullScreenLayout from './layouts/FullScreenLayout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    border: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
    },
}));

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    border: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
    },
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
    backgroundColor: status === 'completed' ? theme.palette.success.main :
        status === 'pending' ? theme.palette.warning.main :
        status === 'cancelled' ? theme.palette.error.main :
        theme.palette.info.main,
    color: '#fff',
    fontWeight: 'bold',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    },
}));

const StatusButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    },
}));

const InfoItem = ({ icon, label, value }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {icon}
        <Box sx={{ ml: 2 }}>
            <Typography variant="body2" color="text.secondary">{label}</Typography>
            <Typography variant="body1">{value}</Typography>
        </Box>
    </Box>
);

function DistributionDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [distribution, setDistribution] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [chartView, setChartView] = useState('items'); // 'items' or 'locations'
    const [destinationsFilters, setDestinationsFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        location_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_by: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [itemsFilters, setItemsFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        item_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        item_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [destinationsGlobalFilter, setDestinationsGlobalFilter] = useState('');
    const [itemsGlobalFilter, setItemsGlobalFilter] = useState('');
    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [statusUpdateSuccess, setStatusUpdateSuccess] = useState(false);

    useEffect(() => {
        fetchDistributionDetails();
    }, [id]);

    const fetchDistributionDetails = async () => {
        try {
            const data = await getDistributionById(id);
            setDistribution(data);
        } catch (error) {
            setError('Failed to fetch distribution details');
            console.error('Error fetching distribution details:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusClick = (event) => {
        setStatusAnchorEl(event.currentTarget);
    };

    const handleStatusClose = () => {
        setStatusAnchorEl(null);
    };

    const handleStatusUpdate = async (newStatus) => {
        setUpdatingStatus(true);
        setStatusAnchorEl(null);
        try {
            await updateDistributionStatus(id, newStatus);
            setStatusUpdateSuccess(true);
            setTimeout(() => {
                setStatusUpdateSuccess(false);
                fetchDistributionDetails();
            }, 1500);
            enqueueSnackbar('Status updated successfully', { 
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        } catch (error) {
            console.error('Error updating status:', error);
            enqueueSnackbar('Failed to update status', { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
        } finally {
            setUpdatingStatus(false);
        }
    };

    const renderHeader = (title, globalFilterValue, onGlobalFilterChange) => {
        return (
            <Box className="flex justify-content-between align-items-center">
                <Typography variant="h6" component="h2">{title}</Typography>
                <span className="p-input-icon-left">
                    <SearchIcon />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Search..."
                    />
                </span>
            </Box>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            rowData.sheet_url && (
                <Tooltip title="View Distribution Sheet">
                    <IconButton
                        href={rowData.sheet_url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DescriptionIcon />
                    </IconButton>
                </Tooltip>
            )
        );
    };

    const dateBodyTemplate = (rowData, field) => {
        return rowData[field] ? new Date(rowData[field]).toLocaleString() : '-';
    };

    const destinationBodyTemplate = (rowData) => {
        return distribution.destinations.find(d => d.destination_id === rowData.destination_id)?.location_name || '-';
    };

    const renderItemsChart = () => {
        if (!distribution || !distribution.items) return null;

        const chartData = distribution.items.map(item => {
            const data = {
                name: item.item_name,
                total: item.quantity
            };

            distribution.destinations.forEach(dest => {
                const destQuantity = distribution.items.find(
                    i => i.item_id === item.item_id && i.destination_id === dest.destination_id
                )?.quantity || 0;
                data[dest.location_name] = destQuantity;
            });

            return data;
        });

        const uniqueChartData = chartData.reduce((acc, curr) => {
            const existingItem = acc.find(item => item.name === curr.name);
            if (!existingItem) {
                acc.push(curr);
            } else {
                Object.keys(curr).forEach(key => {
                    if (key !== 'name') {
                        existingItem[key] = (existingItem[key] || 0) + curr[key];
                    }
                });
            }
            return acc;
        }, []);

        return (
            <Box sx={{ height: 400, mt: 3 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={uniqueChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        {distribution.destinations.map((dest, index) => (
                            <Bar
                                key={dest.destination_id}
                                dataKey={dest.location_name}
                                fill={`hsl(${index * 137.5}, 70%, 50%)`}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        );
    };

    const renderLocationsChart = () => {
        if (!distribution || !distribution.destinations) return null;

        const chartData = distribution.destinations.map(dest => {
            const data = {
                name: dest.location_name,
                total: distribution.items.reduce((sum, item) => {
                    if (item.destination_id === dest.destination_id) {
                        return sum + item.quantity;
                    }
                    return sum;
                }, 0)
            };

            const itemTypes = [...new Set(distribution.items.map(item => item.item_type))];
            itemTypes.forEach(itemType => {
                const typeQuantity = distribution.items.reduce((sum, item) => {
                    if (item.destination_id === dest.destination_id && item.item_type === itemType) {
                        return sum + item.quantity;
                    }
                    return sum;
                }, 0);
                data[itemType] = typeQuantity;
            });

            return data;
        });

        return (
            <Box sx={{ height: 400, mt: 3 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        {[...new Set(distribution.items.map(item => item.item_type))].map((itemType, index) => (
                            <Bar
                                key={itemType}
                                dataKey={itemType}
                                fill={`hsl(${index * 137.5}, 70%, 50%)`}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        );
    };

    if (loading) {
        return <LinearProgress />;
    }

    if (error) {
        return (
            <Alert severity="error">{error}</Alert>
        );
    }

    if (!distribution) {
        return (
            <Container>
                <Typography variant="h5" color="error">Distribution not found</Typography>
            </Container>
        );
    }

    return (
        <FullScreenLayout
            header={
                <Box sx={{ mb: 4 }}>
                    <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate('/inventory-distribution')}
                        sx={{ mb: 2 }}
                    >
                        Back to Distributions
                    </Button>
                    <Typography variant="h4" gutterBottom>
                        Distribution #{distribution.id}
                    </Typography>
                </Box>
            }
            mainPanel={
                <Container maxWidth="lg">
                    <Grid container spacing={3}>
                        {/* Status and Overview Card */}
                        <Grid item xs={12} md={4}>
                            <StyledCard>
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6">Status & Overview</Typography>
                                        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                                            <Fade in={statusUpdateSuccess} timeout={300}>
                                                <Box sx={{ mr: 2, display: statusUpdateSuccess ? 'block' : 'none' }}>
                                                    <CircularProgress
                                                        size={20}
                                                        color="success"
                                                        sx={{
                                                            animation: 'fadeOut 1.5s ease-in-out',
                                                            '@keyframes fadeOut': {
                                                                '0%': { opacity: 1 },
                                                                '100%': { opacity: 0 },
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Fade>
                                            <StatusChip
                                                label={distribution.status_name.toUpperCase()}
                                                status={distribution.status_name.toLowerCase()}
                                            />
                                            <StatusButton
                                                variant="outlined"
                                                endIcon={<KeyboardArrowDownIcon />}
                                                onClick={handleStatusClick}
                                                disabled={updatingStatus}
                                            >
                                                {updatingStatus ? (
                                                    <CircularProgress size={20} />
                                                ) : (
                                                    'Update Status'
                                                )}
                                            </StatusButton>
                                            <Menu
                                                anchorEl={statusAnchorEl}
                                                open={Boolean(statusAnchorEl)}
                                                onClose={handleStatusClose}
                                                TransitionComponent={Fade}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MenuItem 
                                                    onClick={() => handleStatusUpdate('pending')}
                                                    disabled={distribution.status_name.toLowerCase() === 'pending'}
                                                >
                                                    <StatusChip 
                                                        label="PENDING" 
                                                        status="pending" 
                                                        size="small"
                                                        sx={{ mr: 1 }}
                                                    />
                                                    Set as Pending
                                                </MenuItem>
                                                <MenuItem 
                                                    onClick={() => handleStatusUpdate('completed')}
                                                    disabled={distribution.status_name.toLowerCase() === 'completed'}
                                                >
                                                    <StatusChip 
                                                        label="COMPLETED" 
                                                        status="completed" 
                                                        size="small"
                                                        sx={{ mr: 1 }}
                                                    />
                                                    Set as Completed
                                                </MenuItem>
                                                <MenuItem 
                                                    onClick={() => handleStatusUpdate('cancelled')}
                                                    disabled={distribution.status_name.toLowerCase() === 'cancelled'}
                                                >
                                                    <StatusChip 
                                                        label="CANCELLED" 
                                                        status="cancelled" 
                                                        size="small"
                                                        sx={{ mr: 1 }}
                                                    />
                                                    Set as Cancelled
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    </Box>
                                    <InfoItem
                                        icon={<AccessTimeIcon color="action" />}
                                        label="Created At"
                                        value={new Date(distribution.created_at).toLocaleString()}
                                    />
                                    <InfoItem
                                        icon={<PersonIcon color="action" />}
                                        label="Created by"
                                        value={distribution.created_by_username}
                                    />
                                    {distribution.updated_by_username && (
                                        <InfoItem
                                            icon={<PersonIcon color="action" />}
                                            label="Last Updated by"
                                            value={distribution.updated_by_username}
                                        />
                                    )}
                                    <InfoItem
                                        icon={<LocalShippingIcon color="action" />}
                                        label="Distribution Type"
                                        value={distribution.distribution_type.toUpperCase()}
                                    />
                                    <InfoItem
                                        icon={<LocalShippingIcon color="action" />}
                                        label="Distribution Method"
                                        value={distribution.distribution_method || 'Percentage Based'}
                                    />
                                </CardContent>
                            </StyledCard>
                        </Grid>

                        {/* Source Location Card */}
                        <Grid item xs={12} md={4}>
                            <StyledCard>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>Source Location</Typography>
                                    <InfoItem
                                        icon={<InventoryIcon color="action" />}
                                        label="Location Name"
                                        value={distribution.source_location_name}
                                    />
                                    {distribution.notes && (
                                        <InfoItem
                                            icon={<NoteIcon color="action" />}
                                            label="Notes"
                                            value={distribution.notes}
                                        />
                                    )}
                                </CardContent>
                            </StyledCard>
                        </Grid>

                        {/* Destinations Card */}
                        <Grid item xs={12}>
                            <StyledPaper>
                                <DataTable
                                    value={distribution.destinations}
                                    paginator
                                    rows={10}
                                    dataKey="destination_id"
                                    filters={destinationsFilters}
                                    filterDisplay="menu"
                                    loading={loading}
                                    globalFilterFields={['location_name', 'created_by']}
                                    header={renderHeader('Destinations', destinationsGlobalFilter, (e) => {
                                        const value = e.target.value;
                                        setDestinationsGlobalFilter(value);
                                        const _filters = { ...destinationsFilters };
                                        _filters['global'].value = value;
                                        setDestinationsFilters(_filters);
                                    })}
                                    emptyMessage="No destinations found."
                                    responsiveLayout="scroll"
                                    className="p-datatable-sm"
                                >
                                    <Column 
                                        field="location_name" 
                                        header="Location" 
                                        sortable 
                                        filter 
                                        filterPlaceholder="Search by location"
                                    />
                                    <Column 
                                        field="created_at" 
                                        header="Created At" 
                                        sortable
                                        body={(rowData) => dateBodyTemplate(rowData, 'created_at')}
                                    />
                                    <Column 
                                        field="created_by" 
                                        header="Created By" 
                                        sortable 
                                        filter 
                                        filterPlaceholder="Search by creator"
                                    />
                                    <Column 
                                        field="updated_at" 
                                        header="Last Updated" 
                                        sortable
                                        body={(rowData) => dateBodyTemplate(rowData, 'updated_at')}
                                    />
                                    <Column 
                                        header="Actions" 
                                        body={actionBodyTemplate} 
                                        style={{ width: '100px' }}
                                    />
                                </DataTable>
                            </StyledPaper>
                        </Grid>

                        {/* Items Card */}
                        <Grid item xs={12}>
                            <StyledPaper>
                                <DataTable
                                    value={distribution.items}
                                    paginator
                                    rows={10}
                                    dataKey="item_id"
                                    filters={itemsFilters}
                                    filterDisplay="menu"
                                    loading={loading}
                                    globalFilterFields={['item_name', 'item_type']}
                                    header={renderHeader('Distributed Items', itemsGlobalFilter, (e) => {
                                        const value = e.target.value;
                                        setItemsGlobalFilter(value);
                                        const _filters = { ...itemsFilters };
                                        _filters['global'].value = value;
                                        setItemsFilters(_filters);
                                    })}
                                    emptyMessage="No items found."
                                    responsiveLayout="scroll"
                                    className="p-datatable-sm"
                                >
                                    <Column 
                                        field="item_name" 
                                        header="Item Name" 
                                        sortable 
                                        filter 
                                        filterPlaceholder="Search by name"
                                    />
                                    <Column 
                                        field="item_type" 
                                        header="Type" 
                                        sortable 
                                        filter 
                                        filterPlaceholder="Search by type"
                                    />
                                    <Column 
                                        field="quantity" 
                                        header="Quantity" 
                                        sortable
                                    />
                                    <Column 
                                        field="destination_id" 
                                        header="Destination" 
                                        sortable
                                        body={destinationBodyTemplate}
                                    />
                                    <Column 
                                        field="created_at" 
                                        header="Created At" 
                                        sortable
                                        body={(rowData) => dateBodyTemplate(rowData, 'created_at')}
                                    />
                                </DataTable>
                            </StyledPaper>
                        </Grid>

                        {/* Distribution Visualization */}
                        <Grid item xs={12}>
                            <StyledPaper>
                                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h6">Distribution Visualization</Typography>
                                    <Box>
                                        <Button
                                            variant={chartView === 'items' ? 'contained' : 'outlined'}
                                            onClick={() => setChartView('items')}
                                            sx={{ mr: 1 }}
                                        >
                                            By Items
                                        </Button>
                                        <Button
                                            variant={chartView === 'locations' ? 'contained' : 'outlined'}
                                            onClick={() => setChartView('locations')}
                                        >
                                            By Locations
                                        </Button>
                                    </Box>
                                </Box>
                                {chartView === 'items' ? renderItemsChart() : renderLocationsChart()}
                            </StyledPaper>
                        </Grid>

                        {/* Distribution Details Table */}
                        <Grid item xs={12}>
                            <StyledPaper>
                                <Typography variant="h6" gutterBottom>
                                    Distribution Details
                                </Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Type</TableCell>
                                                {distribution.destinations.map(dest => (
                                                    <TableCell key={dest.destination_id}>
                                                        {dest.location_name}
                                                    </TableCell>
                                                ))}
                                                <TableCell>Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {[...new Set(distribution.items.map(item => item.item_id))].map(itemId => {
                                                const item = distribution.items.find(i => i.item_id === itemId);
                                                const totalQuantity = distribution.items.reduce((sum, i) => 
                                                    i.item_id === itemId ? sum + i.quantity : sum, 0
                                                );
                                                
                                                return (
                                                    <TableRow key={itemId}>
                                                        <TableCell>{item.item_name}</TableCell>
                                                        <TableCell>{item.item_type}</TableCell>
                                                        {distribution.destinations.map(dest => {
                                                            const destQuantity = distribution.items.find(
                                                                i => i.item_id === itemId && i.destination_id === dest.destination_id
                                                            )?.quantity || 0;
                                                            return (
                                                                <TableCell key={dest.destination_id}>
                                                                    {destQuantity}
                                                                </TableCell>
                                                            );
                                                        })}
                                                        <TableCell>{totalQuantity}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </StyledPaper>
                        </Grid>
                    </Grid>
                </Container>
            }
        />
    );
}

export default DistributionDetails; 