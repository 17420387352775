import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Chip,
  Tabs,
  Tab,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import {
  Edit as EditIcon,
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import {
  getProductionEntryById,
  confirmProductionEntry,
  cancelProductionEntry,
} from '../services/api';
import SpreadsheetExportableV2 from './spreadsheets/SpreadsheetExportableV2';
import FullScreenLayout from './layouts/FullScreenLayout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`production-tabpanel-${index}`}
      aria-labelledby={`production-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ProductionDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [production, setProduction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('item_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    item_name: { value: null, matchMode: 'contains' },
    item_type: { value: null, matchMode: 'contains' },
    quantity: { value: null, matchMode: 'equals' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  useEffect(() => {
    fetchProductionData();
  }, [id]);

  const fetchProductionData = async () => {
    try {
      setLoading(true);
      const data = await getProductionEntryById(id);
      setProduction(data);
      
      // Transform items data for spreadsheet view
      const transformedData = {};
      
      // Group items by type
      if (data.items && Array.isArray(data.items)) {
        data.items.forEach(item => {
          // Log the item to see what we're getting
          console.log('Processing item:', item);
          
          const { 
            item_type,
            item_size,
            item_shape,
            item_brim,
            quantity 
          } = item;
          
          // Skip if we don't have all required fields
          if (!item_type || !item_size || !item_shape || !item_brim) {
            console.warn('Skipping item due to missing fields:', item);
            return;
          }

          // Initialize nested objects if they don't exist
          if (!transformedData[item_type]) {
            transformedData[item_type] = {};
          }
          
          if (!transformedData[item_type][item_size]) {
            transformedData[item_type][item_size] = {};
          }
          
          // Convert brim to string to ensure consistent keys
          const brimKey = item_brim.toString();
          if (!transformedData[item_type][item_size][brimKey]) {
            transformedData[item_type][item_size][brimKey] = {};
          }
          
          // Set the quantity, defaulting to 0 if undefined
          transformedData[item_type][item_size][brimKey][item_shape] = quantity || 0;
        });
      }
      
      console.log('Transformed data:', transformedData);
      setSpreadsheetData(transformedData);
    } catch (err) {
      console.error('Error fetching production data:', err);
      setError(err.message || 'Failed to fetch production details');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmProduction = async () => {
    try {
      await confirmProductionEntry(id);
      await fetchProductionData();
      setConfirmDialog(false);
    } catch (err) {
      setError(err.message || 'Failed to confirm production');
    }
  };

  const handleCancelProduction = async () => {
    try {
      await cancelProductionEntry(id);
      await fetchProductionData();
      setCancelDialog(false);
      setCancelReason('');
    } catch (err) {
      setError(err.message || 'Failed to cancel production');
    }
  };

  const getFilteredAndSortedItems = () => {
    if (!production?.items) return [];
    
    let filteredItems = [...production.items].filter(item => 
      item && item.item_type && item.item_size // Basic validation
    );
    
    if (searchTerm) {
      filteredItems = filteredItems.filter(item => 
        (item.item_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
         item.item_type?.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    filteredItems.sort((a, b) => {
      const aValue = a[sortBy] || '';
      const bValue = b[sortBy] || '';
      
      return sortOrder === 'asc' 
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    return filteredItems;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={(e) => {
              const value = e.target.value;
              setGlobalFilterValue(value);
              let _filters = { ...filters };
              _filters['global'].value = value;
              setFilters(_filters);
            }}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const renderItemsGrid = () => (
    <Box sx={{ mb: 3 }}>
      <DataTable
        value={getFilteredAndSortedItems()}
        paginator
        rows={10}
        dataKey="production_item_id"
        filters={filters}
        filterDisplay="menu"
        loading={loading}
        globalFilterFields={['item_name', 'item_type', 'item_size', 'item_shape', 'item_brim']}
        header={renderHeader()}
        emptyMessage="No items found."
        responsiveLayout="scroll"
        scrollHeight="600px"
        sortField={sortBy}
        sortOrder={sortOrder === 'asc' ? 1 : -1}
        onSort={(e) => {
          setSortBy(e.sortField);
          setSortOrder(e.sortOrder === 1 ? 'asc' : 'desc');
        }}
      >
        <Column 
          field="item_name" 
          header="Item Name" 
          sortable 
          filter
          filterPlaceholder="Search by name"
        />
        <Column 
          field="item_type" 
          header="Type" 
          sortable 
          filter
          filterPlaceholder="Search by type"
        />
        <Column 
          field="item_size" 
          header="Size" 
          sortable 
          filter
          filterPlaceholder="Search by size"
        />
        <Column 
          field="item_shape" 
          header="Shape" 
          sortable 
          filter
          filterPlaceholder="Search by shape"
        />
        <Column 
          field="item_brim" 
          header="Brim" 
          sortable 
          filter
          filterPlaceholder="Search by brim"
        />
        <Column 
          field="quantity" 
          header="Quantity" 
          sortable 
          filter
          filterPlaceholder="Search by quantity"
          body={(rowData) => (
            <Typography align="right">
              {rowData.quantity || 0}
            </Typography>
          )}
        />
        <Column 
          field="notes" 
          header="Notes" 
          filter
          filterPlaceholder="Search notes"
        />
      </DataTable>
    </Box>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!production) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">Production entry not found</Alert>
      </Box>
    );
  }

  const renderSummary = () => (
    <Box>
      <Chip 
        label={production.status.toUpperCase()}
        color={
          production.status === 'confirmed' ? 'success' :
          production.status === 'cancelled' ? 'error' :
          'warning'
        }
        sx={{ mr: 1 }}
      />
      <Typography component="span" sx={{ ml: 2 }}>
        <strong>Warehouse:</strong> {production.warehouse_name}
      </Typography>
    </Box>
  );

  const renderMainPanel = () => (
    <Box>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => setCurrentTab(newValue)}
        sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
      >
        <Tab label="Details" />
        <Tab label="Items Grid" />
        <Tab label="Items Spreadsheet" />
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Production Details
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography><strong>Status:</strong> {production.status.toUpperCase()}</Typography>
                <Typography><strong>Warehouse:</strong> {production.warehouse_name}</Typography>
                <Typography><strong>Created By:</strong> {production.created_by_username}</Typography>
                <Typography><strong>Created At:</strong> {format(new Date(production.created_at), 'PPpp')}</Typography>
                {production.notes && (
                  <Typography><strong>Notes:</strong> {production.notes}</Typography>
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Processing Details
              </Typography>
              <Box sx={{ mt: 2 }}>
                {production.confirmed_by_username && (
                  <>
                    <Typography><strong>Confirmed By:</strong> {production.confirmed_by_username}</Typography>
                    <Typography><strong>Confirmed At:</strong> {format(new Date(production.confirmation_date), 'PPpp')}</Typography>
                  </>
                )}
                {production.cancelled_by_username && (
                  <>
                    <Typography><strong>Cancelled By:</strong> {production.cancelled_by_username}</Typography>
                    <Typography><strong>Cancelled At:</strong> {format(new Date(production.cancellation_date), 'PPpp')}</Typography>
                    {production.cancellation_reason && (
                      <Typography><strong>Cancellation Reason:</strong> {production.cancellation_reason}</Typography>
                    )}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        {renderItemsGrid()}
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <Box sx={{ mb: 3 }}>
          <SpreadsheetExportableV2
            data={spreadsheetData}
            title="Production Items"
          />
        </Box>
      </TabPanel>

      {/* Confirm Dialog */}
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>Confirm Production Entry</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to confirm this production entry? 
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmProduction} variant="contained" color="success">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Dialog */}
      <Dialog open={cancelDialog} onClose={() => setCancelDialog(false)}>
        <DialogTitle>Cancel Production Entry</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Are you sure you want to cancel this production entry?
            This action cannot be undone.
          </Typography>
          <TextField
            fullWidth
            label="Cancellation Reason"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialog(false)}>No</Button>
          <Button onClick={handleCancelProduction} variant="contained" color="error">
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  return (
    <FullScreenLayout
      header={renderHeader()}
      summary={renderSummary()}
      mainPanel={renderMainPanel()}
    />
  );
}

export default ProductionDetails; 