import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, TextField, Paper, Stack, IconButton, Chip, Grid, Card, CardContent, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import OptimizedSpreadsheet from './OptimizedSpreadsheet';
import ItemsCacheService from '../../services/itemsCacheService';

function TestOptimizedSpreadsheet() {
  const [newMetric, setNewMetric] = useState('');
  const [metrics, setMetrics] = useState(['inventory', 'replenish', 'order']);
  const [changes, setChanges] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch items when component mounts
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const fetchedItems = await ItemsCacheService.getItems();
        console.log('Fetched items:', {
          length: fetchedItems.length,
          sampleItems: fetchedItems.slice(0, 3),
          hasItem22: fetchedItems.some(item => item.id === 22)
        });
        setItems(fetchedItems);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching items:', error);
        setIsLoading(false);
      }
    };

    fetchItems();
  }, []);

  // Initialize spreadsheet data
  const [spreadsheetData, setSpreadsheetData] = useState(() => {
    const cachedItems = ItemsCacheService.getCachedItems() || [];
    
    const initialData = {};
    cachedItems.forEach(item => {
      if (!item.active || !item.is_valid) return;
      
      initialData[item.id] = {};
      metrics.forEach(metric => {
        initialData[item.id][metric] = Math.floor(Math.random() * 100);
      });
    });
    return initialData;
  });

  const handleAddMetric = () => {
    if (newMetric && !metrics.includes(newMetric)) {
      setMetrics([...metrics, newMetric]);
      
      // Add the new metric to all items with random values
      const updatedData = { ...spreadsheetData };
      Object.keys(updatedData).forEach(itemId => {
        updatedData[itemId][newMetric] = Math.floor(Math.random() * 100);
      });
      
      setSpreadsheetData(updatedData);
      setNewMetric('');
    }
  };

  const handleDeleteMetric = (metricToDelete) => {
    if (metrics.length <= 1) {
      alert("Cannot delete the last metric");
      return;
    }
    
    setMetrics(metrics.filter(metric => metric !== metricToDelete));
    
    // Remove the metric from all items
    const updatedData = { ...spreadsheetData };
    Object.keys(updatedData).forEach(itemId => {
      delete updatedData[itemId][metricToDelete];
    });
    
    setSpreadsheetData(updatedData);
  };

  const handleCellValueChanged = useCallback(({ itemId, metric, newValue, oldValue }) => {
    setSpreadsheetData(prevData => ({
      ...prevData,
      [itemId]: {
        ...prevData[itemId],
        [metric]: newValue
      }
    }));

    // Convert itemId to number if it's a string
    const numericItemId = typeof itemId === 'string' ? parseInt(itemId, 10) : itemId;
    
    // Find item details for the change history
    const item = items.find(i => i.id === numericItemId);
    
    if (!item) {
      console.warn(`Item with id ${itemId} not found in items array. ItemId type: ${typeof itemId}`, {
        searchedId: itemId,
        numericId: numericItemId,
        itemsLength: items.length,
        firstFewItems: items.slice(0, 3)
      });
    }

    // Add change to history
    setChanges(prevChanges => [{
      timestamp: new Date().toLocaleTimeString(),
      itemName: item?.name || 'Unknown Item',
      type: item?.type_name || 'Unknown Type',
      size: item?.size_name || 'Unknown Size',
      brim: item?.brim_name || 'Unknown Brim',
      shape: item?.shape_name || 'Unknown Shape',
      metric,
      oldValue,
      newValue
    }, ...prevChanges]);
  }, [items]);

  const handleRandomize = () => {
    const updatedData = { ...spreadsheetData };
    Object.keys(updatedData).forEach(itemId => {
      metrics.forEach(metric => {
        updatedData[itemId][metric] = Math.floor(Math.random() * 100);
      });
    });
    setSpreadsheetData(updatedData);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={2} sx={{ p: 3 }}>
      <Grid item xs={12} md={9}>
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Stack spacing={3}>
            <Box>
              <h3>Metrics</h3>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  label="New Metric"
                  value={newMetric}
                  onChange={(e) => setNewMetric(e.target.value)}
                  size="small"
                />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddMetric}
                >
                  Add Metric
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {metrics.map(metric => (
                  <Chip
                    key={metric}
                    label={metric}
                    onDelete={() => handleDeleteMetric(metric)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>

            <Button
              variant="outlined"
              onClick={handleRandomize}
              sx={{ alignSelf: 'flex-start' }}
            >
              Randomize All Values
            </Button>
          </Stack>
        </Paper>

        <OptimizedSpreadsheet
          data={spreadsheetData}
          metrics={metrics}
          title="Optimized Multi-Value Spreadsheet"
          onCellValueChanged={handleCellValueChanged}
          hideZeros={false}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <Card sx={{ position: 'sticky', top: 16 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Change History
            </Typography>
            <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
              {changes.map((change, index) => (
                <Paper 
                  key={index} 
                  sx={{ 
                    p: 1, 
                    mb: 1, 
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                    borderLeft: '3px solid #1a2b3c'
                  }}
                >
                  <Typography variant="caption" display="block" color="text.secondary">
                    {change.timestamp}
                  </Typography>
                  <Typography variant="body2">
                    Item: {change.itemName}
                  </Typography>
                  <Typography variant="body2">
                    Type: {change.type}, Size: {change.size}, 
                    Brim: {change.brim}, Shape: {change.shape}
                  </Typography>
                  <Typography variant="body2">
                    {change.metric}: {change.oldValue} → {change.newValue}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default TestOptimizedSpreadsheet; 